import React from 'react';

import AppContainer from '../../containers/App';
import AttributionContainer from '../../containers/Attribution';

type Props = {};

const AttributionPage = (_props: Props) => {
  return (
    <AppContainer>
      <AttributionContainer />
    </AppContainer>
  );
};

export default AttributionPage;
