import React from 'react';
import dc from 'dc';
import { Helmet } from 'react-helmet';
import { observer } from 'mobx-react';

import DailyRhythmChart from '../Charts/DailyRhythmChart';
import DataCountContainer from '../Charts/DataCount';
import DayOfWeekChart from '../Charts/DayOfWeekChart';
import LastTweetsGridContainer from '../Charts/LastTweetsGrid';
import QuotedUserChart from '../Charts/QuotedUserChart';
import RepliedUserChart from '../Charts/RepliedUserChart';
import RetweetedUserChart from '../Charts/RetweetedUserChart';
import TweetTypeChart from '../Charts/TweetTypeChart';
import Twitter from '../../components/TwitterAccount';
import TwitterUserCardContainer from '../TwitterUserCard';
import UsedAppChart from '../Charts/UsedAppChart';
import UsedHashtagsChart from '../Charts/UsedHashtagsChart';
import UsedHostnamesChart from '../Charts/UsedHostnamesChart';
import UsedLanguageChart from '../Charts/UsedLanguageChart';
import VolumeByDateChart from '../Charts/VolumeByDateChart';
import envConfig from '../../config/env';
import useLocalStorage from '../../utils/hooks/useLocalStorage';
import { useAuthStore } from '../../data/models/auth/AuthStore';
import { useTwitterCrossfilterContext } from '../../utils/context/TwitterCrossfilterContext';
import { useTwitterStore } from '../../data/models/twitter/TwitterStore';

type Props = {};

const HomeContainer = (_props: Props) => {
  const twitterStore = useTwitterStore({ useMockStore: true });
  const { crossfilterMockInstance } = useTwitterCrossfilterContext();
  const { isSignedIn } = useAuthStore();

  const [isHelpVisible, setIsHelpVisible] = useLocalStorage(envConfig.localStorageKeyHelpModeDemo, true, { saveInitialIfNone: true });

  const handleToggleIsHelpVisible = () => {
    setIsHelpVisible(prevState => !prevState);
  };

  React.useEffect(() => {
    dc.redrawAll();
  }, []);

  return (
    <>
      <Helmet>
        <title>Analysis of Twitter Accounts</title>
      </Helmet>

      <Twitter
        dailyRhythmChart={<DailyRhythmChart crossfilterInstance={crossfilterMockInstance} twitterStore={twitterStore} />}
        dataCount={
          <DataCountContainer
            crossfilterInstance={crossfilterMockInstance}
            isHelpVisible={isHelpVisible}
            onToggleHelp={handleToggleIsHelpVisible}
          />
        }
        dayOfWeekChart={<DayOfWeekChart crossfilterInstance={crossfilterMockInstance} twitterStore={twitterStore} />}
        isDemoMode={true}
        isHelpVisible={isHelpVisible}
        isSignedIn={isSignedIn}
        lastTweetsGrid={<LastTweetsGridContainer crossfilterInstance={crossfilterMockInstance} twitterStore={twitterStore} />}
        quotedUserChart={<QuotedUserChart crossfilterInstance={crossfilterMockInstance} twitterStore={twitterStore} />}
        repliedUserChart={<RepliedUserChart crossfilterInstance={crossfilterMockInstance} twitterStore={twitterStore} />}
        retweetedUserChart={<RetweetedUserChart crossfilterInstance={crossfilterMockInstance} twitterStore={twitterStore} />}
        title="Analysis of Twitter Accounts"
        tweetTypeChart={<TweetTypeChart crossfilterInstance={crossfilterMockInstance} twitterStore={twitterStore} />}
        twitterUserCard={<TwitterUserCardContainer twitterStore={twitterStore} />}
        usedAppChart={<UsedAppChart crossfilterInstance={crossfilterMockInstance} twitterStore={twitterStore} />}
        usedHashtagsChart={<UsedHashtagsChart crossfilterInstance={crossfilterMockInstance} twitterStore={twitterStore} />}
        usedHostnamesChart={<UsedHostnamesChart crossfilterInstance={crossfilterMockInstance} twitterStore={twitterStore} />}
        usedLanguageChart={<UsedLanguageChart crossfilterInstance={crossfilterMockInstance} twitterStore={twitterStore} />}
        volumeByDateChart={<VolumeByDateChart crossfilterInstance={crossfilterMockInstance} twitterStore={twitterStore} />}
      />
    </>
  );
};

export default observer(HomeContainer);
