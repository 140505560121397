import React from 'react';

// Types
import { HTMLAttributes } from 'react';

type Props = {
  label: string;
} & HTMLAttributes<HTMLSpanElement>;

const Chart2AxisYAxis = ({ label, ...rest }: Props) => {
  return (
    <span
      className="absolute left-0 top-1/2 -ml-4 sm:-ml-2 font-semibold text-gray-400 tracking-wide"
      style={{ transform: 'rotate(-90deg)' }}
      {...rest}
    >
      {label}
    </span>
  );
};

export default Chart2AxisYAxis;
