import React from 'react';

import ContentPageHeader from '../ContentPageItems/ContentPageHeader';
import ContentPageLayout from '../ContentPageItems/ContentPageLayout';
import ContentPageParagraph from '../ContentPageItems/ContentPageParagraph';
import ContentPageSection from '../ContentPageItems/ContentPageSection';
import ContentPageSectionHeader from '../ContentPageItems/ContentPageSectionHeader';

type Props = {};

const TermsOfService = (_props: Props) => {
  return (
    <ContentPageLayout>
      <ContentPageHeader>Terms of Service</ContentPageHeader>
      <ContentPageParagraph>Last updated: 28 August 2019</ContentPageParagraph>

      <ContentPageSection>
        <ContentPageSectionHeader>Introduction</ContentPageSectionHeader>

        <ContentPageParagraph>Welcome to Accountanalysis by Luca Hammer (“Company”, “we”, “our”, “us”)! </ContentPageParagraph>
        <ContentPageParagraph>
          These Terms of Service (“Terms”, “Terms of Service”) govern your use of our web pages located at https://accountanalysis.app
          operated by Luca Hammer.
        </ContentPageParagraph>
        <ContentPageParagraph>
          Our Privacy Policy also governs your use of our Service and explains how we collect, safeguard and disclose information that
          results from your use of our web pages. Please read it here https://accountanalysis.app/privacy.
        </ContentPageParagraph>
        <ContentPageParagraph>
          Your agreement with us includes these Terms and our Privacy Policy (“Agreements”). You acknowledge that you have read and
          understood Agreements, and agree to be bound of them.
        </ContentPageParagraph>
        <ContentPageParagraph>
          If you do not agree with (or cannot comply with) Agreements, then you may not use the Service, but please let us know by emailing
          at contact@lucahammer.com so we can try to find a solution. These Terms apply to all visitors, users and others who wish to access
          or use Service.
        </ContentPageParagraph>
        <ContentPageParagraph>Thank you for being responsible.</ContentPageParagraph>
      </ContentPageSection>

      <ContentPageSection>
        <ContentPageSectionHeader>Communications</ContentPageSectionHeader>

        <ContentPageParagraph>
          You do not receive any emails from us unless per default. If you want to receive updates about the services (eg. changes to the
          terms of service or the privacy policy), you need to enter your address on your account page. If you want to receive information
          about new features promotions and other information, you need to check that option in your account page.
        </ContentPageParagraph>
      </ContentPageSection>

      <ContentPageSection>
        <ContentPageSectionHeader>Purchases</ContentPageSectionHeader>

        <ContentPageParagraph>
          If you wish to purchase any product or service made available through Service (“Purchase”), you may be asked to supply certain
          information relevant to your Purchase including, without limitation, your credit card number, the expiration date of your credit
          card and your billing address.
        </ContentPageParagraph>
        <ContentPageParagraph>
          You represent and warrant that: (i) you have the legal right to use any credit card(s) or other payment method(s) in connection
          with any Purchase; and that (ii) the information you supply to us is true, correct and complete.
        </ContentPageParagraph>
        <ContentPageParagraph>
          We use a third party service for the purpose of facilitating payment and the completion of purchases. The whole subscription and
          payment process is handled by Chargebee.com. You can use their self-service portal to cancel your subscription or update your
          data. We have access to the relevant parts of the data you provide to Chargebee, but not full credit card information.
        </ContentPageParagraph>
        <ContentPageParagraph>
          We reserve the right to refuse or cancel your order at any time for reasons including but not limited to: product or service
          availability, errors in the description or price of the product or service, error in your order or other reasons.
        </ContentPageParagraph>
        <ContentPageParagraph>
          We reserve the right to refuse or cancel your order if fraud or an unauthorized or illegal transaction is suspected.
        </ContentPageParagraph>
      </ContentPageSection>

      <ContentPageSection>
        <ContentPageSectionHeader>Subscriptions</ContentPageSectionHeader>

        <ContentPageParagraph>
          Some parts of Service are billed on a subscription basis (“Subscription(s)”). You will be billed in advance on a recurring and
          periodic basis (“Billing Cycle”). Billing cycles are set either on a monthly or annual basis, depending on the type of
          subscription plan you select when purchasing a Subscription.
        </ContentPageParagraph>
        <ContentPageParagraph>
          At the end of each Billing Cycle, your Subscription will automatically renew under the exact same conditions unless you cancel it
          or Luca Hammer cancels it. You may cancel your Subscription renewal either through your online account management page or by
          contacting Luca Hammer customer support team.
        </ContentPageParagraph>
        <ContentPageParagraph>
          A valid payment method, including credit card or PayPal, is required to process the payment for your subcription. By submitting
          such payment information, you automatically authorize Chargebee to charge all Subscription fees incurred through your account to
          any such payment instruments for Luca Hammer.
        </ContentPageParagraph>
        <ContentPageParagraph>
          Should automatic billing fail to occur for any reason, Chargebee or Luca Hammer will issue an electronic invoice indicating that
          you must proceed manually, within a certain deadline date, with the full payment corresponding to the billing period as indicated
          on the invoice.
        </ContentPageParagraph>
      </ContentPageSection>

      <ContentPageSection>
        <ContentPageSectionHeader>Fee Changes</ContentPageSectionHeader>

        <ContentPageParagraph>
          Luca Hammer, in its sole discretion and at any time, may modify Subscription fees for the Subscriptions. Any Subscription fee
          change will become effective at the end of the then-current Billing Cycle.
        </ContentPageParagraph>
        <ContentPageParagraph>
          Luca Hammer will provide you with a reasonable prior notice of any change in Subscription fees to give you an opportunity to
          terminate your Subscription before such change becomes effective.
        </ContentPageParagraph>
        <ContentPageParagraph>
          Your continued use of Service after Subscription fee change comes into effect constitutes your agreement to pay the modified
          Subscription fee amount.
        </ContentPageParagraph>
      </ContentPageSection>

      <ContentPageSection>
        <ContentPageSectionHeader>Refund</ContentPageSectionHeader>

        <ContentPageParagraph>
          If there is a problem with your Contract, please contact us (contact@lucahammer.com) with 7 days after the start date, so we can
          find a solution or issue a refund.
        </ContentPageParagraph>
      </ContentPageSection>

      <ContentPageSection>
        <ContentPageSectionHeader>Prohibited Uses</ContentPageSectionHeader>

        <ContentPageParagraph>
          You may use Service only for lawful purposes and in accordance with Terms. You agree not to use Service:
        </ContentPageParagraph>
        <ContentPageParagraph>
          (a) In any way that violates any applicable national or international law or regulation.
        </ContentPageParagraph>
        <ContentPageParagraph>
          (b) For the purpose of exploiting, harming, or attempting to exploit or harm minors in any way by exposing them to inappropriate
          content or otherwise.
        </ContentPageParagraph>
        <ContentPageParagraph>
          (c) In any way that infringes upon the rights of others, or in any way is illegal, threatening, fraudulent, or harmful, or in
          connection with any unlawful, illegal, fraudulent, or harmful purpose or activity.
        </ContentPageParagraph>
        <ContentPageParagraph>
          (d) To engage in any other conduct that restricts or inhibits anyone’s use or enjoyment of Service, or which, as determined by us,
          may harm or offend Luca Hammer or users of Service or expose them to liability.
        </ContentPageParagraph>
        <ContentPageParagraph>Additionally, you agree not to:</ContentPageParagraph>
        <ContentPageParagraph>
          (a) Use Service in any manner that could disable, overburden, damage, or impair Service or interfere with any other party’s use of
          Service, including their ability to engage in real time activities through Service.
        </ContentPageParagraph>
        <ContentPageParagraph>
          (b) Use any device, software, or routine that interferes with the proper working of Service.
        </ContentPageParagraph>
        <ContentPageParagraph>
          (c) Introduce any viruses, trojan horses, worms, logic bombs, or other material which is malicious or technologically harmful.
        </ContentPageParagraph>
        <ContentPageParagraph>
          (d) Attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of Service, the server on which Service
          is stored, or any server, computer, or database connected to Service.
        </ContentPageParagraph>
        <ContentPageParagraph>
          (e) Attack Service via a denial-of-service attack or a distributed denial-of-service attack.
        </ContentPageParagraph>
        <ContentPageParagraph>(f) Otherwise attempt to interfere with the proper working of Service.</ContentPageParagraph>
      </ContentPageSection>

      <ContentPageSection>
        <ContentPageSectionHeader>Analytics</ContentPageSectionHeader>

        <ContentPageParagraph>We may use third-party Service Providers to monitor and analyze the use of our Service.</ContentPageParagraph>
        <ContentPageParagraph>
          Matomo. Matomo is a web analytics service. You can visit their Privacy Policy page here: https://matomo.org/privacy-policy
        </ContentPageParagraph>
      </ContentPageSection>

      <ContentPageSection>
        <ContentPageSectionHeader>Error Reporting and Feedback</ContentPageSectionHeader>

        <ContentPageParagraph>
          You may provide us directly at contact@lucahammer.com with information and feedback concerning errors, suggestions for
          improvements, ideas, problems, complaints, and other matters related to our Service (“Feedback”). You acknowledge and agree that:
          (i) you shall not retain, acquire or assert any intellectual property right or other right, title or interest in or to the
          Feedback; (ii) Luca Hammer may have development ideas similar to the Feedback; (iii) Feedback does not contain confidential
          information or proprietary information from you or any third party; and (iv) Luca Hammer is not under any obligation of
          confidentiality with respect to the Feedback. In the event the transfer of the ownership to the Feedback is not possible due to
          applicable mandatory laws, you grant Luca Hammer and its affiliates an exclusive, transferable, irrevocable, free-of-charge,
          sub-licensable, unlimited and perpetual right to use (including copy, modify, create derivative works, publish, distribute and
          commercialize) Feedback in any manner and for any purpose.
        </ContentPageParagraph>
      </ContentPageSection>

      <ContentPageSection>
        <ContentPageSectionHeader>Termination</ContentPageSectionHeader>

        <ContentPageParagraph>
          We may terminate or suspend your account and bar access to Service immediately, without prior notice or liability, under our sole
          discretion, for any reason whatsoever and without limitation, including but not limited to a breach of Terms.
        </ContentPageParagraph>
        <ContentPageParagraph>
          If you wish to terminate your account, you can do so on your account page. That will delete your user object from our database.
          Please be aware that not all data can be deleted if you signed up for a Subscription because of retention laws and to prevent
          fraud.
        </ContentPageParagraph>
        <ContentPageParagraph>
          All provisions of Terms which by their nature should survive termination shall survive termination, including, without limitation,
          ownership provisions, warranty disclaimers, indemnity and limitations of liability.
        </ContentPageParagraph>
      </ContentPageSection>

      <ContentPageSection>
        <ContentPageSectionHeader>Changes To Service</ContentPageSectionHeader>

        <ContentPageParagraph>
          We reserve the right to withdraw or amend our Service, and any service or material we provide via Service, in our sole discretion
          without notice. We will not be liable if for any reason all or any part of Service is unavailable at any time or for any period.
          From time to time, we may restrict access to some parts of Service, or the entire Service, to users, including registered users.
        </ContentPageParagraph>
      </ContentPageSection>

      <ContentPageSection>
        <ContentPageSectionHeader>Amendments To Terms</ContentPageSectionHeader>

        <ContentPageParagraph>
          We may amend Terms at any time by posting the amended terms on this site. It is your responsibility to review these Terms
          periodically.
        </ContentPageParagraph>
        <ContentPageParagraph>
          Your continued use of the Platform following the posting of revised Terms means that you accept and agree to the changes. You are
          expected to check this page frequently so you are aware of any changes, as they are binding on you.
        </ContentPageParagraph>
        <ContentPageParagraph>
          By continuing to access or use our Service after any revisions become effective, you agree to be bound by the revised terms. If
          you do not agree to the new terms, you are no longer authorized to use Service.
        </ContentPageParagraph>
      </ContentPageSection>

      <ContentPageSection>
        <ContentPageSectionHeader>Contact Us</ContentPageSectionHeader>

        <ContentPageParagraph>
          Please send your feedback, comments, requests for technical support by email: contact@lucahammer.com.
        </ContentPageParagraph>
      </ContentPageSection>
    </ContentPageLayout>
  );
};

export default TermsOfService;
