import { types } from 'mobx-state-tree';

// Types
import { Instance } from 'mobx-state-tree';
import { TwitterTweetType } from '../../../types/twitter';

const TwitterTweetModel = types.model('TwitterTweet', {
  date: types.string,
  dayOfWeek: types.number,
  id: types.string,
  language: types.string,
  length: types.number,
  name: types.string,
  profileImageUrl: types.string,
  quotedUser: types.maybeNull(types.string),
  repliedUser: types.maybeNull(types.string),
  retweetedUser: types.maybeNull(types.string),
  text: types.maybeNull(types.string),
  timeOfDay: types.string,
  timestamp: types.string,
  type: types.enumeration('Type', [
    TwitterTweetType.Quote,
    TwitterTweetType.Reply,
    TwitterTweetType.Retweet,
    TwitterTweetType.SelfReply,
    TwitterTweetType.Tweet,
  ]),
  url: types.string,
  usedApp: types.string,
  usedHashtags: types.array(types.string),
  usedHostnames: types.array(types.string),
  username: types.string,
});

export interface TwitterTweet extends Instance<typeof TwitterTweetModel> {}

export default TwitterTweetModel;
