import React from 'react';

import AccountContainer from '../../containers/Account';
import AppContainer from '../../containers/App';

// Types

type Props = {};

const AccountPage = (_props: Props) => {
  return (
    <AppContainer>
      <AccountContainer />
    </AppContainer>
  );
};

export default AccountPage;
