import { describe, Type } from '@mrboolean/envconfig';

type EnvConfig = {
  appName: string;
  backendUrl: string;
  chargebeeProPlan: string;
  dateFormatLong: string;
  dateFormatShort: string;
  isDev: boolean;
  isProduction: boolean;
  localStorageKeyHelpMode: string;
  localStorageKeyHelpModeDemo: string;
  twitterChartsCapLarge: number;
  twitterChartsCapSmall: number;
  twitterChartsContentLoaderSpeed: number;
  twitterChartsGap: number;
  twitterDefaultProfilePicture: string;
  twitterMaxFreeFetches: number;
  twitterMaxFreeTweets: number;
  twitterMaxProTweets: number;
  twitterUrl: number;
  twitterMaxDownloads: number;
  chargebeeSite: string;
};

const envConfig: EnvConfig = describe({
  appName: {
    name: 'REACT_APP_APP_NAME',
    type: Type.STRING,
    isRequired: false,
    standard: 'accountanalysis',
  },
  backendUrl: {
    name: 'REACT_APP_BACKEND_URL',
    isRequired: true,
    sanitize: (value: any): string => (value !== 'production' ? (value as string) : ''),
  },
  chargebeeSite: {
    name: 'REACT_APP_CHARGEBEE_SITE',
    type: Type.STRING,
    isRequired: true,
    standard: 'lucahammer-test',
  },
  // Currently, we only have one pro plan so this works.
  chargebeeProPlan: {
    name: 'REACT_APP_CHARGEBEE_PRO_PLAN',
    type: Type.STRING,
    isRequired: false,
    standard: 'pro-v1',
  },
  dateFormatLong: {
    name: 'REACT_APP_DATE_FORMAT_LONG',
    type: Type.STRING,
    isRequired: false,
    standard: 'YYYY-MM-DD',
  },
  isDev: {
    name: 'NODE_ENV',
    isRequired: true,
    sanitize: (value: any): boolean => value !== 'production',
  },
  isProduction: {
    name: 'NODE_ENV',
    isRequired: true,
    sanitize: (value: any): boolean => value === 'production',
  },
  localStorageKeyHelpMode: {
    name: 'LOCAL_STORAGE_KEY_HELP_MODE',
    type: Type.STRING,
    isRequired: false,
    standard: 'HELP_MODE',
  },
  localStorageKeyHelpModeDemo: {
    name: 'LOCAL_STORAGE_KEY_HELP_MODE_DEMO',
    type: Type.STRING,
    isRequired: false,
    standard: 'HELP_MODE_DEMO',
  },
  twitterChartsCapLarge: {
    name: 'REACT_APP_TWITTER_CHARTS_CAP_LARGE',
    type: Type.NUMBER,
    isRequired: false,
    standard: 10,
  },
  twitterChartsCapSmall: {
    name: 'REACT_APP_TWITTER_CHARTS_CAP_SMALL',
    type: Type.NUMBER,
    isRequired: false,
    standard: 4,
  },
  twitterChartsContentLoaderSpeed: {
    name: 'REACT_APP_TWITTER_CHARTS_CONTENT_LOADER_SPEED',
    type: Type.NUMBER,
    isRequired: false,
    standard: 2,
  },
  twitterChartsGap: {
    name: 'REACT_APP_TWITTER_CHARTS_GAP',
    type: Type.NUMBER,
    isRequired: false,
    standard: 2,
  },
  twitterDefaultProfilePicture: {
    name: 'REACT_APP_TWITTER_DEFAULT_PROFILE_PICTURE',
    type: Type.STRING,
    isRequired: false,
    standard: 'https://abs.twimg.com/sticky/default_profile_images/default_profile_normal.png',
  },
  twitterMaxFreeFetches: {
    name: 'REACT_APP_TWITTER_MAX_FREE_FETCHES',
    type: Type.NUMBER,
    isRequired: false,
    standard: 5,
  },
  twitterMaxFreeTweets: {
    name: 'REACT_APP_TWITTER_MAX_FREE_TWEETS',
    type: Type.NUMBER,
    isRequired: false,
    standard: 600,
  },
  twitterMaxProTweets: {
    name: 'REACT_APP_TWITTER_MAX_PRO_TWEETS',
    type: Type.NUMBER,
    isRequired: false,
    standard: 3200,
  },
  twitterUrl: {
    name: 'REACT_APP_TWITTER_URL',
    type: Type.STRING,
    isRequired: false,
    standard: 'https://twitter.com',
  },
  twitterMaxDownloads: {
    name: 'REACT_APP_TWITTER_MAX_DOWNLOADS',
    type: Type.NUMBER,
    isRequired: false,
    standard: '50000',
  },
});

export default envConfig;
