import React from 'react';

import Link from '../Link';

import FooterLink from './FooterLink';
import TwitterSignInButton from '../TwitterSignInButton';
import routes from '../../config/routing/routes';

type Props = {
  handleLogout: () => void;
  isAbout: boolean;
  isAccount: boolean;
  isAttribution: boolean;
  isContact: boolean;
  isImprint: boolean;
  isPlans: boolean;
  isPrivacyPolicy: boolean;
  isSignedIn: boolean;
  isTermsOfService: boolean;
};

const Footer = (props: Props) => {
  const {
    handleLogout,
    isAbout,
    isAccount,
    isAttribution,
    isContact,
    isImprint,
    isSignedIn,
    isPlans,
    isPrivacyPolicy,
    isTermsOfService,
  } = props;

  return (
    <footer className="bg-white py-8 mt-8 lg:mt-16 w-full">
      <div className="container mx-auto px-4">
        <div className="text-lg">
          Not affiliated with Twitter. Made by{' '}
          <Link title="Luca's Twitter Profile" url="https://www.twitter.com/Luca">
            <span className="text-gray-800 hover:text-brand">@Luca</span>
          </Link>
          .
        </div>

        <nav className="flex flex-col sm:flex-row justify-between w-full mt-4">
          <div className="mb-2 w-full">
            <p className="uppercase text-gray-400 font-medium text-lg sm:mb-2">You</p>

            <ul className="text-xs mb-3">
              {isSignedIn && (
                <li className="inline-block mr-2 sm:block sm:mr-0">
                  <FooterLink isActive={isAccount} url={routes.account}>
                    My Account
                  </FooterLink>
                </li>
              )}

              {isSignedIn && (
                <li className="mt-2 inline-block mr-2 sm:block sm:mr-0">
                  <FooterLink isActive={false} onClick={handleLogout} url={routes.home}>
                    Sign Out
                  </FooterLink>
                </li>
              )}

              {!isSignedIn && (
                <>
                  <li className="mt-4 inline-block mr-2 sm:block sm:mr-0">
                    <TwitterSignInButton />
                  </li>
                </>
              )}
            </ul>
          </div>

          <div className="w-full">
            <p className="uppercase text-gray-400 font-medium text-lg sm:mb-2">We</p>

            <ul className="text-xs mb-3">
              <li className="mt-2 inline-block mr-2 sm:block sm:mr-0">
                <FooterLink isActive={false} url="https://www.twitter.com/accountanalysis">
                  @accountanalysis
                </FooterLink>
              </li>
              <li className="mt-2 inline-block mr-2 sm:block sm:mr-0">
                <FooterLink isActive={isAbout} url={routes.about}>
                  About
                </FooterLink>
              </li>
              <li className="mt-2 inline-block mr-2 sm:block sm:mr-0">
                <FooterLink isActive={false} url="https://lucahammer.com/tag/twitter/">
                  Blog
                </FooterLink>
              </li>
              <li className="mt-2 inline-block mr-2 sm:block sm:mr-0">
                <FooterLink isActive={isContact} url={routes.contact}>
                  Contact
                </FooterLink>
              </li>
              <li className="mt-2 inline-block mr-2 sm:block sm:mr-0">
                <FooterLink isActive={isPlans} url={routes.plans}>
                  Pricing
                </FooterLink>
              </li>
            </ul>
          </div>

          <div className="w-full">
            <p className="uppercase text-gray-400 font-medium text-lg sm:mb-2">Legal</p>

            <ul className="text-xs mb-3">
              <li className="mt-2 inline-block mr-2 sm:block sm:mr-0">
                <FooterLink isActive={isAttribution} url={routes.attribution}>
                  Attribution
                </FooterLink>
              </li>

              <li className="mt-2 inline-block mr-2 sm:block sm:mr-0">
                <FooterLink isActive={isTermsOfService} url={routes.termsOfService}>
                  Terms of Service
                </FooterLink>
              </li>

              <li className="mt-2 inline-block mr-2 sm:block sm:mr-0">
                <FooterLink isActive={isPrivacyPolicy} url={routes.privacyPolicy}>
                  Privacy Policy
                </FooterLink>
              </li>

              <li className="mt-2 inline-block mr-2 sm:block sm:mr-0">
                <FooterLink isActive={isImprint} url={routes.imprint}>
                  Imprint
                </FooterLink>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </footer>
  );
};

export default Footer;
