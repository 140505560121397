import React from 'react';
import Helmet from 'react-helmet';

import Imprint from '../../components/Imprint';

type Props = {};

const ImprintContainer = (_props: Props) => {
  return (
    <>
      <Helmet>
        <title>Imprint</title>
      </Helmet>

      <Imprint />
    </>
  );
};

export default ImprintContainer;
