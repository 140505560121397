import React from 'react';

import cx from '../../../../utils/styling/cx';

// Types
import { HTMLAttributes } from 'react';

type Props = {
  isDisabled?: boolean;
  onClick: () => void;
  size?: 'sm' | 'md';
} & HTMLAttributes<HTMLButtonElement>;

const LoadMoreButton = React.forwardRef<HTMLButtonElement, Props>(({ children, className, isDisabled, onClick, ...rest }, ref) => {
  return (
    <button
      className={cx(
        {
          'cursor-not-allowed': isDisabled,
          'hover:bg-gray-400': !isDisabled,
          'opacity-50': isDisabled,
        },
        'bg-gray-200 text-gray-800 font-medium py-2 px-4 rounded-r',
        className,
      )}
      onClick={!isDisabled ? onClick : undefined}
      ref={ref}
      type="button"
      {...rest}
    >
      {children}
    </button>
  );
});

export default LoadMoreButton;
