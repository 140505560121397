import React from 'react';
import { faCertificate, faEgg, faLock, faLockOpen } from '@fortawesome/free-solid-svg-icons';

import ChartIconGroup from '../../Chart/ChartIconGroup';
import Icon from '../../Icon';
import Tooltip from '../../Tooltip';

type Props = {
  hasDefaultProfileImage: boolean;
  isProtected: boolean;
  isVerified: boolean;
  username: string;
};

const TwitterUserCardMeta = ({ hasDefaultProfileImage, isProtected, isVerified, username }: Props) => {
  const defaultProfileImageText = hasDefaultProfileImage
    ? `@${username} has a default profile picture.`
    : `@${username} has changed their profile picture.`;
  const protectedAccountText = isProtected ? 'Protected Account' : 'Unprotected Account';
  const verifiedAccountText = isVerified ? 'Verified Account' : 'Unverified Account';

  return (
    <>
      <ChartIconGroup className="hidden md:block xl:hidden">
        <Tooltip ariaLabel={defaultProfileImageText} label={defaultProfileImageText}>
          <Icon icon={faEgg} isDisabled={!hasDefaultProfileImage} size="lg" />
        </Tooltip>

        <Tooltip ariaLabel={protectedAccountText} label={protectedAccountText}>
          <Icon disabledIcon={faLockOpen} icon={faLock} isDisabled={!isProtected} size="lg" />
        </Tooltip>

        <Tooltip ariaLabel={verifiedAccountText} label={verifiedAccountText}>
          <Icon icon={faCertificate} isDisabled={!isVerified} size="lg" />
        </Tooltip>
      </ChartIconGroup>

      <ChartIconGroup className="md:hidden xl:block">
        <Tooltip ariaLabel={defaultProfileImageText} label={defaultProfileImageText}>
          <Icon icon={faEgg} isDisabled={!hasDefaultProfileImage} />
        </Tooltip>

        <Tooltip ariaLabel={protectedAccountText} label={protectedAccountText}>
          <Icon disabledIcon={faLockOpen} icon={faLock} isDisabled={!isProtected} />
        </Tooltip>

        <Tooltip ariaLabel={verifiedAccountText} label={verifiedAccountText}>
          <Icon icon={faCertificate} isDisabled={!isVerified} />
        </Tooltip>
      </ChartIconGroup>
    </>
  );
};

export default TwitterUserCardMeta;
