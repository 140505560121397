import React from 'react';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import Footer from '../../components/Footer';
import isRoute from '../../utils/routing/isRoute';
import routes from '../../config/routing/routes';
import { useAuthStore } from '../../data/models/auth/AuthStore';

// Types
import { RouteComponentProps } from 'react-router-dom';

type Props = {} & RouteComponentProps;

const FooterContainer = ({ history, match }: Props) => {
  const authStore = useAuthStore();

  const isAbout = isRoute(match, routes.about);
  const isAccount = isRoute(match, routes.account);
  const isAttribution = isRoute(match, routes.attribution);
  const isContact = isRoute(match, routes.contact);
  const isImprint = isRoute(match, routes.imprint);
  const isPlans = isRoute(match, routes.plans);
  const isPrivacyPolicy = isRoute(match, routes.privacyPolicy);
  const isSignedIn = authStore.isSignedIn;
  const isTermsOfService = isRoute(match, routes.termsOfService);

  const handleLogout = () => {
    authStore.signOut();

    history.push('/');
  };

  return (
    <Footer
      handleLogout={handleLogout}
      isAbout={isAbout}
      isAccount={isAccount}
      isAttribution={isAttribution}
      isContact={isContact}
      isImprint={isImprint}
      isPlans={isPlans}
      isPrivacyPolicy={isPrivacyPolicy}
      isSignedIn={isSignedIn}
      isTermsOfService={isTermsOfService}
    />
  );
};

export default withRouter(observer(FooterContainer));
