import React from 'react';

import HeaderAnalyzeTwitterStreamInput from './HeaderAnalyzeTwitterStreamInput';
import HeaderLayout from '../../Header/HeaderLayout';
import TwitterStreamHeaderConnectionIndicator from './TwitterStreamHeaderConnectionIndicator';

// Types
import { SyntheticEvent } from 'react';

type Props = {
  isConnected: boolean;
  isLoadingUser: boolean;
  isSignedIn: boolean;
  isSubscribed: boolean;
  onAnalyzeClick: (event: SyntheticEvent<HTMLFormElement>) => void;
  onStartClick: () => void;
  onStopClick: () => void;
  onTermChange: (event: SyntheticEvent<HTMLInputElement>) => void;
  term: string;
};

const TwitterStreamHeader = (props: Props) => {
  const { isConnected, isLoadingUser, isSignedIn, isSubscribed, onAnalyzeClick, onStartClick, onStopClick, onTermChange, term } = props;

  return (
    <HeaderLayout isSignInButtonVisible={!isLoadingUser && !isSignedIn} isSubscribed={isSubscribed}>
      {isSignedIn && (
        <>
          <TwitterStreamHeaderConnectionIndicator isConnected={isConnected} onStartClick={onStartClick} onStopClick={onStopClick} />

          <HeaderAnalyzeTwitterStreamInput onAnalyzeClick={onAnalyzeClick} onTermChange={onTermChange} isTwitterRoute={false} term={term} />
        </>
      )}
    </HeaderLayout>
  );
};

export default TwitterStreamHeader;
