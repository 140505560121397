import React from 'react';

import AppContainer from '../../containers/App';
import ImprintContainer from '../../containers/Imprint';

type Props = {};

const ImprintPage = (_props: Props) => {
  return (
    <AppContainer>
      <ImprintContainer />
    </AppContainer>
  );
};

export default ImprintPage;
