// Types
import { match as Match } from 'react-router-dom';

const isRoute = (match: Match<any>, route: string) => {
  if (match.path === route) {
    return true;
  }

  return false;
};

export default isRoute;
