import React from 'react';
import { withRouter } from 'react-router-dom';

import AppContainer from '../../containers/App';
import TwitterStreamContainer from '../../containers/TwitterStream';

// types
import { RouteComponentProps } from 'react-router-dom';

type Params = {
  term: string;
};

type Props = {} & RouteComponentProps<Params>;

const TwitterStreamPage = ({ match }: Props) => {
  const term = match.params.term;

  return (
    <AppContainer isNotRenderingHeader={true}>
      <TwitterStreamContainer initialTerm={term} />
    </AppContainer>
  );
};

export default withRouter(TwitterStreamPage);
