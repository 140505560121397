import { parse } from 'date-fns';

const getLocalizedDate = (timestamp: string) => {
  // Thu Sep 05 15:32:25 +0000 2019
  // 'EEE MMM dd HH:mm:ss xxxx yyyy'
  const date = parse(timestamp);
  return date;
};

export default getLocalizedDate;
