import React from 'react';
import { Route, Switch } from 'react-router-dom';

import AboutPage from '../../pages/About';
import AccountPage from '../../pages/Account';
import AttributionPage from '../../pages/Attribution';
import ContactPage from '../../pages/Contact';
import Error404Page from '../../pages/Error404';
import HomePage from '../../pages/Home';
import ImprintPage from '../../pages/Imprint';
import PlansPage from '../../pages/Plans';
import PrivacyPolicyPage from '../../pages/PrivacyPolicy';
import ProtectedRoute from '../../utils/routing/ProtectedRoute';
import SignInPage from '../../pages/SignIn';
import TermsOfServicePage from '../../pages/TermsOfService';
import TwitterAccountPage from '../../pages/TwitterAccount';
import TwitterStreamPage from '../../pages/TwitterStream';
import routes from './routes';
import envConfig from '../env';

const RoutesProvider = () => {
  return (
    <Switch>
      <Route component={AboutPage} exact={true} path={routes.about} />

      <ProtectedRoute component={AccountPage} exact={true} path={routes.account} />

      <Route component={AttributionPage} exact={true} path={routes.attribution} />

      <Route component={ContactPage} exact={true} path={routes.contact} />

      <Route component={HomePage} exact={true} path={routes.home} />

      <Route component={ImprintPage} exact={true} path={routes.imprint} />

      <Route component={PlansPage} exact={true} path={routes.plans} />

      <Route component={PrivacyPolicyPage} exact={true} path={routes.privacyPolicy} />

      <Route component={SignInPage} exact={true} path={routes.signIn} />

      <Route component={TermsOfServicePage} exact={true} path={routes.termsOfService} />

      <ProtectedRoute component={TwitterAccountPage} exact={true} path={routes.withParams.twitterAccount} />

      {envConfig.isDev && <ProtectedRoute component={TwitterStreamPage} exact={true} path={routes.withParams.twitterStream} />}

      <Route component={Error404Page} />
    </Switch>
  );
};

export default RoutesProvider;
