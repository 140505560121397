import React from 'react';
import cx from '../../utils/styling/cx';

import Button from '../Button';
import ChartHeading from './ChartHeading';
import ChartIconGroup from './ChartIconGroup';

// Types
import { ReactNode } from 'react';

type Props = {
  contentLoader?: ReactNode;
  isAccountProtectedMessageShowing?: boolean;
  isLoading?: boolean;
  isNoTweetsMessageShowing: boolean;
  isWaitingForFirstTweetMessageShowing?: boolean;
  onReset: () => void;
  title: string;
};

const Chart = React.forwardRef<HTMLElement, Props>((props, ref) => {
  const {
    contentLoader,
    isAccountProtectedMessageShowing,
    isLoading,
    isNoTweetsMessageShowing,
    isWaitingForFirstTweetMessageShowing,
    onReset,
    title,
  } = props;

  return (
    <div className="w-full flex flex-col relative">
      <ChartHeading>{title}</ChartHeading>

      {isLoading && !isWaitingForFirstTweetMessageShowing && <figure>{contentLoader}</figure>}

      {isWaitingForFirstTweetMessageShowing && (
        <div className="mx-auto flex flex-col" style={{ minHeight: 200 }}>
          <span className="mx-auto font-semibold text-gray-400 tracking-wide" style={{ paddingTop: 60 }}>
            Waiting for first Tweet to arrive.
          </span>
        </div>
      )}

      {isAccountProtectedMessageShowing && !isLoading && (
        <div className="mx-auto flex flex-col max-w-md lg:max-w-2xs xl:max-w-xs" style={{ minHeight: 200 }}>
          <span className="mx-auto font-semibold text-gray-400 tracking-wide pt-12 lg:pt-8" /* style={{ paddingTop: 20 }} */>
            {`Can't show tweets. This account is protected and hasn't granted you permission to follow them.`}
          </span>
        </div>
      )}

      {isNoTweetsMessageShowing && !isLoading && (
        <div className="mx-auto flex flex-col" style={{ minHeight: 200 }}>
          <span className="mx-auto font-semibold text-gray-400 tracking-wide" style={{ paddingTop: 60 }}>
            User has no tweets.
          </span>
        </div>
      )}

      <figure
        className={cx(
          { hidden: isLoading || isAccountProtectedMessageShowing || isNoTweetsMessageShowing || isWaitingForFirstTweetMessageShowing },
          'dc-chart',
        )}
        ref={ref}
      >
        <ChartIconGroup className="reset" style={{ display: 'none' }}>
          <Button onClick={onReset} size="sm">
            Reset
          </Button>
        </ChartIconGroup>
      </figure>
    </div>
  );
});

export default Chart;
