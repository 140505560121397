import React from 'react';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import TwitterAccountHeader from '../../../components/TwitterAccount/TwitterAccountHeader';
import isRoute from '../../../utils/routing/isRoute';
import routes from '../../../config/routing/routes';
import { useAuthStore } from '../../../data/models/auth/AuthStore';
import { useTwitterCrossfilterContext } from '../../../utils/context/TwitterCrossfilterContext';
import { useTwitterStore } from '../../../data/models/twitter/TwitterStore';

// Types
import { RouteComponentProps } from 'react-router-dom';
import { SyntheticEvent } from 'react';

type Props = {} & RouteComponentProps;

const TwitterAccountHeaderContainer = ({ history, match }: Props) => {
  const { isSignedIn, isSubscribed, userFetched } = useAuthStore();
  const { isFetchMoreDisabled, tweets, tweetsLoadingMore, username } = useTwitterStore();
  const { crossfilterInstance, fetchTweets } = useTwitterCrossfilterContext();

  const [nextUsername, setNextUsername] = React.useState<string>('');

  const isAccountRoute = isRoute(match, routes.account);
  const isTwitterRoute = isRoute(match, routes.withParams.twitterAccount);
  const tweetsCount = tweets.length;

  React.useEffect(() => {
    setNextUsername(username || '');
  }, [username]);

  const handleAnalyzeClick = (event: SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();

    const cleanedUsername = nextUsername.replace('@', '');

    setNextUsername(cleanedUsername);

    history.push(`${routes.twitterAccount}/${cleanedUsername}`);
  };

  const handleLoadMore = () => {
    if (!isFetchMoreDisabled) {
      fetchTweets(crossfilterInstance);
    }
  };

  const handleUsernameChange = (event: SyntheticEvent<HTMLInputElement>) => {
    setNextUsername(event.currentTarget.value);
  };

  return (
    <TwitterAccountHeader
      handleAnalyzeClick={handleAnalyzeClick}
      handleLoadMore={handleLoadMore}
      handleUsernameChange={handleUsernameChange}
      isAccountRoute={isAccountRoute}
      isLoadMoreDisabled={isFetchMoreDisabled}
      isLoadingMore={tweetsLoadingMore}
      isLoadingUser={!userFetched}
      isSignedIn={isSignedIn}
      isSubscribed={isSubscribed}
      isTwitterRoute={isTwitterRoute}
      tweetsCount={tweetsCount}
      username={nextUsername}
    />
  );
};

export default withRouter(observer(TwitterAccountHeaderContainer));
