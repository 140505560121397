import React from 'react';
import Helmet from 'react-helmet';

import Error404 from '../../components/Error404';

type Props = {};

const Error404PageContainer = (_props: Props) => {
  return (
    <>
      <Helmet>
        <title>404</title>
      </Helmet>

      <Error404 />
    </>
  );
};

export default Error404PageContainer;
