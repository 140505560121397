import React from 'react';

import Section from '../../Heading/Section';
import cx from '../../../utils/styling/cx';

// Types
import { HTMLAttributes, ReactNode } from 'react';

type Props = {
  children: ReactNode;
} & HTMLAttributes<HTMLDivElement>;

const AnalysisHeaderSection = ({ children, className, ...rest }: Props) => {
  const classes = cx('flex flex-col xl:flex-row', className);

  return (
    <Section className={classes} {...rest}>
      {children}
    </Section>
  );
};

export default AnalysisHeaderSection;
