import { types } from 'mobx-state-tree';

// Types
import { Instance } from 'mobx-state-tree';

const AuthUserTwitterProfileModel = types.model('AuthUserTwitterProfile', {
  displayName: types.maybeNull(types.string),
  id: types.string,
  location: types.maybeNull(types.string),
  username: types.string,
});

export interface AuthUserTwitterProfile extends Instance<typeof AuthUserTwitterProfileModel> {}

export default AuthUserTwitterProfileModel;
