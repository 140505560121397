import RethrownError from '../../../../utils/misc/RethrownError';
import axios from '../../../../utils/network/axios';
import logger from '../../../../utils/misc/logger';
import routesApi from '../../../../config/routing/routesApi';

// Types
import { NormalizedUser } from '../../../../types/user';

type FetchAuthUserResponse = {
  user: NormalizedUser | null;
};

const fetchAuthUser = async (): Promise<NormalizedUser | null> => {
  try {
    const response = await axios.get<FetchAuthUserResponse>(routesApi.auth.me);

    if (!response.data) {
      return null;
    }

    return response.data.user;
  } catch (err) {
    logger.error(err);

    throw new RethrownError(err, 'fetchAuthUser.ts');
  }
};

export default fetchAuthUser;
