import React from 'react';

import AppContainer from '../../containers/App';
import PlansContainer from '../../containers/Plans';

type Props = {};

const PlansPage = (_props: Props) => {
  return (
    <AppContainer>
      <PlansContainer />
    </AppContainer>
  );
};

export default PlansPage;
