import React from 'react';
import ContentLoader from 'react-content-loader';

import envConfig from '../../config/env';
import tailwind from '../../config/tailwind';

type Props = {};

const TwitterUserCardContentLoader = (_props: Props) => {
  return (
    <>
      <div className="xl:hidden">
        <ContentLoader
          height={200}
          primaryColor={tailwind.theme.colors.brand.light}
          secondaryColor={tailwind.theme.colors.brand['light-extra']}
          speed={envConfig.twitterChartsContentLoaderSpeed}
          width={900}
        >
          <circle cx="44" cy="43" r="30" />
          <rect x="90" y="26" rx="4" ry="4" width="100" height="13" />
          <rect x="90" y="50" rx="4" ry="4" width="50" height="8" />
          <rect x="15" y="83" rx="5" ry="5" width="397" height="44" />
          <rect x="15" y="133" rx="5" ry="5" width="869" height="44" />
        </ContentLoader>
      </div>

      <div className="hidden xl:block">
        <ContentLoader
          style={{ maxHeight: 500 }}
          height={327}
          primaryColor={tailwind.theme.colors.brand.light}
          secondaryColor={tailwind.theme.colors.brand['light-extra']}
          speed={envConfig.twitterChartsContentLoaderSpeed}
          width={450}
        >
          <circle cx="44" cy="43" r="30" />
          <rect x="90" y="26" rx="4" ry="4" width="100" height="13" />
          <rect x="90" y="50" rx="4" ry="4" width="50" height="8" />
          <rect x="15" y="83" rx="5" ry="5" width="400" height="44" />
          <rect x="15" y="138" rx="5" ry="5" width="400" height="101" />
          <rect x="15" y="247" rx="0" ry="0" width="398" height="56" />
        </ContentLoader>
      </div>
    </>
  );
};

export default TwitterUserCardContentLoader;
