import React from 'react';
import { MobXProviderContext } from 'mobx-react';
import { types } from 'mobx-state-tree';

import storeSnapshot from '../../storeSnapshot';

import TwitterTweetModel from './TwitterTweet';

// Types
import { Instance } from 'mobx-state-tree';
import { TwitterNormalizedTweet } from '../../../types/twitter';

const TwitterStreamStoreModel = types
  .model('TwitterStreamStore', {
    isMockStore: types.boolean,
    streamStartDate: types.maybeNull(types.Date),
    term: types.maybeNull(types.string),
    tweets: types.array(TwitterTweetModel),
    tweetsError: types.boolean,
    tweetsLoading: types.boolean,
    tweetsPressure: types.array(types.number),
  })

  // Views

  .views(_self => ({
    get isAccountProtected() {
      return false;
    },
  }))

  .views(_self => ({
    get isNoTweetsMessageShowing() {
      return false;
    },
  }))

  .views(_self => ({
    get isAccountProtectedMessageShowing() {
      return false;
    },
  }))

  .views(self => ({
    get tweetsCount() {
      return self.tweets.length;
    },
  }))

  .views(self => ({
    get isWaitingForFirstTweetMessageShowing() {
      return self.tweets.length <= 0;
    },
  }))

  // Async Actions

  // Sync Actions

  .actions(self => {
    function resetStore() {
      self.streamStartDate = null;
      self.term = null;
      self.tweets = [] as any;
      self.tweetsError = false;
      self.tweetsLoading = false;
      self.tweetsPressure = [] as any;
    }

    return { resetStore };
  })

  .actions(self => {
    function startStreamStartDate() {
      self.streamStartDate = new Date();
    }

    return { startStreamStartDate };
  })

  .actions(self => {
    function addTweets(tweetsToAdd: TwitterNormalizedTweet[]) {
      self.tweets.push(...tweetsToAdd);
    }

    return { addTweets };
  })

  .actions(self => {
    function setTerm(nextTerm: string) {
      self.term = nextTerm;
    }

    return { setTerm };
  })

  .actions(self => {
    function addPressurePoint(pressurePoint: number) {
      self.tweetsPressure.push(pressurePoint);
    }

    return { addPressurePoint };
  });

export interface TwitterStreamStore extends Instance<typeof TwitterStreamStoreModel> {}

type UseTwitterStreamStoreOptions = {
  useMockStore?: boolean;
};

export const useTwitterStreamStore = ({ useMockStore = false }: UseTwitterStreamStoreOptions = {}) => {
  const [snapshotTwitterStreamStore] = React.useState<TwitterStreamStore>(TwitterStreamStoreModel.create(storeSnapshot as any));

  const context = React.useContext<{ twitterStreamStore: TwitterStreamStore }>(MobXProviderContext);

  if (useMockStore) {
    return snapshotTwitterStreamStore;
  }

  return context.twitterStreamStore;
};

export default TwitterStreamStoreModel;
