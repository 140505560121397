import React from 'react';

type UseWindowBlurInput = {
  onBlur: () => void;
  onFocus: () => void;
};

const useWindowBlur = ({ onBlur, onFocus }: UseWindowBlurInput) => {
  React.useEffect(() => {
    window.addEventListener('blur', onBlur);
    window.addEventListener('focus', onFocus);

    return () => {
      window.removeEventListener('blur', onBlur);
      window.removeEventListener('focus', onFocus);
    };
  });
};

export default useWindowBlur;
