import React from 'react';
import * as d3 from 'd3';
import dc from 'dc';

import DailyRhythmChartContentLoader from '../../../components/Chart/ChartContentLoaders/DailyRhythmChartContentLoader';
import TwitterChartContainer from '../../TwitterChart';
import getLongWeekdayName from '../../../utils/time/getLongWeekdayName';
import getWeekdayName from '../../../utils/time/getWeekdayName';
import removeRecordsWithEmptyKeys from '../../../utils/dc/removeRecordsWithEmptyKeys';
import tailwind from '../../../config/tailwind';
import { rows, columns } from './constants';

// Types
import { ChartFunctionInput, HeatMapDatum } from '../../../types/twitterCharts';
import { Crossfilter } from 'crossfilter2';
import { TwitterNormalizedTweet } from '../../../types/twitter';
import { TwitterStore } from '../../../data/models/twitter/TwitterStore';
import { TwitterStreamStore } from '../../../data/models/twitter/TwitterStreamStore';

const getColor = d3.scaleLinear<string, string>().range([tailwind.theme.colors.brand['light-extra'], tailwind.theme.colors.brand.default]);

const createDailyRhythmChart = ({ crossfilter, divRef }: ChartFunctionInput) => {
  const dailyRhythmChart = dc.heatMap(divRef);

  const dimension = crossfilter.dimension(tweet => [tweet.timeOfDay, tweet.dayOfWeek]);

  const group = removeRecordsWithEmptyKeys(dimension.group());

  dailyRhythmChart
    .width(866)
    .height(266)
    .useViewBoxResizing(true)
    .margins({ bottom: 40, left: 40, right: 40, top: 20 })

    .rows(rows)
    .cols(columns)

    .dimension(dimension)
    .group(group)

    .rowsLabel((dayOfWeek: number) => getWeekdayName(dayOfWeek))
    .colorCalculator(({ value }: HeatMapDatum) => (value === 0 ? 'white' : getColor(value * 0.05)))
    .keyAccessor(({ key: [timeOfDay] }: HeatMapDatum) => timeOfDay)
    .valueAccessor(({ key: [_timeOfDay, dayOfWeek] }: HeatMapDatum) => dayOfWeek)
    .title(
      ({ key: [timeOfDay, dayOfWeek], value }: HeatMapDatum) =>
        `${getLongWeekdayName(getWeekdayName(dayOfWeek))}, ${timeOfDay}: ${value} Tweets`,
    )

    // @ts-ignore
    .xBorderRadius(0)
    .yBorderRadius(0);

  return dailyRhythmChart;
};

type Props = {
  crossfilterInstance: Crossfilter<TwitterNormalizedTweet>;
  twitterStore: TwitterStore | TwitterStreamStore;
};

const DailyRhythmChart = ({ crossfilterInstance, twitterStore }: Props) => {
  return (
    <TwitterChartContainer
      chartFunction={createDailyRhythmChart}
      contentLoader={<DailyRhythmChartContentLoader />}
      crossfilterInstance={crossfilterInstance}
      dontSetBorderRadius={true}
      title="Daily Rhythm"
      tooltipSelector=".heat-box"
      twitterStore={twitterStore}
    />
  );
};

export default DailyRhythmChart;
