import React from "react";
import {
  faVial,
  faPaperPlane,
  faBatteryQuarter
} from "@fortawesome/free-solid-svg-icons";

import PlanCardBody from "../../PlanCardItems/PlanCardBody";
import PlanCardFeature from "../../PlanCardItems/PlanCardFeature";
import PlanCardFeatures from "../../PlanCardItems/PlanCardFeatures";
import PlanCardFooter from "../../PlanCardItems/PlanCardFooter";
import PlanCardHeader from "../../PlanCardItems/PlanCardHeader";
import PlanCardHeadlineFeature from "../../PlanCardItems/PlanCardHeadlineFeature";
import PlanCardHeadlineFeatures from "../../PlanCardItems/PlanCardHeadlineFeatures";
import PlanCardLayout from "../../PlanCardItems/PlanCardLayout";

type Props = {
  className?: string;
};

const PlansCardFree = ({ className }: Props) => {
  return (
    <PlanCardLayout className={className}>
      <PlanCardHeader>Standard</PlanCardHeader>

      <PlanCardBody>
        <PlanCardHeadlineFeatures>
          <PlanCardHeadlineFeature
            disclaimer="up to"
            disclaimerClarification="It cannot be guaranteed that the Twitter API will actually return 600 tweets."
            label="Tweets per Analysis"
            value="600"
          />

          <PlanCardHeadlineFeature
            disclaimer="up to"
            disclaimerClarification="It cannot be guaranteed that the Twitter API will actually return 200 tweets."
            label="Tweets at Once"
            value="200"
          />
        </PlanCardHeadlineFeatures>

        <PlanCardFeatures>
          <PlanCardFeature icon={faVial} text="Test features for free" />
          <PlanCardFeature
            icon={faBatteryQuarter}
            text="Limited number of Tweets"
          />
          <PlanCardFeature icon={faPaperPlane} text="Load Tweets manually" />
        </PlanCardFeatures>
      </PlanCardBody>

      <PlanCardFooter>Free</PlanCardFooter>
    </PlanCardLayout>
  );
};

export default PlansCardFree;
