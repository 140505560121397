import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleRight, faSpinner } from '@fortawesome/free-solid-svg-icons';

import Tooltip from '../../Tooltip';
import cx from '../../../utils/styling/cx';
import envConfig from '../../../config/env';

// Types
import { ReactNode } from 'react';

type Props = {
  buttonText: ReactNode;
  hasUser: boolean;
  isLoading: boolean;
  onClick: () => void;
  shouldOpenCheckout: boolean;
  shouldOpenPortal: boolean;
};

const SubscribeButton = ({ buttonText, hasUser, isLoading, onClick, shouldOpenCheckout, shouldOpenPortal }: Props) => {
  return (
    <Tooltip ariaLabel="Please sign in to subscribe." isDisabled={isLoading || !!hasUser} label="Please sign in to subscribe.">
      <button
        className={cx('mx-8 px-6 py-4 font-medium rounded shadow w-full bg-indigo-600 text-white', {
          'cursor-not-allowed': !hasUser,
          'hover:bg-indigo-700': !!hasUser,
        })}
        data-cb-plan-id={shouldOpenCheckout ? envConfig.chargebeeProPlan : undefined}
        // eslint-disable-next-line no-nested-ternary
        data-cb-type={shouldOpenCheckout ? 'checkout' : shouldOpenPortal ? 'portal' : undefined}
        onClick={onClick}
        type="button"
      >
        {!isLoading && buttonText}

        {!isLoading && <FontAwesomeIcon className="ml-2" fixedWidth={true} icon={faChevronCircleRight} />}

        {isLoading && <FontAwesomeIcon className="ml-2" fixedWidth={true} icon={faSpinner} pulse={true} />}
      </button>
    </Tooltip>
  );
};

export default SubscribeButton;
