import React from 'react';

// Types
import { HTMLAttributes } from 'react';

type Props = {} & HTMLAttributes<SVGElement>;

const Arrow3 = ({ ...rest }: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 283.46 283.46" {...rest}>
      <path d="M17.83 266.23c-.49.01-.97.03-1.46.04-1.56.04-3.21-1.44-3.07-3.07 1.76-21.37 1.45-42.46.01-63.83-.1-1.47 1.45-3.15 2.97-2.97 41.51 4.96 89.35-1.28 119.78-32.65 14.42-14.87 24.07-34.91 24.84-55.77.15-4.14.71-8.58-1.95-12.03-3.37-4.38-7.69-3.05-13.05-3.21-9.64-.28-19.27-.53-28.91-.83-.15.12-.29.25-.44.38-1.44 1.18-3.19.61-4.07-.51-.19-.01-.39-.01-.58-.02-2.74-.09-2.75-4.18 0-4.27.66-.02 1.33-.03 1.99-.05 23.18-21.53 39.17-49.1 45.87-80.07.59-2.71 4.47-3.74 6.31-1.67.29.32.58.62.87.94.17.06.34.08.52.18 26.04 14.94 51.27 31.45 76.76 47.3 7.44 4.63 34.3 14.86 24.9 28.46-2.18 3.16-6.03 4.85-10.18 5.91-.66 1-1.78 1.75-3.17 1.92-14.16 1.66-28.3 3.44-42.44 5.19-3.05 1.06-5.23 2.4-5.82 4.12-.5 1.45 1.66 10 1.83 12.14.61 7.5.39 15.15-.32 22.64-1.1 11.74-3.5 23.35-6.76 34.67-6.37 22.11-15.37 46.47-29.81 64.65-12.18 15.34-29.46 23.39-47.79 29.14-23.47 7.35-48.49 13.57-73.23 13.51-10.81-.02-27.77.03-33.6-10.24zM122.39 87.2c13.5-.37 27.01-.69 40.51-1.04 1.48-.04 3.08 1.12 3.41 2.6 8.36 37.4-13.36 74.81-44.79 94.5-18.6 11.65-40.4 17.43-62.09 19.42-5.38.5-10.79.76-16.19.8-5.18.03-14.1-2.27-18.91-.64-6.62 2.24-4 6.85-3.66 13.93.32 6.77.44 13.56.33 20.34-.05 3.23-2.3 14.79-1.47 19.07 2.08-.07 4.31 1.18 4.52 3.81 2.96.65 6.45-.2 9.73-.32 15.14-.56 30.33-1.42 45.33-3.7 26.26-3.99 52.78-12.46 73.58-29.56 38.36-31.54 47.27-85.02 38.67-131.59-.53-2.86 1.17-4.88 3.97-5.21 15.44-1.83 30.9-3.54 46.35-5.31.73-.14 1.46-.29 2.19-.43.17-.03.37-.06.55-.09-29.1-19.26-55.48-41.82-79.03-67.63-7.4 27.67-21.85 51.75-43 71.05zm137.03-15.95c-2.02-4.13-14.34-9.09-18.4-11.64-19.64-12.39-39.55-24.47-59.05-37.12 22.36 22.5 47.03 42.4 73.91 59.48 4.21-1.32 6.81-4.06 3.54-10.72zm-58.81 25.92l-.74.09c1.52 12.47 1.96 25.29 1.16 38.02 1.42-12.67 1.39-25.47-.42-38.11zm-98.64 164.11c20.77-5.33 43.64-11.02 59.25-26.67 9.64-9.66 16.76-22.55 22.36-35.88-4.44 7.99-9.74 15.55-16.12 22.44-35.76 38.66-90.23 43.24-140.03 44.79 13.89 9.99 66.01-2.49 74.54-4.68z" />
    </svg>
  );
};

export default Arrow3;
