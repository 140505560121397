import React from 'react';
import idx from 'idx';
import { MobXProviderContext } from 'mobx-react';
import { flow, types } from 'mobx-state-tree';

import envConfig from '../../../config/env';
import fetchTweets from './utils/fetchTweets';
import fetchUser from './utils/fetchUser';
import logger from '../../../utils/misc/logger';
import storeSnapshot from '../../storeSnapshot';

import TwitterTweetModel from './TwitterTweet';
import TwitterUserModel from './TwitterUser';

// Types
import { Instance } from 'mobx-state-tree';

const TwitterStoreModel = types
  .model('TwitterStore', {
    isMockStore: types.boolean,
    tweets: types.array(TwitterTweetModel),
    tweetsError: types.boolean,
    tweetsFetches: types.number,
    tweetsLoading: types.boolean,
    tweetsLoadingMore: types.boolean,
    user: types.maybeNull(TwitterUserModel),
    userError: types.boolean,
    userLoading: types.boolean,
    userNotFound: types.boolean,
    username: types.maybeNull(types.string),
  })

  // Views

  .views(self => ({
    get isAccountProtected() {
      return !!self.user && self.user.isProtected;
    },
  }))

  .views(self => ({
    get isFetchMoreDisabled() {
      return self.tweetsFetches >= envConfig.twitterMaxFreeFetches;
    },
  }))

  .views(self => ({
    get userTweetsCount() {
      if (!self.user) {
        return 0;
      }

      return self.user.tweetsCount;
    },
  }))

  .views(self => ({
    get isNoTweetsMessageShowing() {
      if (self.isMockStore) {
        return false;
      }

      if (!self.userLoading && self.userTweetsCount === 0) {
        return true;
      }

      return false;
    },
  }))

  .views(self => ({
    get isAccountProtectedMessageShowing() {
      return !self.userLoading && !!self.user && self.user.isProtected && !self.tweetsLoading && self.tweets.length === 0;
    },
  }))

  .views(_self => ({
    get isWaitingForFirstTweetMessageShowing() {
      // Used in `TwitterStreamStore`
      return false;
    },
  }))

  // Async Actions

  .actions(self => {
    const fetchInitialTweetsFlow = flow(function*() {
      if (!self.username) {
        return;
      }

      self.tweetsError = false;
      self.tweetsLoading = true;

      try {
        const params = { maxId: null, username: self.username };

        const initialTweets = yield fetchTweets(params);

        self.tweets.push(...initialTweets);

        self.tweetsLoading = false;
        self.tweetsFetches = 1;

        return initialTweets;
      } catch (err) {
        logger.error(err);

        self.tweetsError = true;
        self.tweetsLoading = false;
      }
    });

    return { fetchInitialTweets: fetchInitialTweetsFlow };
  })

  .actions(self => {
    const fetchTweetsFlow = flow(function*() {
      self.tweetsError = false;
      self.tweetsLoadingMore = true;

      if (!self.username || self.tweetsFetches >= envConfig.twitterMaxFreeFetches) {
        return;
      }

      try {
        const maxId: string | null = idx(self, context => context.tweets[context.tweets.length - 1].id) || null;
        const params = { maxId, username: self.username };

        const nextTweets = yield fetchTweets(params);

        self.tweets.push(...nextTweets);

        self.tweetsLoadingMore = false;
        self.tweetsFetches += 1;

        return nextTweets;
      } catch (err) {
        logger.error(err);

        self.tweetsError = true;
        self.tweetsLoadingMore = false;
      }
    });

    return { fetchTweets: fetchTweetsFlow };
  })

  .actions(self => {
    const fetchUserFlow = flow(function*() {
      self.userError = false;
      self.userLoading = true;
      self.userNotFound = false;

      if (!self.username) {
        return;
      }

      try {
        const user = yield fetchUser(self.username);

        if (!user) {
          self.userNotFound = true;
        }

        self.user = user;
        self.userLoading = false;
      } catch (err) {
        logger.error(err);

        self.userError = true;
        self.userLoading = false;
      }
    });

    return { fetchUser: fetchUserFlow };
  })

  // Sync Actions

  .actions(self => {
    function resetStore() {
      self.tweetsError = false;
      self.tweetsLoading = true;

      self.userError = false;
      self.userLoading = true;

      self.tweets = [] as any;
      self.tweetsFetches = 0;

      self.user = null;
    }

    return { resetStore };
  })

  .actions(self => {
    function setUsername(nextUsername: string) {
      if (nextUsername === self.username) {
        return;
      }

      self.username = nextUsername;
    }

    return { setUsername };
  });

export interface TwitterStore extends Instance<typeof TwitterStoreModel> {}

type UseTwitterStoreOptions = {
  useMockStore?: boolean;
};

export const useTwitterStore = ({ useMockStore = false }: UseTwitterStoreOptions = {}) => {
  const [snapshotTwitterStore] = React.useState<TwitterStore>(TwitterStoreModel.create(storeSnapshot as any));

  const context = React.useContext<{ twitterStore: TwitterStore }>(MobXProviderContext);

  if (useMockStore) {
    return snapshotTwitterStore;
  }

  return context.twitterStore;
};

export default TwitterStoreModel;
