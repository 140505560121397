import React from 'react';

import AnalysisHeaderSection from '../LayoutItems/AnalysisHeaderSection';
import AnalysisSection from '../LayoutItems/AnalysisSection';
import Arrow from '../Arrow';
import Chart1AxisXAxis from '../Chart/Chart1AxisXAxis';
import Chart2AxisXAxis from '../Chart/Chart2AxisXAxis';
import Chart2AxisYAxis from '../Chart/Chart2AxisYAxis';
import Heading from '../Heading';
import Infobox from '../Infobox';
import cx from '../../utils/styling/cx';
import envConfig from '../../config/env';

import * as Explanation from './explanations';

// Types
import { ReactNode } from 'react';

type Props = {
  areAxisVisible?: boolean;
  dailyRhythmChart: ReactNode;
  dataCount: ReactNode;
  dayOfWeekChart: ReactNode;
  isDemoMode?: boolean;
  isHelpVisible: boolean;
  isSignedIn?: boolean;
  isUserNotFound?: boolean;
  lastTweetsGrid?: ReactNode;
  quotedUserChart: ReactNode;
  repliedUserChart: ReactNode;
  retweetedUserChart: ReactNode;
  title: string;
  tweetTypeChart: ReactNode;
  twitterUserCard: ReactNode;
  usedAppChart: ReactNode;
  usedHashtagsChart: ReactNode;
  usedHostnamesChart: ReactNode;
  usedLanguageChart: ReactNode;
  volumeByDateChart: ReactNode;
};

const TwitterAccount = (props: Props) => {
  const {
    areAxisVisible = true,
    dailyRhythmChart,
    dataCount,
    dayOfWeekChart,
    isDemoMode = false,
    isHelpVisible,
    isSignedIn,
    isUserNotFound,
    lastTweetsGrid,
    quotedUserChart,
    repliedUserChart,
    retweetedUserChart,
    title,
    tweetTypeChart,
    twitterUserCard,
    usedAppChart,
    usedHashtagsChart,
    usedHostnamesChart,
    usedLanguageChart,
    volumeByDateChart,
  } = props;

  return (
    <>
      <div className={cx('flex container mx-auto flex-col ', { 'mt-16': !isDemoMode, 'pt-16': !isDemoMode })}>
        <Heading isVisuallyHidden={true}>{title}</Heading>

        {isDemoMode && (
          <AnalysisHeaderSection className="mb-16 mt-8">
            <Infobox>
              <div className="flex-1">
                <p className="text-3xl font-bold">Welcome to {envConfig.appName}!</p>

                {!isSignedIn ? (
                  <p>
                    This tool enables you to evaluate Twitter accounts. For example how automated they are, how many Retweets they post, or
                    which websites they link to most often.
                    <br />
                    <br />
                    Play around with the demo data below; to analyze other accounts, sign in for free.
                  </p>
                ) : (
                  <p>Play around with the demo data below or enter any Twitter account to analyze it.</p>
                )}
              </div>

              <div>
                <Arrow direction="up" side="right" size="mobile" number={3} />
              </div>
            </Infobox>
          </AnalysisHeaderSection>
        )}

        {isUserNotFound && (
          <AnalysisHeaderSection className="mb-16">
            <Infobox level="error">
              <div className="flex-1">
                <p className="text-3xl font-bold">User Not Found</p>

                <p>Please try another account.</p>
              </div>

              <div>
                <Arrow direction="up" side="right" size="mobile" number={2} />
              </div>
            </Infobox>
          </AnalysisHeaderSection>
        )}

        <AnalysisHeaderSection className="relative">
          {dataCount}

          <div className="xl:w-1/3 h-auto bg-white rounded-lg shadow mx-2">{twitterUserCard}</div>

          {/* EXPLANATION */}
          {isHelpVisible && (
            <div className="relative text-green-800 flex flex-col lg:flex-row justify-around mt-2 xl:hidden mr-1 lg:mr-0">
              <div className="flex flex-row items-end w-full lg:w-1/2">
                <Arrow direction="down" side="left" size="mobile" number={2} />

                <p className="ml-2 mr-1 px-4 py-2 rounded border-r-4 border-green-400 rounded bg-green-100 flex-1 max-h-48 shadow">
                  <Explanation.DailyRhytm />
                </p>
              </div>

              <div className="flex flex-row items-start w-full lg:w-1/2 order-first lg:order-none ml-1 lg:ml-0 mb-2 lg:mb-0">
                <p className="mr-2 ml-1 px-4 py-2 rounded border-l-4 border-green-400 rounded bg-green-100 flex-1 max-h-48 shadow">
                  <Explanation.UserCard />
                </p>

                <Arrow direction="up" side="right" size="mobile" number={1} />
              </div>
            </div>
          )}
          {/* EXPLANATION */}

          <div className="mt-4 xl:mt-0  xl:w-2/3 h-auto bg-white rounded-lg shadow mx-2 relative">{dailyRhythmChart}</div>
        </AnalysisHeaderSection>

        {/* EXPLANATION */}
        {isHelpVisible && (
          <div className="hidden xl:flex relative text-green-800 flex flex-row justify-around mt-2">
            <div className="flex flex-row items-start w-1/2">
              <Arrow direction="up" side="left" size="desktop" number={1} />

              <p className="ml-2 mr-2 px-4 py-2 rounded border-r-4 border-green-400 rounded bg-green-100 w-1/3 flex-1">
                <Explanation.UserCard />
              </p>
            </div>

            <div className="flex flex-row items-start w-1/2">
              <p className="mr-2 ml-2 px-4 py-2 rounded border-l-4 border-green-400 rounded bg-green-100 w-1/3 flex-1">
                <Explanation.DailyRhytm />
              </p>

              <Arrow direction="up" side="right" size="desktop" number={2} />
            </div>
          </div>
        )}
        {/* EXPLANATION */}

        <AnalysisSection className="mt-4">
          <div className="lg:w-2/3 h-auto bg-white rounded-lg shadow mx-2 relative">
            {areAxisVisible && <Chart2AxisYAxis label="Tweets" />}

            {areAxisVisible && <Chart2AxisXAxis label="Date" />}

            {volumeByDateChart}
          </div>

          {/* EXPLANATION */}
          {isHelpVisible && (
            <div className="relative text-green-800 flex flex-col lg:flex-row justify-around mt-2 lg:hidden mr-1 lg:mr-0">
              <div className="flex flex-row items-end w-full lg:w-1/2">
                <Arrow direction="down" side="left" size="mobile" number={1} />

                <p className="ml-2 mr-1 px-4 py-2 rounded border-r-4 border-green-400 rounded bg-green-100 flex-1 max-h-48 shadow">
                  <Explanation.DayOfWeek />
                </p>
              </div>

              <div className="flex flex-row items-start w-full lg:w-1/2 order-first lg:order-none ml-1 lg:ml-0 mb-2 lg:mb-0">
                <p className="mr-2 ml-1 px-4 py-2 rounded border-l-4 border-green-400 rounded bg-green-100 flex-1 max-h-48 shadow">
                  <Explanation.VolumeByDate />
                </p>

                <Arrow direction="up" side="right" size="mobile" number={2} />
              </div>
            </div>
          )}
          {/* EXPLANATION */}

          <div className="mt-4 lg:mt-0 lg:w-1/3 h-auto bg-white rounded-lg shadow mx-2 relative">
            {areAxisVisible && <Chart2AxisYAxis label="Tweets" />}

            {areAxisVisible && <Chart2AxisXAxis label="Day of Week" />}

            {dayOfWeekChart}
          </div>
        </AnalysisSection>

        {/* EXPLANATION */}
        {isHelpVisible && (
          <div className="hidden lg:flex relative text-green-800 flex flex-row justify-around mt-2">
            <div className="flex flex-row items-start w-1/2">
              <Arrow direction="up" side="left" size="desktop" number={2} />

              <p className="ml-2 mr-2 px-4 py-2 rounded border-r-4 border-green-400 rounded bg-green-100 w-1/3 flex-1">
                <Explanation.VolumeByDate />
              </p>
            </div>

            <div className="flex flex-row items-start w-1/2">
              <p className="mr-2 ml-2 px-4 py-2 rounded border-l-4 border-green-400 rounded bg-green-100 w-1/3 flex-1">
                <Explanation.DayOfWeek />
              </p>

              <Arrow direction="up" side="right" size="desktop" number={1} />
            </div>
          </div>
        )}
        {/* EXPLANATION */}

        <AnalysisSection className="mt-4">
          <div className="lg:w-1/3 h-auto bg-white rounded-lg shadow mx-2 relative">
            {areAxisVisible && <Chart1AxisXAxis label="Tweets" />}

            {tweetTypeChart}
          </div>

          {/* EXPLANATION */}
          {isHelpVisible && (
            <div className="relative text-green-800 flex flex-col lg:flex-row justify-around mt-2 lg:hidden mr-1 lg:mr-0">
              <div className="flex flex-row items-end w-full lg:w-1/2">
                <Arrow direction="down" side="left" size="mobile" number={3} />

                <p className="ml-2 mr-1 px-4 py-2 rounded border-r-4 border-green-400 rounded bg-green-100 flex-1 max-h-48 shadow">
                  <Explanation.UsedLanguage />
                </p>
              </div>

              <div className="flex flex-row items-start w-full lg:w-1/2 order-first lg:order-none ml-1 lg:ml-0 mb-2 lg:mb-0">
                <p className="mr-2 ml-1 px-4 py-2 rounded border-l-4 border-green-400 rounded bg-green-100 flex-1 max-h-48 shadow">
                  <Explanation.TweetType />
                </p>

                <Arrow direction="up" side="right" size="mobile" number={2} />
              </div>
            </div>
          )}
          {/* EXPLANATION */}

          <div className="mt-4 lg:mt-0 lg:w-1/3 h-auto bg-white rounded-lg shadow mx-2 relative">
            {areAxisVisible && <Chart1AxisXAxis label="Tweets" />}

            {usedLanguageChart}
          </div>

          {/* EXPLANATION */}
          {isHelpVisible && (
            <div className="relative text-green-800 flex flex-col lg:flex-row justify-around mt-2 lg:hidden mr-1 lg:mr-0">
              <div className="flex flex-row items-end w-full lg:w-1/2">
                <Arrow direction="down" side="left" size="mobile" number={1} />

                <p className="ml-2 mr-1 px-4 py-2 rounded border-r-4 border-green-400 rounded bg-green-100 flex-1 max-h-48 shadow">
                  <Explanation.UsedApp />
                </p>
              </div>
            </div>
          )}
          {/* EXPLANATION */}

          <div className="mt-4 lg:mt-0 lg:w-1/3 h-auto bg-white rounded-lg shadow mx-2 relative">
            {areAxisVisible && <Chart1AxisXAxis label="Tweets" />}

            {usedAppChart}
          </div>
        </AnalysisSection>

        {/* EXPLANATION */}
        {isHelpVisible && (
          <div className="hidden lg:flex relative text-green-800 flex flex-row justify-around mt-2">
            <div className="flex flex-row items-start w-1/3">
              <Arrow direction="up" side="left" size="desktop" number={2} />

              <p className="ml-2 mr-2 px-4 py-2 rounded border-r-4 border-green-400 rounded bg-green-100 w-1/3 flex-1">
                <Explanation.TweetType />
              </p>
            </div>

            <div className="flex flex-row items-start w-1/3">
              <Arrow direction="up" side="left" size="desktop" number={3} />

              <p className="ml-2 mr-2 px-4 py-2 rounded border-r-4 border-green-400 rounded bg-green-100 w-1/3 flex-1">
                <Explanation.UsedLanguage />
              </p>
            </div>

            <div className="flex flex-row items-start w-1/3">
              <p className="mr-2 ml-2 px-4 py-2 rounded border-l-4 border-green-400 rounded bg-green-100 w-1/3 flex-1">
                <Explanation.UsedApp />
              </p>

              <Arrow direction="up" side="right" size="desktop" number={1} />
            </div>
          </div>
        )}
        {/* EXPLANATION */}

        <AnalysisSection className="mt-4">
          <div className="lg:w-1/2 h-auto bg-white rounded-lg shadow mx-2 relative">
            {areAxisVisible && <Chart1AxisXAxis label="Tweets" />}

            {usedHashtagsChart}
          </div>

          {/* EXPLANATION */}
          {isHelpVisible && (
            <div className="relative text-green-800 flex flex-col lg:flex-row justify-around mt-2 lg:hidden mr-1 lg:mr-0">
              <div className="flex flex-row items-end w-full lg:w-1/2">
                <Arrow direction="down" side="left" size="mobile" number={1} />

                <p className="ml-2 mr-1 px-4 py-2 rounded border-r-4 border-green-400 rounded bg-green-100 flex-1 max-h-48 shadow">
                  <Explanation.UsedHostnames />
                </p>
              </div>

              <div className="flex flex-row items-start w-full lg:w-1/2 order-first lg:order-none ml-1 lg:ml-0 mb-2 lg:mb-0">
                <p className="mr-2 ml-1 px-4 py-2 rounded border-l-4 border-green-400 rounded bg-green-100 flex-1 max-h-48 shadow">
                  <Explanation.UsedHashtags />
                </p>

                <Arrow direction="up" side="right" size="mobile" number={2} />
              </div>
            </div>
          )}
          {/* EXPLANATION */}

          <div className="mt-4 lg:mt-0 lg:w-1/2 h-auto bg-white rounded-lg shadow mx-2 relative">
            {areAxisVisible && <Chart1AxisXAxis label="Tweets" />}

            {usedHostnamesChart}
          </div>
        </AnalysisSection>

        {/* EXPLANATION */}
        {isHelpVisible && (
          <div className="hidden lg:flex relative text-green-800 flex flex-row justify-around mt-2">
            <div className="flex flex-row items-start w-1/2">
              <Arrow direction="up" side="left" size="desktop" number={1} />

              <p className="ml-2 mr-2 px-4 py-2 rounded border-r-4 border-green-400 rounded bg-green-100 w-1/3 flex-1">
                <Explanation.UsedHashtags />
              </p>
            </div>

            <div className="flex flex-row items-start w-1/2">
              <p className="mr-2 ml-2 px-4 py-2 rounded border-l-4 border-green-400 rounded bg-green-100 w-1/3 flex-1">
                <Explanation.UsedHostnames />
              </p>

              <Arrow direction="up" side="right" size="desktop" number={2} />
            </div>
          </div>
        )}
        {/* EXPLANATION */}

        <AnalysisSection className="mt-4">
          <div className="lg:w-1/3 h-auto bg-white rounded-lg shadow mx-2 relative">
            {areAxisVisible && <Chart1AxisXAxis label="Tweets" />}

            {repliedUserChart}
          </div>

          {/* EXPLANATION */}
          {isHelpVisible && (
            <div className="relative text-green-800 flex flex-col lg:flex-row justify-around mt-2 lg:hidden mr-1 lg:mr-0">
              <div className="flex flex-row items-end w-full lg:w-1/2">
                <Arrow direction="down" side="left" size="mobile" number={3} />

                <p className="ml-2 mr-1 px-4 py-2 rounded border-r-4 border-green-400 rounded bg-green-100 flex-1 max-h-48 shadow">
                  <Explanation.RetweetedUser />
                </p>
              </div>

              <div className="flex flex-row items-start w-full lg:w-1/2 order-first lg:order-none ml-1 lg:ml-0 mb-2 lg:mb-0">
                <p className="mr-2 ml-1 px-4 py-2 rounded border-l-4 border-green-400 rounded bg-green-100 flex-1 max-h-48 shadow">
                  <Explanation.RepliedUser />
                </p>

                <Arrow direction="up" side="right" size="mobile" number={2} />
              </div>
            </div>
          )}
          {/* EXPLANATION */}

          <div className="mt-4 lg:mt-0 lg:w-1/3 h-auto bg-white rounded-lg shadow mx-2 relative">
            {areAxisVisible && <Chart1AxisXAxis label="Tweets" />}

            {retweetedUserChart}
          </div>

          {/* EXPLANATION */}
          {isHelpVisible && (
            <div className="relative text-green-800 flex flex-col lg:flex-row justify-around mt-2 lg:hidden mr-1 lg:mr-0">
              <div className="flex flex-row items-end w-full lg:w-1/2">
                <Arrow direction="down" side="left" size="mobile" number={1} />

                <p className="ml-2 mr-1 px-4 py-2 rounded border-r-4 border-green-400 rounded bg-green-100 flex-1 max-h-48 shadow">
                  <Explanation.QuotedUser />
                </p>
              </div>
            </div>
          )}
          {/* EXPLANATION */}

          <div className="mt-4 lg:mt-0 lg:w-1/3 h-auto bg-white rounded-lg shadow mx-2 relative">
            {areAxisVisible && <Chart1AxisXAxis label="Tweets" />}

            {quotedUserChart}
          </div>
        </AnalysisSection>

        {/* EXPLANATION */}
        {isHelpVisible && (
          <div className="hidden lg:flex relative text-green-800 flex flex-row justify-around mt-2">
            <div className="flex flex-row items-start w-1/3">
              <Arrow direction="up" side="left" size="desktop" number={2} />

              <p className="ml-2 mr-2 px-4 py-2 rounded border-r-4 border-green-400 rounded bg-green-100 w-1/3 flex-1">
                <Explanation.RepliedUser />
              </p>
            </div>

            <div className="flex flex-row items-start w-1/3">
              <p className="ml-2 mr-2 px-4 py-2 rounded border-l-4 border-green-400 rounded bg-green-100 w-1/3 flex-1">
                <Explanation.RetweetedUser />
              </p>

              <Arrow direction="up" side="right" size="desktop" number={3} />
            </div>

            <div className="flex flex-row items-start w-1/3">
              <p className="mr-2 ml-2 px-4 py-2 rounded border-l-4 border-green-400 rounded bg-green-100 w-1/3 flex-1">
                <Explanation.QuotedUser />
              </p>

              <Arrow direction="up" side="right" size="desktop" number={1} />
            </div>
          </div>
        )}
        {/* EXPLANATION */}

        {/* EXPLANATION */}
        {isHelpVisible && (
          <div className="relative text-green-800 flex flex-col lg:flex-row justify-around mt-2 lg:hidden mr-1 lg:mr-0">
            <div className="flex flex-row items-end w-full lg:w-1/2">
              <Arrow direction="down" side="left" size="mobile" number={2} />

              <p className="ml-2 mr-1 px-4 py-2 rounded border-r-4 border-green-400 rounded bg-green-100 flex-1 max-h-48 shadow">
                <Explanation.LastTweetsGrid />
              </p>
            </div>
          </div>
        )}
        {/* EXPLANATION */}

        {/* EXPLANATION */}
        {isHelpVisible && (
          <div className="hidden lg:flex relative text-green-800 flex flex-row justify-around mt-4">
            <div className="flex flex-row items-end w-full">
              <Arrow direction="down" side="left" size="desktop" number={2} />

              <p className="mr-2 ml-2 px-4 py-2 rounded border-r-4 border-green-400 rounded bg-green-100 w-1/3 flex-1">
                <Explanation.LastTweetsGrid />
              </p>
            </div>
          </div>
        )}
        {/* EXPLANATION */}

        {!!lastTweetsGrid && (
          <AnalysisSection className={cx('mb-2 lg:mb-4', { 'mt-2': isHelpVisible, 'mt-4': !isHelpVisible })}>
            <div className="lg:mt-0 lg:w-full mx-2 h-auto bg-white rounded-lg shadow relative p-4 mt-4" style={{ minHeight: 200 }}>
              {lastTweetsGrid}
            </div>
          </AnalysisSection>
        )}
      </div>
    </>
  );
};

export default TwitterAccount;
