import React from 'react';
import { observer } from 'mobx-react';

import GlobalMessageError from '../../components/GlobalMessage/GlobalMessageError';
import { useAuthStore } from '../../data/models/auth/AuthStore';
import { useTwitterStore } from '../../data/models/twitter/TwitterStore';

type Props = {};

const GlobalMessageContainer = (_props: Props) => {
  const authStore = useAuthStore();
  const twitterStore = useTwitterStore();

  const [isVisible, setIsVisible] = React.useState<boolean>(false);

  const handleClose = () => {
    setIsVisible(false);
  };

  React.useEffect(() => {
    if (authStore.userError || twitterStore.tweetsError || twitterStore.userError) {
      setIsVisible(true);
    }
  }, [authStore.userError, twitterStore.tweetsError, twitterStore.userError]);

  if (!isVisible) {
    return null;
  }

  return <GlobalMessageError onClose={handleClose} />;
};

export default observer(GlobalMessageContainer);
