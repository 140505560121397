import React from 'react';

import cx from '../../../utils/styling/cx';

// Types
import { HTMLAttributes, ReactNode } from 'react';

type Props = {
  children: ReactNode;
  isHighlighted?: boolean;
  onClick?: () => void;
} & HTMLAttributes<HTMLDivElement>;

const PlanCardFooter = ({ children, className, isHighlighted = false, onClick, ...rest }: Props) => {
  const classes = cx(
    'py-8 bg-gray-400 text-center uppercase font-bold flex items-center justify-center',
    { 'text-gray-800': !isHighlighted, 'text-white-600': isHighlighted },
    className,
  );

  if (!!onClick) {
    return (
      <div className={classes} {...rest}>
        {children}
      </div>
    );
  }

  return (
    <div className={classes} {...rest}>
      {children}
    </div>
  );
};

export default PlanCardFooter;
