import RethrownError from '../../../../utils/misc/RethrownError';
import axios from '../../../../utils/network/axios';
import logger from '../../../../utils/misc/logger';
import routesApi from '../../../../config/routing/routesApi';

const logOut = async (): Promise<boolean> => {
  try {
    await axios.get(routesApi.auth.twitter.signOut);

    return true;
  } catch (err) {
    logger.error(err);

    throw new RethrownError(err, 'logOut.ts');
  }
};

export default logOut;
