import React from 'react';
import Papa from 'papaparse';
import { useTwitterStore } from '../../../../data/models/twitter/TwitterStore';
import { useAuthStore } from '../../../../data/models/auth/AuthStore';

import cx from '../../../../utils/styling/cx';

// Types
import { HTMLAttributes } from 'react';

type Props = {
  isDisabled?: boolean;
  size?: 'sm' | 'md';
} & HTMLAttributes<HTMLButtonElement>;

const DownloadButton = React.forwardRef<HTMLButtonElement, Props>(({ children, className, isDisabled, ...rest }, ref) => {
  const twitterStore = useTwitterStore({ useMockStore: false });
  const { downloadedTweetsUpdate } = useAuthStore();

  // @ts-ignore
  const generate_csv = download => {
    // https://www.papaparse.com/docs#json-to-csv
    const papaParseConfig = {
      quotes: true,
      quoteChar: '"',
      escapeChar: '"',
      delimiter: ',',
      header: true,
      newline: '\r\n',
      skipEmptyLines: false,
      columns: [
        'timestamp',
        'id',
        'username',
        'text',
        'language',
        'length',
        'type',
        'quotedUser',
        'repliedUser',
        'retweetedUser',
        'usedApp',
        'usedHashtags',
        'usedHostnames',
        'url',
      ], // null or array of strings
    };
    return Papa.unparse(download, papaParseConfig);
  };

  const downloadTweets = () => {
    downloadedTweetsUpdate();

    let tweets = JSON.parse(JSON.stringify(twitterStore.tweets.toJSON()));

    function decodeHtml(html: string) {
      const txt = document.createElement('textarea');
      txt.innerHTML = html;
      return txt.value;
    }

    const regex = /<.+?>/g;
    // @ts-ignore
    tweets = tweets.map(tweet => {
      // eslint-disable-next-line no-param-reassign
      tweet.timestamp = new Date(tweet.timestamp);
      // eslint-disable-next-line no-param-reassign
      tweet.text = decodeHtml(tweet.text);
      // eslint-disable-next-line no-param-reassign
      tweet.text = tweet.text.replace(regex, '');
      return tweet;
    });
    const csv = generate_csv(tweets);
    const data = new Blob([new Uint8Array([0xef, 0xbb, 0xbf]), csv], {
      type: 'text/csv;charset=utf-8',
    });

    const saveData = (() => {
      const a = document.createElement('a');
      // @ts-ignore
      return (blob, fileName) => {
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
      };
    })();
    saveData(data, 'tweets.csv');
  };

  return (
    <button
      className={cx(
        {
          'cursor-not-allowed': isDisabled,
          'hover:bg-gray-400': !isDisabled,
          'opacity-50': isDisabled,
        },
        'bg-gray-200 text-gray-800 font-medium py-2 px-4 rounded-r',
        className,
      )}
      onClick={!isDisabled ? downloadTweets : undefined}
      ref={ref}
      type="button"
      {...rest}
    >
      {children}
    </button>
  );
});

export default DownloadButton;
