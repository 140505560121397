import React from 'react';

import Heading from '../../Heading';
import cx from '../../../utils/styling/cx';

// Types
import { HTMLAttributes, ReactNode } from 'react';

type Props = {
  children: ReactNode;
} & HTMLAttributes<HTMLHeadingElement>;

const ContentPageSectionHeader = ({ children, className, ...rest }: Props) => {
  const classes = cx('text-2xl', className);

  return (
    <Heading className={classes} {...rest}>
      {children}
    </Heading>
  );
};

export default ContentPageSectionHeader;
