import React from 'react';
import ContentLoader from 'react-content-loader';

import envConfig from '../../../config/env';
import tailwind from '../../../config/tailwind';

const RowChartTypeCContentLoader = () => (
  <ContentLoader
    ariaLabel="Loading Chart"
    height={415}
    primaryColor={tailwind.theme.colors.brand.light}
    secondaryColor={tailwind.theme.colors.brand['light-extra']}
    speed={envConfig.twitterChartsContentLoaderSpeed}
    width={420}
  >
    <rect x="20" y="3" rx="2" ry="2" width="176" height="30" />
    <rect x="20" y="35" rx="2" ry="2" width="37" height="30" />
    <rect x="20" y="67" rx="2" ry="2" width="28" height="30" />
    <rect x="20" y="99" rx="2" ry="2" width="28" height="30" />
    <rect x="20" y="131" rx="2" ry="2" width="18" height="30" />
    <rect x="20" y="163" rx="2" ry="2" width="18" height="30" />
    <rect x="20" y="195" rx="2" ry="2" width="18" height="30" />
    <rect x="20" y="227" rx="2" ry="2" width="18" height="30" />
    <rect x="20" y="259" rx="2" ry="2" width="18" height="30" />
    <rect x="20" y="291" rx="2" ry="2" width="18" height="30" />
    <rect x="20" y="323" rx="2" ry="2" width="388" height="30" />
  </ContentLoader>
);

export default RowChartTypeCContentLoader;
