import React from 'react';
import dc from 'dc';
import { compareDesc } from 'date-fns';
import { observer } from 'mobx-react';
import { throttle, uniqBy } from 'lodash';

import LastTweetsGrid from '../../../components/LastTweetsGrid';
import LastTweetsGridTweetContainer from './LastTweetsGridTweet';
import useIsMounted from '../../../utils/hooks/useIsMounted';

// Types
import { Crossfilter } from 'crossfilter2';
import { DataCountWidget } from 'dc';
import { TwitterNormalizedTweet } from '../../../types/twitter';
import { TwitterStore } from '../../../data/models/twitter/TwitterStore';
import { TwitterStreamStore } from '../../../data/models/twitter/TwitterStreamStore';

type Props = {
  crossfilterInstance: Crossfilter<TwitterNormalizedTweet>;
  twitterStore: TwitterStore | TwitterStreamStore;
};

const LastTweetsGridContainer = ({ crossfilterInstance, twitterStore }: Props) => {
  const isMounted = useIsMounted();

  const [lastTweets, setLastTweets] = React.useState<TwitterNormalizedTweet[]>([]);
  const [lastTweetsCount, setLastTweetsCount] = React.useState<number>(12);

  const all = crossfilterInstance.groupAll();

  const handleCallback = throttle((dataCount: DataCountWidget) => {
    // @ts-ignore
    setLastTweets(dataCount.crossfilter().allFiltered());
  }, 500);

  React.useEffect(() => {
    const dataCountInstance = dc
      .dataCount('.last-tweets')
      .on('renderlet', handleCallback)

      // @ts-ignore
      .crossfilter(crossfilterInstance)
      .groupAll(all);

    return () => {
      dataCountInstance.on('renderlet', null);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLoadMoreLastTweets = () => {
    if (isMounted.current) {
      setLastTweetsCount(prevLastTweetsCount => prevLastTweetsCount + 12);
    }
  };

  const mappedLastTweets = uniqBy(lastTweets.slice(0, lastTweetsCount), tweet => tweet.id)
    .sort((a, b) => compareDesc(a.timestamp, b.timestamp))
    .map(lastTweet => <LastTweetsGridTweetContainer key={lastTweet.id} tweet={lastTweet} />);

  return (
    <LastTweetsGrid
      isAccountProtected={twitterStore.isAccountProtected}
      lastTweets={mappedLastTweets}
      lastTweetsCount={lastTweetsCount}
      onLoadMoreTweets={handleLoadMoreLastTweets}
      totalTweetsCount={twitterStore.tweets.length}
    />
  );
};

export default observer(LastTweetsGridContainer);
