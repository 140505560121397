// Source: https://ui.reach.tech/tooltip/
export const centered = (triggerRect: DOMRect, tooltipRect: DOMRect): DOMRect => {
  const triggerCenter = triggerRect.left + triggerRect.width / 2;
  const left = triggerCenter - tooltipRect.width / 2;
  const maxLeft = window.innerWidth - tooltipRect.width - 2;

  const centeredRect = {
    left: Math.min(Math.max(2, left), maxLeft) + window.scrollX,
    top: triggerRect.bottom + 8 + window.scrollY,
  } as DOMRect;

  return centeredRect;
};
