import React from 'react';

import cx from '../../../../utils/styling/cx';

// Types
import { TwitterTweetType } from '../../../../types/twitter';

type Props = {
  type: TwitterTweetType;
};

const LastTweetsGridTweetType = ({ type }: Props) => {
  return (
    <span
      className={cx(
        'shadow-inner absolute inline-flex justify-center items-baseline text-sm mt-2 mr-2 px-2 rounded top-0 right-0 text-white',
        {
          'bg-blue-600': type === TwitterTweetType.Retweet,
          'bg-orange-600': type === TwitterTweetType.SelfReply,
          'bg-pink-600': type === TwitterTweetType.Tweet,
          'bg-purple-600': type === TwitterTweetType.Quote,
          'bg-teal-600': type === TwitterTweetType.Reply,
        },
      )}
    >
      {type}
    </span>
  );
};

export default LastTweetsGridTweetType;
