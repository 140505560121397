import React from 'react';
import { Sparklines, SparklinesCurve } from 'react-sparklines';
import { observer } from 'mobx-react';

import ChartHeading from '../../../components/Chart/ChartHeading';
import tailwind from '../../../config/tailwind';
import useInterval from '../../../utils/hooks/useInterval';

import { calculatePeakPressure, calculatePressure, formatCurrentPressure, getCurrentPressureObject } from './utils';

// Types
import { TwitterStreamStore } from '../../../data/models/twitter/TwitterStreamStore';

const DEFAULT_PRESSURE_VALUE = 0;
const MAX_POINTS = 100;
const PRESSURE_SPARKLINE_UPDATE_MS = 1000;

const defaultPressureState = Array(MAX_POINTS).fill(DEFAULT_PRESSURE_VALUE);

type Props = {
  twitterStore: TwitterStreamStore;
};

const TweetsPressure = ({ twitterStore }: Props) => {
  const [peakPressure, setPeakPressure] = React.useState<number>(10);
  const [pressures, setPressures] = React.useState<number[]>(defaultPressureState);
  const { startStreamStartDate, streamStartDate, term, tweetsCount } = twitterStore;

  React.useEffect(() => {
    if (!streamStartDate && tweetsCount > 0) {
      startStreamStartDate();
    }
  }, [startStreamStartDate, streamStartDate, tweetsCount]);

  const handleCalculatePressure = () => {
    setPressures(previousPressures => calculatePressure({ maxPoints: MAX_POINTS, previousPressures, streamStartDate, tweetsCount }));
    setPeakPressure(previousPeakPressure => calculatePeakPressure({ pressures, previousPeakPressure }));
  };

  useInterval(handleCalculatePressure, PRESSURE_SPARKLINE_UPDATE_MS);

  const { currentPressure, unit } = getCurrentPressureObject(pressures);

  const mappedPressures = pressures.map(formatCurrentPressure);

  React.useEffect(() => {
    return () => {
      setPressures(defaultPressureState);
    };
  }, [term]);

  return (
    <div className="relative max-h-48">
      <ChartHeading>Pressure</ChartHeading>

      <div className="mx-2 text-brand text-6xl absolute top-0 left-0 right-0 bottom-0 text-center flex flex-row content-center items-center justify-around flex-wrap">
        <div className="flex flex-col content-center items-center justify-center w-1/2">
          <span className="text-brand-dark font-semibold text-3xl text-center break-all leading-none">#{term}</span>

          <span className="text-gray-800 font-semibold text-lg text-center uppercase mt-0">Term</span>
        </div>

        <div className="flex flex-col content-center items-center justify-center w-1/2">
          <span className="text-brand-dark font-semibold text-3xl text-center break-all leading-none">
            {currentPressure > 0 && '~'}
            {currentPressure}
          </span>

          <span className="text-gray-800 font-semibold text-lg text-center uppercase mt-0">Tweets/{unit}</span>
        </div>
      </div>

      <div className="max-w-full h-full mt-2 rounded">
        <Sparklines
          data={mappedPressures}
          height={30}
          limit={MAX_POINTS}
          margin={0}
          max={peakPressure + 2}
          min={0}
          style={{ borderRadius: '0.5rem' }}
          width={120}
        >
          <SparklinesCurve color={tailwind.theme.colors.brand.default} />
        </Sparklines>
      </div>
    </div>
  );
};

export default observer(TweetsPressure);
