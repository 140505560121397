import { addDays, subDays, parse } from 'date-fns';

// Types
import { Crossfilter } from 'crossfilter2';
import { TwitterNormalizedTweet } from '../../../types/twitter';

export const getDateRange = (crossfilter: Crossfilter<TwitterNormalizedTweet>) => {
  const tweets = crossfilter.all();
  const firstDate = tweets.length > 0 ? parse(tweets[0].date) : new Date();
  const lastDate = tweets.length > 0 ? parse(tweets[tweets.length - 1].date) : new Date();

  return [subDays(lastDate, 1), addDays(firstDate, 1)];
};
