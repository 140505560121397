import { format } from 'date-fns';

const getFriendlyDateTime = (timestamp?: string | number | null): string | null => {
  if (!timestamp) {
    return null;
  }

  const formattedTimestamp = format(timestamp, 'YYYY-MM-DD, HH:mm');

  return formattedTimestamp;
};

export default getFriendlyDateTime;
