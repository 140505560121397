const routes = {
  about: '/about',
  account: '/account',
  attribution: '/attribution',
  authTwitterLogin: '/auth/twitter/signIn',
  contact: '/contact',
  home: '/',
  imprint: '/imprint',
  plans: '/plans',
  privacyPolicy: '/privacy',
  signIn: '/signIn',
  termsOfService: '/tos',
  twitterAccount: '/twitter/account',
  twitterStream: '/twitter/stream',
  withParams: {
    twitterAccount: '/twitter/account/:username',
    twitterStream: '/twitter/stream/:term',
  },
};

export default routes;
