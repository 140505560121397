import React from 'react';

import AppContainer from '../../containers/App';
import SignInContainer from '../../containers/SignIn';

type Props = {};

const SignInPage = (_props: Props) => {
  return (
    <AppContainer>
      <SignInContainer />
    </AppContainer>
  );
};

export default SignInPage;
