import React from 'react';

import ContentPageHeader from '../ContentPageItems/ContentPageHeader';
import ContentPageLayout from '../ContentPageItems/ContentPageLayout';
import ContentPageParagraph from '../ContentPageItems/ContentPageParagraph';
import ContentPageSection from '../ContentPageItems/ContentPageSection';
import ContentPageSectionHeader from '../ContentPageItems/ContentPageSectionHeader';
import envConfig from '../../config/env';
import ContentPageParagraphLink from '../ContentPageItems/ContentPageParagraphLink';
import routes from '../../config/routing/routes';

type Props = {};

const PrivacyPolicy = (_props: Props) => {
  return (
    <ContentPageLayout>
      <ContentPageHeader>Privacy Policy</ContentPageHeader>

      <ContentPageParagraph>Effective date 28 August 2019</ContentPageParagraph>

      <ContentPageSection>
        <ContentPageSectionHeader>Introduction</ContentPageSectionHeader>

        <ContentPageParagraph>
          Welcome to {envConfig.appName} by Luca Hammer. Luca Hammer (“us”, “we”, or “our”) operates {'https://accountanalysis.app'}{' '}
          (hereinafter referred to as “Service”). Our{' '}
          <ContentPageParagraphLink url={routes.privacyPolicy}>Privacy Policy</ContentPageParagraphLink> governs your visit to
          {'https://accountanalysis.app'}, and explains how we collect, safeguard and disclose information that results from your use of our
          Service. We use your data to provide and improve Service. By using Service, you agree to the collection and use of information in
          accordance with this policy. Unless otherwise defined in this Privacy Policy, the terms used in this Privacy Policy have the same
          meanings as in our <ContentPageParagraphLink url={routes.termsOfService}>Terms and Conditions</ContentPageParagraphLink>. Our
          Terms and Conditions (“Terms”) govern all use of our Service and together with the Privacy Policy constitutes your agreement with
          us (“agreement”).
        </ContentPageParagraph>
      </ContentPageSection>

      <ContentPageSection>
        <ContentPageSectionHeader>Definitions</ContentPageSectionHeader>

        <ContentPageParagraph>SERVICE means the {'https://accountanalysis.app'} website operated by Luca Hammer.</ContentPageParagraph>
        <ContentPageParagraph>
          PERSONAL DATA means data about a living individual who can be identified from those data (or from those and other information
          either in our possession or likely to come into our possession).
        </ContentPageParagraph>
        <ContentPageParagraph>
          USAGE DATA is data collected automatically either generated by the use of Service or from Service infrastructure itself (for
          example, the duration of a page visit).
        </ContentPageParagraph>
        <ContentPageParagraph>COOKIES are small files stored on your device (computer or mobile device).</ContentPageParagraph>
        <ContentPageParagraph>
          DATA CONTROLLER means a natural or legal person who (either alone or jointly or in common with other persons) determines the
          purposes for which and the manner in which any personal data are, or are to be, processed. For the purpose of this Privacy Policy,
          we are a Data Controller of your data.
        </ContentPageParagraph>
        <ContentPageParagraph>
          DATA PROCESSORS (OR SERVICE PROVIDERS) means any natural or legal person who processes the data on behalf of the Data Controller.
          We may use the services of various Service Providers in order to process your data more effectively.
        </ContentPageParagraph>
        <ContentPageParagraph>DATA SUBJECT is any living individual who is the subject of Personal Data.</ContentPageParagraph>
        <ContentPageParagraph>
          THE USER is the individual using our Service. The User corresponds to the Data Subject, who is the subject of Personal Data.
        </ContentPageParagraph>
      </ContentPageSection>

      <ContentPageSection>
        <ContentPageSectionHeader>Information Collection and Use</ContentPageSectionHeader>

        <ContentPageParagraph>
          We collect several different types of information for various purposes to provide and improve our Service to you.
        </ContentPageParagraph>
      </ContentPageSection>

      <ContentPageSection>
        <ContentPageSectionHeader>Types of Data Collected</ContentPageSectionHeader>

        <ContentPageSectionHeader>Personal Data</ContentPageSectionHeader>
        <ContentPageParagraph>
          While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact
          or identify you (“Personal Data”). Personally identifiable information may include, but is not limited to:
        </ContentPageParagraph>
        <ContentPageParagraph>(a) Twitter user ID and screenname</ContentPageParagraph>
        <ContentPageParagraph>(a) Email address</ContentPageParagraph>
        <ContentPageParagraph>(b) Cookies and Usage Data</ContentPageParagraph>
        <ContentPageParagraph>
          We may use your Personal Data to contact you with newsletters, marketing or promotional materials and other information that may
          be of interest to you. You may opt out of receiving any, or all, of these communications from us by following the unsubscribe link
          or by emailing at contact@lucahammer.com or by sending a reply or direct message to @accountanalysis on Twitter.
        </ContentPageParagraph>

        <ContentPageSectionHeader>Usage Data</ContentPageSectionHeader>
        <ContentPageParagraph>
          We may also collect information that your browser sends whenever you visit our Service or when you access Service by or through a
          mobile device (“Usage Data”).
        </ContentPageParagraph>
        <ContentPageParagraph>
          This Usage Data may include information such as your computer&#39;s Internet Protocol address (e.g. IP address), browser type,
          browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, unique
          device identifiers and other diagnostic data.
        </ContentPageParagraph>
        <ContentPageParagraph>
          When you access Service with a mobile device, this Usage Data may include information such as the type of mobile device you use,
          your mobile device unique ID, the IP address of your mobile device, your mobile operating system, the type of mobile Internet
          browser you use, unique device identifiers and other diagnostic data.
        </ContentPageParagraph>

        <ContentPageSectionHeader>Tracking Cookies Data</ContentPageSectionHeader>
        <ContentPageParagraph>
          We use cookies and similar tracking technologies to track the activity on our Service and we hold certain information.
        </ContentPageParagraph>
        <ContentPageParagraph>
          Cookies are files with a small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser
          from a website and stored on your device. Other tracking technologies are also used such as beacons, tags and scripts to collect
          and track information and to improve and analyze our Service.
        </ContentPageParagraph>
        <ContentPageParagraph>
          You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept
          cookies, you may not be able to use some portions of our Service.
        </ContentPageParagraph>
      </ContentPageSection>

      <ContentPageSection>
        <ContentPageSectionHeader>Use of Data</ContentPageSectionHeader>

        <ContentPageParagraph>{envConfig.appName} by Luca Hammer uses the collected data for various purposes:</ContentPageParagraph>
        <ContentPageParagraph>(a) to provide and maintain our Service;</ContentPageParagraph>
        <ContentPageParagraph>(b) to notify you about changes to our Service; </ContentPageParagraph>
        <ContentPageParagraph>
          (c) to allow you to participate in interactive features of our Service when you choose to do so;{' '}
        </ContentPageParagraph>
        <ContentPageParagraph>(d) to provide customer support; </ContentPageParagraph>
        <ContentPageParagraph>(e) to gather analysis or valuable information so that we can improve our Service; </ContentPageParagraph>
        <ContentPageParagraph>(f) to monitor the usage of our Service;</ContentPageParagraph>
        <ContentPageParagraph>(g) to detect, prevent and address technical issues;</ContentPageParagraph>
        <ContentPageParagraph>(h) to fulfill any other purpose for which you provide it;</ContentPageParagraph>
        <ContentPageParagraph>
          (i) to carry out our obligations and enforce our rights arising from any contracts entered into between you and us, including for
          billing and collection;
        </ContentPageParagraph>
        <ContentPageParagraph>
          (j) to provide you with notices about your account and/or subscription, including expiration and renewal notices,
          email-instructions, etc.;
        </ContentPageParagraph>
        <ContentPageParagraph>
          (k) to provide you with news, special offers and general information about other goods, services and events which we offer that
          are similar to those that you have already purchased or enquired about unless you have opted not to receive such information;
        </ContentPageParagraph>
        <ContentPageParagraph>(l) in any other way we may describe when you provide the information;</ContentPageParagraph>
        <ContentPageParagraph>(m) for any other purpose with your consent. </ContentPageParagraph>
      </ContentPageSection>

      <ContentPageSection>
        <ContentPageSectionHeader>Retention of Data</ContentPageSectionHeader>

        <ContentPageParagraph>
          We will retain your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain
          and use your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain
          your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.
        </ContentPageParagraph>
        <ContentPageParagraph>
          We will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period, except when
          this data is used to strengthen the security or to improve the functionality of our Service, or we are legally obligated to retain
          this data for longer time periods.
        </ContentPageParagraph>
      </ContentPageSection>

      <ContentPageSection>
        <ContentPageSectionHeader>Transfer of Data</ContentPageSectionHeader>

        <ContentPageParagraph>
          Your information, including Personal Data, may be transferred to – and maintained on – computers located outside of your state,
          province, country or other governmental jurisdiction where the data protection laws may differ from those of your jurisdiction. If
          you are located outside United States and choose to provide information to us, please note that we transfer the data, including
          Personal Data, to United States and process it there. Your consent to this Privacy Policy followed by your submission of such
          information represents your agreement to that transfer. {envConfig.appName} by Luca Hammer will take all the steps reasonably
          necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal
          Data will take place to an organisation or a country unless there are adequate controls in place including the security of your
          data and other personal information.
        </ContentPageParagraph>
      </ContentPageSection>

      <ContentPageSection>
        <ContentPageSectionHeader>Disclosure of Data</ContentPageSectionHeader>

        <ContentPageParagraph>
          (a) Disclosure for Law Enforcement. Under certain circumstances, we may be required to disclose your Personal Data if required to
          do so by law or in response to valid requests by public authorities.
        </ContentPageParagraph>
        <ContentPageParagraph>
          (b) Business Transaction. If we or our subsidiaries are involved in a merger, acquisition or asset sale, your Personal Data may be
          transferred.
        </ContentPageParagraph>
        <ContentPageParagraph>
          (c) Other cases. We may disclose your information also: (i) to contractors, service providers, and other third parties we use to
          support our business;
        </ContentPageParagraph>
      </ContentPageSection>

      <ContentPageSection>
        <ContentPageSectionHeader>Security of Data</ContentPageSectionHeader>

        <ContentPageParagraph>
          The security of your data is important to us but remember that no method of transmission over the Internet or method of electronic
          storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee
          its absolute security.
        </ContentPageParagraph>
      </ContentPageSection>

      <ContentPageSection>
        <ContentPageSectionHeader>Your Data Protection Rights Under General Data Protection Regulation (GDPR)</ContentPageSectionHeader>

        <ContentPageParagraph>
          If you are a resident of the European Union (EU) and European Economic Area (EEA), you have certain data protection rights,
          covered by GDPR. – See more at https://eur-lex.europa.eu/eli/reg/2016/679/oj{' '}
        </ContentPageParagraph>
        <ContentPageParagraph>
          We aim to take reasonable steps to allow you to correct, amend, delete, or limit the use of your Personal Data.
        </ContentPageParagraph>
        <ContentPageParagraph>
          If you wish to be informed what Personal Data we hold about you and if you want it to be removed from our systems, please email us
          at contact@lucahammer.com.{' '}
        </ContentPageParagraph>
        <ContentPageParagraph>In certain circumstances, you have the following data protection rights:</ContentPageParagraph>
        <ContentPageParagraph>(a) the right to access, update or to delete the information we have on you;</ContentPageParagraph>
        <ContentPageParagraph>
          (b) the right of rectification. You have the right to have your information rectified if that information is inaccurate or
          incomplete;
        </ContentPageParagraph>
        <ContentPageParagraph>
          (c) the right to object. You have the right to object to our processing of your Personal Data;
        </ContentPageParagraph>
        <ContentPageParagraph>
          (d) the right of restriction. You have the right to request that we restrict the processing of your personal information;
        </ContentPageParagraph>
        <ContentPageParagraph>
          (e) the right to data portability. You have the right to be provided with a copy of your Personal Data in a structured,
          machine-readable and commonly used format;
        </ContentPageParagraph>
        <ContentPageParagraph>
          (f) the right to withdraw consent. You also have the right to withdraw your consent at any time where we rely on your consent to
          process your personal information;
        </ContentPageParagraph>
        <ContentPageParagraph>
          Please note that we may ask you to verify your identity before responding to such requests. Please note, we may not able to
          provide Service without some necessary data.
        </ContentPageParagraph>
        <ContentPageParagraph>
          You have the right to complain to a Data Protection Authority about our collection and use of your Personal Data. For more
          information, please contact your local data protection authority in the European Economic Area (EEA).
        </ContentPageParagraph>
      </ContentPageSection>

      <ContentPageSection>
        <ContentPageSectionHeader>Service Providers</ContentPageSectionHeader>

        <ContentPageParagraph>
          We may employ third party companies and individuals to facilitate our Service (“Service Providers”), provide Service on our
          behalf, perform Service-related services or assist us in analysing how our Service is used.
        </ContentPageParagraph>
        <ContentPageParagraph>
          These third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose
          or use it for any other purpose.
        </ContentPageParagraph>
        <ContentPageParagraph>We use Chargebee to handle Subscriptions and payment.</ContentPageParagraph>
        <ContentPageParagraph>We use the Twitter API to retreive data in your name</ContentPageParagraph>
        <ContentPageParagraph>We use Twitter embeds to show full Tweets.</ContentPageParagraph>
      </ContentPageSection>

      <ContentPageSection>
        <ContentPageSectionHeader>Analytics</ContentPageSectionHeader>

        <ContentPageParagraph>We may use third-party Service Providers to monitor and analyze the use of our Service.</ContentPageParagraph>
        <ContentPageParagraph>
          Piwik or Matomo is a web analytics service. You can visit their Privacy Policy page here: https://matomo.org/privacy-policy
        </ContentPageParagraph>
      </ContentPageSection>

      <ContentPageSection>
        <ContentPageSectionHeader>Payments</ContentPageSectionHeader>

        <ContentPageParagraph>
          We may provide paid products and/or services within Service. In that case, we use third-party services for payment processing
          (e.g. payment processors).
        </ContentPageParagraph>
        <ContentPageParagraph>
          We will not store or collect your payment card details. That information is provided directly to our third-party payment
          processors whose use of your personal information is governed by their Privacy Policy. These payment processors adhere to the
          standards set by PCI-DSS as managed by the PCI Security Standards Council, which is a joint effort of brands like Visa,
          Mastercard, American Express and Discover. PCI-DSS requirements help ensure the secure handling of payment information.
        </ContentPageParagraph>
        <ContentPageParagraph>The payment processors we work with are:</ContentPageParagraph>
        <ContentPageParagraph>
          <em>Chargebee</em>: Their Privacy Policy can be viewed at:
          {'https://www.chargebee.com/privacy/'}
        </ContentPageParagraph>
      </ContentPageSection>

      <ContentPageSection>
        <ContentPageSectionHeader>Changes to This Privacy Policy</ContentPageSectionHeader>

        <ContentPageParagraph>
          We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this
          page.
        </ContentPageParagraph>
        <ContentPageParagraph>
          We will let you know via email and/or a prominent notice on our Service, prior to the change becoming effective and update
          “effective date” at the top of this Privacy Policy.
        </ContentPageParagraph>
        <ContentPageParagraph>
          You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they
          are posted on this page.
        </ContentPageParagraph>
      </ContentPageSection>

      <ContentPageSection>
        <ContentPageSectionHeader>Contact Us</ContentPageSectionHeader>

        <ContentPageParagraph>
          If you have any questions about this Privacy Policy, please contact us by email: contact@lucahammer.com.
        </ContentPageParagraph>
      </ContentPageSection>
    </ContentPageLayout>
  );
};

export default PrivacyPolicy;
