import React from 'react';
import pluralize from 'pluralize';

import Link from '../../Link';
import Tooltip from '../../Tooltip';
import envConfig from '../../../config/env';
import formatNumberWithCommas from '../../../utils/number/formatNumberWithCommas';

type Props = {
  followersCount: number;
  followingCount: number;
  likedCount: number;
  listedCount: number;
  tweetsCount: number;
  username: string;
};

const TwitterUserCardStatistics = ({ followersCount, followingCount, likedCount, listedCount, tweetsCount, username }: Props) => {
  const followersLabel = `How many accounts follow @${username}. Click the number to see all followers.`;
  const followingLabel = `How many accounts @${username} follows. Click the number to see all following.`;
  const likesLabel = `How many tweets @${username} liked. Click the number to see all likes.`;
  const listedLabel = `How many public lists @${username} is a member of. Click the number to see all lists.`;
  const tweetsLabel = `How many tweets @${username} has published. Click the number to see all tweets.`;

  return (
    <div className="flex flex-row flex-wrap mt-2">
      <Tooltip ariaLabel={tweetsLabel} label={tweetsLabel}>
        <div className="flex flex-col mr-3 mb-2">
          <Link className="text-brand font-semibold text-lg hover:text-brand-dark" url={`${envConfig.twitterUrl}/${username}/with_replies`}>
            {formatNumberWithCommas(tweetsCount)}
          </Link>

          <span className="text-gray-500 uppercase">{pluralize('Tweets', tweetsCount)}</span>
        </div>
      </Tooltip>

      <Tooltip ariaLabel={followingLabel} label={followingLabel}>
        <div className="flex flex-col mr-3 mb-2">
          <Link className="text-brand font-semibold text-lg hover:text-brand-dark" url={`${envConfig.twitterUrl}/${username}/following`}>
            {formatNumberWithCommas(followingCount)}
          </Link>

          <span className="text-gray-500 uppercase">Following</span>
        </div>
      </Tooltip>

      <Tooltip ariaLabel={followersLabel} label={followersLabel}>
        <div className="flex flex-col mr-3 mb-2">
          <Link className="text-brand font-semibold text-lg hover:text-brand-dark" url={`${envConfig.twitterUrl}/${username}/followers`}>
            {formatNumberWithCommas(followersCount)}
          </Link>

          <span className="text-gray-500 uppercase">{pluralize('Followers', followersCount)}</span>
        </div>
      </Tooltip>

      <Tooltip ariaLabel={likesLabel} label={likesLabel}>
        <div className="flex flex-col mr-3 mb-2">
          <Link className="text-brand font-semibold text-lg hover:text-brand-dark" url={`${envConfig.twitterUrl}/${username}/likes`}>
            {formatNumberWithCommas(likedCount)}
          </Link>

          <span className="text-gray-500 uppercase">{pluralize('Like', likedCount)}</span>
        </div>
      </Tooltip>

      <Tooltip ariaLabel={listedLabel} label={listedLabel}>
        <div className="flex flex-col mr-3 mb-2">
          <Link
            className="text-brand font-semibold text-lg hover:text-brand-dark"
            url={`${envConfig.twitterUrl}/${username}/lists/memberships`}
          >
            {formatNumberWithCommas(listedCount)}
          </Link>

          <span className="text-gray-500 uppercase">Listed</span>
        </div>
      </Tooltip>
    </div>
  );
};

export default TwitterUserCardStatistics;
