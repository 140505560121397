import envConfig from '../../config/env';

type BuildTweetLinkInput = {
  tweetId: string;
  username: string;
};

const buildTweetLink = ({ tweetId, username }: BuildTweetLinkInput) => {
  return `${envConfig.twitterUrl}/${username}/status/${tweetId}`;
};

export default buildTweetLink;
