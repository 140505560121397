import React from 'react';
import dc from 'dc';
import { throttle } from 'lodash';

import DataCount from '../../../components/DataCount';
import { useTwitterCrossfilterContext } from '../../../utils/context/TwitterCrossfilterContext';

// Types
import { DataCountWidget } from 'dc';
import { Crossfilter } from 'crossfilter2';
import { TwitterNormalizedTweet } from '../../../types/twitter';

type Props = {
  crossfilterInstance: Crossfilter<TwitterNormalizedTweet>;
  isHelpVisible: boolean;
  onToggleHelp: () => void;
};

const DataCountContainer = ({ crossfilterInstance, isHelpVisible, onToggleHelp }: Props) => {
  const { resetAllFilters } = useTwitterCrossfilterContext();

  const [selectedCount, setSelectedCount] = React.useState<number | null>(null);
  const [totalCount, setTotalCount] = React.useState<number | null>(null);

  const isDisabled = typeof selectedCount !== 'number' || typeof totalCount !== 'number';

  const all = crossfilterInstance.groupAll();

  const handleCallback = throttle((dataCount: DataCountWidget) => {
    // @ts-ignore
    const nextSelectedCount = dataCount.groupAll().value();
    // @ts-ignore
    const nextTotalValue = dataCount.crossfilter().size();

    setSelectedCount(nextSelectedCount);
    setTotalCount(nextTotalValue);
  }, 500);

  React.useEffect(() => {
    const dataCountInstance = dc
      .dataCount('.i-dont-care')
      .on('renderlet', handleCallback)

      // @ts-ignore
      .crossfilter(crossfilterInstance)
      .groupAll(all);

    return () => {
      dataCountInstance.on('renderlet', null);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DataCount
      isDisabled={isDisabled}
      isHelpVisible={isHelpVisible}
      onToggleHelp={onToggleHelp}
      resetAllFilters={resetAllFilters}
      selectedCount={selectedCount}
      totalCount={totalCount}
    />
  );
};

export default DataCountContainer;
