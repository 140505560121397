import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDotCircle } from '@fortawesome/free-solid-svg-icons';

import Tooltip from '../../../Tooltip';
import cx from '../../../../utils/styling/cx';

type Props = {
  isConnected: boolean;
  onStartClick: () => void;
  onStopClick: () => void;
};

const TwitterStreamHeaderConnectionIndicator = ({ isConnected, onStartClick, onStopClick }: Props) => {
  const buttonText = isConnected ? 'Stop' : 'Start';
  const buttonTooltipText = isConnected ? 'Stop stream.' : 'Start stream.';
  const connectionTooltipText = isConnected ? `Connected to stream.` : `Disconnected from stream.`;

  const handleClick = () => {
    if (isConnected) {
      onStopClick();
    } else {
      onStartClick();
    }
  };

  return (
    <div className="relative text-gray-400 flex items-center focus-within:text-brand">
      <Tooltip ariaLabel={connectionTooltipText} label={connectionTooltipText}>
        <span className={cx('bg-gray-200 h-10 px-4 py-2 rounded-l-lg', { 'text-green-500': isConnected, 'text-red-600': !isConnected })}>
          <FontAwesomeIcon icon={faDotCircle} />
        </span>
      </Tooltip>

      <Tooltip ariaLabel={buttonTooltipText} label={buttonTooltipText}>
        <button
          className="h-auto bg-gray-200 text-gray-800 h-10 px-4 py-2 rounded-r-lg hover:bg-gray-400"
          onClick={handleClick}
          type="button"
        >
          {buttonText}
        </button>
      </Tooltip>
    </div>
  );
};

export default TwitterStreamHeaderConnectionIndicator;
