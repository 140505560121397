import React from 'react';

import AppContainer from '../../containers/App';
import PrivacyPolicyContainer from '../../containers/PrivacyPolicy';

type Props = {};

const PrivacyPolicyPage = (_props: Props) => {
  return (
    <AppContainer>
      <PrivacyPolicyContainer />
    </AppContainer>
  );
};

export default PrivacyPolicyPage;
