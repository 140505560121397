import React from 'react';

import Button from '../Button';
import Heading from '../Heading';

// Types
import { ReactNode } from 'react';

type Props = {
  isAccountProtected: boolean;
  lastTweets: ReactNode;
  lastTweetsCount: number;
  onLoadMoreTweets: () => void;
  totalTweetsCount: number;
};

const LastTweetsGrid = ({ isAccountProtected, lastTweetsCount, lastTweets, onLoadMoreTweets, totalTweetsCount }: Props) => {
  return (
    <>
      <Heading className="ml-4 mt-2 mb-4 text-lg font-semibold">Last {lastTweetsCount} Tweets (in selection)</Heading>

      {isAccountProtected && (
        <div className="mx-auto flex flex-col" style={{ minHeight: 200 }}>
          <span className="mx-auto font-semibold text-gray-400 tracking-wide" style={{ paddingTop: 60 }}>
            {`Can't embed tweets of protected accounts.`}
          </span>
        </div>
      )}

      {!isAccountProtected && (
        <div className="p-2">
          <div className="flex flex-col md:flex-row md:flex-wrap items-center md:items-start justify-around -m-2">{lastTweets}</div>

          {lastTweetsCount < totalTweetsCount && (
            <div className="flex flex-col items-center mt-8 mb-2">
              <Button onClick={onLoadMoreTweets}>Load up to 12 more tweets</Button>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default LastTweetsGrid;
