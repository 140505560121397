import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider as MobxProvider } from 'mobx-react';
import { onSnapshot } from 'mobx-state-tree';

import AuthStoreModel from '../../data/models/auth/AuthStore';
import ContextProviderCrossfilter from './ContextProviderCrossfilter';
import TwitterStoreModel from '../../data/models/twitter/TwitterStore';
import TwitterStreamStoreModel from '../../data/models/twitter/TwitterStreamStore';
import envConfig from '../../config/env';

// Types
import { ReactNode } from 'react';

// Setup MST-Stores

const authStore = AuthStoreModel.create({
  user: null,
  userError: false,
  userFetched: false,
  userLoading: false,
});

const twitterStore = TwitterStoreModel.create({
  isMockStore: false,
  tweets: [],
  tweetsError: false,
  tweetsFetches: 0,
  tweetsLoading: false,
  tweetsLoadingMore: false,
  user: null,
  userError: false,
  userLoading: false,
  userNotFound: false,
  username: null,
});

const twitterStreamStore = TwitterStreamStoreModel.create({
  isMockStore: false,
  tweets: [],
  tweetsError: false,
  tweetsLoading: false,
});

if (envConfig.isDev) {
  (window as any).authStore = authStore;
  (window as any).twitterStore = twitterStore;
  (window as any).twitterStreamStore = twitterStreamStore;
  (window as any).stores = { authStore, twitterStore, twitterStreamStore };

  // Log when anything changes on the store.
  onSnapshot(authStore, _snapshot => {
    // eslint-disable-next-line no-console
    // console.dir(snapshot);
  });
}

type Props = {
  children: ReactNode;
};

const ContextProvider = ({ children }: Props) => {
  return (
    <Router>
      <MobxProvider authStore={authStore} twitterStore={twitterStore} twitterStreamStore={twitterStreamStore}>
        <ContextProviderCrossfilter>{children}</ContextProviderCrossfilter>
      </MobxProvider>
    </Router>
  );
};

export default ContextProvider;
