// Source: https://stackoverflow.com/a/42755876/4951214

class ExtendedError extends Error {
  constructor(message: string) {
    super(message);

    this.message = message;
    this.name = this.constructor.name;

    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, this.constructor);
    } else {
      this.stack = new Error(message).stack;
    }
  }
}

class RethrownError extends ExtendedError {
  original: Error;

  newStack: string;

  constructor(error: Error, ...message: any[]) {
    super([...message].join(' '));

    if (!error) {
      throw new Error('RethrownError requires a message and error');
    }

    this.original = error;
    this.newStack = this.stack || '';

    const messageLines = (this.message.match(/\n/g) || []).length + 1;

    this.stack = `${this.newStack
      .split('\n')
      .slice(0, messageLines + 1)
      .join('\n')}\n${error.stack}`;
  }
}

export default RethrownError;
