import getLocalizedDate from './getLocalizedDate';
import getDate from './getDate';
import getDayOfWeek from './getDayOfWeek';
import getTimeOfDay from './getTimeOfDay';

// Types
import { TwitterMinimizedTweet } from '../../../../types/twitter';
import { TwitterNormalizedTweet } from '../../../../types/twitter';

const normalizeTweet = ({
  id,
  language,
  length,
  name,
  profileImageUrl,
  quotedUser,
  repliedUser,
  retweetedUser,
  text,
  timestamp,
  type,
  url,
  usedApp,
  usedHashtags,
  usedHostnames,
  username,
}: TwitterMinimizedTweet): TwitterNormalizedTweet | null => {
  const localizedDate = getLocalizedDate(timestamp);
  const date = getDate(localizedDate);
  const dayOfWeek = getDayOfWeek(localizedDate);
  const timeOfDay = getTimeOfDay(localizedDate);

  const normalizedTweet = {
    date,
    dayOfWeek,
    id,
    language,
    length,
    name,
    profileImageUrl,
    quotedUser,
    repliedUser,
    retweetedUser,
    text,
    timeOfDay,
    timestamp,
    type,
    url,
    usedApp,
    usedHashtags,
    usedHostnames,
    username,
  };

  return normalizedTweet;
};

export default normalizeTweet;
