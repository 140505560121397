import React from 'react';
import dc from 'dc';

import RowChartTypeCContentLoader from '../../../components/Chart/ChartContentLoaders/RowChartTypeCContentLoader';
import TwitterChartContainer from '../../TwitterChart';
import buildRowChartTitle from '../../../utils/dc/buildRowChartTitle';
import envConfig from '../../../config/env';
import removeRecordsWithEmptyKeys from '../../../utils/dc/removeRecordsWithEmptyKeys';

// Types
import { ChartFunctionInput, RowChartDatum } from '../../../types/twitterCharts';
import { Crossfilter } from 'crossfilter2';
import { TwitterNormalizedTweet } from '../../../types/twitter';
import { TwitterStore } from '../../../data/models/twitter/TwitterStore';
import { TwitterStreamStore } from '../../../data/models/twitter/TwitterStreamStore';

const createQuotedUserChart = ({ crossfilter, divRef }: ChartFunctionInput) => {
  const quotedUserChart = dc.rowChart(divRef);

  const dimension = crossfilter.dimension(tweet => tweet.quotedUser || '');

  const group = removeRecordsWithEmptyKeys(dimension.group());

  quotedUserChart
    .width(420)
    .height(415)
    .useViewBoxResizing(true)
    .margins({ bottom: 50, left: 12, right: 20, top: 0 })

    .dimension(dimension)
    .group(group)

    .label(({ key, value }: RowChartDatum) => `${key} (${value})`)
    .title((datum: RowChartDatum) => buildRowChartTitle(datum))

    .fixedBarHeight(30)
    .elasticX(true)
    .cap(envConfig.twitterChartsCapLarge)
    .gap(envConfig.twitterChartsGap)
    .xAxis()
    .ticks(4);

  return quotedUserChart;
};

type Props = {
  crossfilterInstance: Crossfilter<TwitterNormalizedTweet>;
  twitterStore: TwitterStore | TwitterStreamStore;
};

const QuotedUserChart = ({ crossfilterInstance, twitterStore }: Props) => {
  return (
    <TwitterChartContainer
      chartFunction={createQuotedUserChart}
      crossfilterInstance={crossfilterInstance}
      contentLoader={<RowChartTypeCContentLoader />}
      title="Quoted Users"
      tooltipSelector="g.row"
      twitterStore={twitterStore}
    />
  );
};

export default QuotedUserChart;
