import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { observer } from 'mobx-react';

import AppContainer from '../../containers/App';
import LoadingBar from '../../components/LoadingBar';
import routes from '../../config/routing/routes';
import { useAuthStore } from '../../data/models/auth/AuthStore';

// Types
import { RouteProps, RouteComponentProps } from 'react-router-dom';

type Props = {
  component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
} & RouteProps;

const ProtectedRoute = ({ component: Component, location, ...rest }: Props) => {
  const { isSignedIn, userFetched } = useAuthStore();

  if (!userFetched) {
    return (
      <AppContainer>
        <LoadingBar isLoading={true} />
      </AppContainer>
    );
  }

  if (!isSignedIn) {
    return <Redirect to={{ pathname: routes.signIn, state: { from: location && location.pathname } }} />;
  }

  return <Route {...rest} render={props => <Component {...props} />} />;
};

export default observer(ProtectedRoute);
