import envConfig from '../../config/env';
import querystring from 'querystring';
import routesApi from '../../config/routing/routesApi';

export const buildQuerystring = (input?: string) => {
  if (!input) {
    return '';
  }

  return querystring.stringify({ redirectUrl: input });
};

export const buildUrl = (builtQuerystring: string) => {
  if (!builtQuerystring) {
    return `${envConfig.backendUrl}${routesApi.auth.twitter.signIn}`;
  }

  return `${envConfig.backendUrl}${routesApi.auth.twitter.signIn}?${builtQuerystring}`;
};
