import React from 'react';
import TweetEmbed from 'react-tweet-embed';

import LastTweetsGridTweetType from './LastTweetsGridTweetType';
import Link from '../../Link';
import buildTweetLink from '../../../utils/twitter/buildTweetLink';
import buildTwitterProfileLink from '../../../utils/twitter/buildTwitterProfileLink';
import cx from '../../../utils/styling/cx';
import fromNow from '../../../utils/time/fromNow';
import getFriendlyDateTime from '../../../utils/time/getFriendlyDateTime';

// Type
import { TwitterTweetType } from '../../../types/twitter';

type Props = {
  id: string;
  isRenderingOriginalTweet: boolean;
  name: string;
  onRenderOriginalTweet: () => void;
  text: string | null;
  timestamp: string;
  type: TwitterTweetType;
  username: string;
};

const LastTweetsGridTweet = ({ id, isRenderingOriginalTweet, name, onRenderOriginalTweet, text, timestamp, type, username }: Props) => {
  if (isRenderingOriginalTweet) {
    return <TweetEmbed className="px-2 w-full lg:w-1/2 xl:w-1/3" id={id} key={id} options={{ dnt: true }} />;
  }

  return (
    <div className="p-4 w-full lg:w-1/2 xl:w-1/3">
      <div
        className={cx('flex flex-col shadow rounded text-gray-800 p-2 relative border-l-4 bg-gray-100', {
          'border-blue-600': type === TwitterTweetType.Retweet,
          'border-orange-600': type === TwitterTweetType.SelfReply,
          'border-pink-600': type === TwitterTweetType.Tweet,
          'border-purple-600': type === TwitterTweetType.Quote,
          'border-teal-600': type === TwitterTweetType.Reply,
        })}
        key={id}
      >
        <LastTweetsGridTweetType type={type} />

        <div className="flex flex-row ">
          <div className="flex flex-col flex-1 ml-2">
            <div className="flex flex-row flex-wrap items-baseline mr-20">
              <Link url={buildTwitterProfileLink(username)}>
                <span className="text-lg font-bold mr-2 hover:text-brand">{name}</span>
              </Link>

              <span className="font-semibold">
                <Link className="hover:text-brand" url={buildTwitterProfileLink(username)}>
                  @{username}
                </Link>{' '}
                &middot;{' '}
                <Link url={buildTweetLink({ tweetId: id, username })}>
                  <time className="hover:text-brand" dateTime={timestamp} title={fromNow(timestamp) || undefined}>
                    {getFriendlyDateTime(timestamp)}
                  </time>
                </Link>
              </span>
            </div>

            {!!text ? (
              <p dangerouslySetInnerHTML={{ __html: text }} />
            ) : (
              <p className="text-brand-dark">Can&#39;t render Tweet. No text found.</p>
            )}
          </div>
        </div>

        <button className="ml-auto text-brand hover:text-brand-dark mt-2" onClick={onRenderOriginalTweet} type="button">
          Show Full Tweet
        </button>
      </div>
    </div>
  );
};

export default LastTweetsGridTweet;
