import React from 'react';
import { observer } from 'mobx-react';

import SubscribeButton from '../../../components/Plans/SubscribeButton';
import logger from '../../../utils/misc/logger';
import { useAuthStore } from '../../../data/models/auth/AuthStore';

type Props = {
  planId: string;
  price: string;
};

const SubscribeButtonContainer = ({ planId, price }: Props) => {
  const { refreshAuthUser, user, userFetched } = useAuthStore();

  const [cart, setCart] = React.useState<any>(null);
  const [portal, setPortal] = React.useState<any>(null);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  const buttonText = !!user && user.activePlan === planId ? 'Manage Subscriptions' : price;

  const shouldOpenCheckout = !!cart && !!user && !user.isChargebeeCustomer;
  const shouldOpenPortal = !!portal && !!user && user.isChargebeeCustomer;

  // This is so we're sure that we have the current user's current plan.
  React.useEffect(() => {
    if (!!user && isLoading) {
      const refreshAuthUser2 = async () => {
        try {
          await refreshAuthUser();
        } catch (err) {
          logger.error(err);
        }

        setIsLoading(false);
      };

      refreshAuthUser2();
    } else if (!user && isLoading && userFetched) {
      setIsLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, userFetched]);

  // Setup the first time subscription button (only shown if the user was never subscribed before).
  React.useEffect(() => {
    if (!cart && !!user) {
      const chargebeeInstance = (window as any).Chargebee.getInstance();
      const cartInstance = chargebeeInstance.getCart();
      const productInstance = chargebeeInstance.initializeProduct(planId);

      productInstance.setCustomData({ id: `${user.twitter.id}` });
      cartInstance.replaceProduct(productInstance);

      setCart(cartInstance);
    }
  }, [cart, planId, user]);

  // Setup the self-service portal (only shown if the user is subscribed or was subscribed before).
  React.useEffect(() => {
    if (!portal && !!user) {
      const chargebeeInstance = (window as any).Chargebee.getInstance();
      const portalInstance = chargebeeInstance.createChargebeePortal();

      setPortal(portalInstance);
    }
  }, [portal, user]);

  const handleClick = () => {
    if (shouldOpenPortal) {
      portal.open();
    } else if (shouldOpenCheckout) {
      cart.proceedToCheckout();
    }
  };

  return (
    <SubscribeButton
      buttonText={buttonText}
      hasUser={!!user}
      isLoading={isLoading}
      onClick={handleClick}
      shouldOpenCheckout={shouldOpenCheckout}
      shouldOpenPortal={shouldOpenPortal}
    />
  );
};

export default observer(SubscribeButtonContainer);
