import React from 'react';

import ContentPageHeader from '../ContentPageItems/ContentPageHeader';
import ContentPageLayout from '../ContentPageItems/ContentPageLayout';
import ContentPageParagraph from '../ContentPageItems/ContentPageParagraph';
import ContentPageParagraphLink from '../ContentPageItems/ContentPageParagraphLink';
import ContentPageSection from '../ContentPageItems/ContentPageSection';
import ContentPageSectionHeader from '../ContentPageItems/ContentPageSectionHeader';

type Props = {};

const Error404 = (_props: Props) => {
  return (
    <ContentPageLayout>
      <ContentPageHeader>404</ContentPageHeader>

      <ContentPageSection>
        <ContentPageSectionHeader>Sorry.</ContentPageSectionHeader>

        <ContentPageParagraph>We couldn&#39;t find the page you&#39;re looking for. Probably an error on our side.</ContentPageParagraph>

        <ContentPageParagraph>
          Just to make sure, you could check whether you have a typo in the url or navigate to the{' '}
          <ContentPageParagraphLink url="/">home page</ContentPageParagraphLink>.
        </ContentPageParagraph>

        <ContentPageParagraph>
          If this keeps happening, please drop an email to{' '}
          <ContentPageParagraphLink url="mailto:accountanalysis@lucahammer.com">accountanalysis@lucahammer.com</ContentPageParagraphLink>.
        </ContentPageParagraph>
      </ContentPageSection>
    </ContentPageLayout>
  );
};

export default Error404;
