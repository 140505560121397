import React from 'react';
import cx from '../../../utils/styling/cx';

// Types
import { HTMLAttributes, ReactNode } from 'react';

type Props = {
  children: ReactNode;
} & HTMLAttributes<HTMLDivElement>;

const PlanCardHeadlineFeatures = ({ children, className, ...rest }: Props) => {
  const classes = cx('flex flex-row items-top justify-around', className);

  return (
    <div className={classes} {...rest}>
      {children}
    </div>
  );
};

export default PlanCardHeadlineFeatures;
