import React from 'react';

type Props = {};

const TwitterUserCardNotFound = (_props: Props) => {
  return (
    <div className="p-4 relative w-full h-full flex items-center">
      <span className="mx-auto font-semibold text-gray-400 tracking-wide">User not found.</span>
    </div>
  );
};

export default TwitterUserCardNotFound;
