import React from 'react';
import { withRouter } from 'react-router-dom';

import ContentPageHeader from '../ContentPageItems/ContentPageHeader';
import ContentPageLayout from '../ContentPageItems/ContentPageLayout';
import ContentPageParagraph from '../ContentPageItems/ContentPageParagraph';
import ContentPageSection from '../ContentPageItems/ContentPageSection';
import ContentPageSectionHeader from '../ContentPageItems/ContentPageSectionHeader';
import TwitterSignInButton from '../TwitterSignInButton';
import { getTargetText } from './utils';

// Types
import { RouteComponentProps } from 'react-router-dom';

type Props = {} & RouteComponentProps<any, any, { from: string }>;

const SignIn = ({ location }: Props) => {
  const targetText = getTargetText(location);

  return (
    <ContentPageLayout>
      <ContentPageHeader>Oops</ContentPageHeader>

      <ContentPageSection>
        <ContentPageSectionHeader>Please sign in to continue{targetText ? ` ${targetText}` : ''}.</ContentPageSectionHeader>

        <ContentPageParagraph className="mt-4">
          <TwitterSignInButton redirectUrl={location.state.from} />
        </ContentPageParagraph>
      </ContentPageSection>
    </ContentPageLayout>
  );
};

export default withRouter(SignIn);
