import idx from 'idx';

import routes from '../../config/routing/routes';

// Types
import { Location } from 'history';

export const getTargetText = (location: Location<{ from: string }>) => {
  const fromUrl = idx(location, context => context.state.from) || null;

  if (typeof fromUrl !== 'string') {
    return null;
  }

  if (fromUrl.startsWith(routes.twitterAccount)) {
    const username = fromUrl.replace(`${routes.twitterAccount}/`, '');

    return `to analyze @${username}`;
  }

  if (fromUrl === routes.account) {
    return 'to your account settings';
  }

  return `to ${fromUrl}`;
};
