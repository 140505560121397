import locale from 'date-fns/locale/en';
import { distanceInWords } from 'date-fns';

const fromNow = (date?: string | Date): string | null => {
  if (!date) {
    return null;
  }

  return distanceInWords(new Date(), date, { addSuffix: true, locale });
};

export default fromNow;
