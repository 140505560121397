import React from 'react';
import * as d3 from 'd3';
import dc from 'dc';

import DayOfWeekChartContentLoader from '../../../components/Chart/ChartContentLoaders/DayOfWeekChartContentLoader';
import TwitterChartContainer from '../../TwitterChart';
import buildBarChartTitle from '../../../utils/dc/buildBarChartTitle';
import getWeekdayName from '../../../utils/time/getWeekdayName';

// Types
import { ChartFunctionInput } from '../../../types/twitterCharts';
import { Crossfilter } from 'crossfilter2';
import { TwitterNormalizedTweet } from '../../../types/twitter';
import { TwitterStore } from '../../../data/models/twitter/TwitterStore';

const createDayOfWeekChart = ({ crossfilter, divRef }: ChartFunctionInput) => {
  const dayOfWeekChart = dc.barChart(divRef);

  const dimension = crossfilter.dimension(tweet => getWeekdayName(tweet.dayOfWeek) || '');

  const group = dimension.group();

  dayOfWeekChart
    .width(433)
    .height(200)
    .useViewBoxResizing(true)
    .margins({ bottom: 55, left: 55, right: 15, top: 20 })

    .dimension(dimension)
    .group(group)

    .title(({ key, value }: { key: string; value: number }) => buildBarChartTitle({ data: { key, value } }))

    .x(d3.scaleBand().domain(['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']))
    .renderHorizontalGridLines(true)
    .xUnits(dc.units.ordinal)

    .gap(1)
    .elasticY(true)
    .yAxis()
    .ticks(3);

  return dayOfWeekChart;
};

type Props = {
  crossfilterInstance: Crossfilter<TwitterNormalizedTweet>;
  twitterStore: TwitterStore;
};

const DayOfWeekChart = ({ crossfilterInstance, twitterStore }: Props) => {
  return (
    <TwitterChartContainer
      chartFunction={createDayOfWeekChart}
      crossfilterInstance={crossfilterInstance}
      contentLoader={<DayOfWeekChartContentLoader />}
      title="Day of Week"
      tooltipSelector=".bar"
      twitterStore={twitterStore}
    />
  );
};

export default DayOfWeekChart;
