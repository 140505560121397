import React from 'react';
import Helmet from 'react-helmet';
import ReactDOM from 'react-dom';

import ContextProvider from './utils/context/ContextProvider';
import RoutesProvider from './config/routing/RoutesProvider';
import envConfig from './config/env';

// CSS
import './index.css';
import 'dc/dc.min.css';

const App = () => {
  return (
    <>
      <Helmet titleTemplate={`%s | ${envConfig.appName}`} />

      <RoutesProvider />
    </>
  );
};

ReactDOM.render(
  <ContextProvider>
    <App />
  </ContextProvider>,
  document.getElementById('root'),
);
