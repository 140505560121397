import React from "react";
import pluralize from "pluralize";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

import Button from "../Button";
import Icon from "../Icon";
import Toggle from "../Toggle";
import Tooltip from "../Tooltip";
import cx from "../../utils/styling/cx";

type Props = {
  isDisabled: boolean;
  isHelpVisible: boolean;
  onToggleHelp: () => void;
  resetAllFilters: () => void;
  selectedCount: number | null;
  totalCount: number | null;
};

const DataCount = ({
  isDisabled,
  isHelpVisible,
  onToggleHelp,
  resetAllFilters,
  selectedCount,
  totalCount
}: Props) => {
  return (
    <div className="flex flex-row absolute left-0 top-0 items-center px-5 -mt-10 justify-between w-full">
      {!isDisabled && (
        <div>
          <span>
            <strong className="font-semibold text-brand">
              {selectedCount}
            </strong>{" "}
            selected out of{" "}
            <strong className="font-semibold text-brand">{totalCount}</strong>{" "}
            retrieved {pluralize("Tweet", totalCount || 0)}.
          </span>

          <Button
            className={cx("ml-4", { invisible: selectedCount === totalCount })}
            onClick={resetAllFilters}
            size="sm"
          >
            Reset all Filters
          </Button>
        </div>
      )}

      {isDisabled && <div />}

      <Tooltip
        ariaLabel="Toggle Chart Explanations"
        label="Toggle Chart Explanations"
      >
        <div className="flex flex-row">
          <Icon icon={faQuestionCircle} />

          <Toggle isActive={isHelpVisible} onToggle={onToggleHelp} />
        </div>
      </Tooltip>
    </div>
  );
};

export default DataCount;
