import getLongWeekdayName from '../time/getLongWeekdayName';

// Types
import { BarChartDatum } from '../../types/twitterCharts';

const buildBarChartTitle = ({ data: { key, value } }: BarChartDatum) => {
  return `${getLongWeekdayName(key)} (${value} Tweets)`;
};

export default buildBarChartTitle;
