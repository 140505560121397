import React from 'react';

import ContentPageHeader from '../ContentPageItems/ContentPageHeader';
import ContentPageLayout from '../ContentPageItems/ContentPageLayout';
import ContentPageParagraph from '../ContentPageItems/ContentPageParagraph';
import ContentPageParagraphLink from '../ContentPageItems/ContentPageParagraphLink';

type Props = {};

const Contact = (_props: Props) => {
  return (
    <ContentPageLayout>
      <ContentPageHeader>Contact</ContentPageHeader>
      <ContentPageParagraph>
        If you have any questions or feedback, don't hestiate to contact us by mail{' '}
        <ContentPageParagraphLink url="mailto:contact@lucahammer.com">contact@lucahammer.com</ContentPageParagraphLink> or via Twitter{' '}
        <ContentPageParagraphLink url="https://twitter.com/accountanalysis">@accountanalysis</ContentPageParagraphLink>.
      </ContentPageParagraph>
    </ContentPageLayout>
  );
};

export default Contact;
