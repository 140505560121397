import React from 'react';

type UsePermanentToggleReturn = [boolean, () => void];

const usePermanentToggle = (inititalValue: boolean = false): UsePermanentToggleReturn => {
  const [value, setValue] = React.useState<boolean>(inititalValue);

  const toggleValue = () => {
    setValue(previousValue => (previousValue === inititalValue ? !previousValue : previousValue));
  };

  return [value, toggleValue];
};

export default usePermanentToggle;
