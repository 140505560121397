import React from 'react';
import Helmet from 'react-helmet';

import About from '../../components/About';

type Props = {};

const AboutContainer = (_props: Props) => {
  return (
    <>
      <Helmet>
        <title>About</title>
      </Helmet>

      <About />
    </>
  );
};

export default AboutContainer;
