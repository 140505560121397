import React from 'react';
import ContentLoader from 'react-content-loader';

import envConfig from '../../../config/env';
import tailwind from '../../../config/tailwind';

const RowChartTypeAContentLoader = () => (
  <ContentLoader
    ariaLabel="Loading Chart"
    height={300}
    primaryColor={tailwind.theme.colors.brand.light}
    secondaryColor={tailwind.theme.colors.brand['light-extra']}
    speed={envConfig.twitterChartsContentLoaderSpeed}
    width={650}
  >
    <rect x="20" y="2" rx="2" ry="2" width="610" height="42" />
    <rect x="20" y="45" rx="2" ry="2" width="429" height="42" />
    <rect x="20" y="88" rx="2" ry="2" width="343" height="42" />
    <rect x="20" y="131" rx="2" ry="2" width="243" height="42" />
    <rect x="20" y="174" rx="2" ry="2" width="181" height="42" />
  </ContentLoader>
);

export default RowChartTypeAContentLoader;
