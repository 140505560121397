import React from 'react';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import TwitterStreamHeader from '../../../components/TwitterStream/TwitterStreamHeader';
import routes from '../../../config/routing/routes';
import useTwitterStreamSocket from '../../../utils/hooks/useTwitterStreamSocket';
import useWindowBlur from '../../../utils/hooks/useWindowBlur';
import { throttleRedrawAll } from './utils';
import { useAuthStore } from '../../../data/models/auth/AuthStore';
import { useTwitterStreamStore } from '../../../data/models/twitter/TwitterStreamStore';
import { useTwitterStreamingCrossfilterContext } from '../../../utils/context/TwitterStreamingCrossfilterContext';

// Types
import { RouteComponentProps } from 'react-router-dom';
import { SyntheticEvent } from 'react';
import { TwitterNormalizedTweet } from '../../../types/twitter';

type Props = {} & RouteComponentProps;

const TwitterStreamHeaderContainer = ({ history }: Props) => {
  const [isStarted, setIsStarted] = React.useState(false);
  const { addTweets, term } = useTwitterStreamStore();
  const { crossfilterInstance } = useTwitterStreamingCrossfilterContext();
  const { isSignedIn, isSubscribed, userFetched } = useAuthStore();

  const [nextTerm, setNextTerm] = React.useState<string>('');

  const handleNewTweet = (tweets: TwitterNormalizedTweet[]) => {
    // Add to crossfilter instance.
    crossfilterInstance.add(tweets);

    // Add to twitter store.
    addTweets(tweets);

    // Redraw all charts (throttled).
    throttleRedrawAll();
  };

  const { isConnected, startSocket, stopSocket } = useTwitterStreamSocket({ onNewTweet: handleNewTweet });

  const handleBlur = () => {
    if (isStarted && isConnected) {
      stopSocket();
    }
  };

  const handleFocus = () => {
    if (!!term && term.length > 0 && !isConnected && isStarted) {
      startSocket(term);
    }
  };

  useWindowBlur({ onBlur: handleBlur, onFocus: handleFocus });

  const handleStartClick = () => {
    // Connect to the websocket and subscribe to the currently set term.
    if (!!term && term.length > 0) {
      setIsStarted(true);
      startSocket(term);
    }
  };

  const handleStopClick = () => {
    stopSocket();
    setIsStarted(false);
  };

  React.useEffect(() => {
    if (!!term && term.length > 0) {
      // Connect to the websocket and subscribe to the currently set term.
      // handleStartClick();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAnalyzeClick = (event: SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();

    handleStopClick();

    const cleanedTerm = nextTerm.replace('#', '');

    setNextTerm(cleanedTerm);

    history.push(`${routes.twitterStream}/${cleanedTerm}`);
  };

  const handleTermChange = (event: SyntheticEvent<HTMLInputElement>) => {
    setNextTerm(event.currentTarget.value);
  };

  React.useEffect(() => {
    return () => {
      // Make sure to close the socket on navigating away from the stream page.
      handleStopClick();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TwitterStreamHeader
      isConnected={isConnected}
      isLoadingUser={!userFetched}
      isSignedIn={isSignedIn}
      isSubscribed={isSubscribed}
      onAnalyzeClick={handleAnalyzeClick}
      onStartClick={handleStartClick}
      onStopClick={handleStopClick}
      onTermChange={handleTermChange}
      term={nextTerm}
    />
  );
};

export default withRouter(observer(TwitterStreamHeaderContainer));
