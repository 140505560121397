import React from 'react';

import ContentPageHeader from '../ContentPageItems/ContentPageHeader';
import ContentPageLayout from '../ContentPageItems/ContentPageLayout';
import ContentPageParagraphLink from '../ContentPageItems/ContentPageParagraphLink';
import ContentPageSection from '../ContentPageItems/ContentPageSection';

type Props = {};

const Attribution = (_props: Props) => {
  return (
    <ContentPageLayout>
      <ContentPageHeader>Attribution</ContentPageHeader>

      <ContentPageSection>
        <ul>
          <li>
            {/* https://www.freepik.com/free-vector/set-arrows-mark-hand-drawn-style_2093622.htm */}
            <ContentPageParagraphLink url="https://www.freepik.com/free-photos-vectors/arrow">
              Arrow vector created by freepik - www.freepik.com
            </ContentPageParagraphLink>
          </li>
        </ul>
      </ContentPageSection>
    </ContentPageLayout>
  );
};

export default Attribution;
