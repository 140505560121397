import React from 'react';
import { MobXProviderContext } from 'mobx-react';
import { flow, types } from 'mobx-state-tree';

import envConfig from '../../../config/env';
import AuthUserModel from './AuthUser';
import deleteAuthUser from './utils/deleteAuthUser';
import fetchAuthUser from './utils/fetchAuthUser';
import logOut from './utils/logOut';
import logger from '../../../utils/misc/logger';
import updateAuthUser from './utils/updateAuthUser';

// Types
import { Instance } from 'mobx-state-tree';

const AuthStoreModel = types
  .model('AuthStore', {
    user: types.maybeNull(AuthUserModel),
    userError: types.boolean,
    userFetched: types.boolean,
    userLoading: types.boolean,
  })

  // Async Actions

  .actions(self => {
    const deleteAuthUserFlow = flow(function*() {
      self.userError = false;
      self.userFetched = false;
      self.userLoading = true;

      try {
        yield deleteAuthUser();

        self.user = null;
        self.userError = false;
        self.userFetched = true;
        self.userLoading = false;

        return;
      } catch (err) {
        logger.error(err);

        self.userError = true;
        self.userLoading = false;
      }
    });

    return { deleteAuthUser: deleteAuthUserFlow };
  })

  .actions(self => {
    const fetchAuthUserFlow = flow(function*() {
      self.userError = false;
      self.userFetched = false;
      self.userLoading = true;

      try {
        const authUser = yield fetchAuthUser();

        if (!authUser) {
          self.user = null;
          self.userError = false;
          self.userFetched = true;
          self.userLoading = false;

          return null;
        }

        self.user = authUser;
        self.userFetched = true;
        self.userLoading = false;

        return self.user;
      } catch (err) {
        logger.error(err);

        self.userError = true;
        self.userLoading = false;
      }
    });

    return { fetchAuthUser: fetchAuthUserFlow };
  })

  .actions(self => {
    const refreshAuthUserFlow = flow(function*() {
      try {
        const authUser = yield fetchAuthUser();

        if (!authUser) {
          self.user = null;

          return null;
        }

        self.user = authUser;

        return self.user;
      } catch (err) {
        logger.error(err);

        self.userError = true;
        self.userLoading = false;
      }
    });

    return { refreshAuthUser: refreshAuthUserFlow };
  })

  .actions(self => {
    const updateAuthUserFlow = flow(function*(nextEmail: string, nextIsSubscribedToNewsletter: boolean) {
      try {
        const input = {
          nextEmail: nextEmail.length > 0 ? nextEmail : undefined,
          nextIsSubscribedToNewsletter:
            !!self.user && self.user.isSubscribedToNewsletter !== nextIsSubscribedToNewsletter ? nextIsSubscribedToNewsletter : undefined,
        };

        const updatedUser = yield updateAuthUser(input);

        if (!!updatedUser) {
          self.user = updatedUser;
        }

        return;
      } catch (err) {
        logger.error(err);

        self.userError = true;
        self.userLoading = false;
      }
    });

    return { updateAuthUser: updateAuthUserFlow };
  })

  .actions(self => {
    const logOutFlow = flow(function*() {
      // self.userError = false;
      // self.userLoading = true;

      try {
        if (!self.user) {
          return;
        }

        yield logOut();

        self.user = null;

        self.userLoading = false;

        return;
      } catch (err) {
        self.userError = true;
        self.userLoading = false;
      }
    });

    return { signOut: logOutFlow };
  })

  .actions(self => {
    const downloadTweetsFlow = flow(function*() {
      if (self.user) {
        const getDate = (): string => {
          const now = new Date();
          return now.getUTCFullYear().toString() + now.getUTCDate().toString() + now.getUTCHours().toString();
        };

        // set current date if there is none
        if (!self.user.downloadedTweetsDate) {
          self.user.downloadedTweetsDate = getDate();
        }

        // reset downloaded Tweets if saved date isn't current date
        if (self.user.downloadedTweetsDate !== getDate()) {
          self.user.downloadedTweets = 0;
        }

        // test if downloaded Tweets are left
        if (!self.user.downloadedTweets) {
          self.user.downloadedTweets = 0;
        }
        self.user.downloadedTweets += 3200; // todo: add correct number of Tweets
      }
      function resolveAfter2Seconds() {
        return new Promise(resolve => {
          setTimeout(() => {
            resolve('resolved');
          }, 10);
        });
      }

      yield resolveAfter2Seconds();
    });

    return { downloadedTweetsUpdate: downloadTweetsFlow };
  })

  // Lifecycle Hooks

  .actions(self => ({
    afterCreate() {
      self.fetchAuthUser();
    },
  }))

  // Views

  .views(self => ({
    get isSignedIn() {
      return !!self.user;
    },
  }))

  .views(self => ({
    get tweetsLeftToDownload() {
      if (self.user && self.user.downloadedTweets) {
        return envConfig.twitterMaxDownloads - self.user.downloadedTweets;
      }
      return 50000;
    },
  }))

  .views(self => ({
    get isSubscribed() {
      return !!self.user && !!self.user.activePlan;
    },
  }));

export interface AuthStore extends Instance<typeof AuthStoreModel> {}

export const useAuthStore = () => {
  const context = React.useContext<{ authStore: AuthStore }>(MobXProviderContext);

  return context.authStore;
};

export default AuthStoreModel;
