import React from 'react';
import d3tip from 'd3-tip';
import dc from 'dc';
import { observer } from 'mobx-react';

import Chart from '../../components/Chart';
import buildBarChartTitle from '../../utils/dc/buildBarChartTitle';
import buildRowChartTitle from '../../utils/dc/buildRowChartTitle';
import createAttachTooltip from '../../utils/dc/createAttachTooltip';
import getLongWeekdayName from '../../utils/time/getLongWeekdayName';
import getWeekdayName from '../../utils/time/getWeekdayName';
import setChartBorderRadius from '../../utils/dc/setChartBorderRadius';
import useChartIsLoading from '../../utils/hooks/useChartIsLoading';

// Types
import { BarChart, HeatMap, RowChart } from 'dc';
import { ChartFunctionInput, RowChartDatum, BarChartDatum, HeatMapDatum } from '../../types/twitterCharts';
import { Crossfilter } from 'crossfilter2';
import { ReactNode } from 'react';
import { TwitterNormalizedTweet } from '../../types/twitter';
import { TwitterStore } from '../../data/models/twitter/TwitterStore';
import { TwitterStreamStore } from '../../data/models/twitter/TwitterStreamStore';

// @ts-ignore
const tooltip = d3tip()
  .attr('class', 'd3-tip')
  .offset([-10, 0])
  .html((datum: BarChartDatum | HeatMapDatum | RowChartDatum) => {
    // Bar Chart
    if ('data' in datum) {
      return `<span>${buildBarChartTitle(datum)}</span>`;
    }

    const key = datum.key;
    const value = datum.value;

    // Row Chart
    if (typeof key === 'string') {
      return `<span>${buildRowChartTitle({ key, value })}</span>`;
    }

    // HeatMap
    return `<span>${getLongWeekdayName(getWeekdayName(key[1]))}, ${key[0]}: ${value} Tweets</span>`;
  });

type Props = {
  chartFunction: (input: ChartFunctionInput) => BarChart | HeatMap | RowChart;
  contentLoader?: ReactNode;
  crossfilterInstance: Crossfilter<TwitterNormalizedTweet>;
  dontSetBorderRadius?: boolean;
  title: string;
  tooltipSelector?: string;
  twitterStore: TwitterStore | TwitterStreamStore;
};

const TwitterChartContainer = (props: Props) => {
  const { chartFunction, contentLoader, crossfilterInstance, dontSetBorderRadius, title, tooltipSelector, twitterStore } = props;

  const {
    isAccountProtectedMessageShowing,
    isMockStore,
    isNoTweetsMessageShowing,
    isWaitingForFirstTweetMessageShowing,
    tweetsLoading,
  } = twitterStore;

  const { chartIsLoadingCallback, isLoading } = useChartIsLoading();

  const chartRef = React.useRef<HTMLElement>(null);

  const [chart, setChart] = React.useState<BarChart | HeatMap | RowChart | null>(null);

  React.useEffect(() => {
    if (!!chartRef.current) {
      // @ts-ignore
      const createdChart = chartFunction({
        // eslint-disable-next-line no-nested-ternary
        crossfilter: crossfilterInstance,
        divRef: (chartRef.current as unknown) as string,
      });

      // We don't redraw mock graphs before they've loaded, so we have to use a different callback.
      if (isMockStore) {
        // @ts-ignore
        createdChart.on('pretransition', (callbackChart: BarChart | HeatMap | RowChart) => {
          chartIsLoadingCallback({ chart: callbackChart, event: 'pretransition' });
        });
      } else {
        // @ts-ignore
        createdChart.on('postRedraw', (callbackChart: BarChart | HeatMap | RowChart) => {
          chartIsLoadingCallback({ chart: callbackChart, event: 'postRedraw' });
        });
      }

      setChart(createdChart);

      createdChart.render();

      // @ts-ignore
      createdChart.on('pretransition', (callbackChart: BarChart | HeatMap | RowChart) => {
        if (!dontSetBorderRadius) {
          setChartBorderRadius(callbackChart);
        }

        if (!!tooltipSelector) {
          const attachTooltip = createAttachTooltip({ selector: tooltipSelector, tooltip });

          attachTooltip(callbackChart);
        }
      });

      // Round corners of bar/row charts.
      if (!dontSetBorderRadius) {
        setChartBorderRadius(createdChart);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleReset = () => {
    if (!!chart) {
      chart.filterAll();
      dc.redrawAll();
    }
  };

  return (
    <Chart
      contentLoader={contentLoader}
      isAccountProtectedMessageShowing={isAccountProtectedMessageShowing}
      isLoading={isLoading || tweetsLoading}
      isNoTweetsMessageShowing={isNoTweetsMessageShowing}
      isWaitingForFirstTweetMessageShowing={isWaitingForFirstTweetMessageShowing}
      onReset={handleReset}
      ref={chartRef}
      title={title}
    />
  );
};

export default observer(TwitterChartContainer);
