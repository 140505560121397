import React from 'react';

import AnalysisHeaderSection from '../LayoutItems/AnalysisHeaderSection';
import AnalysisSection from '../LayoutItems/AnalysisSection';
// import Arrow from '../Arrow';
import Chart1AxisXAxis from '../Chart/Chart1AxisXAxis';
import Heading from '../Heading';
// import Infobox from '../Infobox';

import cx from '../../utils/styling/cx';

// import * as Explanation from './explanations';

// Types
import { ReactNode } from 'react';

type Props = {
  areAxisVisible?: boolean;
  dataCount: ReactNode;
  isDemoMode?: boolean;
  isHelpVisible: boolean;
  isSignedIn?: boolean;
  lastTweetsGrid?: ReactNode;
  quotedUserChart: ReactNode;
  repliedUserChart: ReactNode;
  retweetedUserChart: ReactNode;
  stream: ReactNode;
  title: string;
  tweetTypeChart: ReactNode;
  usedAppChart: ReactNode;
  usedHashtagsChart: ReactNode;
  usedHostnamesChart: ReactNode;
  usedLanguageChart: ReactNode;
};

const TwitterStream = (props: Props) => {
  const {
    areAxisVisible = true,
    dataCount,
    isDemoMode = false,
    isHelpVisible,
    lastTweetsGrid,
    quotedUserChart,
    repliedUserChart,
    retweetedUserChart,
    title,
    tweetTypeChart,
    usedAppChart,
    usedHashtagsChart,
    usedHostnamesChart,
    usedLanguageChart,
    stream,
  } = props;

  return (
    <div className={cx('flex container mx-auto flex-col', { 'mt-16': !isDemoMode, 'pt-16': !isDemoMode })}>
      <Heading isVisuallyHidden={true}>{title}</Heading>

      <AnalysisHeaderSection className="relative">
        {dataCount}

        <div className="xl:w-2/3 h-auto bg-white rounded-lg shadow mx-2">
          {/* <div className="mx-4 my-2 w-full h-full flex flex-col content-center items-center justify-center"> */}
          {/* <p className="text-gray-800 text-2xl font-semibold">Popeyes</p> */}
          {/* <div className="flex flex-col mr-3 mb-2">
              <span className="text-brand-dark font-semibold text-6xl">popeyes</span>

              <span className="text-gray-500 uppercase">Tracking Term</span>
            </div> */}

          {/* <div className="flex flex-row flex-wrap mt-2">
              <div className="flex flex-row flex-wrap mt-2">
                <div className="flex flex-col mr-3 mb-2">
                  <a className="text-brand font-semibold text-lg hover:text-brand-dark">1200</a>

                  <span className="text-gray-500 uppercase">{pluralize('Tweets', 1200)}</span>
                </div>
              </div>

              <div className="flex flex-row flex-wrap mt-2">
                <div className="flex flex-col mr-3 mb-2">
                  <a className="text-brand font-semibold text-lg hover:text-brand-dark">990</a>

                  <span className="text-gray-500 uppercase">{pluralize('Account', 990)}</span>
                </div>
              </div>

              <div className="flex flex-row flex-wrap mt-2">
                <div className="flex flex-col mr-3 mb-2">
                  <a className="text-brand font-semibold text-lg hover:text-brand-dark">990</a>

                  <span className="text-gray-500 uppercase">Since {pluralize('Second', 990)}</span>
                </div>
              </div>
            </div> */}
          {/* </div> */}
          {stream}
        </div>

        <div className="mt-4 xl:mt-0  xl:w-1/3 h-auto bg-white rounded-lg shadow mx-2 relative"></div>
      </AnalysisHeaderSection>

      <AnalysisSection className="mt-4">
        <div className="lg:w-1/3 h-auto bg-white rounded-lg shadow mx-2 relative">
          {areAxisVisible && <Chart1AxisXAxis label="Tweets" />}

          {tweetTypeChart}
        </div>

        <div className="mt-4 lg:mt-0 lg:w-1/3 h-auto bg-white rounded-lg shadow mx-2 relative">
          {areAxisVisible && <Chart1AxisXAxis label="Tweets" />}

          {usedLanguageChart}
        </div>

        <div className="mt-4 lg:mt-0 lg:w-1/3 h-auto bg-white rounded-lg shadow mx-2 relative">
          {areAxisVisible && <Chart1AxisXAxis label="Tweets" />}

          {usedAppChart}
        </div>
      </AnalysisSection>

      <AnalysisSection className="mt-4">
        <div className="lg:w-1/2 h-auto bg-white rounded-lg shadow mx-2 relative">
          {areAxisVisible && <Chart1AxisXAxis label="Tweets" />}

          {usedHashtagsChart}
        </div>

        <div className="mt-4 lg:mt-0 lg:w-1/2 h-auto bg-white rounded-lg shadow mx-2 relative">
          {areAxisVisible && <Chart1AxisXAxis label="Tweets" />}

          {usedHostnamesChart}
        </div>
      </AnalysisSection>

      <AnalysisSection className="mt-4">
        <div className="lg:w-1/3 h-auto bg-white rounded-lg shadow mx-2 relative">
          {areAxisVisible && <Chart1AxisXAxis label="Tweets" />}

          {repliedUserChart}
        </div>

        <div className="mt-4 lg:mt-0 lg:w-1/3 h-auto bg-white rounded-lg shadow mx-2 relative">
          {areAxisVisible && <Chart1AxisXAxis label="Tweets" />}

          {retweetedUserChart}
        </div>

        <div className="mt-4 lg:mt-0 lg:w-1/3 h-auto bg-white rounded-lg shadow mx-2 relative">
          {areAxisVisible && <Chart1AxisXAxis label="Tweets" />}

          {quotedUserChart}
        </div>
      </AnalysisSection>

      {!!lastTweetsGrid && (
        <AnalysisSection className={cx('mb-2 lg:mb-4', { 'mt-2': isHelpVisible, 'mt-4': !isHelpVisible })}>
          <div className="lg:mt-0 lg:w-full mx-2 h-auto bg-white rounded-lg shadow relative p-4 mt-4" style={{ minHeight: 200 }}>
            {lastTweetsGrid}
          </div>
        </AnalysisSection>
      )}
    </div>
  );
};

export default TwitterStream;
