import React from 'react';
import Helmet from 'react-helmet';

import Contact from '../../components/Contact';

type Props = {};

const ContactContainer = (_props: Props) => {
  return (
    <>
      <Helmet>
        <title>Contact</title>
      </Helmet>

      <Contact />
    </>
  );
};

export default ContactContainer;
