import React from 'react';

import cx from '../../../utils/styling/cx';

// Types
import { HTMLAttributes, ReactNode } from 'react';

type Props = {
  children: ReactNode;
} & HTMLAttributes<HTMLParagraphElement>;

const ContentPageParagraph = ({ children, className, ...rest }: Props) => {
  const classes = cx('leading-relaxed mb-2', className);

  return (
    <p className={classes} {...rest}>
      {children}
    </p>
  );
};

export default ContentPageParagraph;
