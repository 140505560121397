import React from 'react';
import Helmet from 'react-helmet';
import { withRouter } from 'react-router-dom';

import Account from '../../components/Account';
import SubscribeButtonContainer from '../Plans/SubscribeButton';
import routes from '../../config/routing/routes';
import useToggle from '../../utils/hooks/useToggle';
import { useAuthStore } from '../../data/models/auth/AuthStore';

// Types
import { RouteComponentProps } from 'react-router-dom';
import { SyntheticEvent } from 'react';
import { observer } from 'mobx-react';

type Props = {} & RouteComponentProps;

const AccountContainer = ({ history }: Props) => {
  const str = useAuthStore();
  const { deleteAuthUser, updateAuthUser, user } = str;

  const subscribeButton = <SubscribeButtonContainer planId="pro-v1" price="15€/month" />;

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [nextEmail, setNextEmail] = React.useState<string>(user && user.email ? user.email : '');
  const [nextIsSubscribedToNewsletter, toggleNextIsSubscribedToNewsletter] = useToggle(
    user && user.isSubscribedToNewsletter ? user.isSubscribedToNewsletter : false,
  );
  const [isDeleteAccountPopupOpen, toggleIsDeleteAccountPopupOpen] = useToggle(false);

  const handleDeleteAccount = async () => {
    history.push(routes.home);

    await deleteAuthUser();
  };

  const handleSubmit = async (event: SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();

    setIsLoading(true);

    await updateAuthUser(nextEmail, nextIsSubscribedToNewsletter);

    setIsLoading(false);
  };

  const handleEmailChange = (event: SyntheticEvent<HTMLInputElement>) => {
    setNextEmail(event.currentTarget.value);
  };

  if (!user) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>My Account</title>
      </Helmet>

      <Account
        email={nextEmail}
        isDeleteAccountPopupOpen={isDeleteAccountPopupOpen}
        isLoading={isLoading}
        isSubscribedToNewsletter={nextIsSubscribedToNewsletter}
        onDeleteAccount={handleDeleteAccount}
        onEmailChange={handleEmailChange}
        onIsSubscribedToNewsletterChange={toggleNextIsSubscribedToNewsletter}
        onSubmit={handleSubmit}
        subscribeButton={subscribeButton}
        toggleIsDeleteAccountPopupOpen={toggleIsDeleteAccountPopupOpen}
        username={user.twitter.username}
      />
    </>
  );
};

export default withRouter(observer(AccountContainer));
