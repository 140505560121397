import React from 'react';

import HeadingContext from '../../../utils/context/HeadingContext';

// Types
import { HTMLAttributes, ReactNode } from 'react';

type Props = {
  children: ReactNode;
} & HTMLAttributes<HTMLDivElement>;

const Section = ({ children, ...rest }: Props) => {
  return (
    <HeadingContext.Consumer>
      {level => (
        <HeadingContext.Provider value={level + 1}>
          <section {...rest}>{children}</section>
        </HeadingContext.Provider>
      )}
    </HeadingContext.Consumer>
  );
};

export default Section;
