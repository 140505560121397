import React from 'react';

import AppContainer from '../../containers/App';
import Error404Container from '../../containers/Error404';

type Props = {};

const Error404Page = (_props: Props) => {
  return (
    <AppContainer>
      <Error404Container />
    </AppContainer>
  );
};

export default Error404Page;
