import React from 'react';
import ErrorBoundary from 'react-error-boundary';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHashtag } from '@fortawesome/free-solid-svg-icons';

import Error500 from '../../components/Error500';
import FooterContainer from '../FooterContainer';
import GlobalMessageContainer from '../GlobalMessage';
import HeaderContainer from '../Header';
import Link from '../../components/Link';
import ScrollToTopButton from '../../components/ScrollToTopButton';
import envConfig from '../../config/env';
import logger from '../../utils/misc/logger';
import routes from '../../config/routing/routes';

// Types
import { ReactNode } from 'react';

// TODO: LogRocket or something?
const onError = (error: Error, componentStack: string) => {
  logger.error(error, componentStack);
};

type Props = {
  children: ReactNode;
  isNotRenderingHeader?: boolean;
};

const AppContainer = ({ children, isNotRenderingHeader = false }: Props) => {
  return (
    <ErrorBoundary FallbackComponent={Error500} onError={onError}>
      <GlobalMessageContainer />

      <ScrollToTopButton />

      <span id="d3-tip" />

      {!isNotRenderingHeader && <HeaderContainer />}

      {envConfig.isDev && (
        <div className="fixed z-50 w-full md:w-16 md:h-full flex flex-row md:flex-col content-center items-center ml-4 md:ml-0 py-2 mx-auto bottom-0">
          <Link
            className="ml-2 md:ml-0 mr-2 md:mr-0 w-6 md:mt-2 px-8 py-4 md:px-4 md:py-2 md:w-1/2 hover:border-brand cursor-pointer bg-white text-brand flex content-center justify-center items-center shadow border-4 rounded border-gray-500"
            url={`${routes.twitterStream}/popeyes`}
          >
            <FontAwesomeIcon icon={faHashtag} fixedWidth={true} />
          </Link>
        </div>
      )}

      <div className="flex flex-1">
        <ErrorBoundary FallbackComponent={Error500} onError={onError}>
          {children}
        </ErrorBoundary>
      </div>

      <FooterContainer />
    </ErrorBoundary>
  );
};

export default AppContainer;
