import React from 'react';

import TwitterUserCardDescription from './TwitterUserCardDescription';
import TwitterUserCardHeader from './TwitterUserCardHeader';
import TwitterUserCardMeta from './TwitterUserCardMeta';
import TwitterUserCardMeta2 from './TwitterUserCardMeta2';
import TwitterUserCardStatistics from './TwitterUserCardStatistics';

type Props = {
  bioUrl: string | null;
  createdAt: string;
  description: string | null;
  followersCount: number;
  followingCount: number;
  hasDefaultProfileImage: boolean;
  id: string;
  isProtected: boolean;
  isVerified: boolean;
  likedCount: number;
  listedCount: number;
  location: string | null;
  name: string;
  profileImageUrl: string | null;
  tweetsCount: number;
  username: string;
};

const TwitterUserCard = (props: Props) => {
  return (
    <div className="p-4 relative">
      <TwitterUserCardMeta
        hasDefaultProfileImage={props.hasDefaultProfileImage}
        isProtected={props.isProtected}
        isVerified={props.isVerified}
        username={props.username}
      />

      <TwitterUserCardHeader name={props.name} profileImageUrl={props.profileImageUrl} username={props.username} />

      <TwitterUserCardStatistics
        followersCount={props.followersCount}
        followingCount={props.followingCount}
        likedCount={props.likedCount}
        listedCount={props.listedCount}
        tweetsCount={props.tweetsCount}
        username={props.username}
      />

      <TwitterUserCardDescription description={props.description} />

      <TwitterUserCardMeta2 bioUrl={props.bioUrl} createdAt={props.createdAt} id={props.id} location={props.location} />
    </div>
  );
};

export default TwitterUserCard;
