import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHashtag } from '@fortawesome/free-solid-svg-icons';

import cx from '../../../../utils/styling/cx';
import envConfig from '../../../../config/env';

// Types
import { SyntheticEvent } from 'react';

type Props = {
  onAnalyzeClick: (event: SyntheticEvent<HTMLFormElement>) => void;
  onTermChange: (event: SyntheticEvent<HTMLInputElement>) => void;
  isTwitterRoute: boolean;
  term: string;
};

const HeaderAnalyzeTwitterStreamInput = ({ onAnalyzeClick, onTermChange, isTwitterRoute, term }: Props) => {
  return (
    <form className="flex h-16 items-center ml-4" onSubmit={onAnalyzeClick}>
      <div className="relative text-gray-400 flex items-center focus-within:text-brand">
        <div className="absolute left-0 h-auto ml-4 flex items-center">
          <FontAwesomeIcon icon={faHashtag} size="sm" />
        </div>

        <input
          className={cx('bg-gray-200 h-10 pr-4 pl-8 font-medium rounded-l-lg text-md focus:outline-none border-r text-gray-900', {
            'max-w-3xs md:max-w-full': isTwitterRoute,
          })}
          name="analyze-term"
          onChange={onTermChange}
          placeholder={envConfig.appName}
          style={{ paddingBottom: 1 }}
          type="search"
          value={term}
        />
      </div>

      <button className="h-auto bg-gray-200 h-10 px-4 py-2 rounded-r-lg hover:bg-gray-400" type="submit">
        Analyze
      </button>
    </form>
  );
};

export default HeaderAnalyzeTwitterStreamInput;
