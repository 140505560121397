import React from 'react';

import { TwitterCrossfilterContextProvider } from './TwitterCrossfilterContext';
import { useTwitterStore } from '../../data/models/twitter/TwitterStore';

// Types
import { ReactNode } from 'react';

type Props = {
  children: ReactNode;
};

const ContextProviderCrossfilter = ({ children }: Props) => {
  const twitterStore = useTwitterStore();

  return <TwitterCrossfilterContextProvider data={twitterStore.tweets}>{children}</TwitterCrossfilterContextProvider>;
};

export default ContextProviderCrossfilter;
