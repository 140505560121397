import resolveTailwindConfig from 'tailwindcss/resolveConfig';

import tailwindConfig from './tailwind.config';

type TailwindConfig = {
  theme: {
    colors: {
      brand: {
        default: string;
        dark: string;
        light: string;
        'light-extra': string;
      };
    };
  };
};

const tailwind: TailwindConfig = resolveTailwindConfig(tailwindConfig);

export default tailwind;
