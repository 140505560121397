import React from 'react';
import { faRocket, faEnvelope, faTimes, faBatteryFull, faFlask, faDownload } from '@fortawesome/free-solid-svg-icons';

import Link from '../../Link';
import PlanCardBody from '../../PlanCardItems/PlanCardBody';
import PlanCardFeature from '../../PlanCardItems/PlanCardFeature';
import PlanCardFeatures from '../../PlanCardItems/PlanCardFeatures';
import PlanCardFooter from '../../PlanCardItems/PlanCardFooter';
import PlanCardHeader from '../../PlanCardItems/PlanCardHeader';
import PlanCardHeadlineFeature from '../../PlanCardItems/PlanCardHeadlineFeature';
import PlanCardHeadlineFeatures from '../../PlanCardItems/PlanCardHeadlineFeatures';
import PlanCardLayout from '../../PlanCardItems/PlanCardLayout';

// Types
import { ReactNode } from 'react';

type Props = {
  className?: string;
  subscribeButton: ReactNode;
};

const PlansCardProV1 = ({ className, subscribeButton }: Props) => {
  return (
    <>
      <PlanCardLayout className={className} isHighlighted={true}>
        <PlanCardHeader isHighlighted={true}>Pro</PlanCardHeader>

        <PlanCardBody>
          <PlanCardHeadlineFeatures>
            <PlanCardHeadlineFeature
              disclaimer="up to"
              disclaimerClarification="It cannot be guaranteed that the Twitter API will actually return 3200  tweets."
              isHighlighted={true}
              label="Tweets per Analysis"
              value="3200"
            />

            <PlanCardHeadlineFeature
              disclaimer="up to"
              disclaimerClarification="It cannot be guaranteed that the Twitter API will actually return 3200 tweets."
              isHighlighted={true}
              label="Tweets at Once"
              value="3200"
            />
          </PlanCardHeadlineFeatures>

          <PlanCardFeatures>
            <PlanCardFeature icon={faFlask} isHighlighted={true} text="All features you love" />

            <PlanCardFeature icon={faBatteryFull} isHighlighted={true} text="As many Tweets as possible" />

            <PlanCardFeature icon={faRocket} isHighlighted={true} text="Load all Tweets at once" />

            <PlanCardFeature
              disclaimerClarification="Up to 50000 Tweets per day"
              icon={faDownload}
              isHighlighted={true}
              text="Export Tweets as CSV"
            />

            <PlanCardFeature icon={faEnvelope} isHighlighted={true} text="Email Support" />

            <PlanCardFeature
              disclaimerClarification="Subscription ends with end of term"
              icon={faTimes}
              isHighlighted={true}
              text="Cancel Anytime"
            />
          </PlanCardFeatures>
        </PlanCardBody>

        <PlanCardFooter isHighlighted={true} onClick={() => {}}>
          {subscribeButton}
        </PlanCardFooter>

        <div className="w-full bg-white rounded rounded-t-none">
          <p className="text-gray-800 p-4 text-sm text-center">
            Payment is securely handled by{' '}
            <Link className="text-brand hover:text-brand-light" url="https://www.chargebee.com/">
              Chargebee
            </Link>
            .
            <br />
            <Link className="text-brand hover:text-brand-light" url="https://www.chargebee.com/security/pci/#chargebee-pci-compliance">
              PCI DSS Level 1 certified
            </Link>
          </p>
        </div>
      </PlanCardLayout>
    </>
  );
};

export default PlansCardProV1;
