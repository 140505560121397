const getLongWeekdayName = (weekday: string) => {
  switch (weekday) {
    case 'Mon':
      return 'Mondays';

    case 'Tue':
      return 'Tuesdays';

    case 'Wed':
      return 'Wednesdays';

    case 'Thu':
      return 'Thursdays';

    case 'Fri':
      return 'Fridays';

    case 'Sat':
      return 'Saturdays';

    case 'Sun':
      return 'Sundays';

    default:
      throw Error(`Unexpected short weekday name: '${weekday}'`);
  }
};

export default getLongWeekdayName;
