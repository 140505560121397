// Types
import { Group, NaturallyOrderedValue } from 'crossfilter2';

const removeRecordsWithEmptyKeys = (group: Group<any, NaturallyOrderedValue, unknown>) => {
  return {
    all: () =>
      group.all().filter(d => {
        if (typeof d.key === 'string') {
          return d.key.length > 0;
        }

        if (typeof d.key === 'number') {
          return true;
        }

        return !!d.key;
      }),
  };
};

export default removeRecordsWithEmptyKeys;
