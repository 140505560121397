import React from 'react';

import AppContainer from '../../containers/App';
import TermsOfServiceContainer from '../../containers/TermsOfService';

type Props = {};

const TermsOfServicePage = (_props: Props) => {
  return (
    <AppContainer>
      <TermsOfServiceContainer />
    </AppContainer>
  );
};

export default TermsOfServicePage;
