import React from 'react';

import Link from '../../Link';
import cx from '../../../utils/styling/cx';

// Types
import { HTMLAttributes } from 'react';

type Props = {
  isActive: boolean;
  params?: { [key: string]: string | number };
  url?: string | null;
} & HTMLAttributes<HTMLAnchorElement>;

const FooterLink = ({ children, className, isActive, ...rest }: Props) => {
  return (
    <Link
      className={cx(
        { 'text-gray-800': !isActive, 'hover:text-brand': !isActive, 'text-brand': isActive, 'hover:text-brand-dark': isActive },
        'text-lg',
        className,
      )}
      {...rest}
    >
      {children}
    </Link>
  );
};

export default FooterLink;
