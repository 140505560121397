import RethrownError from '../../../../utils/misc/RethrownError';
import axios from '../../../../utils/network/axios';
import routesApi from '../../../../config/routing/routesApi';

type UpdateAuthUserInput = {
  nextEmail?: string;
  nextIsSubscribedToNewsletter?: boolean;
};

const updateAuthUser = async ({ nextEmail, nextIsSubscribedToNewsletter }: UpdateAuthUserInput) => {
  try {
    const response = await axios.patch(routesApi.auth.deleteUser, { nextEmail, nextIsSubscribedToNewsletter });

    if (!response.data) {
      return { user: null };
    }

    return response.data.user;
  } catch (err) {
    throw new RethrownError(err, 'updateAuthUser.ts');
  }
};

export default updateAuthUser;
