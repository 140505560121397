import React from 'react';
import Helmet from 'react-helmet';

import Attribution from '../../components/Attribution';

type Props = {};

const AttributionContainer = (_props: Props) => {
  return (
    <>
      <Helmet>
        <title>Attribution</title>
      </Helmet>

      <Attribution />
    </>
  );
};

export default AttributionContainer;
