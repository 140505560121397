import usePermanentToggle from './usePermanentToggle';

// Types
import { BarChart, HeatMap, RowChart } from 'dc';

type ChartIsLoadingCallbackInput = {
  chart: BarChart | HeatMap | RowChart;
  event: 'postRedraw' | 'pretransition';
};

const useChartIsLoading = () => {
  const [isLoading, toggleIsLoading] = usePermanentToggle(true);

  const chartIsLoadingCallback = ({ chart, event }: ChartIsLoadingCallbackInput) => {
    if (isLoading) {
      toggleIsLoading();

      // Disable callback
      // @ts-ignore
      chart.on(event, () => {});
    }
  };

  return { chartIsLoadingCallback, isLoading };
};

export default useChartIsLoading;
