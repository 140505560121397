import React from 'react';
import { observer } from 'mobx-react';

import { useTwitterStore } from '../../data/models/twitter/TwitterStore';

import TwitterUserCard from '../../components/TwitterUserCard';
import TwitterUserCardContentLoader from '../../components/TwitterUserCard/TwitterUserCardContentLoader';
import TwitterUserCardNotFound from '../../components/TwitterUserCard/TwitterUserCardNotFound';

// Types
import { TwitterStore } from '../../data/models/twitter/TwitterStore';

type Props = {
  twitterStore: TwitterStore;
  useMockStore?: boolean;
};

const TwitterUserCardContainer = ({ twitterStore, useMockStore = false }: Props) => {
  const ctx = useTwitterStore({ useMockStore });
  const { user, userLoading, userNotFound } = twitterStore || ctx;

  if (userLoading) {
    return <TwitterUserCardContentLoader />;
  }

  if (userNotFound) {
    return <TwitterUserCardNotFound />;
  }

  if (!user) {
    return null;
  }

  return (
    <TwitterUserCard
      bioUrl={user.bioUrl}
      createdAt={user.createdAt}
      description={user.description}
      followersCount={user.followersCount}
      followingCount={user.followingCount}
      hasDefaultProfileImage={user.hasDefaultProfileImage}
      id={user.id}
      isProtected={user.isProtected}
      isVerified={user.isVerified}
      likedCount={user.likedCount}
      listedCount={user.listedCount}
      location={user.location}
      name={user.name}
      profileImageUrl={user.profileImageUrl}
      tweetsCount={user.tweetsCount}
      username={user.username}
    />
  );
};

export default observer(TwitterUserCardContainer);
