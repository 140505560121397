import React from 'react';

import Tooltip from '../../Tooltip';
import cx from '../../../utils/styling/cx';

// Types
import { HTMLAttributes } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type Props = {
  disclaimerClarification?: string;
  icon: IconProp;
  isHighlighted?: boolean;
  text: string;
} & HTMLAttributes<HTMLDivElement>;

const PlanCardFeature = ({ className, disclaimerClarification, icon, isHighlighted = false, text, ...rest }: Props) => {
  const classes = cx('flex items-center mb-4', className);

  const renderText = () => {
    const textClasses = cx({ 'text-gray-800': !isHighlighted, 'text-white': isHighlighted });

    if (!!disclaimerClarification) {
      return (
        <Tooltip label={disclaimerClarification}>
          <span className={cx('border-dashed border-b', textClasses)}>{text}</span>
        </Tooltip>
      );
    }

    return <span className={textClasses}>{text}</span>;
  };

  return (
    <div className={classes} {...rest}>
      <span className={cx('inline-flex rounded-full mr-4 p-1', { 'text-gray-800': !isHighlighted, 'text-white': isHighlighted })}>
        <FontAwesomeIcon icon={icon} fixedWidth={true} />
      </span>
      {renderText()}
    </div>
  );
};

export default PlanCardFeature;
