import React from 'react';

import Tooltip from '../../Tooltip';
import cx from '../../../utils/styling/cx';

// Types
import { HTMLAttributes } from 'react';

type Props = {
  disclaimer?: string;
  disclaimerClarification?: string;
  isHighlighted?: boolean;
  label: string;
  value: string;
} & HTMLAttributes<HTMLDivElement>;

const PlanCardHeadlineFeature = (props: Props) => {
  const { className, disclaimer, disclaimerClarification, isHighlighted = false, label, value, ...rest } = props;

  const classes = cx('flex flex-col items-center', className);

  const renderDisclaimer = () => {
    if (!disclaimer) {
      return null;
    }

    const disclaimerClasses = cx('text-center text-xs border-dashed border-b', {
      'text-white': isHighlighted,
      'text-gray-800': !isHighlighted,
    });

    if (!!disclaimerClarification) {
      return (
        <Tooltip label={disclaimerClarification}>
          <span className={disclaimerClasses}>{disclaimer}</span>
        </Tooltip>
      );
    }

    return <span className={disclaimerClasses}>{disclaimer}</span>;
  };

  return (
    <div className={classes} {...rest}>
      {renderDisclaimer()}

      <span className={cx('text-3xl font-semibold', { 'text-white': isHighlighted, 'text-gray-800': !isHighlighted })}>{value}</span>

      <span className={cx('text-center text-xs', { 'text-white': isHighlighted, 'text-gray-800': !isHighlighted })}>{label}</span>
    </div>
  );
};

export default PlanCardHeadlineFeature;
