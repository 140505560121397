import React from 'react';

import cx from '../../utils/styling/cx';

// Types
import { HTMLAttributes } from 'react';

type Props = {
  isActive: boolean;
  onToggle: () => void;
} & HTMLAttributes<HTMLButtonElement>;

const Toggle = ({ className, isActive, onToggle, ...rest }: Props) => {
  return (
    <button
      className={cx(
        'ml-2 rounded-full flex items-center cursor-pointer w-12',
        {
          'bg-gray-200': !isActive,
          'bg-brand': isActive,
          'justify-end': isActive,
        },
        className,
      )}
      onClick={onToggle}
      type="button"
      style={{ transition: 'background-color 300ms ease-in-out' }}
      {...rest}
    >
      <span className={cx('rounded-full border border-gray-500 w-6 h-6 shadow-inner shadow', { 'bg-white': isActive })}></span>
    </button>
  );
};

export default Toggle;
