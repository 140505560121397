import React from 'react';

import cx from '../../utils/styling/cx';

// Types
import { HTMLAttributes, ReactNode } from 'react';

type Props = {
  children: ReactNode;
  level?: 'error' | 'info' | 'warning';
} & HTMLAttributes<HTMLDivElement>;

const Infobox = ({ children, className, level = 'info', ...rest }: Props) => {
  return (
    <div
      className={cx(
        'border-l-4 p-4 m-2 shadow rounded xl:w-full flex flex-row justify-around',
        {
          // level = 'error'
          'bg-red-100': level === 'error',
          'border-red-500': level === 'error',
          'text-red-800': level === 'error',
          // level = 'info
          'bg-brand-light-extra': level === 'info',
          'border-brand': level === 'info',
          'text-brand-dark': level === 'info',
          // level = 'warning
          'bg-yellow-100': level === 'warning',
          'border-yellow-500': level === 'warning',
          'text-yellow-800': level === 'warning',
        },
        className,
      )}
      {...rest}
    >
      {children}
    </div>
  );
};

export default Infobox;
