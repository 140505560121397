import RethrownError from '../../../../utils/misc/RethrownError';
import axios from '../../../../utils/network/axios';
import logger from '../../../../utils/misc/logger';
import routesApi from '../../../../config/routing/routesApi';

const deleteAuthUser = async () => {
  try {
    await axios.delete(routesApi.auth.deleteUser);

    return;
  } catch (err) {
    logger.error(err);

    throw new RethrownError(err, 'deleteAuthUser.ts');
  }
};

export default deleteAuthUser;
