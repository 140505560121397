import React from 'react';

import cx from '../../../utils/styling/cx';

// Types
import { HTMLAttributes, ReactNode } from 'react';

type Props = {
  children: ReactNode;
  isHighlighted?: boolean;
} & HTMLAttributes<HTMLDivElement>;

const PlanCardHeader = ({ children, className, isHighlighted = false, ...rest }: Props) => {
  const classes = cx(
    'py-8 text-center font-semibold uppercase text-xl',
    {
      'text-indigo-900': !isHighlighted,
      'text-white': isHighlighted,
    },
    className,
  );

  return (
    <>
      <div className={classes} {...rest}>
        <span>{children}</span>
      </div>

      <hr className="py-0 my-0 border" />
    </>
  );
};

export default PlanCardHeader;
