import React from 'react';

import ContentPageHeader from '../ContentPageItems/ContentPageHeader';
import ContentPageLayout from '../ContentPageItems/ContentPageLayout';
import ContentPageParagraph from '../ContentPageItems/ContentPageParagraph';
import ContentPageParagraphLink from '../ContentPageItems/ContentPageParagraphLink';

type Props = {};

const Imprint = (_props: Props) => {
  return (
    <ContentPageLayout>
      <ContentPageHeader>Imprint</ContentPageHeader>

      <address className="mb-4 not-italic">
        <ContentPageParagraph>
          <span className="font-semibold">Luca Hammer</span> <br />
          Heistermannweg 36
          <br />
          33184 Altenbeken
          <br />
          Germany
        </ContentPageParagraph>

        <ContentPageParagraph>
          <ContentPageParagraphLink target="_self" url="mailto:lucahammer@gmail.com">
            lucahammer@gmail.com
          </ContentPageParagraphLink>
        </ContentPageParagraph>
      </address>
    </ContentPageLayout>
  );
};

export default Imprint;
