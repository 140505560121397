import { types } from 'mobx-state-tree';

import AuthUserTwitterProfileModel from './AuthUserTwitterProfile';

// Types
import { Instance } from 'mobx-state-tree';

const AuthUserModel = types.model('AuthUser', {
  activePlan: types.maybeNull(types.string),
  createdAt: types.string,
  email: types.maybeNull(types.string),
  id: types.string,
  isChargebeeCustomer: types.boolean,
  isSubscribedToNewsletter: types.boolean,
  twitter: AuthUserTwitterProfileModel,
  downloadedTweets: types.maybeNull(types.number),
  downloadedTweetsDate: types.maybeNull(types.string),
});

export interface AuthUser extends Instance<typeof AuthUserModel> {}

export default AuthUserModel;
