// Types
import { BaseMixin } from 'dc';

type CreateAttachTooltipInput = {
  selector: string;
  tooltip: any;
};

const createAttachTooltip = ({ selector, tooltip }: CreateAttachTooltipInput) => (chart: BaseMixin<any>) => {
  chart
    .selectAll(selector)
    .call(tooltip)
    .on('mouseout', (datum: any) => {
      tooltip.hide(datum);
      tooltip.style('left', '-999px');
      tooltip.style('top', '-999px');
    })
    .on('mouseover', tooltip.show);
};

export default createAttachTooltip;
