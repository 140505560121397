import React from 'react';

import Heading from '../../Heading';
import cx from '../../../utils/styling/cx';

// Types
import { HTMLAttributes } from 'react';

type Props = {} & HTMLAttributes<HTMLHeadingElement>;

const ChartHeading = ({ children, className, ...rest }: Props) => {
  return (
    <Heading className={cx('font-semibold ml-4 mt-2 mb-4 text-lg', className)} {...rest}>
      {children}
    </Heading>
  );
};

export default ChartHeading;
