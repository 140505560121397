import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAt } from '@fortawesome/free-solid-svg-icons';

import cx from '../../../utils/styling/cx';

// Types
import { SyntheticEvent } from 'react';

type Props = {
  handleAnalyzeClick: (event: SyntheticEvent<HTMLFormElement>) => void;
  handleUsernameChange: (event: SyntheticEvent<HTMLInputElement>) => void;
  isTwitterRoute: boolean;
  username: string;
};

const HeaderAnalyzeTwitterAccountInput = ({ handleAnalyzeClick, handleUsernameChange, isTwitterRoute, username }: Props) => {
  return (
    <form className="flex h-16 items-center ml-4" onSubmit={handleAnalyzeClick}>
      <div className="relative text-gray-400 flex items-center focus-within:text-brand">
        <div className="absolute left-0 h-auto ml-4 flex items-center">
          <FontAwesomeIcon icon={faAt} size="sm" />
        </div>

        <input
          className={cx('bg-gray-200 h-10 pr-4 pl-8 font-medium rounded-l-lg text-md focus:outline-none border-r text-gray-900', {
            'max-w-3xs md:max-w-full': isTwitterRoute,
          })}
          name="analyze"
          onChange={handleUsernameChange}
          placeholder="Luca"
          style={{ paddingBottom: 1 }}
          type="search"
          value={username}
        />
      </div>

      <button className="h-auto bg-gray-200 h-10 px-4 py-2 rounded-r-lg hover:bg-gray-400" type="submit">
        Analyze
      </button>
    </form>
  );
};

export default HeaderAnalyzeTwitterAccountInput;
