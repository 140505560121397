import { types } from 'mobx-state-tree';

// Types
import { Instance } from 'mobx-state-tree';

const TwitterUserModel = types.model('TwitterUser', {
  bioUrl: types.maybeNull(types.string),
  createdAt: types.string,
  description: types.maybeNull(types.string),
  followersCount: types.number,
  followingCount: types.number,
  hasDefaultProfile: types.boolean,
  hasDefaultProfileImage: types.boolean,
  id: types.string,
  isProtected: types.boolean,
  isVerified: types.boolean,
  likedCount: types.number,
  listedCount: types.number,
  location: types.maybeNull(types.string),
  name: types.string,
  profileImageUrl: types.maybeNull(types.string),
  tweetsCount: types.number,
  username: types.string,
});

export interface TwitterUser extends Instance<typeof TwitterUserModel> {}

export default TwitterUserModel;
