import React from 'react';
import { NProgress } from '@tanem/react-nprogress';

type Props = {
  isLoading: boolean;
};

const LoadingBar = ({ isLoading }: Props) => {
  return (
    <div className="relative">
      <NProgress isAnimating={isLoading} key="loadingBar" minimum={0.5}>
        {({ animationDuration, isFinished, progress }) => {
          return (
            <div aria-hidden="true" style={{ opacity: isFinished ? 0 : 1, transition: `opacity ${animationDuration}ms linear` }}>
              <div
                className="bg-brand fixed w-full"
                style={{
                  height: 2,
                  left: 0,
                  marginLeft: `${(-1 + progress) * 100}%`,
                  top: 63,
                  transition: `margin-left ${animationDuration}ms linear`,
                  zIndex: 9,
                }}
              >
                <div
                  className="absolute block h-full right-0 shadow-inner"
                  style={{ opacity: 1, transform: 'rotate(3deg) translate(0px, -4px)', width: 100 }}
                />
              </div>
            </div>
          );
        }}
      </NProgress>
    </div>
  );
};

export default LoadingBar;
