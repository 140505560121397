import { differenceInSeconds } from 'date-fns';
import { last, takeRight } from 'lodash';

const getCurrentPressureBySeconds = (data: number[]) => {
  const currentPressureBySeconds = last(data);

  if (typeof currentPressureBySeconds !== 'number') {
    return 0;
  }

  return currentPressureBySeconds;
};

type CalculatePressureInput = {
  maxPoints: number;
  previousPressures: number[];
  streamStartDate: Date | null;
  tweetsCount: number;
};

export const calculatePressure = ({ maxPoints, previousPressures, streamStartDate, tweetsCount }: CalculatePressureInput) => {
  if (!!streamStartDate) {
    const difference = differenceInSeconds(new Date(), streamStartDate);

    const tweetsPerSecond = tweetsCount / difference;

    if (tweetsPerSecond !== Infinity && tweetsPerSecond >= 0) {
      return [...takeRight(previousPressures, maxPoints - 1), tweetsPerSecond];
    }
  }

  return previousPressures;
};

// This is so we don't have overly long float values like `2.123412342`,
// so we'll shorten it to two decimal places (which converts it to a string)
// but convert it back to number so we can math with it.
export const formatCurrentPressure = (currentPressure: number) => {
  return parseFloat(currentPressure.toFixed(2));
};

type CalculatePeakPressureInput = {
  pressures: number[];
  previousPeakPressure: number;
};

export const calculatePeakPressure = ({ pressures, previousPeakPressure }: CalculatePeakPressureInput) => {
  const mappedPressures = pressures.map(formatCurrentPressure);

  return Math.max(...mappedPressures, formatCurrentPressure(previousPeakPressure));
};

export const getCurrentPressureObject = (data: number[]) => {
  const currentPressureBySeconds = getCurrentPressureBySeconds(data);

  // If the tweets by seconds is too low, we'll switch to tweets per minute.
  if (currentPressureBySeconds > 0 && currentPressureBySeconds < 0.5) {
    const currentPressureByMinutes = currentPressureBySeconds * 60;

    // If the tweets by minutes is still too low, we'll switch to tweets per hours.
    if (currentPressureByMinutes > 0 && currentPressureByMinutes < 0.5) {
      const currentPressureByHours = currentPressureByMinutes * 60;

      return {
        currentPressure: formatCurrentPressure(currentPressureByHours),
        unit: 'hour',
      };
    }

    // Nope, fine to stick with minutes.
    return {
      currentPressure: formatCurrentPressure(currentPressureByMinutes),
      unit: 'minute',
    };
  }

  // Nope, fine to stick with seconds.
  return {
    currentPressure: formatCurrentPressure(currentPressureBySeconds),
    unit: 'second',
  };
};
