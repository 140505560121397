import React from 'react';

export const UserCard = () => (
  <>
    This is the <em>User Card</em>, it shows the basic information of the account you are currently analyzing. How many Tweets the account
    published, how many accounts they follow and how many follow them. The number of Tweets they favorited and on how many lists they are.
    Most of these metrics can be directly or indirectly changed by the user. Only the date of creation and ID are fixed.
  </>
);

export const DailyRhytm = () => (
  <>
    The <em>Daily Rhythm</em> chart shows at which hours on which weekdays an account is most active. The darker the blue, the more tweets
    were published at that time. You can click on each individual hour or select all hours of a weekday or the same hour each weekday by
    clicking on the respective label. Or any combination. The times are shown in your local time zone (of your computer). Watch out for
    weird patterns. Most people need to sleep, bots don't. Some people work night shifts or post irregularly.
  </>
);

export const VolumeByDate = () => (
  <>
    The <em>Tweetvolume by Date</em> shows how many Tweets the account published at a specific date. You can click and drag to only look at
    Tweets that were published on the highlighted dates. Only few people Tweet the same number of Tweets each day. Single days with high
    volume can indicate that an event happened. The Hashtag-chart can help with identifying what kind of event.
  </>
);

export const DayOfWeek = () => (
  <>
    The <em>Day of Week</em> chart shows on which weekdays an account is most active. You can click on each weekday to select Tweets that
    were published on that weekday. The chart shows the aggregated number of Tweets for that weekday from all Tweets that were retrieved
    from the account. Most people tweet either more on workdays or on weekends. Some have different schedules.
  </>
);

export const TweetType = () => (
  <>
    There are five different <em>Tweet Type</em>s. A "Retweet" is a native Retweet either of a different account or one of their own Tweets.
    It only takes one or two taps to retweet an Tweet. Therefore it's easy to generate high numbers of Retweets. A "Quote" is a Retweet with
    a comment above it. Sometimes referred to as a Quote-Tweet or Quote-Retweet. A "Reply" is a Tweet that starts with an @ and a username.
    In most cases it is attached to a Tweet by someone else. Only users who follow both accounts see Replies in their chronological
    timeline. "Self-Replies" are Replies that are attached to the Tweets of the same account. Often referred to as Threads. A Thread consists
    of one Tweet and one or more Self-Replies. Finally the "Tweet" is a Tweet that isn't a Retweet, Quote or Reply. Each account has a
    different Tweeting style, but most use multiple if not all types of Tweets with different intensity.
  </>
);

export const UsedLanguage = () => (
  <>
    Twitter tries to determine in which language each Tweet is written. The <em>Language of Tweets</em> chart shows those languages and how
    many Tweets the account has published in them. The categorization algorithm isn't perfect. Especially short Tweets or Tweets with
    loanwords result in false categorizations. Tweets with a high uncertainty (eg. media only Tweets) are categorized as "Unknown". Few
    people post many Tweets in more than three languages.
  </>
);

export const UsedApp = () => (
  <>
    The <em>Used Interface</em> chart shows which apps the account used to publish the Tweets. Most users stay with the official ones
    (Twitter Web App, Twitter for Android, Twitter for iPhone, Twitter for iPad, TweetDeck), but there are some popular fully featured third
    party apps as well (Tweetbot, Fenix,…). Other third party apps are mostly used for customer support (swat.io, hootsuite,…) or automation
    (Buffer, IFTTT,…). Advanced bots use the official apps to appear less suspicious.
  </>
);

export const UsedHashtags = () => (
  <>
    Each Tweet can contain one or more Hashtags. The <em>Used Hashtags</em> shows them all and allows you to filter for Tweets that contain
    the Hashtags you are interested in. Hashtags have multiple uses. Most accounts use them to categorize their Tweets, participate in
    events and/or increase the visibility of the Tweets. Looking at the most used Hashtags helps with understanding the main topics an
    account tweets about.
  </>
);

export const UsedHostnames = () => (
  <>
    Each Tweet can contain one or multiple URLs. The <em>Hostnames of URLs</em> chart shows the hostnames of those URLs. The hostname
    contains the subdomain but not the path, query or fragment of the URL. "twitter.com" and "mobile.twitter.com" are excluded as they
    represent quoted Tweets and media uploads.
  </>
);

export const RepliedUser = () => (
  <>
    The <em>Replied Users</em> chart shows which accounts the analyzed account writes most Replies to. Only the user the account directly
    replied to is counted. If user A posts a Tweet, user B replies and user C replies to that reply, only user B shows up in the chart when
    analyzing user C. Self-Replies are excluded.
  </>
);

export const RetweetedUser = () => (
  <>
    The <em>Retweeted Users</em> chart shows which accounts the analyzed account retweets most often.
  </>
);

export const QuotedUser = () => (
  <>
    The <em>Quoted Users</em> chart shows which accounts the analyzed account quotes most often.
  </>
);

export const LastTweetsGrid = () => (
  <>
    The <em>Last Tweets Grid</em> displays the Tweets in the current selection. While you drill down in the data it helps to look at the
    actual Tweets. The label on the right shows what type the specific Tweet has. By clicking on the date you can visit the Tweet on
    Twitter. By clicking on "Show Full Tweet" you can look at the Twitter-Embed of the Tweet.
  </>
);
