// Types
import { BaseMixin } from 'dc';

const setChartBorderRadius = (chart: BaseMixin<any>) => {
  chart
    .selectAll('rect')
    .attr('rx', 2)
    .attr('ry', 2);
};

export default setChartBorderRadius;
