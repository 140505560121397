import React from 'react';
import dc from 'dc';

import RowChartTypeAContentLoader from '../../../components/Chart/ChartContentLoaders/RowChartTypeAContentLoader';
import TwitterChartContainer from '../../TwitterChart';
import buildRowChartTitle from '../../../utils/dc/buildRowChartTitle';
import envConfig from '../../../config/env';
import removeRecordsWithEmptyKeys from '../../../utils/dc/removeRecordsWithEmptyKeys';

// Types
import { ChartFunctionInput, RowChartDatum } from '../../../types/twitterCharts';
import { Crossfilter } from 'crossfilter2';
import { TwitterNormalizedTweet } from '../../../types/twitter';
import { TwitterStore } from '../../../data/models/twitter/TwitterStore';
import { TwitterStreamStore } from '../../../data/models/twitter/TwitterStreamStore';

const createTweetTypeChart = ({ crossfilter, divRef }: ChartFunctionInput) => {
  const tweetTypeChart = dc.rowChart(divRef);

  const dimension = crossfilter.dimension(tweet => tweet.type);

  const group = removeRecordsWithEmptyKeys(dimension.group());

  tweetTypeChart
    .width(650)
    .height(300)
    .useViewBoxResizing(true)
    .margins({ bottom: 60, left: 20, right: 20, top: 0 })

    .dimension(dimension)
    .group(group)

    .label(({ key, value }: RowChartDatum) => `${key} (${value})`)
    .title((datum: RowChartDatum) => buildRowChartTitle(datum))

    .fixedBarHeight(42)
    .elasticX(true)
    .cap(5)
    .gap(envConfig.twitterChartsGap)
    .xAxis()
    .ticks(4);

  return tweetTypeChart;
};

type Props = {
  crossfilterInstance: Crossfilter<TwitterNormalizedTweet>;
  twitterStore: TwitterStore | TwitterStreamStore;
};

const TweetTypeChart = ({ crossfilterInstance, twitterStore }: Props) => {
  return (
    <TwitterChartContainer
      chartFunction={createTweetTypeChart}
      crossfilterInstance={crossfilterInstance}
      contentLoader={<RowChartTypeAContentLoader />}
      title="Tweet Type"
      tooltipSelector="g.row"
      twitterStore={twitterStore}
    />
  );
};

export default TweetTypeChart;
