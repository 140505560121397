import React from 'react';
import Helmet from 'react-helmet';

import SignIn from '../../components/SignIn';

type Props = {};

const SignInContainer = (_props: Props) => {
  return (
    <>
      <Helmet>
        <title>SignIn</title>
      </Helmet>

      <SignIn />
    </>
  );
};

export default SignInContainer;
