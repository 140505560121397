import { parse } from 'date-fns';

const getIsoDateTime = (timestamp?: string | number | null): string | null => {
  if (!timestamp) {
    return null;
  }

  const dateTime = parse(timestamp).toISOString();

  return dateTime;
};

export default getIsoDateTime;
