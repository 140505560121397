import React from 'react';

import cx from '../../../utils/styling/cx';

// Types
import { HTMLAttributes } from 'react';

type Props = {} & HTMLAttributes<HTMLDivElement>;

const ChartIconGroup = ({ className, ...rest }: Props) => {
  return <div className={cx('absolute top-0 right-0 m-4', className)} {...rest} />;
};

export default ChartIconGroup;
