export enum TwitterTweetType {
  Quote = 'Quote',
  Reply = 'Reply',
  Retweet = 'Retweet',
  SelfReply = 'Self-Reply',
  Tweet = 'Tweet',
}

export type TwitterMinimizedTweet = {
  id: string;
  language: string;
  length: number;
  name: string;
  profileImageUrl: string;
  quotedUser: string | null;
  repliedUser: string | null;
  retweetedUser: string | null;
  text: string | null;
  timestamp: string;
  type: TwitterTweetType;
  url: string;
  usedApp: string;
  usedHashtags: string[];
  usedHostnames: string[];
  username: string;
};

export type TwitterNormalizedTweet = {
  date: string;
  dayOfWeek: number;
  id: string;
  language: string;
  length: number;
  name: string;
  profileImageUrl: string;
  quotedUser: string | null;
  repliedUser: string | null;
  retweetedUser: string | null;
  text: string | null;
  timeOfDay: string;
  timestamp: string;
  type: TwitterTweetType;
  url: string;
  usedApp: string;
  usedHashtags: string[];
  usedHostnames: string[];
  username: string;
};

export type TwitterNormalizedUser = {
  bioUrl: string | null;
  createdAt: string;
  description: string;
  followersCount: number;
  followingCount: number;
  hasDefaultProfile: boolean;
  hasDefaultProfileImage: boolean;
  id: string;
  isProtected: boolean;
  isVerified: boolean;
  likedCount: number;
  listedCount: number;
  location: string;
  name: string;
  profileImageUrl?: string | null;
  tweetsCount: number;
  username: string;
};
