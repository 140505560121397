import React from 'react';
import ContentLoader from 'react-content-loader';

import envConfig from '../../../config/env';
import tailwind from '../../../config/tailwind';

const VolumeByDateChartContentLoader = () => (
  <ContentLoader
    ariaLabel="Loading Chart"
    height={200}
    primaryColor={tailwind.theme.colors.brand.light}
    secondaryColor={tailwind.theme.colors.brand['light-extra']}
    speed={envConfig.twitterChartsContentLoaderSpeed}
    width={867}
  >
    <rect x="40" y="88" rx="2" ry="2" width="35" height="59" />
    <rect x="90" y="59" rx="2" ry="2" width="35" height="87" />
    <rect x="140" y="17" rx="2" ry="2" width="35" height="128" />
    <rect x="190" y="75" rx="2" ry="2" width="35" height="72" />
    <rect x="240" y="33" rx="2" ry="2" width="35" height="112" />
    <rect x="290" y="110" rx="2" ry="2" width="35" height="36" />
    <rect x="340" y="123" rx="2" ry="2" width="35" height="24" />
    <rect x="390" y="47" rx="2" ry="2" width="35" height="100" />
    <rect x="440" y="68" rx="2" ry="2" width="35" height="77" />
    <rect x="490" y="83" rx="2" ry="2" width="35" height="63" />
    <rect x="540" y="90" rx="2" ry="2" width="35" height="57" />
    <rect x="590" y="25" rx="2" ry="2" width="35" height="120" />
    <rect x="640" y="41" rx="2" ry="2" width="35" height="104" />
    <rect x="690" y="71" rx="2" ry="2" width="35" height="75" />
    <rect x="740" y="53" rx="2" ry="2" width="35" height="93" />
    <rect x="790" y="1" rx="2" ry="2" width="35" height="144" />
  </ContentLoader>
);

export default VolumeByDateChartContentLoader;
