import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';

import Link from '../Link';
import cx from '../../utils/styling/cx';
import envConfig from '../../config/env';

import { buildQuerystring, buildUrl } from './utils';

// Types
import { HTMLAttributes } from 'react';

type Props = {
  redirectUrl?: string;
  text?: string;
} & HTMLAttributes<HTMLAnchorElement>;

const TwitterSignInButton = ({ className, redirectUrl, text = 'Sign in with Twitter', ...rest }: Props) => {
  const querystring = buildQuerystring(redirectUrl);
  const url = buildUrl(querystring);

  return (
    <Link
      asExternal={envConfig.isProduction}
      className={cx('min-w-2xs sm:min-w-0  h-auto bg-twitter text-white h-10 px-4 py-2 rounded-lg hover:bg-blue-600', className)}
      target="_self"
      url={url}
      {...rest}
    >
      <FontAwesomeIcon className="mr-2 text-white" icon={faTwitter as any} fixedWidth={true} size="sm" />

      {text}
    </Link>
  );
};

export default TwitterSignInButton;
