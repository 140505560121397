type GetStyleInput = {
  direction: 'down' | 'up';
  side: 'left' | 'right';
};

export const getStyle = ({ direction, side }: GetStyleInput) => {
  if (direction === 'down') {
    return { transform: 'rotate(180deg)' };
  }

  if (side === 'left') {
    return { transform: 'scaleX(-1)' };
  }

  return {};
};
