const getWeekdayName = (dayOfWeekNumber: number) => {
  switch (dayOfWeekNumber) {
    case 0:
      return 'Sun';
    case 1:
      return 'Mon';
    case 2:
      return 'Tue';
    case 3:
      return 'Wed';
    case 4:
      return 'Thu';
    case 5:
      return 'Fri';
    case 6:
      return 'Sat';

    default:
      throw new Error(`Wrong 'dayOfWeekNumber' input: '${dayOfWeekNumber}'`);
  }
};

export default getWeekdayName;
