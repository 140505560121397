import React from 'react';
import pluralize from 'pluralize';

import HeaderAnalyzeTwitterAccountInput from '../../Header/HeaderAnalyzeTwitterAccountInput';
import HeaderLayout from '../../Header/HeaderLayout';
import LoadMoreButton from './LoadMoreButton';
import DownloadButton from './DownloadButton';
import Tooltip from '../../Tooltip';
import envConfig from '../../../config/env';
import Link from '../../Link';
import routes from '../../../config/routing/routes';
import { useAuthStore } from '../../../data/models/auth/AuthStore';


// Types
import { SyntheticEvent } from 'react';

type Props = {
  handleAnalyzeClick: (event: SyntheticEvent<HTMLFormElement>) => void;
  handleLoadMore: () => void;
  handleUsernameChange: (event: SyntheticEvent<HTMLInputElement>) => void;
  isLoadMoreDisabled: boolean;
  isLoadingMore: boolean;
  isLoadingUser: boolean;
  isSignedIn: boolean;
  isAccountRoute: boolean;
  isSubscribed: boolean;
  isTwitterRoute: boolean;
  tweetsCount: number;
  username: string;
};

const TwitterAccountHeader = (props: Props) => {
  const {
    handleAnalyzeClick,
    handleLoadMore,
    handleUsernameChange,
    isLoadMoreDisabled,
    isLoadingMore,
    isLoadingUser,
    isSignedIn,
    isSubscribed,
    isTwitterRoute,
    tweetsCount,
    username,
  } = props;

  const { tweetsLeftToDownload } = useAuthStore();


  const tweetsCountLabel = pluralize('Tweet', tweetsCount);

  return (
    <HeaderLayout isLoading={isLoadingMore} isSignInButtonVisible={!isLoadingUser && !isSignedIn} isSubscribed={isSubscribed}>
      {isSignedIn && (
        <>
          <div className="hidden sm:inline-flex">
            <span className="bg-gray-100 text-gray-800 py-2 px-4 rounded-l">
              {tweetsCount} {tweetsCountLabel}
            </span>

            {isSubscribed && tweetsLeftToDownload > 0 &&(
              <DownloadButton isDisabled={isLoadMoreDisabled || tweetsCount === 0 }> Download as CSV</DownloadButton>
            )}

            {isSubscribed && tweetsLeftToDownload < 0 && (
              <DownloadButton isDisabled={true}> Download (reached daily limit)</DownloadButton>
            )}

            {!isSubscribed && (
              <Tooltip
                isDisabled={!isLoadMoreDisabled}
                label={`Subscribe to the Pro-Plan to analyze up to ${envConfig.twitterMaxProTweets} ${tweetsCountLabel}.`}
              >
                <LoadMoreButton isDisabled={isLoadMoreDisabled || tweetsCount === 0} onClick={handleLoadMore}>
                  Load More
                </LoadMoreButton>
              </Tooltip>
            )}

            {!isSubscribed && tweetsCount <= 400 && <div className="w-40"> </div>}

            {!isSubscribed && tweetsCount > 400 && (
              <Link url={routes.account} className="ml-4 mr-4 px-4 py-2 bg-indigo-600 text-white rounded hover:bg-indigo-700">
                Upgrade to Pro
              </Link>
            )}
          </div>

          <div className="sm:hidden inline-flex ml-2">
            <span className="bg-gray-100 text-gray-800 py-2 px-4 rounded-l">{tweetsCount}</span>

            <LoadMoreButton isDisabled={isLoadMoreDisabled || tweetsCount === 0} onClick={handleLoadMore}>
              More
            </LoadMoreButton>
          </div>

          <HeaderAnalyzeTwitterAccountInput
            handleAnalyzeClick={handleAnalyzeClick}
            handleUsernameChange={handleUsernameChange}
            isTwitterRoute={isTwitterRoute}
            username={username}
          />
        </>
      )}
    </HeaderLayout>
  );
};

export default TwitterAccountHeader;
