import React from 'react';

import HeaderAnalyzeTwitterAccountInput from './HeaderAnalyzeTwitterAccountInput';
import HeaderLayout from './HeaderLayout';

// Types
import { SyntheticEvent } from 'react';

type Props = {
  handleAnalyzeClick: (event: SyntheticEvent<HTMLFormElement>) => void;
  handleUsernameChange: (event: SyntheticEvent<HTMLInputElement>) => void;
  isLoadingUser: boolean;
  isSignedIn: boolean;
  isAccountRoute: boolean;
  isSubscribed: boolean;
  isTwitterRoute: boolean;
  username: string;
};

const Header = (props: Props) => {
  const { handleAnalyzeClick, handleUsernameChange, isLoadingUser, isSignedIn, isSubscribed, isTwitterRoute, username } = props;

  return (
    <HeaderLayout isSignInButtonVisible={!isLoadingUser && !isSignedIn} isSubscribed={isSubscribed}>
      {isSignedIn && (
        <HeaderAnalyzeTwitterAccountInput
          handleAnalyzeClick={handleAnalyzeClick}
          handleUsernameChange={handleUsernameChange}
          isTwitterRoute={isTwitterRoute}
          username={username}
        />
      )}
    </HeaderLayout>
  );
};

export default Header;
