import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import ContentPageHeader from '../ContentPageItems/ContentPageHeader';
import ContentPageLayout from '../ContentPageItems/ContentPageLayout';
import Heading from '../Heading';
import PlansCardProV1 from '../Plans/PlansCardProV1';
import Section from '../Heading/Section';
import { useAuthStore } from '../../data/models/auth/AuthStore';

// Types
import { ReactNode, SyntheticEvent } from 'react';

type Props = {
  email: string;
  isDeleteAccountPopupOpen: boolean;
  isLoading: boolean;
  isSubscribedToNewsletter: boolean;
  onDeleteAccount: () => void;
  onEmailChange: (event: SyntheticEvent<HTMLInputElement>) => void;
  onIsSubscribedToNewsletterChange: () => void;
  onSubmit: (event: SyntheticEvent<HTMLFormElement>) => void;
  subscribeButton: ReactNode;
  toggleIsDeleteAccountPopupOpen: () => void;
  username: string;
};

const Account = (props: Props) => {
  const {
    email,
    isDeleteAccountPopupOpen,
    isLoading,
    isSubscribedToNewsletter,
    onDeleteAccount,
    onEmailChange,
    onIsSubscribedToNewsletterChange,
    onSubmit,
    subscribeButton,
    toggleIsDeleteAccountPopupOpen,
    username,
  } = props;

  const { user } = useAuthStore();

  if (!user) {
    return null;
  }

  const plansText = user.isChargebeeCustomer && !!user.activePlan ? 'Your Current Plan' : 'Upgrade to Pro';

  return (
    <ContentPageLayout>
      <ContentPageHeader>Account</ContentPageHeader>

      <div className="flex flex-col md:flex-row w-full">
        <Section className="w-full md:w-1/2">
          <Heading className="text-gray-800 text-2xl mb-4">Your Account Information</Heading>

          <div>
            <form className="w-full md:w-3/4" onSubmit={onSubmit}>
              <div className="mt-6">
                <label className="block text-gray-800 text-sm font-semibold mb-2" htmlFor="email">
                  Connected Account
                  <input
                    className="cursor-not-allowed mt-1 text-sm appearance-none rounded w-full py-2 px-3 text-gray-500 bg-brand-light-extra leading-tight focus:outline-none focus:shadow-outline h-10"
                    disabled={true}
                    id="email"
                    placeholder="Your email address"
                    type="text"
                    value={`@${username}`}
                  />
                </label>
              </div>

              <div className="mt-4">
                <label className="block text-gray-800 text-sm font-semibold mb-2" htmlFor="email">
                  Email
                  <input
                    className="mt-1 text-sm appearance-none rounded w-full py-2 px-3 text-gray-800 bg-brand-light-extra leading-tight focus:outline-none focus:shadow-outline h-10"
                    id="email"
                    onChange={onEmailChange}
                    placeholder="Your email address"
                    type="email"
                    value={email}
                  />
                  <span className="text-sm">Contact information to announce service changes or problems.</span>
                </label>
              </div>

              <div className="mt-4">
                <div className=""></div>

                <label className="block text-gray-800" htmlFor="checkboxNewsletter">
                  <input
                    checked={isSubscribedToNewsletter}
                    className="mr-2 leading-tight"
                    id="checkboxNewsletter"
                    onChange={onIsSubscribedToNewsletterChange}
                    type="checkbox"
                  />

                  <span className="text-sm">I want to receive mails about new features, promotions, and more.</span>
                </label>
              </div>

              <div className="mt-6 w-full">
                <button className="px-4 py-2 w-full bg-brand text-gray-100 rounded hover:bg-brand-dark" type="submit">
                  {isLoading && <FontAwesomeIcon icon={faSpinner} pulse={true} />}

                  {!isLoading && 'Save'}
                </button>
              </div>

              <div className="mt-4 w-full flex flex-col justify-center relative">
                <button
                  className="w-full px-4 py-2 text-red-500 hover:text-red-900 rounded"
                  onClick={toggleIsDeleteAccountPopupOpen}
                  type="button"
                >
                  Delete Account
                </button>

                {isDeleteAccountPopupOpen && (
                  <div className="p-4 absolute shadow rounded -m-1 top-0 bg-white">
                    <p className="block mb-1 text-gray-800 leading-tight mb-4">
                      Are you sure you want to delete your account? This action is irreversible and will <b>cancel your subscription</b> as well.
                    </p>

                    <div className="block flex flex-wrap w-full">
                      <button
                        className="rounded-l justify-center p-2 text-xs font-bold bg-green-500 hover:bg-green-900 text-white flex w-1/2"
                        onClick={onDeleteAccount}
                        type="button"
                      >
                        Yes
                      </button>

                      <button
                        className="rounded-r justify-center p-2 text-xs bg-red-500 hover:bg-red-900 text-white flex w-1/2"
                        onClick={toggleIsDeleteAccountPopupOpen}
                        type="button"
                      >
                        No
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </form>
          </div>
        </Section>

        <Section className="w-full md:w-1/2 lg:w-1/3">
          <Heading className="text-gray-800 text-2xl mb-4">{plansText}</Heading>

          <PlansCardProV1 subscribeButton={subscribeButton} />
        </Section>
      </div>
    </ContentPageLayout>
  );
};

export default Account;
