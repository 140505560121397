const routesApi = {
  auth: {
    deleteUser: '/auth/me',
    me: '/auth/me',
    twitter: {
      signIn: '/auth/twitter/signIn',
      signOut: '/auth/twitter/signOut',
    },
    updateUser: '/auth/me',
  },
  twitter: {
    fetchTweets: '/api/twitter/tweets',
    fetchUser: '/api/twitter/user',
  },
};

export default routesApi;
