import React from 'react';

import cx from '../../../utils/styling/cx';

// Types
import { HTMLAttributes, ReactNode } from 'react';

type Props = {
  children: ReactNode;
} & HTMLAttributes<HTMLDivElement>;

const PlanCardFeatures = ({ children, className, ...rest }: Props) => {
  const classes = cx('mt-8 mx-auto', className);

  return (
    <div className={classes} {...rest}>
      {children}
    </div>
  );
};

export default PlanCardFeatures;
