import React from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';

import { isInternalLink } from './utils';

// Types
import { FC, ReactNode, SyntheticEvent } from 'react';
import { RouteComponentProps } from 'react-router-dom';

type Props = {
  asExternal?: boolean;
  children: ReactNode;
  className?: string;
  onClick?: (...args: any[]) => void;
  target?: string;
  title?: string;
  url?: string | null;
} & RouteComponentProps;

const Link = ({ asExternal, children, className, onClick, target = '_blank', title, url }: Props) => {
  if (!url) {
    return null;
  }

  const handleClick = (event: SyntheticEvent<HTMLAnchorElement> | SyntheticEvent<HTMLButtonElement>) => {
    if (!!onClick) {
      onClick(event);
    }
  };

  if (!asExternal && isInternalLink(url)) {
    return (
      <RouterLink onClick={handleClick} to={url}>
        <button className={className} onKeyPress={handleClick} title={title} type="button">
          {children}
        </button>
      </RouterLink>
    );
  }

  const rel = target === '_blank' ? 'noopener noreferrer' : undefined;

  // External Link
  return (
    <a className={className} href={url} onClick={handleClick} rel={rel} target={target} title={title}>
      {children}
    </a>
  );
};

export default withRouter<Props, FC<Props>>(Link);
