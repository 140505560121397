import React from 'react';

import cx from '../../../utils/styling/cx';

// Types
import { HTMLAttributes, ReactNode } from 'react';

type Props = {
  children: ReactNode;
  isHighlighted?: boolean;
} & HTMLAttributes<HTMLDivElement>;

const PlanCardLayout = ({ children, className, isHighlighted = false, ...rest }: Props) => {
  const classes = cx(
    'shadow rounded md:flex-1 lg:flex-none z-10 mr-4 mb-4',
    {
      'bg-indigo-900': isHighlighted,
      'bg-white': !isHighlighted,
    },
    className,
  );

  return (
    <div className={classes} {...rest}>
      {children}
    </div>
  );
};

export default PlanCardLayout;
