import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartBar, faPlus } from '@fortawesome/free-solid-svg-icons';

import Link from '../../Link';
import LoadingBar from '../../LoadingBar';
import Tooltip from '../../Tooltip';
import TwitterSignInButton from '../../TwitterSignInButton';
import envConfig from '../../../config/env';

// Types
import { ReactNode } from 'react';

type Props = {
  children: ReactNode;
  isLoading?: boolean;
  isSignInButtonVisible: boolean;
  isSubscribed?: boolean;
};

const HeaderLayout = ({ children, isLoading = false, isSignInButtonVisible, isSubscribed }: Props) => {
  return (
    <header className="h-16">
      <div className="bg-white fixed h-16 bg-white shadow-md top-0 inset-x-0 z-20" />

      <div className="container mx-auto flex fixed h-16 top-0 inset-x-0 z-20 items-center justify-between p-2 ">
        <div className="pl-0 flex flex-row items-center">
          <Link className="pl-4 py-2 font-bold flex items-center flex-row" url="/">
            <FontAwesomeIcon className="lg:hidden mr-2 text-brand hover:text-gray-800" icon={faChartBar} size="2x" />

            <FontAwesomeIcon className="hidden lg:inline-block xl:hidden mr-2 text-brand" icon={faChartBar} size="sm" />

            <FontAwesomeIcon className="hidden xl:inline-block mr-2 text-brand" icon={faChartBar} size="lg" />

            <span className="hidden lg:inline-block mr-2 xl:text-xl text-gray-800 hover:text-brand">{envConfig.appName}</span>
          </Link>

          {!!isSubscribed && (
            <Tooltip ariaLabel="You're a pro-user, yay!" label="You're a pro-user, yay!">
              <span>
                <FontAwesomeIcon className="text-brand-light" fixedWidth={true} icon={faPlus} size="sm" />
              </span>
            </Tooltip>
          )}
        </div>

        {isSignInButtonVisible && <TwitterSignInButton />}

        {!isSignInButtonVisible && children}
      </div>

      <LoadingBar isLoading={isLoading} />
    </header>
  );
};

export default HeaderLayout;
