import React from 'react';

import cx from '../../../utils/styling/cx';

// Types
import { HTMLAttributes, ReactNode } from 'react';

type Props = {
  children: ReactNode;
} & HTMLAttributes<HTMLDivElement>;

const ContentPageLayout = ({ children, className, ...rest }: Props) => {
  const classes = cx('container flex flex-col mx-auto p-4 text-gray-800', className);

  return (
    <div className={classes} {...rest}>
      {children}
    </div>
  );
};

export default ContentPageLayout;
