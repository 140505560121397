import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import cx from '../../utils/styling/cx';

// Types
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Props as FontAwesomeProps } from '@fortawesome/react-fontawesome';

type Props = {
  disabledIcon?: IconProp;
  isDisabled?: boolean;
} & FontAwesomeProps;

const Icon = React.forwardRef<HTMLSpanElement, Props>(({ className, disabledIcon, fixedWidth = true, icon, isDisabled, ...rest }, ref) => {
  const renderIcon = () => {
    if (isDisabled && !!disabledIcon) {
      return disabledIcon;
    }

    return icon;
  };

  return (
    <span ref={ref}>
      <FontAwesomeIcon
        className={cx({ 'text-brand': !isDisabled, 'text-gray-300': isDisabled }, className)}
        fixedWidth={fixedWidth}
        icon={renderIcon()}
        {...rest}
      />
    </span>
  );
});

export default Icon;
