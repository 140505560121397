import React from 'react';
import crossfilter from 'crossfilter2';
import dc from 'dc';

import storeSnapshot from '../../data/storeSnapshot';
import { useTwitterStore } from '../../data/models/twitter/TwitterStore';

// Types
import { Crossfilter } from 'crossfilter2';
import { ReactNode } from 'react';
import { TwitterNormalizedTweet } from '../../types/twitter';

const initialValue = {
  crossfilterInstance: crossfilter<TwitterNormalizedTweet>([]),
  crossfilterMockInstance: crossfilter<TwitterNormalizedTweet>([]),
};

type TwitterCrossFilterContextValue = {
  crossfilterInstance: Crossfilter<TwitterNormalizedTweet>;
  crossfilterMockInstance: Crossfilter<TwitterNormalizedTweet>;
};

const TwitterCrossfilterContext = React.createContext<TwitterCrossFilterContextValue>(initialValue);

type Props = {
  children: ReactNode;
  data: TwitterNormalizedTweet[];
};

export const TwitterCrossfilterContextProvider = ({ children, data }: Props) => {
  const crossfilterInstance = crossfilter(data);
  const crossfilterMockInstance = crossfilter(storeSnapshot.tweets as TwitterNormalizedTweet[]);

  const value = { crossfilterInstance, crossfilterMockInstance };

  return <TwitterCrossfilterContext.Provider value={value}>{children}</TwitterCrossfilterContext.Provider>;
};

export const useTwitterCrossfilterContext = () => {
  const { crossfilterInstance, crossfilterMockInstance } = React.useContext(TwitterCrossfilterContext);

  const twitterStore = useTwitterStore();

  const addTweets = async (injectedCrossfilterInstance: Crossfilter<TwitterNormalizedTweet>) => {
    const newTweets = await twitterStore.fetchTweets();

    if (!!newTweets) {
      injectedCrossfilterInstance.add(newTweets);
    }
  };

  const fetchInitialTweets = async (injectedCrossfilterInstance: Crossfilter<TwitterNormalizedTweet>) => {
    const initialTweets = await twitterStore.fetchInitialTweets();

    if (!!initialTweets) {
      injectedCrossfilterInstance.add(initialTweets);

      dc.redrawAll();
    }
  };

  const fetchTweets = async (injectedCrossfilterInstance: Crossfilter<TwitterNormalizedTweet>) => {
    const newTweets = await twitterStore.fetchTweets();

    if (!!newTweets) {
      injectedCrossfilterInstance.add(newTweets);

      dc.redrawAll();
    }
  };

  const removeAllTweets = (injectedCrossfilterInstance: Crossfilter<TwitterNormalizedTweet>) => {
    injectedCrossfilterInstance.remove(() => true);

    twitterStore.resetStore();
  };

  const resetAllFilters = () => {
    dc.filterAll();

    dc.redrawAll();
  };

  return { addTweets, crossfilterInstance, crossfilterMockInstance, fetchInitialTweets, fetchTweets, removeAllTweets, resetAllFilters };
};

export default TwitterCrossfilterContext;
