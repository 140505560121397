import React from 'react';
import io from 'socket.io-client';

import envConfig from '../../config/env';

// Types
import { TwitterNormalizedTweet } from '../../types/twitter';

const socket = io(envConfig.backendUrl, {
  autoConnect: false,
  reconnection: true,
  reconnectionAttempts: Infinity,
  reconnectionDelay: 1000,
  reconnectionDelayMax: 5000,
});

type UseTwitterStreamSocketInput = {
  onNewTweet: (tweets: TwitterNormalizedTweet[]) => void;
};

const useTwitterStreamSocket = ({ onNewTweet }: UseTwitterStreamSocketInput) => {
  const [isConnected, setIsConnected] = React.useState<boolean>(false);
  const [subscribedTerm, setSubscribedTerm] = React.useState<string | null>(null);

  const subscribeToTerm = (nextSubscribedTerm: string) => {
    socket.emit('SUBSCRIBE_TO_TERM', { term: nextSubscribedTerm });

    setSubscribedTerm(nextSubscribedTerm);
  };

  const unsubscribeFromTerm = () => {
    if (typeof subscribedTerm === 'string') {
      socket.emit('UNSUBSCRIBE_FROM_TERM', { term: subscribedTerm });
      setSubscribedTerm(null);
    }
  };

  const startSocket = (nextSubscribedTerm: string) => {
    socket.connect();

    subscribeToTerm(nextSubscribedTerm);
  };

  const stopSocket = () => {
    unsubscribeFromTerm();

    socket.disconnect();
  };

  React.useEffect(() => {
    socket.on('NEW_TWEET', onNewTweet);

    return () => {
      socket.off('NEW_TWEET');
    };
  });

  React.useEffect(() => {
    socket.on('connect', () => {
      setIsConnected(true);
    });

    socket.on('disconnect', () => {
      setIsConnected(false);
    });

    socket.on('reconnect', () => {
      setIsConnected(true);

      if (typeof subscribedTerm === 'string') {
        subscribeToTerm(subscribedTerm);
      }
    });

    return () => {
      socket.off('connect');
      socket.off('disconnect');
      socket.off('reconnect');
    };
  });

  return { isConnected, startSocket, stopSocket };
};

export default useTwitterStreamSocket;
