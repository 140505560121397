import React from 'react';

// Types
import { HTMLAttributes } from 'react';

type Props = {
  label: string;
} & HTMLAttributes<HTMLSpanElement>;

const Chart1AxisXAxis = ({ label, ...rest }: Props) => {
  return (
    <span
      className="absolute bottom-0 left-1/2 mb-1 sm:mb-2 font-semibold text-gray-400 tracking-wide"
      style={{ marginLeft: `-${label.length * 1.5}px` }}
      {...rest}
    >
      {label}
    </span>
  );
};

export default Chart1AxisXAxis;
