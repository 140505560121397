import React from 'react';
import ContentLoader from 'react-content-loader';

import envConfig from '../../../config/env';
import tailwind from '../../../config/tailwind';

const DayOfWeekChartContentLoader = () => (
  <ContentLoader
    ariaLabel="Loading Chart"
    height={200}
    primaryColor={tailwind.theme.colors.brand.light}
    secondaryColor={tailwind.theme.colors.brand['light-extra']}
    speed={envConfig.twitterChartsContentLoaderSpeed}
    width={433}
  >
    <rect x="40" y="102" rx="2" ry="2" width="35" height="41" />
    <rect x="90" y="31" rx="2" ry="2" width="35" height="112" />
    <rect x="140" y="51" rx="2" ry="2" width="35" height="92" />
    <rect x="190" y="72" rx="2" ry="2" width="35" height="70" />
    <rect x="240" y="30" rx="2" ry="2" width="35" height="112" />
    <rect x="290" y="61" rx="2" ry="2" width="35" height="81" />
    <rect x="340" y="61" rx="2" ry="2" width="35" height="81" />
  </ContentLoader>
);

export default DayOfWeekChartContentLoader;
