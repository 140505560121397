import React from 'react';
import { Helmet } from 'react-helmet';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import DataCountContainer from '../Charts/DataCount/DataCountContainer';
import LastTweetsGridContainer from '../Charts/LastTweetsGrid';
import QuotedUserChart from '../Charts/QuotedUserChart';
import RepliedUserChart from '../Charts/RepliedUserChart';
import RetweetedUserChart from '../Charts/RetweetedUserChart';
import TweetTypeChart from '../Charts/TweetTypeChart';
import TweetsPressure from '../Charts/TweetsPressure';
import TwitterStream from '../../components/TwitterStream';
import TwitterStreamHeaderContainer from './TwitterStreamHeader';
import UsedAppChart from '../Charts/UsedAppChart';
import UsedHashtagsChart from '../Charts/UsedHashtagsChart';
import UsedHostnamesChart from '../Charts/UsedHostnamesChart';
import UsedLanguageChart from '../Charts/UsedLanguageChart';
import useToggle from '../../utils/hooks/useToggle';
import { useTwitterStreamStore } from '../../data/models/twitter/TwitterStreamStore';
import { useTwitterStreamingCrossfilterContext } from '../../utils/context/TwitterStreamingCrossfilterContext';

// Types
import { RouteComponentProps } from 'react-router-dom';

type Props = {
  initialTerm: string;
} & RouteComponentProps;

const TwitterStreamContainer = ({ initialTerm }: Props) => {
  const twitterStore = useTwitterStreamStore();
  const { crossfilterInstance, removeAllTweets } = useTwitterStreamingCrossfilterContext();

  const [isHelpVisible, toggleIsHelpVisible] = useToggle(false);

  const areAxisVisible = twitterStore.tweets.length > 0;
  const isLastTweetsGridVisible = twitterStore.tweets.length > 0;

  React.useEffect(() => {
    twitterStore.setTerm(initialTerm);

    return () => {
      removeAllTweets();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialTerm]);

  return (
    <>
      <Helmet>
        <title>Stream</title>
      </Helmet>

      <TwitterStreamHeaderContainer />

      <TwitterStream
        areAxisVisible={areAxisVisible}
        dataCount={
          <DataCountContainer crossfilterInstance={crossfilterInstance} isHelpVisible={isHelpVisible} onToggleHelp={toggleIsHelpVisible} />
        }
        isHelpVisible={isHelpVisible}
        lastTweetsGrid={
          isLastTweetsGridVisible && <LastTweetsGridContainer crossfilterInstance={crossfilterInstance} twitterStore={twitterStore} />
        }
        quotedUserChart={<QuotedUserChart crossfilterInstance={crossfilterInstance} twitterStore={twitterStore} />}
        repliedUserChart={<RepliedUserChart crossfilterInstance={crossfilterInstance} twitterStore={twitterStore} />}
        retweetedUserChart={<RetweetedUserChart crossfilterInstance={crossfilterInstance} twitterStore={twitterStore} />}
        stream={<TweetsPressure twitterStore={twitterStore} />}
        title="Stream"
        tweetTypeChart={<TweetTypeChart crossfilterInstance={crossfilterInstance} twitterStore={twitterStore} />}
        usedAppChart={<UsedAppChart crossfilterInstance={crossfilterInstance} twitterStore={twitterStore} />}
        usedHashtagsChart={<UsedHashtagsChart crossfilterInstance={crossfilterInstance} twitterStore={twitterStore} />}
        usedHostnamesChart={<UsedHostnamesChart crossfilterInstance={crossfilterInstance} twitterStore={twitterStore} />}
        usedLanguageChart={<UsedLanguageChart crossfilterInstance={crossfilterInstance} twitterStore={twitterStore} />}
      />
    </>
  );
};

export default withRouter(observer(TwitterStreamContainer));
