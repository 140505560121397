import React, { useState } from 'react';
import * as d3 from 'd3';
import dc from 'dc';
import { parse, format, addDays, isAfter } from 'date-fns';

import TwitterChartContainer from '../../TwitterChart';
import VolumeByDateChartContentLoader from '../../../components/Chart/ChartContentLoaders/VolumeByDateChartContentLoader';
import { getDateRange } from './utils';

// Types
import { ChartFunctionInput } from '../../../types/twitterCharts';
import { Crossfilter } from 'crossfilter2';
import { TwitterNormalizedTweet } from '../../../types/twitter';
import { TwitterStore } from '../../../data/models/twitter/TwitterStore';
import { TwitterStreamStore } from '../../../data/models/twitter/TwitterStreamStore';

const createVolumeByDateChart = (filterCallback: Function) => ({ crossfilter, divRef }: ChartFunctionInput) => {
  const volumeByDateChart = dc.barChart(divRef);

  const dimension = crossfilter.dimension((tweet: any) => parse(tweet.date));

  const group = dimension.group().reduceSum(() => 1);

  volumeByDateChart
    .width(867)
    .height(200)
    .useViewBoxResizing(true)
    .margins({ bottom: 55, left: 55, right: 30, top: 20 })

    .dimension(dimension)
    .group(group)

    .on('filtered', function(chart) {
      const filters = chart.filters();
      if (filters.length) {
        const range = filters[0];
        if (isAfter(addDays(range[0], 1), range[1])) {
          filterCallback('');
        } else {
          filterCallback(`(${format(addDays(range[0], 1), 'YYYY-MM-DD')}-${format(range[1], 'YYYY-MM-DD')})`);
        }
      } else {filterCallback('');}
    })

    .on('preRedraw', chart => {
      chart.x(d3.scaleTime().domain(getDateRange(crossfilter)));
    })
    .on('pretransition', chart => {
      chart.x(d3.scaleTime().domain(getDateRange(crossfilter)));
    })

    .elasticX(true)
    .elasticY(true)
    .mouseZoomable(false)
    .controlsUseVisibility(false)
    .centerBar(true)
    .gap(1)
    .renderHorizontalGridLines(true)
    .x(d3.scaleTime().domain(getDateRange(crossfilter)))
    .xAxisPadding(1)
    .xUnits(d3.timeDays as any)
    .yAxis()
    .ticks(4);

  return volumeByDateChart;
};

type Props = {
  crossfilterInstance: Crossfilter<TwitterNormalizedTweet>;
  twitterStore: TwitterStore | TwitterStreamStore;
};

const VolumeByDateChart = ({ crossfilterInstance, twitterStore }: Props) => {
  const [title, setTitle] = useState('Tweetvolume by Date');

  const onRangeChange = (range: string) => {
    setTitle(`Tweetvolume by Date ${range}`);
  };
  return (
    <TwitterChartContainer
      chartFunction={createVolumeByDateChart(onRangeChange)}
      contentLoader={<VolumeByDateChartContentLoader />}
      crossfilterInstance={crossfilterInstance}
      title={title}
      twitterStore={twitterStore}
    />
  );
};

export default VolumeByDateChart;
