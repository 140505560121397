import React from 'react';
import useWindowScrollPosition from '@rehooks/window-scroll-position';
import { faChevronCircleUp } from '@fortawesome/free-solid-svg-icons';

import Icon from '../Icon';
import cx from '../../utils/styling/cx';
import normalizeNumber from '../../utils/number/normalizeNumber';
import useScrollToTop from '../../utils/hooks/useScrollToTop';

// Types
import { HTMLAttributes } from 'react';

type Props = {} & HTMLAttributes<HTMLButtonElement>;

const ScrollToTopButton = ({ className, ...rest }: Props) => {
  const handleScrollToTop = useScrollToTop();
  const scrollPosition = useWindowScrollPosition();

  const [isHovering, setIsHovering] = React.useState<boolean>(false);

  const opacity = isHovering ? 1 : Math.max(Math.min(normalizeNumber(scrollPosition.y, 0, 1200), 1), 0);
  const isHidden = opacity < 0.05;

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  return (
    <button
      className={cx(
        'fixed bottom-0 right-0 p-4 z-20 inline-flex justify-center items-center md:mr-8 md:mb-10',
        { 'pointer-events-none': isHidden },
        className,
      )}
      onClick={handleScrollToTop}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{ opacity, transition: 'opacity 300ms ease' }}
      title="Scroll to top"
      type="button"
      {...rest}
    >
      <Icon icon={faChevronCircleUp} size="3x" />
    </button>
  );
};

export default ScrollToTopButton;
