const useScrollToTop = () => {
  const scrollToTop = () => {
    if (typeof window !== 'undefined') {
      try {
        window.scroll({ behavior: 'smooth', left: 0, top: 0 });
      } catch (error) {
        window.scrollTo(0, 0);
      }
    }
  };

  return scrollToTop;
};

export default useScrollToTop;
