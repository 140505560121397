/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react';

import Heading from '../../Heading';
import Link from '../../Link';
import buildTwitterProfileLink from '../../../utils/twitter/buildTwitterProfileLink';
import envConfig from '../../../config/env';

type Props = {
  name: string;
  profileImageUrl: string | null;
  username: string;
};

const TwitterUserCardHeader = ({ name, profileImageUrl, username }: Props) => {
  return (
    <div className="flex flex-row">
      <img
        alt={`@${username}'s Profile Picture`}
        className="rounded-lg mr-4 h-12 w-h-12"
        src={profileImageUrl || envConfig.twitterDefaultProfilePicture}
        title={`@${username}'s Profile Picture`}
      />

      <div className="flex flex-col overflow-hidden max-w-2xs lg:max-w-full xl:max-w-2xs">
        <Link title={`@${username}'s Twitter Profile`} url={buildTwitterProfileLink(username)}>
          <span className="break-all leading-none font-semibold text-xl hover:text-brand whitespace-no-wrap">{name}</span>
        </Link>

        <Link title={`@${username}'s Twitter Profile`} url={buildTwitterProfileLink(username)}>
          <Heading className="font-medium text-lg text-gray-500 hover:text-brand">@{username}</Heading>
        </Link>
      </div>
    </div>
  );
};

export default TwitterUserCardHeader;
