import React from 'react';

import ContentPageHeader from '../ContentPageItems/ContentPageHeader';
import ContentPageLayout from '../ContentPageItems/ContentPageLayout';
import ContentPageParagraph from '../ContentPageItems/ContentPageParagraph';
import ContentPageParagraphLink from '../ContentPageItems/ContentPageParagraphLink';
import ContentPageSection from '../ContentPageItems/ContentPageSection';
import ContentPageSectionHeader from '../ContentPageItems/ContentPageSectionHeader';

type Props = {};

const Error500 = (_props: Props) => {
  return (
    <ContentPageLayout>
      <ContentPageHeader>Something went terribly wrong.</ContentPageHeader>

      <ContentPageSection>
        <ContentPageSectionHeader>Sorry.</ContentPageSectionHeader>

        <ContentPageParagraph>We did something bad and the app couldn&#39;t recover.</ContentPageParagraph>

        <ContentPageParagraph>
          Let&#39;s start at <ContentPageParagraphLink url="/">the beginning</ContentPageParagraphLink>.
        </ContentPageParagraph>

        <ContentPageParagraph>
          If this keeps happening, please drop an email to{' '}
          <ContentPageParagraphLink url="mailto:accountanalysis@lucahammer.com">accountanalysis@lucahammer.com</ContentPageParagraphLink>.
        </ContentPageParagraph>
      </ContentPageSection>
    </ContentPageLayout>
  );
};

export default Error500;
