import React from 'react';
import { faAddressCard, faCalendar, faGlobe, faMapMarker } from '@fortawesome/free-solid-svg-icons';

import Icon from '../../Icon';
import Link from '../../Link';
import Tooltip from '../../Tooltip';
import fromNow from '../../../utils/time/fromNow';
import getFriendlyDateTime from '../../../utils/time/getFriendlyDateTime';
import getIsoDateTime from '../../../utils/time/getIsoDateTime';

type Props = {
  bioUrl: string | null;
  createdAt: string;
  id: string;
  location: string | null;
};

const TwitterUserCardMeta2 = ({ bioUrl, createdAt, id, location }: Props) => {
  return (
    <div className="mt-4">
      <ul>
        <li>
          <Tooltip ariaLabel="Joined Date" label="Joined Date">
            <Icon icon={faCalendar} />
          </Tooltip>
          &nbsp;&nbsp;
          <time dateTime={getIsoDateTime(createdAt) || undefined}>
            {getFriendlyDateTime(createdAt)} ({fromNow(createdAt)})
          </time>
        </li>

        <li>
          <Tooltip ariaLabel="Unique User ID" label="Unique User ID">
            <Icon icon={faAddressCard} />
          </Tooltip>
          &nbsp;&nbsp;{id}
        </li>

        {!!bioUrl && (
          <li>
            <Tooltip ariaLabel="Bio URL" label="Bio URL">
              <Icon icon={faGlobe} />
            </Tooltip>
            &nbsp;&nbsp;
            <Link className="hover:text-brand" url={bioUrl}>
              {bioUrl}
            </Link>
          </li>
        )}

        {!!location && (
          <li>
            <Tooltip ariaLabel="Location" label="Location">
              <Icon icon={faMapMarker} />
            </Tooltip>
            &nbsp;&nbsp;{location}
          </li>
        )}
      </ul>
    </div>
  );
};

export default TwitterUserCardMeta2;
