import React from 'react';
import Helmet from 'react-helmet';

import PrivacyPolicy from '../../components/PrivacyPolicy';

type Props = {};

const PrivacyPolicyContainer = (_props: Props) => {
  return (
    <>
      <Helmet>
        <title>Privacy Policy</title>
      </Helmet>

      <PrivacyPolicy />
    </>
  );
};

export default PrivacyPolicyContainer;
