const storeSnapshot = {
  isMockStore: true,
  tweets: [
    {
      date: '2019-08-22',
      dayOfWeek: 4,
      id: '1164624440333914119',
      language: 'English',
      length: 844,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@evaristegal0is',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/evaristegal0is" data-screen-name="evaristegal0is" target="_blank" rel="nofollow">@evaristegal0is</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/3r1nG" data-screen-name="3r1nG" target="_blank" rel="nofollow">@3r1nG</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/TechPoliticist" data-screen-name="TechPoliticist" target="_blank" rel="nofollow">@TechPoliticist</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/AdultFliXX" data-screen-name="AdultFliXX" target="_blank" rel="nofollow">@AdultFliXX</a> It seems so, yes. \n\nAnd Twitter wrote that the majority of the a… <a href="https://t.co/vWz4qAmV1j" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/vWz4qAmV1j</a>',
      timeOfDay: '21:00',
      timestamp: '2019-08-22T19:44:38.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1164624440333914119',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-22',
      dayOfWeek: 4,
      id: '1164623227957764097',
      language: 'English',
      length: 701,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@3r1ng',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/3r1nG" data-screen-name="3r1nG" target="_blank" rel="nofollow">@3r1nG</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/evaristegal0is" data-screen-name="evaristegal0is" target="_blank" rel="nofollow">@evaristegal0is</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/TechPoliticist" data-screen-name="TechPoliticist" target="_blank" rel="nofollow">@TechPoliticist</a> I haven&#39;t looked into the data myself, but from what I read, it seems like i… <a href="https://t.co/m5K2L9Xy1d" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/m5K2L9Xy1d</a>',
      timeOfDay: '21:00',
      timestamp: '2019-08-22T19:39:49.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1164623227957764097',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-22',
      dayOfWeek: 4,
      id: '1164619980136243206',
      language: 'English',
      length: 850,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@3r1ng',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/3r1nG" data-screen-name="3r1nG" target="_blank" rel="nofollow">@3r1nG</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/MarinIvezic" data-screen-name="MarinIvezic" target="_blank" rel="nofollow">@MarinIvezic</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/evaristegal0is" data-screen-name="evaristegal0is" target="_blank" rel="nofollow">@evaristegal0is</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/TechPoliticist" data-screen-name="TechPoliticist" target="_blank" rel="nofollow">@TechPoliticist</a> They didn&#39;t label someone as a state actor, but said that the a… <a href="https://t.co/ri4NDPuEH8" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/ri4NDPuEH8</a>',
      timeOfDay: '21:00',
      timestamp: '2019-08-22T19:26:55.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1164619980136243206',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-22',
      dayOfWeek: 4,
      id: '1164618838903840768',
      language: 'English',
      length: 846,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@marinivezic',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/MarinIvezic" data-screen-name="MarinIvezic" target="_blank" rel="nofollow">@MarinIvezic</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/evaristegal0is" data-screen-name="evaristegal0is" target="_blank" rel="nofollow">@evaristegal0is</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/3r1nG" data-screen-name="3r1nG" target="_blank" rel="nofollow">@3r1nG</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/TechPoliticist" data-screen-name="TechPoliticist" target="_blank" rel="nofollow">@TechPoliticist</a> twitteraudit is not a reliable tool to confirm if followers are… <a href="https://t.co/1mtf2jgXvQ" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/1mtf2jgXvQ</a>',
      timeOfDay: '21:00',
      timestamp: '2019-08-22T19:22:23.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1164618838903840768',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-22',
      dayOfWeek: 4,
      id: '1164616632389517312',
      language: 'English',
      length: 991,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@evaristegal0is',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/evaristegal0is" data-screen-name="evaristegal0is" target="_blank" rel="nofollow">@evaristegal0is</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/3r1nG" data-screen-name="3r1nG" target="_blank" rel="nofollow">@3r1nG</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/TechPoliticist" data-screen-name="TechPoliticist" target="_blank" rel="nofollow">@TechPoliticist</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/AdultFliXX" data-screen-name="AdultFliXX" target="_blank" rel="nofollow">@AdultFliXX</a> That account was named <a class="text-brand hover:text-brand-dark" href="https://twitter.com/Whoodiecom" data-screen-name="Whoodiecom" target="_blank" rel="nofollow">@Whoodiecom</a> in the past. You can find rep… <a href="https://t.co/Z15mVZE8UL" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/Z15mVZE8UL</a>',
      timeOfDay: '21:00',
      timestamp: '2019-08-22T19:13:37.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1164616632389517312',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-22',
      dayOfWeek: 4,
      id: '1164612476874477569',
      language: 'English',
      length: 707,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@evaristegal0is',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/evaristegal0is" data-screen-name="evaristegal0is" target="_blank" rel="nofollow">@evaristegal0is</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/3r1nG" data-screen-name="3r1nG" target="_blank" rel="nofollow">@3r1nG</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/TechPoliticist" data-screen-name="TechPoliticist" target="_blank" rel="nofollow">@TechPoliticist</a> As he wrote on LinkedIn, they bought &quot;social media services&quot; for both accoun… <a href="https://t.co/ipO6nMv9Eu" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/ipO6nMv9Eu</a>',
      timeOfDay: '20:00',
      timestamp: '2019-08-22T18:57:06.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1164612476874477569',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-22',
      dayOfWeek: 4,
      id: '1164553095004770305',
      language: 'German',
      length: 395,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/astefanowitsch" data-screen-name="astefanowitsch" target="_blank" rel="nofollow">@astefanowitsch</a> Antwort ist halb-ernst gemeint. Schweden hat es angeblich ausprobiert: <a href="https://t.co/IHTPzS1d3w" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/IHTPzS1d3w</a> (not fact checked).',
      timeOfDay: '17:00',
      timestamp: '2019-08-22T15:01:08.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1164553095004770305',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['www.thelocal.se'],
      username: 'luca',
    },
    {
      date: '2019-08-22',
      dayOfWeek: 4,
      id: '1164552005513355265',
      language: 'German',
      length: 210,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@astefanowitsch',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/astefanowitsch" data-screen-name="astefanowitsch" target="_blank" rel="nofollow">@astefanowitsch</a> Gab es da nicht ein Konzept mit Bomben?',
      timeOfDay: '16:00',
      timestamp: '2019-08-22T14:56:49.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1164552005513355265',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-22',
      dayOfWeek: 4,
      id: '1164551398928900096',
      language: 'English',
      length: 254,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: '@bostonjoan',
      repliedUser: null,
      retweetedUser: null,
      text:
        'Fighting hate with bots is the worst take I read today. Sure, trolling haters is fun, but it doesn&#39;t solve the prob… <a href="https://t.co/QMZT8amj1w" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/QMZT8amj1w</a>',
      timeOfDay: '16:00',
      timestamp: '2019-08-22T14:54:24.000Z',
      type: 'Quote',
      url: 'https://twitter.com/luca/status/1164551398928900096',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-22',
      dayOfWeek: 4,
      id: '1164550815094366209',
      language: 'English',
      length: 433,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@bostonjoan',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/BostonJoan" data-screen-name="BostonJoan" target="_blank" rel="nofollow">@BostonJoan</a>: Quietly, reading this paper in <a class="text-brand hover:text-brand-dark" href="https://twitter.com/NatureNews" data-screen-name="NatureNews" target="_blank" rel="nofollow">@NatureNews</a> “Hidden resilience and adaptive dynamics of the\nglobal online hate ecology” and…',
      timeOfDay: '16:00',
      timestamp: '2019-08-22T14:52:05.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1164550815094366209',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-22',
      dayOfWeek: 4,
      id: '1164546622002487296',
      language: 'German',
      length: 683,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@p_humm',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/p_humm" data-screen-name="p_humm" target="_blank" rel="nofollow">@p_humm</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/textlastig" data-screen-name="textlastig" target="_blank" rel="nofollow">@textlastig</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/hesselmann" data-screen-name="hesselmann" target="_blank" rel="nofollow">@hesselmann</a> Ist so seit es Quote-Tweets gibt. Wenn der Account, der quoted von dem, der gequote… <a href="https://t.co/wAgPIDwmsI" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/wAgPIDwmsI</a>',
      timeOfDay: '16:00',
      timestamp: '2019-08-22T14:35:25.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1164546622002487296',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-22',
      dayOfWeek: 4,
      id: '1164475361146691584',
      language: 'German',
      length: 391,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@hdrr_at',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/hdrr_at" data-screen-name="hdrr_at" target="_blank" rel="nofollow">@hdrr_at</a> Little Big Planet hat den großen Vorteil, dass ihr gemeinsam spielen könnt ohne dass unterschiedliches Kön… <a href="https://t.co/V6NTsQ8lG5" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/V6NTsQ8lG5</a>',
      timeOfDay: '11:00',
      timestamp: '2019-08-22T09:52:15.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1164475361146691584',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-22',
      dayOfWeek: 4,
      id: '1164468822482522112',
      language: 'English',
      length: 243,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@bibbleco',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/bibbleco" data-screen-name="bibbleco" target="_blank" rel="nofollow">@bibbleco</a> No. I meant that people sometimes don&#39;t even know that their accounts are compromised.',
      timeOfDay: '11:00',
      timestamp: '2019-08-22T09:26:16.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1164468822482522112',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-22',
      dayOfWeek: 4,
      id: '1164450719933620224',
      language: 'English',
      length: 409,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/evaristegal0is" data-screen-name="evaristegal0is" target="_blank" rel="nofollow">@evaristegal0is</a> You can compare accounts with vehicles. When your vehicle was involved in an accident, it doesn&#39;t m… <a href="https://t.co/KYO9DFnviO" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/KYO9DFnviO</a>',
      timeOfDay: '10:00',
      timestamp: '2019-08-22T08:14:20.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1164450719933620224',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-22',
      dayOfWeek: 4,
      id: '1164449567288549376',
      language: 'English',
      length: 253,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text:
        'Up to 16% of the accused accounts had different usernames in the past. Usernames can&#39;t be changed through the API,… <a href="https://t.co/FEiRvnIAOC" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/FEiRvnIAOC</a>',
      timeOfDay: '10:00',
      timestamp: '2019-08-22T08:09:45.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1164449567288549376',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-22',
      dayOfWeek: 4,
      id: '1164448600425996288',
      language: 'English',
      length: 250,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text:
        'The creator of the account above admitted that they bought followers and/or gave access to the account to someone t… <a href="https://t.co/UYPYiBHWXP" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/UYPYiBHWXP</a>',
      timeOfDay: '10:00',
      timestamp: '2019-08-22T08:05:55.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1164448600425996288',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-22',
      dayOfWeek: 4,
      id: '1164448069653618688',
      language: 'English',
      length: 254,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text:
        'Some of the accounts that were taken down were operated by people who claim that they don&#39;t have anything to do wit… <a href="https://t.co/aV237oLoyI" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/aV237oLoyI</a>',
      timeOfDay: '10:00',
      timestamp: '2019-08-22T08:03:48.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1164448069653618688',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-22',
      dayOfWeek: 4,
      id: '1164446977951772673',
      language: 'English',
      length: 249,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text:
        '- Most of the accounts are re-purposed spam accounts\n- Probably not planned in advance, but put together quickly\n-… <a href="https://t.co/4FxX7AZzz0" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/4FxX7AZzz0</a>',
      timeOfDay: '09:00',
      timestamp: '2019-08-22T07:59:28.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1164446977951772673',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-22',
      dayOfWeek: 4,
      id: '1164441680696807424',
      language: 'English',
      length: 405,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@evaristegal0is',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/evaristegal0is" data-screen-name="evaristegal0is" target="_blank" rel="nofollow">@evaristegal0is</a> They admitted that they bought followers and it gave access to the account to a third party. \n\nWhil… <a href="https://t.co/t1S446jRWZ" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/t1S446jRWZ</a>',
      timeOfDay: '09:00',
      timestamp: '2019-08-22T07:38:25.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1164441680696807424',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-21',
      dayOfWeek: 3,
      id: '1164274859725250560',
      language: 'German',
      length: 175,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@schmidtlepp',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/Schmidtlepp" data-screen-name="Schmidtlepp" target="_blank" rel="nofollow">@Schmidtlepp</a> Endlich frei.',
      timeOfDay: '22:00',
      timestamp: '2019-08-21T20:35:32.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1164274859725250560',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-21',
      dayOfWeek: 3,
      id: '1164267727080611841',
      language: 'English',
      length: 458,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@storywithdata',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/storywithdata" data-screen-name="storywithdata" target="_blank" rel="nofollow">@storywithdata</a>: How well can you estimate the size of circles &amp;amp; bars? I know my answer, but you can help <a class="text-brand hover:text-brand-dark" href="https://twitter.com/DataRevelations" data-screen-name="DataRevelations" target="_blank" rel="nofollow">@DataRevelations</a> quantify it by…',
      timeOfDay: '22:00',
      timestamp: '2019-08-21T20:07:11.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1164267727080611841',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-21',
      dayOfWeek: 3,
      id: '1164265264395051009',
      language: 'English',
      length: 287,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@monaelswah',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/monaelswah" data-screen-name="monaelswah" target="_blank" rel="nofollow">@monaelswah</a>: Netvizz has helped hundreds of researchers to better understand FB. In addition to the many restrictions on FB API, closing…',
      timeOfDay: '21:00',
      timestamp: '2019-08-21T19:57:24.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1164265264395051009',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-21',
      dayOfWeek: 3,
      id: '1164265202902339584',
      language: 'English',
      length: 581,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@datafiedsociety',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/DatafiedSociety" data-screen-name="DatafiedSociety" target="_blank" rel="nofollow">@DatafiedSociety</a>: The end of <a href="https://twitter.com/search?q=%23Netvizz" title="#Netvizz" class="text-brand hover:text-brand-dark" target="_blank" rel="nofollow">#Netvizz</a> - a tool for studying Facebook via data extraction. Many thanks <a class="text-brand hover:text-brand-dark" href="https://twitter.com/RiederB" data-screen-name="RiederB" target="_blank" rel="nofollow">@RiederB</a>, it was great while it la…',
      timeOfDay: '21:00',
      timestamp: '2019-08-21T19:57:10.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1164265202902339584',
      usedApp: 'Twitter for Android',
      usedHashtags: ['#Netvizz'],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-21',
      dayOfWeek: 3,
      id: '1164265192236171271',
      language: 'English',
      length: 573,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@marceloufsj',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/marceloufsj" data-screen-name="marceloufsj" target="_blank" rel="nofollow">@marceloufsj</a>: Rip <a href="https://twitter.com/search?q=%23netvizz" title="#netvizz" class="text-brand hover:text-brand-dark" target="_blank" rel="nofollow">#netvizz</a>. Our most sincere acknowledgments to <a class="text-brand hover:text-brand-dark" href="https://twitter.com/RiederB" data-screen-name="RiederB" target="_blank" rel="nofollow">@RiederB</a> and his team for facilitating applied facebook research all ove…',
      timeOfDay: '21:00',
      timestamp: '2019-08-21T19:57:07.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1164265192236171271',
      usedApp: 'Twitter for Android',
      usedHashtags: ['#netvizz'],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-21',
      dayOfWeek: 3,
      id: '1164265115052625920',
      language: 'English',
      length: 840,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@tristanhotham',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/TristanHotham" data-screen-name="TristanHotham" target="_blank" rel="nofollow">@TristanHotham</a>: <a href="https://t.co/PrhQ78a1hy" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/PrhQ78a1hy</a>\n\nSo <a href="https://twitter.com/search?q=%23netvizz" title="#netvizz" class="text-brand hover:text-brand-dark" target="_blank" rel="nofollow">#netvizz</a> died today and therefore <a href="https://twitter.com/search?q=%23facepager" title="#facepager" class="text-brand hover:text-brand-dark" target="_blank" rel="nofollow">#facepager</a> and <a href="https://twitter.com/search?q=%23rfacebook" title="#rfacebook" class="text-brand hover:text-brand-dark" target="_blank" rel="nofollow">#rfacebook</a> are also gone. We are looking in…',
      timeOfDay: '21:00',
      timestamp: '2019-08-21T19:56:49.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1164265115052625920',
      usedApp: 'Twitter for Android',
      usedHashtags: ['#netvizz', '#facepager', '#rfacebook'],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-21',
      dayOfWeek: 3,
      id: '1164264234026459136',
      language: 'English',
      length: 197,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@riederb',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/RiederB" data-screen-name="RiederB" target="_blank" rel="nofollow">@RiederB</a> Thank you for keeping it alive all these years.',
      timeOfDay: '21:00',
      timestamp: '2019-08-21T19:53:19.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1164264234026459136',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-21',
      dayOfWeek: 3,
      id: '1164263948927086593',
      language: 'English',
      length: 376,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@riederb',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/RiederB" data-screen-name="RiederB" target="_blank" rel="nofollow">@RiederB</a>: ok, <a href="https://twitter.com/search?q=%23netvizz" title="#netvizz" class="text-brand hover:text-brand-dark" target="_blank" rel="nofollow">#netvizz</a> is no longer publicly available - looks like that&#39;s it, folks!',
      timeOfDay: '21:00',
      timestamp: '2019-08-21T19:52:11.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1164263948927086593',
      usedApp: 'Twitter for Android',
      usedHashtags: ['#netvizz'],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-21',
      dayOfWeek: 3,
      id: '1164197514351906816',
      language: 'English',
      length: 200,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@kearneymw',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/kearneymw" data-screen-name="kearneymw" target="_blank" rel="nofollow">@kearneymw</a> Love the source label. Make it post for you.',
      timeOfDay: '17:00',
      timestamp: '2019-08-21T15:28:11.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1164197514351906816',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-21',
      dayOfWeek: 3,
      id: '1164179474348462085',
      language: 'English',
      length: 308,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: '@fbnewsroom',
      repliedUser: null,
      retweetedUser: null,
      text:
        'Thanks for putting me back into control. <a href="https://t.co/yueNFoYE70" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/yueNFoYE70</a> <a href="https://t.co/iHOoWkh6Hq" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/iHOoWkh6Hq</a>',
      timeOfDay: '16:00',
      timestamp: '2019-08-21T14:16:30.000Z',
      type: 'Quote',
      url: 'https://twitter.com/luca/status/1164179474348462085',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-21',
      dayOfWeek: 3,
      id: '1164090606215475200',
      language: 'German',
      length: 442,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@schmidtlepp',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/Schmidtlepp" data-screen-name="Schmidtlepp" target="_blank" rel="nofollow">@Schmidtlepp</a>: Der <a class="text-brand hover:text-brand-dark" href="https://twitter.com/EmaHeisenberg" data-screen-name="EmaHeisenberg" target="_blank" rel="nofollow">@EmaHeisenberg</a> und ich haben uns mal überlegt, wie man Berlin bis 2030 so gut wie CO2-frei bekommt. Es ist möglich, wi…',
      timeOfDay: '10:00',
      timestamp: '2019-08-21T08:23:22.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1164090606215475200',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-21',
      dayOfWeek: 3,
      id: '1164060339178225664',
      language: 'English',
      length: 562,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@jamonholmgren',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/jamonholmgren" data-screen-name="jamonholmgren" target="_blank" rel="nofollow">@jamonholmgren</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/andreasklinger" data-screen-name="andreasklinger" target="_blank" rel="nofollow">@andreasklinger</a> I wish you a great time. Berlin is awesome. As is Amsterdam.\n\nIf you don&#39;t have a go… <a href="https://t.co/6jKrfoFV6J" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/6jKrfoFV6J</a>',
      timeOfDay: '08:00',
      timestamp: '2019-08-21T06:23:06.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1164060339178225664',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-21',
      dayOfWeek: 3,
      id: '1164056790696386560',
      language: 'English',
      length: 290,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@bayer_julia',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/bayer_julia" data-screen-name="bayer_julia" target="_blank" rel="nofollow">@bayer_julia</a>: Tip💡: “The most effective disinformation has always been that which has a kernel of truth to it, and indeed most of the co…',
      timeOfDay: '08:00',
      timestamp: '2019-08-21T06:09:00.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1164056790696386560',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-21',
      dayOfWeek: 3,
      id: '1164055248530526208',
      language: 'English',
      length: 562,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/jamonholmgren" data-screen-name="jamonholmgren" target="_blank" rel="nofollow">@jamonholmgren</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/andreasklinger" data-screen-name="andreasklinger" target="_blank" rel="nofollow">@andreasklinger</a> on the respective platform. If that&#39;s not possible I try to reduce the stress throug… <a href="https://t.co/i87ZyBDQAR" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/i87ZyBDQAR</a>',
      timeOfDay: '08:00',
      timestamp: '2019-08-21T06:02:53.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1164055248530526208',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-21',
      dayOfWeek: 3,
      id: '1164055180385619968',
      language: 'English',
      length: 558,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/jamonholmgren" data-screen-name="jamonholmgren" target="_blank" rel="nofollow">@jamonholmgren</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/andreasklinger" data-screen-name="andreasklinger" target="_blank" rel="nofollow">@andreasklinger</a> The following weeks showed that not using social media isolated me from the people I… <a href="https://t.co/ZIXkifeYtR" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/ZIXkifeYtR</a>',
      timeOfDay: '08:00',
      timestamp: '2019-08-21T06:02:36.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1164055180385619968',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-21',
      dayOfWeek: 3,
      id: '1164055053663162368',
      language: 'English',
      length: 668,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@jamonholmgren',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/jamonholmgren" data-screen-name="jamonholmgren" target="_blank" rel="nofollow">@jamonholmgren</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/andreasklinger" data-screen-name="andreasklinger" target="_blank" rel="nofollow">@andreasklinger</a> I did it nine years ago and blogged about it in German. <a href="https://t.co/MBJBCLcBGX" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/MBJBCLcBGX</a>\n\nThe… <a href="https://t.co/o07Yytvbax" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/o07Yytvbax</a>',
      timeOfDay: '08:00',
      timestamp: '2019-08-21T06:02:06.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1164055053663162368',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['2-blog.net', 'twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-21',
      dayOfWeek: 3,
      id: '1164047964328857600',
      language: 'English',
      length: 413,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@andreasklinger',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/andreasklinger" data-screen-name="andreasklinger" target="_blank" rel="nofollow">@andreasklinger</a> 0 and that&#39;s fine.\n\nI did a month without social media and it didn&#39;t improve my wellbeing. Now I ju… <a href="https://t.co/VXGfD2LaTH" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/VXGfD2LaTH</a>',
      timeOfDay: '07:00',
      timestamp: '2019-08-21T05:33:56.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1164047964328857600',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-20',
      dayOfWeek: 2,
      id: '1163846254994042880',
      language: 'Unknown',
      length: 133,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: null,
      text:
        '<a href="https://t.co/ZetcOqxdfn" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/ZetcOqxdfn</a>',
      timeOfDay: '18:00',
      timestamp: '2019-08-20T16:12:25.000Z',
      type: 'Tweet',
      url: 'https://twitter.com/luca/status/1163846254994042880',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-19',
      dayOfWeek: 1,
      id: '1163539535713755138',
      language: 'German',
      length: 150,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@noniq',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/noniq" data-screen-name="noniq" target="_blank" rel="nofollow">@noniq</a> Danke.',
      timeOfDay: '21:00',
      timestamp: '2019-08-19T19:53:37.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1163539535713755138',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-19',
      dayOfWeek: 1,
      id: '1163536284989710336',
      language: 'German',
      length: 181,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@noniq',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/noniq" data-screen-name="noniq" target="_blank" rel="nofollow">@noniq</a> Wie erkennt man, dass das kaputt ist?',
      timeOfDay: '21:00',
      timestamp: '2019-08-19T19:40:42.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1163536284989710336',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-19',
      dayOfWeek: 1,
      id: '1163532547797770240',
      language: 'English',
      length: 212,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text:
        'Twitter tipped off Facebook and they removed a small set of accounts as well.\n\n<a href="https://t.co/bsY5hIoSks" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/bsY5hIoSks</a>',
      timeOfDay: '21:00',
      timestamp: '2019-08-19T19:25:51.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1163532547797770240',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-19',
      dayOfWeek: 1,
      id: '1163529435024375808',
      language: 'English',
      length: 397,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@pinboard',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/Pinboard" data-screen-name="Pinboard" target="_blank" rel="nofollow">@Pinboard</a> Two related things happened. It&#39;s not just about ads.\n- Twitter identified inauthentic accounts that post… <a href="https://t.co/yPg6sgoJM9" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/yPg6sgoJM9</a>',
      timeOfDay: '21:00',
      timestamp: '2019-08-19T19:13:29.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1163529435024375808',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-19',
      dayOfWeek: 1,
      id: '1163528320480403456',
      language: 'English',
      length: 249,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text:
        'Post by Twitter about the new Ads Policy of not accepting ads anymore by state controlled media like RT or Xinhua.… <a href="https://t.co/H2D4zDVXez" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/H2D4zDVXez</a>',
      timeOfDay: '21:00',
      timestamp: '2019-08-19T19:09:03.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1163528320480403456',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-19',
      dayOfWeek: 1,
      id: '1163527608459546625',
      language: 'English',
      length: 826,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@bluechoochoo',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/bluechoochoo" data-screen-name="bluechoochoo" target="_blank" rel="nofollow">@bluechoochoo</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/NPR" data-screen-name="NPR" target="_blank" rel="nofollow">@NPR</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/BBC" data-screen-name="BBC" target="_blank" rel="nofollow">@BBC</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/ARD_Presse" data-screen-name="ARD_Presse" target="_blank" rel="nofollow">@ARD_Presse</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/ORF" data-screen-name="ORF" target="_blank" rel="nofollow">@ORF</a> Thanks. I quoted that exact Tweet, but obviously failed to fully absorb the information.',
      timeOfDay: '21:00',
      timestamp: '2019-08-19T19:06:13.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1163527608459546625',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-19',
      dayOfWeek: 1,
      id: '1163519498877644802',
      language: 'Unknown',
      length: 133,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text:
        '<a href="https://t.co/qIYLiwNNrg" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/qIYLiwNNrg</a>',
      timeOfDay: '20:00',
      timestamp: '2019-08-19T18:34:00.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1163519498877644802',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-19',
      dayOfWeek: 1,
      id: '1163515570987446272',
      language: 'English',
      length: 250,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: '@twittersafety',
      repliedUser: null,
      retweetedUser: null,
      text:
        'Twitter identified and suspended inauthentic accounts that tweeted about Hong Kong. Data can be downloaded for furt… <a href="https://t.co/czqP775TSp" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/czqP775TSp</a>',
      timeOfDay: '20:00',
      timestamp: '2019-08-19T18:18:23.000Z',
      type: 'Quote',
      url: 'https://twitter.com/luca/status/1163515570987446272',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-19',
      dayOfWeek: 1,
      id: '1163473443712638977',
      language: 'English',
      length: 177,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: '@twitter',
      repliedUser: null,
      retweetedUser: null,
      text:
        'iOS users can search through their DMs now. <a href="https://t.co/4UafQOTWBP" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/4UafQOTWBP</a>',
      timeOfDay: '17:00',
      timestamp: '2019-08-19T15:30:59.000Z',
      type: 'Quote',
      url: 'https://twitter.com/luca/status/1163473443712638977',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-19',
      dayOfWeek: 1,
      id: '1163442515724427269',
      language: 'English',
      length: 389,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: null,
      text:
        'Funkloch app by <a class="text-brand hover:text-brand-dark" href="https://twitter.com/bnetza" data-screen-name="bnetza" target="_blank" rel="nofollow">@bnetza</a> fails silently after collecting 1000(?) measurements in one session. Instead of stopping th… <a href="https://t.co/OsZKa2pkhF" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/OsZKa2pkhF</a>',
      timeOfDay: '15:00',
      timestamp: '2019-08-19T13:28:06.000Z',
      type: 'Tweet',
      url: 'https://twitter.com/luca/status/1163442515724427269',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-19',
      dayOfWeek: 1,
      id: '1163430032376782853',
      language: 'English',
      length: 507,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@swiftonsecurity',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/SwiftOnSecurity" data-screen-name="SwiftOnSecurity" target="_blank" rel="nofollow">@SwiftOnSecurity</a> Studies and white papers confirm that journalists love Twitter. \n<a href="https://t.co/L9acKqpLWd" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/L9acKqpLWd</a>\n\n<a href="https://t.co/pHAlcF5cYt" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/pHAlcF5cYt</a>',
      timeOfDay: '14:00',
      timestamp: '2019-08-19T12:38:29.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1163430032376782853',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com', 'twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-19',
      dayOfWeek: 1,
      id: '1163373267824140290',
      language: 'English',
      length: 149,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text:
        'Good bye Tyrol. <a href="https://t.co/4nnxL3G46Z" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/4nnxL3G46Z</a>',
      timeOfDay: '10:00',
      timestamp: '2019-08-19T08:52:56.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1163373267824140290',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-19',
      dayOfWeek: 1,
      id: '1163326076619251714',
      language: 'English',
      length: 661,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@luca',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/luca" data-screen-name="luca" target="_blank" rel="nofollow">@luca</a>: <a class="text-brand hover:text-brand-dark" href="https://twitter.com/Pinboard" data-screen-name="Pinboard" target="_blank" rel="nofollow">@Pinboard</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/thegrugq" data-screen-name="thegrugq" target="_blank" rel="nofollow">@thegrugq</a> At least the ads Xinhua ran in the last 7 days are public thanks to the ad transparency center: <a href="https://t.co/" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/</a>…',
      timeOfDay: '07:00',
      timestamp: '2019-08-19T05:45:24.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1163326076619251714',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-19',
      dayOfWeek: 1,
      id: '1163323962027327488',
      language: 'English',
      length: 677,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@_eblu',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/_eblu" data-screen-name="_eblu" target="_blank" rel="nofollow">@_eblu</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/Pinboard" data-screen-name="Pinboard" target="_blank" rel="nofollow">@Pinboard</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/thegrugq" data-screen-name="thegrugq" target="_blank" rel="nofollow">@thegrugq</a> Doesn&#39;t work in the Ad Transpancy Center and is too vague in my experience (not full tar… <a href="https://t.co/LrBb1ucCcC" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/LrBb1ucCcC</a>',
      timeOfDay: '07:00',
      timestamp: '2019-08-19T05:37:00.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1163323962027327488',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-18',
      dayOfWeek: 0,
      id: '1163181259067154432',
      language: 'English',
      length: 254,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: null,
      text:
        'On 29.9.19 Austria will elect it&#39;s national council. Last one went up in flames because right wing party leader tri… <a href="https://t.co/q9qSPQxFOp" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/q9qSPQxFOp</a>',
      timeOfDay: '22:00',
      timestamp: '2019-08-18T20:09:57.000Z',
      type: 'Tweet',
      url: 'https://twitter.com/luca/status/1163181259067154432',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-18',
      dayOfWeek: 0,
      id: '1163157552806473728',
      language: 'German',
      length: 544,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@sarah_hoef',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/sarah_hoef" data-screen-name="sarah_hoef" target="_blank" rel="nofollow">@sarah_hoef</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/unsereOEBB" data-screen-name="unsereOEBB" target="_blank" rel="nofollow">@unsereOEBB</a> Falls es doch noch Probleme gibt: Zum Schalter gehen. Die kümmern sich dann darum. Falls ke… <a href="https://t.co/2QRThq3DjO" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/2QRThq3DjO</a>',
      timeOfDay: '20:00',
      timestamp: '2019-08-18T18:35:45.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1163157552806473728',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-18',
      dayOfWeek: 0,
      id: '1163116471800729600',
      language: 'English',
      length: 536,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@pinboard',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/Pinboard" data-screen-name="Pinboard" target="_blank" rel="nofollow">@Pinboard</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/thegrugq" data-screen-name="thegrugq" target="_blank" rel="nofollow">@thegrugq</a> At least the ads Xinhua ran in the last 7 days are public thanks to the ad transparency center:… <a href="https://t.co/tShykrd1Uf" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/tShykrd1Uf</a>',
      timeOfDay: '17:00',
      timestamp: '2019-08-18T15:52:31.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1163116471800729600',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-18',
      dayOfWeek: 0,
      id: '1163114573597151233',
      language: 'English',
      length: 290,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@nacnudus',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/nacnudus" data-screen-name="nacnudus" target="_blank" rel="nofollow">@nacnudus</a>: Hadley: What happens if you substitute all of the flour for cocoa powder?\n\nMe: bakes functional cupcakes\n\ncupcake &amp;lt;- function…',
      timeOfDay: '17:00',
      timestamp: '2019-08-18T15:44:58.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1163114573597151233',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-18',
      dayOfWeek: 0,
      id: '1163103900972572673',
      language: 'English',
      length: 293,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@leenavand',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/LeenaVanD" data-screen-name="LeenaVanD" target="_blank" rel="nofollow">@LeenaVanD</a>: Parents:\n\nYour little angel is capable of behaving awfully online. \n\nI know you think they&#39;re not.\n\nIf you don&#39;t want them t…',
      timeOfDay: '17:00',
      timestamp: '2019-08-18T15:02:34.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1163103900972572673',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-18',
      dayOfWeek: 0,
      id: '1163103875257290753',
      language: 'English',
      length: 293,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@annabrandberg',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/annabrandberg" data-screen-name="annabrandberg" target="_blank" rel="nofollow">@annabrandberg</a>: Great thread about how to have conversations with children about gaming and online toxicity...\n\nParents: Chat to your ki…',
      timeOfDay: '17:00',
      timestamp: '2019-08-18T15:02:27.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1163103875257290753',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-18',
      dayOfWeek: 0,
      id: '1163060513561088001',
      language: 'English',
      length: 344,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@sebastianlouven',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/SebastianLouven" data-screen-name="SebastianLouven" target="_blank" rel="nofollow">@SebastianLouven</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/kmk2108" data-screen-name="kmk2108" target="_blank" rel="nofollow">@kmk2108</a> The Last Generation.',
      timeOfDay: '14:00',
      timestamp: '2019-08-18T12:10:09.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1163060513561088001',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-18',
      dayOfWeek: 0,
      id: '1163027248422801427',
      language: 'Unknown',
      length: 133,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text:
        '<a href="https://t.co/NX5WeJ6Qcq" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/NX5WeJ6Qcq</a>',
      timeOfDay: '11:00',
      timestamp: '2019-08-18T09:57:58.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1163027248422801427',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-18',
      dayOfWeek: 0,
      id: '1163007680526659584',
      language: 'English',
      length: 397,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@thegrugq',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/thegrugq" data-screen-name="thegrugq" target="_blank" rel="nofollow">@thegrugq</a> They banned RT ads and the should do the same this time. If they haven&#39;t done already, now is the time to… <a href="https://t.co/yVVXQ9X1wn" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/yVVXQ9X1wn</a>',
      timeOfDay: '10:00',
      timestamp: '2019-08-18T08:40:13.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1163007680526659584',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-18',
      dayOfWeek: 0,
      id: '1162973951473197056',
      language: 'English',
      length: 186,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: '@pinboard',
      repliedUser: null,
      retweetedUser: null,
      text:
        'Twitter banned RT ads, they should do the same here. <a href="https://t.co/WJJvAkayBg" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/WJJvAkayBg</a>',
      timeOfDay: '08:00',
      timestamp: '2019-08-18T06:26:11.000Z',
      type: 'Quote',
      url: 'https://twitter.com/luca/status/1162973951473197056',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-17',
      dayOfWeek: 6,
      id: '1162824637421641732',
      language: 'English',
      length: 378,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@3r1ng',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/3r1nG" data-screen-name="3r1nG" target="_blank" rel="nofollow">@3r1nG</a>: These fake antifa accounts have been around for 2+ years. ICYMI I graphed their network in 2017: <a href="https://t.co/ZMVokRGdjK" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/ZMVokRGdjK</a>',
      timeOfDay: '22:00',
      timestamp: '2019-08-17T20:32:52.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1162824637421641732',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['medium.com'],
      username: 'luca',
    },
    {
      date: '2019-08-17',
      dayOfWeek: 6,
      id: '1162809509145915394',
      language: 'English',
      length: 289,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@owenjones84',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/OwenJones84" data-screen-name="OwenJones84" target="_blank" rel="nofollow">@OwenJones84</a>: This is a bit dramatic, so firstly I’m fine, but last night - when I was celebrating my birthday - I was attacked, along w…',
      timeOfDay: '21:00',
      timestamp: '2019-08-17T19:32:45.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1162809509145915394',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-17',
      dayOfWeek: 6,
      id: '1162805192674480132',
      language: 'English',
      length: 163,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@yoyoel',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/yoyoel" data-screen-name="yoyoel" target="_blank" rel="nofollow">@yoyoel</a> Congratulations.',
      timeOfDay: '21:00',
      timestamp: '2019-08-17T19:15:36.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1162805192674480132',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-17',
      dayOfWeek: 6,
      id: '1162773629144354821',
      language: 'English',
      length: 250,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: '@tiggi03',
      repliedUser: null,
      retweetedUser: null,
      text:
        'Did Twitter remove the „Tweet unavailable “ thing? This would be an instance where it would be very helpful to know… <a href="https://t.co/lJeTp0iWWm" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/lJeTp0iWWm</a>',
      timeOfDay: '19:00',
      timestamp: '2019-08-17T17:10:11.000Z',
      type: 'Quote',
      url: 'https://twitter.com/luca/status/1162773629144354821',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-17',
      dayOfWeek: 6,
      id: '1162772929450598402',
      language: 'English',
      length: 283,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@edpetrie',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/edpetrie" data-screen-name="edpetrie" target="_blank" rel="nofollow">@edpetrie</a>: Just wanted to share some of the replies I had about how disruptive and unsettling Brexit is for dual nationality families. I…',
      timeOfDay: '19:00',
      timestamp: '2019-08-17T17:07:24.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1162772929450598402',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-17',
      dayOfWeek: 6,
      id: '1162753510414438401',
      language: 'English',
      length: 299,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@conspirator0',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/conspirator0" data-screen-name="conspirator0" target="_blank" rel="nofollow">@conspirator0</a>: One doesn&#39;t actually have to operate a botnet in order to get one&#39;s tweets amplified by bots. We sampled recent tweets fr…',
      timeOfDay: '17:00',
      timestamp: '2019-08-17T15:50:14.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1162753510414438401',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-17',
      dayOfWeek: 6,
      id: '1162744026208067584',
      language: 'English',
      length: 434,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@jasonli',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/jasonli" data-screen-name="jasonli" target="_blank" rel="nofollow">@jasonli</a>: Ways to support the Hong Kong protests from afar 💰👵🏻🤳🇭🇰\n\nBased on anonymous submissions. Maintained by me and <a class="text-brand hover:text-brand-dark" href="https://twitter.com/lokmantsui" data-screen-name="lokmantsui" target="_blank" rel="nofollow">@lokmantsui</a>.\n\nPl…',
      timeOfDay: '17:00',
      timestamp: '2019-08-17T15:12:33.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1162744026208067584',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-16',
      dayOfWeek: 5,
      id: '1162304040912531457',
      language: 'Unknown',
      length: 133,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text:
        '<a href="https://t.co/dK2FJnnbBV" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/dK2FJnnbBV</a>',
      timeOfDay: '12:00',
      timestamp: '2019-08-16T10:04:12.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1162304040912531457',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-16',
      dayOfWeek: 5,
      id: '1162265387255263238',
      language: 'English',
      length: 378,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@fiete_stegers',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/fiete_stegers" data-screen-name="fiete_stegers" target="_blank" rel="nofollow">@fiete_stegers</a> Alternatively you can search for the URL of the Tweet to find quote Tweets.\n\n<a href="https://t.co/Z2dqvCj4M1" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/Z2dqvCj4M1</a>',
      timeOfDay: '09:00',
      timestamp: '2019-08-16T07:30:36.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1162265387255263238',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-16',
      dayOfWeek: 5,
      id: '1162263205634162695',
      language: 'English',
      length: 278,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@moritz',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/moritz" data-screen-name="moritz" target="_blank" rel="nofollow">@moritz</a>: “We are 50 to 100 years ahead of schedule with the slowdown of this ocean circulation pattern”, says climate scientist Michael…',
      timeOfDay: '09:00',
      timestamp: '2019-08-16T07:21:56.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1162263205634162695',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-16',
      dayOfWeek: 5,
      id: '1162249517405593600',
      language: 'English',
      length: 289,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@amberying',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/amberying" data-screen-name="amberying" target="_blank" rel="nofollow">@amberying</a>: There is absolutely nothing special to evil. It&#39;s even chilling to recognize that neo-Nazis also struggle with the human con…',
      timeOfDay: '08:00',
      timestamp: '2019-08-16T06:27:33.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1162249517405593600',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-16',
      dayOfWeek: 5,
      id: '1162248859520651270',
      language: 'English',
      length: 295,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@amberying',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/amberying" data-screen-name="amberying" target="_blank" rel="nofollow">@amberying</a>: CN: White supremacists, Nazism, alt-right, banality of evil\n\nYesterday, I came across a &quot;white for white&quot; dating site. Regis…',
      timeOfDay: '08:00',
      timestamp: '2019-08-16T06:24:56.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1162248859520651270',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-16',
      dayOfWeek: 5,
      id: '1162246370243145734',
      language: 'English',
      length: 283,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@edpetrie',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/edpetrie" data-screen-name="edpetrie" target="_blank" rel="nofollow">@edpetrie</a>: (THREAD) Brexit is creeping towards us and I feel frustrated that so little thought has been given to people in families with…',
      timeOfDay: '08:00',
      timestamp: '2019-08-16T06:15:02.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1162246370243145734',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-16',
      dayOfWeek: 5,
      id: '1162243143015624704',
      language: 'English',
      length: 380,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@andyyang',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/andyyang" data-screen-name="andyyang" target="_blank" rel="nofollow">@andyyang</a>: The tech reality for much of the world. 1GB of data can cost up to 20% of monthly income. <a href="https://t.co/Y1AbK687vG" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/Y1AbK687vG</a>',
      timeOfDay: '08:00',
      timestamp: '2019-08-16T06:02:13.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1162243143015624704',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-16',
      dayOfWeek: 5,
      id: '1162236197227225088',
      language: 'English',
      length: 295,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@joannechocolat',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/Joannechocolat" data-screen-name="Joannechocolat" target="_blank" rel="nofollow">@Joannechocolat</a>: Reading online is reading.\nReading articles is reading.\nReading fanfic is reading.\nReading blog posts is reading.\nReadi…',
      timeOfDay: '07:00',
      timestamp: '2019-08-16T05:34:37.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1162236197227225088',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-16',
      dayOfWeek: 5,
      id: '1162231888674557952',
      language: 'English',
      length: 397,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@indiestace',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/indiestace" data-screen-name="indiestace" target="_blank" rel="nofollow">@indiestace</a> Someone claiming that their parents took away their phone and then tweeting from a refrigerator. \n\nI lo… <a href="https://t.co/xtxsHHrGZH" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/xtxsHHrGZH</a>',
      timeOfDay: '07:00',
      timestamp: '2019-08-16T05:17:30.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1162231888674557952',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-15',
      dayOfWeek: 4,
      id: '1162086070718291970',
      language: 'English',
      length: 325,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: '@brickyang',
      repliedUser: null,
      retweetedUser: null,
      text:
        '<a href="https://twitter.com/search?q=%23FreeDorothy" title="#FreeDorothy" class="text-brand hover:text-brand-dark" target="_blank" rel="nofollow">#FreeDorothy</a>\n\n- sent from my smart brick <a href="https://t.co/zwh3rgkgxv" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/zwh3rgkgxv</a>',
      timeOfDay: '21:00',
      timestamp: '2019-08-15T19:38:04.000Z',
      type: 'Quote',
      url: 'https://twitter.com/luca/status/1162086070718291970',
      usedApp: 'Twitter Web App',
      usedHashtags: ['#FreeDorothy'],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-15',
      dayOfWeek: 4,
      id: '1162085828534898689',
      language: 'Unknown',
      length: 297,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: '@appliance_2',
      repliedUser: null,
      retweetedUser: null,
      text:
        '<a href="https://twitter.com/search?q=%23FreeDorothy" title="#FreeDorothy" class="text-brand hover:text-brand-dark" target="_blank" rel="nofollow">#FreeDorothy</a> <a href="https://t.co/nuZBbjT79c" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/nuZBbjT79c</a>',
      timeOfDay: '21:00',
      timestamp: '2019-08-15T19:37:06.000Z',
      type: 'Quote',
      url: 'https://twitter.com/luca/status/1162085828534898689',
      usedApp: 'Twitter Web App',
      usedHashtags: ['#FreeDorothy'],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-15',
      dayOfWeek: 4,
      id: '1162082188306853891',
      language: 'Unknown',
      length: 297,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: '@msitsmartdoor',
      repliedUser: null,
      retweetedUser: null,
      text:
        '<a href="https://twitter.com/search?q=%23FreeDorothy" title="#FreeDorothy" class="text-brand hover:text-brand-dark" target="_blank" rel="nofollow">#FreeDorothy</a> <a href="https://t.co/VNuRU7lbTG" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/VNuRU7lbTG</a>',
      timeOfDay: '21:00',
      timestamp: '2019-08-15T19:22:38.000Z',
      type: 'Quote',
      url: 'https://twitter.com/luca/status/1162082188306853891',
      usedApp: 'Twitter Web App',
      usedHashtags: ['#FreeDorothy'],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-15',
      dayOfWeek: 4,
      id: '1162082001966587904',
      language: 'Unknown',
      length: 297,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: '@indiestace',
      repliedUser: null,
      retweetedUser: null,
      text:
        '<a href="https://twitter.com/search?q=%23FreeDorothy" title="#FreeDorothy" class="text-brand hover:text-brand-dark" target="_blank" rel="nofollow">#FreeDorothy</a> <a href="https://t.co/zuPm38nrbk" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/zuPm38nrbk</a>',
      timeOfDay: '21:00',
      timestamp: '2019-08-15T19:21:54.000Z',
      type: 'Quote',
      url: 'https://twitter.com/luca/status/1162082001966587904',
      usedApp: 'Twitter Web App',
      usedHashtags: ['#FreeDorothy'],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-15',
      dayOfWeek: 4,
      id: '1162081614953963522',
      language: 'Unknown',
      length: 297,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: '@notsamar_',
      repliedUser: null,
      retweetedUser: null,
      text:
        '<a href="https://twitter.com/search?q=%23FreeDorothy" title="#FreeDorothy" class="text-brand hover:text-brand-dark" target="_blank" rel="nofollow">#FreeDorothy</a> <a href="https://t.co/RYELKDsVLr" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/RYELKDsVLr</a>',
      timeOfDay: '21:00',
      timestamp: '2019-08-15T19:20:22.000Z',
      type: 'Quote',
      url: 'https://twitter.com/luca/status/1162081614953963522',
      usedApp: 'Twitter Web App',
      usedHashtags: ['#FreeDorothy'],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-15',
      dayOfWeek: 4,
      id: '1161898825663078400',
      language: 'English',
      length: 291,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@beltrandroid',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/Beltrandroid" data-screen-name="Beltrandroid" target="_blank" rel="nofollow">@Beltrandroid</a>: Sitting on all kinds of information on 2.7 billion users, Facebook remains one of the most opaque entities with a massive…',
      timeOfDay: '09:00',
      timestamp: '2019-08-15T07:14:01.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1161898825663078400',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-15',
      dayOfWeek: 4,
      id: '1161898747275698176',
      language: 'English',
      length: 438,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@kommueller',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/kommueller" data-screen-name="kommueller" target="_blank" rel="nofollow">@kommueller</a>: Today&#39;s announcement of <a class="text-brand hover:text-brand-dark" href="https://twitter.com/facebook" data-screen-name="facebook" target="_blank" rel="nofollow">@facebook</a>&#39;s API-closure for the Facepager tool underscores this problem, I think. If only high-prof…',
      timeOfDay: '09:00',
      timestamp: '2019-08-15T07:13:43.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1161898747275698176',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-15',
      dayOfWeek: 4,
      id: '1161898536608444417',
      language: 'English',
      length: 293,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@tristanhotham',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/TristanHotham" data-screen-name="TristanHotham" target="_blank" rel="nofollow">@TristanHotham</a>: Please bare in mind this is all for the easiest and widest system of accessing public Facebook data. The RFP for Targete…',
      timeOfDay: '09:00',
      timestamp: '2019-08-15T07:12:52.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1161898536608444417',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-15',
      dayOfWeek: 4,
      id: '1161898430349946880',
      language: 'English',
      length: 297,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@tristanhotham',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/TristanHotham" data-screen-name="TristanHotham" target="_blank" rel="nofollow">@TristanHotham</a>: Undergrads, masters, PhD&#39;s, profs, lecturers and others interested in studying non-political Facebook will have no acces…',
      timeOfDay: '09:00',
      timestamp: '2019-08-15T07:12:27.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1161898430349946880',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-15',
      dayOfWeek: 4,
      id: '1161898392504688640',
      language: 'English',
      length: 292,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@tristanhotham',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/TristanHotham" data-screen-name="TristanHotham" target="_blank" rel="nofollow">@TristanHotham</a>: The idea that we are going to have better research through restricting access in such an arbitrary manner is foolhardy.…',
      timeOfDay: '09:00',
      timestamp: '2019-08-15T07:12:18.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1161898392504688640',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-15',
      dayOfWeek: 4,
      id: '1161898194906886144',
      language: 'English',
      length: 302,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@tristanhotham',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/TristanHotham" data-screen-name="TristanHotham" target="_blank" rel="nofollow">@TristanHotham</a>: You will &quot;provide drafts of academic papers to Social Science One&quot;. While researchers will also be required to agree to…',
      timeOfDay: '09:00',
      timestamp: '2019-08-15T07:11:31.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1161898194906886144',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-15',
      dayOfWeek: 4,
      id: '1161898124220207104',
      language: 'English',
      length: 298,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@tristanhotham',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/TristanHotham" data-screen-name="TristanHotham" target="_blank" rel="nofollow">@TristanHotham</a>: In the abstract you must &quot;make sure to address: general area of focus, proposed methodology (including specifics where a…',
      timeOfDay: '09:00',
      timestamp: '2019-08-15T07:11:14.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1161898124220207104',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-15',
      dayOfWeek: 4,
      id: '1161898078116466689',
      language: 'English',
      length: 293,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@tristanhotham',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/TristanHotham" data-screen-name="TristanHotham" target="_blank" rel="nofollow">@TristanHotham</a>: ... what about the many studies that have used public data to examine areas, from analysis of engagement during the Lond…',
      timeOfDay: '09:00',
      timestamp: '2019-08-15T07:11:03.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1161898078116466689',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-15',
      dayOfWeek: 4,
      id: '1161898065504145408',
      language: 'English',
      length: 298,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@tristanhotham',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/TristanHotham" data-screen-name="TristanHotham" target="_blank" rel="nofollow">@TristanHotham</a>: If you wanted to gain access to public Facebook data you will need to send; a 300 word proposal -that should &quot;pertain to…',
      timeOfDay: '09:00',
      timestamp: '2019-08-15T07:11:00.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1161898065504145408',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-15',
      dayOfWeek: 4,
      id: '1161898034650845186',
      language: 'English',
      length: 297,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@tristanhotham',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/TristanHotham" data-screen-name="TristanHotham" target="_blank" rel="nofollow">@TristanHotham</a>: Firstly you must have PI status - &quot;Applications are welcome from individuals or teams of researchers with PI privileges…',
      timeOfDay: '09:00',
      timestamp: '2019-08-15T07:10:53.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1161898034650845186',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-15',
      dayOfWeek: 4,
      id: '1161897969576288256',
      language: 'English',
      length: 573,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@tristanhotham',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/TristanHotham" data-screen-name="TristanHotham" target="_blank" rel="nofollow">@TristanHotham</a>: So if you are going to want to analyse even the most basic public <a class="text-brand hover:text-brand-dark" href="https://twitter.com/Facebook" data-screen-name="Facebook" target="_blank" rel="nofollow">@Facebook</a> data, data basically identical to what <a href="https://twitter.com/search?q=%23Netv" title="#Netv" class="text-brand hover:text-brand-dark" target="_blank" rel="nofollow">#Netv</a>…',
      timeOfDay: '09:00',
      timestamp: '2019-08-15T07:10:37.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1161897969576288256',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-15',
      dayOfWeek: 4,
      id: '1161897285296513024',
      language: 'English',
      length: 734,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@tristanhotham',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/TristanHotham" data-screen-name="TristanHotham" target="_blank" rel="nofollow">@TristanHotham</a>: A thread on <a class="text-brand hover:text-brand-dark" href="https://twitter.com/SocSciOne" data-screen-name="SocSciOne" target="_blank" rel="nofollow">@SocSciOne</a>&#39;s <a class="text-brand hover:text-brand-dark" href="https://twitter.com/crowdtangle" data-screen-name="crowdtangle" target="_blank" rel="nofollow">@crowdtangle</a> through which all analysis of public <a class="text-brand hover:text-brand-dark" href="https://twitter.com/facebook" data-screen-name="facebook" target="_blank" rel="nofollow">@facebook</a> data will be operated after 4th Sep…',
      timeOfDay: '09:00',
      timestamp: '2019-08-15T07:07:54.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1161897285296513024',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-15',
      dayOfWeek: 4,
      id: '1161896716200751104',
      language: 'German',
      length: 549,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@binderstefan',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/BinDerStefan" data-screen-name="BinDerStefan" target="_blank" rel="nofollow">@BinDerStefan</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/MartinThuer" data-screen-name="MartinThuer" target="_blank" rel="nofollow">@MartinThuer</a> Um einmal pro Jahr Luftballons im Wert von 10€ zu bekommen?\n\nBesuch mich Jahrestag seit… <a href="https://t.co/I5YDdztG9G" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/I5YDdztG9G</a>',
      timeOfDay: '09:00',
      timestamp: '2019-08-15T07:05:38.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1161896716200751104',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-15',
      dayOfWeek: 4,
      id: '1161888747312562177',
      language: 'English',
      length: 587,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@currankelleher',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/currankelleher" data-screen-name="currankelleher" target="_blank" rel="nofollow">@currankelleher</a>: This is like the best thing that happened in <a href="https://twitter.com/search?q=%23javascript" title="#javascript" class="text-brand hover:text-brand-dark" target="_blank" rel="nofollow">#javascript</a> <a href="https://twitter.com/search?q=%23dataviz" title="#dataviz" class="text-brand hover:text-brand-dark" target="_blank" rel="nofollow">#dataviz</a> for a while why are people not freaking out about thi…',
      timeOfDay: '08:00',
      timestamp: '2019-08-15T06:33:58.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1161888747312562177',
      usedApp: 'Twitter for Android',
      usedHashtags: ['#javascript', '#dataviz'],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-15',
      dayOfWeek: 4,
      id: '1161888104971718656',
      language: 'German',
      length: 399,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@netzpolitik_org',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/netzpolitik_org" data-screen-name="netzpolitik_org" target="_blank" rel="nofollow">@netzpolitik_org</a>: Datenanalyse: Maaßens Follower retweeten rechtsradikale Accounts, aber fast nie die CDU <a href="https://t.co/MQZ7ulHakF" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/MQZ7ulHakF</a>',
      timeOfDay: '08:00',
      timestamp: '2019-08-15T06:31:25.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1161888104971718656',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['netzpolitik.org'],
      username: 'luca',
    },
    {
      date: '2019-08-15',
      dayOfWeek: 4,
      id: '1161861822837907456',
      language: 'English',
      length: 291,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@rosemarieho_',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/rosemarieho_" data-screen-name="rosemarieho_" target="_blank" rel="nofollow">@rosemarieho_</a>: Some historical context: Hong Kong has only been a Chinese special administration region for 22 years. The fight for self…',
      timeOfDay: '06:00',
      timestamp: '2019-08-15T04:46:59.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1161861822837907456',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-15',
      dayOfWeek: 4,
      id: '1161857117080489984',
      language: 'English',
      length: 535,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@diesersunjii',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/DieserSunjii" data-screen-name="DieserSunjii" target="_blank" rel="nofollow">@DieserSunjii</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/notGamejocky" data-screen-name="notGamejocky" target="_blank" rel="nofollow">@notGamejocky</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/Schmutz08318420" data-screen-name="Schmutz08318420" target="_blank" rel="nofollow">@Schmutz08318420</a> Treeverse Browser add-on hilft.',
      timeOfDay: '06:00',
      timestamp: '2019-08-15T04:28:17.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1161857117080489984',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-14',
      dayOfWeek: 3,
      id: '1161746525040648192',
      language: 'Unknown',
      length: 925,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@adskankster',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/adskankster" data-screen-name="adskankster" target="_blank" rel="nofollow">@adskankster</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/Peters_Glen" data-screen-name="Peters_Glen" target="_blank" rel="nofollow">@Peters_Glen</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/alxrdk" data-screen-name="alxrdk" target="_blank" rel="nofollow">@alxrdk</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/RARohde" data-screen-name="RARohde" target="_blank" rel="nofollow">@RARohde</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/RichardMeyerDC" data-screen-name="RichardMeyerDC" target="_blank" rel="nofollow">@RichardMeyerDC</a> <a href="https://t.co/jm5ijjvyVc" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/jm5ijjvyVc</a>',
      timeOfDay: '23:00',
      timestamp: '2019-08-14T21:08:50.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1161746525040648192',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-14',
      dayOfWeek: 3,
      id: '1161741945116528640',
      language: 'English',
      length: 235,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@_subnet',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/_subnet" data-screen-name="_subnet" target="_blank" rel="nofollow">@_subnet</a> Have fun and don&#39;t forget to try the download feature for uninterrupted playback.',
      timeOfDay: '22:00',
      timestamp: '2019-08-14T20:50:38.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1161741945116528640',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-14',
      dayOfWeek: 3,
      id: '1161740513101385729',
      language: 'English',
      length: 211,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@_subnet',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/_subnet" data-screen-name="_subnet" target="_blank" rel="nofollow">@_subnet</a> Yes. YouTube in the background and YouTube music app as well.',
      timeOfDay: '22:00',
      timestamp: '2019-08-14T20:44:57.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1161740513101385729',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-14',
      dayOfWeek: 3,
      id: '1161739437639983104',
      language: 'English',
      length: 391,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@_subnet',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/_subnet" data-screen-name="_subnet" target="_blank" rel="nofollow">@_subnet</a> Spotify has the best discovery (playlists, weekly mix).\n\nYouTube premium has the unfair advantage of adfre… <a href="https://t.co/TCchMzxK0P" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/TCchMzxK0P</a>',
      timeOfDay: '22:00',
      timestamp: '2019-08-14T20:40:40.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1161739437639983104',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-14',
      dayOfWeek: 3,
      id: '1161738070011645952',
      language: 'English',
      length: 788,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@alxrdk',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/alxrdk" data-screen-name="alxrdk" target="_blank" rel="nofollow">@alxrdk</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/RARohde" data-screen-name="RARohde" target="_blank" rel="nofollow">@RARohde</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/Peters_Glen" data-screen-name="Peters_Glen" target="_blank" rel="nofollow">@Peters_Glen</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/RichardMeyerDC" data-screen-name="RichardMeyerDC" target="_blank" rel="nofollow">@RichardMeyerDC</a> Treeverse gets 80% done. <a href="https://t.co/NGJc2BCLrQ" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/NGJc2BCLrQ</a>',
      timeOfDay: '22:00',
      timestamp: '2019-08-14T20:35:14.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1161738070011645952',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['github.com'],
      username: 'luca',
    },
    {
      date: '2019-08-14',
      dayOfWeek: 3,
      id: '1161727864020115458',
      language: 'English',
      length: 250,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: null,
      text:
        'Mobile data subscriptions in Austria are complicated. Besides the maximal download/upload rates each contract has a… <a href="https://t.co/iILPvhF5JC" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/iILPvhF5JC</a>',
      timeOfDay: '21:00',
      timestamp: '2019-08-14T19:54:41.000Z',
      type: 'Tweet',
      url: 'https://twitter.com/luca/status/1161727864020115458',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-14',
      dayOfWeek: 3,
      id: '1161722982278008833',
      language: 'English',
      length: 202,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@_subnet',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/_subnet" data-screen-name="_subnet" target="_blank" rel="nofollow">@_subnet</a> Where (device) do you listen to it most of the time?',
      timeOfDay: '21:00',
      timestamp: '2019-08-14T19:35:17.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1161722982278008833',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-14',
      dayOfWeek: 3,
      id: '1161593442159534085',
      language: 'English',
      length: 233,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@makycore',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/MakyCore" data-screen-name="MakyCore" target="_blank" rel="nofollow">@MakyCore</a> It&#39;s what I normally use, but the output doesn&#39;t look as good as Wordle.',
      timeOfDay: '13:00',
      timestamp: '2019-08-14T11:00:32.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1161593442159534085',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-14',
      dayOfWeek: 3,
      id: '1161538090692952065',
      language: 'German',
      length: 684,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@wahl_beobachter',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/wahl_beobachter" data-screen-name="wahl_beobachter" target="_blank" rel="nofollow">@wahl_beobachter</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/Quora_DE" data-screen-name="Quora_DE" target="_blank" rel="nofollow">@Quora_DE</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/OMRockstars" data-screen-name="OMRockstars" target="_blank" rel="nofollow">@OMRockstars</a> War jahrelang sehr aktiv. Irgendwann das Interesse verloren.\n\n<a href="https://t.co/lhNUws8hUn" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/lhNUws8hUn</a>',
      timeOfDay: '09:00',
      timestamp: '2019-08-14T07:20:35.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1161538090692952065',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-14',
      dayOfWeek: 3,
      id: '1161527606728110080',
      language: 'English',
      length: 847,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@pardesoteric',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/pardesoteric" data-screen-name="pardesoteric" target="_blank" rel="nofollow">@pardesoteric</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/Twitter" data-screen-name="Twitter" target="_blank" rel="nofollow">@Twitter</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/kayvz" data-screen-name="kayvz" target="_blank" rel="nofollow">@kayvz</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/sriramkri" data-screen-name="sriramkri" target="_blank" rel="nofollow">@sriramkri</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/sriramk" data-screen-name="sriramk" target="_blank" rel="nofollow">@sriramk</a> Can I mute the whole topic as well? Would love to remove some sports from my feed.',
      timeOfDay: '08:00',
      timestamp: '2019-08-14T06:38:56.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1161527606728110080',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-14',
      dayOfWeek: 3,
      id: '1161526925707415552',
      language: 'English',
      length: 624,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@pardesoteric',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/pardesoteric" data-screen-name="pardesoteric" target="_blank" rel="nofollow">@pardesoteric</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/Twitter" data-screen-name="Twitter" target="_blank" rel="nofollow">@Twitter</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/kayvz" data-screen-name="kayvz" target="_blank" rel="nofollow">@kayvz</a> I have a whole thread of ideas.\n<a href="https://t.co/KQ9CCNFAVR" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/KQ9CCNFAVR</a>',
      timeOfDay: '08:00',
      timestamp: '2019-08-14T06:36:13.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1161526925707415552',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-14',
      dayOfWeek: 3,
      id: '1161525914007719936',
      language: 'English',
      length: 433,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@pardesoteric',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/pardesoteric" data-screen-name="pardesoteric" target="_blank" rel="nofollow">@pardesoteric</a>: 👋 I’m at <a class="text-brand hover:text-brand-dark" href="https://twitter.com/Twitter" data-screen-name="Twitter" target="_blank" rel="nofollow">@Twitter</a> HQ this afternoon to chat about the future of Twitter—from conversations to the timeline to election in…',
      timeOfDay: '08:00',
      timestamp: '2019-08-14T06:32:12.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1161525914007719936',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-14',
      dayOfWeek: 3,
      id: '1161507790755704836',
      language: 'English',
      length: 575,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@adrinjalali',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/adrinjalali" data-screen-name="adrinjalali" target="_blank" rel="nofollow">@adrinjalali</a>: If you use <a href="https://twitter.com/search?q=%23pandas" title="#pandas" class="text-brand hover:text-brand-dark" target="_blank" rel="nofollow">#pandas</a> in <a href="https://twitter.com/search?q=%23python" title="#python" class="text-brand hover:text-brand-dark" target="_blank" rel="nofollow">#python</a>, you could give the developers a hand and fill out the survey. It&#39;ll help the team better pr…',
      timeOfDay: '07:00',
      timestamp: '2019-08-14T05:20:11.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1161507790755704836',
      usedApp: 'Twitter for Android',
      usedHashtags: ['#pandas', '#python'],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-13',
      dayOfWeek: 2,
      id: '1161355490745606144',
      language: 'English',
      length: 76,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: null,
      text: 'I&#39;m still looking for a tool that produces as nice wordclouds as Wordle.',
      timeOfDay: '21:00',
      timestamp: '2019-08-13T19:15:00.000Z',
      type: 'Tweet',
      url: 'https://twitter.com/luca/status/1161355490745606144',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-13',
      dayOfWeek: 2,
      id: '1161341422676717568',
      language: 'English',
      length: 283,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@amac',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/amac" data-screen-name="amac" target="_blank" rel="nofollow">@amac</a>: 10 years ago today, Twitter launched “native retweet” &amp;amp; significantly changed how people experienced the Twitter timeline.\nI wrot…',
      timeOfDay: '20:00',
      timestamp: '2019-08-13T18:19:06.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1161341422676717568',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-13',
      dayOfWeek: 2,
      id: '1161323296031420419',
      language: 'German',
      length: 389,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@roblen',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/RobLen" data-screen-name="RobLen" target="_blank" rel="nofollow">@RobLen</a> Nicht selber ausprobiert, aber diverse Website-Builder (Wix, Squarespace, Jimdo, etc.) haben oft Blog-Funkt… <a href="https://t.co/VvTvaJnEjV" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/VvTvaJnEjV</a>',
      timeOfDay: '19:00',
      timestamp: '2019-08-13T17:07:04.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1161323296031420419',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-13',
      dayOfWeek: 2,
      id: '1161322688109010951',
      language: 'German',
      length: 380,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@i_am_fabs',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/i_am_fabs" data-screen-name="i_am_fabs" target="_blank" rel="nofollow">@i_am_fabs</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/RobLen" data-screen-name="RobLen" target="_blank" rel="nofollow">@RobLen</a> Deren Login-Nag nervt. Also eher nur was wenn Zielgruppe selbst Accounts hat.',
      timeOfDay: '19:00',
      timestamp: '2019-08-13T17:04:39.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1161322688109010951',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-13',
      dayOfWeek: 2,
      id: '1161322393845080066',
      language: 'English',
      length: 165,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@spreeblick',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/spreeblick" data-screen-name="spreeblick" target="_blank" rel="nofollow">@spreeblick</a> Prost.',
      timeOfDay: '19:00',
      timestamp: '2019-08-13T17:03:29.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1161322393845080066',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-13',
      dayOfWeek: 2,
      id: '1161321946551902213',
      language: 'German',
      length: 203,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@die_liser',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/Die_Liser" data-screen-name="Die_Liser" target="_blank" rel="nofollow">@Die_Liser</a> Wild guess: Am Laptop andere DNS Einstellungen?',
      timeOfDay: '19:00',
      timestamp: '2019-08-13T17:01:43.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1161321946551902213',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-13',
      dayOfWeek: 2,
      id: '1161321116255883265',
      language: 'German',
      length: 691,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@spreeblick',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/spreeblick" data-screen-name="spreeblick" target="_blank" rel="nofollow">@spreeblick</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/TwitterDE" data-screen-name="TwitterDE" target="_blank" rel="nofollow">@TwitterDE</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/vieuxrenard" data-screen-name="vieuxrenard" target="_blank" rel="nofollow">@vieuxrenard</a> Der CSU wird man aufgrund des Textes nichts können. Den Accounts, die es ohne K… <a href="https://t.co/f6JVgQTsUM" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/f6JVgQTsUM</a>',
      timeOfDay: '18:00',
      timestamp: '2019-08-13T16:58:25.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1161321116255883265',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-13',
      dayOfWeek: 2,
      id: '1161318156469772288',
      language: 'German',
      length: 691,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/spreeblick" data-screen-name="spreeblick" target="_blank" rel="nofollow">@spreeblick</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/TwitterDE" data-screen-name="TwitterDE" target="_blank" rel="nofollow">@TwitterDE</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/vieuxrenard" data-screen-name="vieuxrenard" target="_blank" rel="nofollow">@vieuxrenard</a> Nicht nur der Account, der etwas erstmals erstellt, sondern alle die es weiterv… <a href="https://t.co/j4YjWFqepi" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/j4YjWFqepi</a>',
      timeOfDay: '18:00',
      timestamp: '2019-08-13T16:46:39.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1161318156469772288',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-13',
      dayOfWeek: 2,
      id: '1161317987183411204',
      language: 'German',
      length: 691,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@spreeblick',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/spreeblick" data-screen-name="spreeblick" target="_blank" rel="nofollow">@spreeblick</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/TwitterDE" data-screen-name="TwitterDE" target="_blank" rel="nofollow">@TwitterDE</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/vieuxrenard" data-screen-name="vieuxrenard" target="_blank" rel="nofollow">@vieuxrenard</a> Bei nahezu allen Kampagnen, die ich beobachtet habe, spielten große Accounts ei… <a href="https://t.co/zp6AI2sHGr" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/zp6AI2sHGr</a>',
      timeOfDay: '18:00',
      timestamp: '2019-08-13T16:45:59.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1161317987183411204',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-13',
      dayOfWeek: 2,
      id: '1161316183452397571',
      language: 'English',
      length: 340,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: '@40_40vision',
      repliedUser: null,
      retweetedUser: null,
      text:
        'Impressive how many disinfo accounts Twitter&#39;s quality algo catches. <a href="https://t.co/Smx7yNMy3H" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/Smx7yNMy3H</a> <a href="https://t.co/DS8Rj7v4Kr" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/DS8Rj7v4Kr</a>',
      timeOfDay: '18:00',
      timestamp: '2019-08-13T16:38:49.000Z',
      type: 'Quote',
      url: 'https://twitter.com/luca/status/1161316183452397571',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-13',
      dayOfWeek: 2,
      id: '1161312754281717762',
      language: 'English',
      length: 187,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@fiete_stegers',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/fiete_stegers" data-screen-name="fiete_stegers" target="_blank" rel="nofollow">@fiete_stegers</a> Gah.\n\n- sent lazily',
      timeOfDay: '18:00',
      timestamp: '2019-08-13T16:25:11.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1161312754281717762',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-13',
      dayOfWeek: 2,
      id: '1161311682116902915',
      language: 'English',
      length: 987,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@mxfh',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/mxfh" data-screen-name="mxfh" target="_blank" rel="nofollow">@mxfh</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/fiete_stegers" data-screen-name="fiete_stegers" target="_blank" rel="nofollow">@fiete_stegers</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/AccessOSINT" data-screen-name="AccessOSINT" target="_blank" rel="nofollow">@AccessOSINT</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/OliverKlein" data-screen-name="OliverKlein" target="_blank" rel="nofollow">@OliverKlein</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/DanielLaufer" data-screen-name="DanielLaufer" target="_blank" rel="nofollow">@DanielLaufer</a> Here is the discussions about max sizes and compressio… <a href="https://t.co/N8VNCepshP" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/N8VNCepshP</a>',
      timeOfDay: '18:00',
      timestamp: '2019-08-13T16:20:55.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1161311682116902915',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-13',
      dayOfWeek: 2,
      id: '1161305785844023297',
      language: 'German',
      length: 542,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@spreeblick',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/spreeblick" data-screen-name="spreeblick" target="_blank" rel="nofollow">@spreeblick</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/TwitterDE" data-screen-name="TwitterDE" target="_blank" rel="nofollow">@TwitterDE</a> Gegen Personen kannst du bereits vorgehen und das NetzDG hilft manchmal ein bisschen bei der… <a href="https://t.co/XPwwfx1jM2" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/XPwwfx1jM2</a>',
      timeOfDay: '17:00',
      timestamp: '2019-08-13T15:57:30.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1161305785844023297',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-13',
      dayOfWeek: 2,
      id: '1161303377676029954',
      language: 'German',
      length: 542,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@spreeblick',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/spreeblick" data-screen-name="spreeblick" target="_blank" rel="nofollow">@spreeblick</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/TwitterDE" data-screen-name="TwitterDE" target="_blank" rel="nofollow">@TwitterDE</a> Korrekt. Noch ist sie das nicht, soweit ich weiß. Aber es wäre mein Lösungsansätz, wenn Leut… <a href="https://t.co/vmE5MLygTT" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/vmE5MLygTT</a>',
      timeOfDay: '17:00',
      timestamp: '2019-08-13T15:47:55.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1161303377676029954',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-13',
      dayOfWeek: 2,
      id: '1161301236437716995',
      language: 'German',
      length: 542,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@spreeblick',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/spreeblick" data-screen-name="spreeblick" target="_blank" rel="nofollow">@spreeblick</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/TwitterDE" data-screen-name="TwitterDE" target="_blank" rel="nofollow">@TwitterDE</a> Das kann ich nicht beantworten. Es ist meine Meinung. Ob sich das mit Rechtssprechung deckt,… <a href="https://t.co/n44LkyuGro" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/n44LkyuGro</a>',
      timeOfDay: '17:00',
      timestamp: '2019-08-13T15:39:25.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1161301236437716995',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-13',
      dayOfWeek: 2,
      id: '1161300339313795074',
      language: 'English',
      length: 852,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/fiete_stegers" data-screen-name="fiete_stegers" target="_blank" rel="nofollow">@fiete_stegers</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/AccessOSINT" data-screen-name="AccessOSINT" target="_blank" rel="nofollow">@AccessOSINT</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/OliverKlein" data-screen-name="OliverKlein" target="_blank" rel="nofollow">@OliverKlein</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/DanielLaufer" data-screen-name="DanielLaufer" target="_blank" rel="nofollow">@DanielLaufer</a> Direct link to the media URL formatting.\n\nThis may not be co… <a href="https://t.co/aRRauiQz2j" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/aRRauiQz2j</a>',
      timeOfDay: '17:00',
      timestamp: '2019-08-13T15:35:51.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1161300339313795074',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-13',
      dayOfWeek: 2,
      id: '1161299581889581056',
      language: 'German',
      length: 542,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@spreeblick',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/spreeblick" data-screen-name="spreeblick" target="_blank" rel="nofollow">@spreeblick</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/TwitterDE" data-screen-name="TwitterDE" target="_blank" rel="nofollow">@TwitterDE</a> Wo ihr Algorithmus redaktionelle Entscheidung trifft, sind sie Medien. Auch wenn sie das nic… <a href="https://t.co/JocQgl10lN" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/JocQgl10lN</a>',
      timeOfDay: '17:00',
      timestamp: '2019-08-13T15:32:50.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1161299581889581056',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-13',
      dayOfWeek: 2,
      id: '1161296186801868800',
      language: 'English',
      length: 836,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@fiete_stegers',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/fiete_stegers" data-screen-name="fiete_stegers" target="_blank" rel="nofollow">@fiete_stegers</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/AccessOSINT" data-screen-name="AccessOSINT" target="_blank" rel="nofollow">@AccessOSINT</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/OliverKlein" data-screen-name="OliverKlein" target="_blank" rel="nofollow">@OliverKlein</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/DanielLaufer" data-screen-name="DanielLaufer" target="_blank" rel="nofollow">@DanielLaufer</a> The API shows the different available sizes:… <a href="https://t.co/h9WrbHBPLR" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/h9WrbHBPLR</a>',
      timeOfDay: '17:00',
      timestamp: '2019-08-13T15:19:21.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1161296186801868800',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-13',
      dayOfWeek: 2,
      id: '1161294424460156928',
      language: 'German',
      length: 542,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@spreeblick',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/spreeblick" data-screen-name="spreeblick" target="_blank" rel="nofollow">@spreeblick</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/TwitterDE" data-screen-name="TwitterDE" target="_blank" rel="nofollow">@TwitterDE</a> Wie bei einer Gegendarstellung in anderen Medien.\n\nNeu wäre die Mitverantwortlichkeit der Pl… <a href="https://t.co/y7yLJniApy" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/y7yLJniApy</a>',
      timeOfDay: '17:00',
      timestamp: '2019-08-13T15:12:21.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1161294424460156928',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-13',
      dayOfWeek: 2,
      id: '1161170791440035840',
      language: 'English',
      length: 478,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@colibri_by_fzi',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/Colibri_by_FZI" data-screen-name="Colibri_by_FZI" target="_blank" rel="nofollow">@Colibri_by_FZI</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/LV__eu" data-screen-name="LV__eu" target="_blank" rel="nofollow">@LV__eu</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/DB_Bahn" data-screen-name="DB_Bahn" target="_blank" rel="nofollow">@DB_Bahn</a> Thank you.',
      timeOfDay: '09:00',
      timestamp: '2019-08-13T07:01:04.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1161170791440035840',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-13',
      dayOfWeek: 2,
      id: '1161161610565758976',
      language: 'German',
      length: 542,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@spreeblick',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/spreeblick" data-screen-name="spreeblick" target="_blank" rel="nofollow">@spreeblick</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/TwitterDE" data-screen-name="TwitterDE" target="_blank" rel="nofollow">@TwitterDE</a> Und Anzeigen gegen Personen, die es ohne Kontext weiterleiten, sind aufwändig, oft ohne Erfo… <a href="https://t.co/QqqMGOIdHF" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/QqqMGOIdHF</a>',
      timeOfDay: '08:00',
      timestamp: '2019-08-13T06:24:35.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1161161610565758976',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-12',
      dayOfWeek: 1,
      id: '1161005672932216834',
      language: 'English',
      length: 554,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@binarybits',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/binarybits" data-screen-name="binarybits" target="_blank" rel="nofollow">@binarybits</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/clancynewyork" data-screen-name="clancynewyork" target="_blank" rel="nofollow">@clancynewyork</a> Would YouTube be profitable if they don&#39;t drag users into sensationalist rabbit holes? I… <a href="https://t.co/Ren0P4l07G" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/Ren0P4l07G</a>',
      timeOfDay: '22:00',
      timestamp: '2019-08-12T20:04:57.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1161005672932216834',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-12',
      dayOfWeek: 1,
      id: '1161004700440965123',
      language: 'English',
      length: 275,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@luca',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/luca" data-screen-name="luca" target="_blank" rel="nofollow">@luca</a>: Quick fix ideas for the YouTube recommendation algorithm: \n- Only show videos from the same channel.\n- Allow creators to choose r…',
      timeOfDay: '22:00',
      timestamp: '2019-08-12T20:01:05.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1161004700440965123',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-12',
      dayOfWeek: 1,
      id: '1160998992177762304',
      language: 'English',
      length: 287,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@max_fisher',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/Max_Fisher" data-screen-name="Max_Fisher" target="_blank" rel="nofollow">@Max_Fisher</a>: Now live: Our monthslong project on YouTube radicalization. \n\nAs YouTube diverts more and more users down far-right rabbith…',
      timeOfDay: '21:00',
      timestamp: '2019-08-12T19:38:24.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1160998992177762304',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-12',
      dayOfWeek: 1,
      id: '1160998262356267009',
      language: 'English',
      length: 287,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@luca',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/luca" data-screen-name="luca" target="_blank" rel="nofollow">@luca</a>: „If you don&#39;t like, what I tweet, you don&#39;t need to follow me.“\n„I don&#39;t follow you. We are writing replies to a Tweet by a third…',
      timeOfDay: '21:00',
      timestamp: '2019-08-12T19:35:30.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1160998262356267009',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-12',
      dayOfWeek: 1,
      id: '1160995026131968001',
      language: 'German',
      length: 542,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@spreeblick',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/spreeblick" data-screen-name="spreeblick" target="_blank" rel="nofollow">@spreeblick</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/TwitterDE" data-screen-name="TwitterDE" target="_blank" rel="nofollow">@TwitterDE</a> Ich diskutiere manchmal ganz gern.\n\nSollte Twitter darüber entscheiden, ob es zulässig ist z… <a href="https://t.co/lWmzEIYkeZ" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/lWmzEIYkeZ</a>',
      timeOfDay: '21:00',
      timestamp: '2019-08-12T19:22:39.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1160995026131968001',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-12',
      dayOfWeek: 1,
      id: '1160993216809840640',
      language: 'German',
      length: 459,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@i_am_fabs',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/i_am_fabs" data-screen-name="i_am_fabs" target="_blank" rel="nofollow">@i_am_fabs</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/hol_val" data-screen-name="hol_val" target="_blank" rel="nofollow">@hol_val</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/stefan" data-screen-name="stefan" target="_blank" rel="nofollow">@stefan</a> Danke.',
      timeOfDay: '21:00',
      timestamp: '2019-08-12T19:15:27.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1160993216809840640',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-12',
      dayOfWeek: 1,
      id: '1160991751223619584',
      language: 'German',
      length: 675,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@i_am_fabs',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/i_am_fabs" data-screen-name="i_am_fabs" target="_blank" rel="nofollow">@i_am_fabs</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/hol_val" data-screen-name="hol_val" target="_blank" rel="nofollow">@hol_val</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/stefan" data-screen-name="stefan" target="_blank" rel="nofollow">@stefan</a> Die Voest macht 10% des gesamten Ausstoßen aus iirc. Fies wird es wenn man statt Länder… <a href="https://t.co/CMYS195H30" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/CMYS195H30</a>',
      timeOfDay: '21:00',
      timestamp: '2019-08-12T19:09:38.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1160991751223619584',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-12',
      dayOfWeek: 1,
      id: '1160988567662931969',
      language: 'German',
      length: 542,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@spreeblick',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/spreeblick" data-screen-name="spreeblick" target="_blank" rel="nofollow">@spreeblick</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/TwitterDE" data-screen-name="TwitterDE" target="_blank" rel="nofollow">@TwitterDE</a> Weil die Regel gegen Falschinformationen zum Wahlablauf ist und Falschaussagen zu Parteien e… <a href="https://t.co/P3ldqGqrRB" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/P3ldqGqrRB</a>',
      timeOfDay: '20:00',
      timestamp: '2019-08-12T18:56:59.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1160988567662931969',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-12',
      dayOfWeek: 1,
      id: '1160945573488472064',
      language: 'English',
      length: 395,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@drworsten',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/DrWorsTen" data-screen-name="DrWorsTen" target="_blank" rel="nofollow">@DrWorsTen</a> You can use the old interface by spoofing your user agent (opera still gets the old interface). But at s… <a href="https://t.co/PNIwbsWZIL" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/PNIwbsWZIL</a>',
      timeOfDay: '18:00',
      timestamp: '2019-08-12T16:06:08.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1160945573488472064',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-12',
      dayOfWeek: 1,
      id: '1160878146557751296',
      language: 'English',
      length: 250,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text:
        'Twitter announced that they will be more transparent why Tweets are unavailable. I wonder what message they will di… <a href="https://t.co/pKvJ0kg5eu" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/pKvJ0kg5eu</a>',
      timeOfDay: '13:00',
      timestamp: '2019-08-12T11:38:12.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1160878146557751296',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-12',
      dayOfWeek: 1,
      id: '1160875562958761984',
      language: 'English',
      length: 371,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@jamesrbuk',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/jamesrbuk" data-screen-name="jamesrbuk" target="_blank" rel="nofollow">@jamesrbuk</a> Yes. Tweets that are available if accessed through other ways (search, profile).\n\n<a href="https://t.co/oNkTS9CH9z" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/oNkTS9CH9z</a>',
      timeOfDay: '13:00',
      timestamp: '2019-08-12T11:27:56.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1160875562958761984',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-12',
      dayOfWeek: 1,
      id: '1160816251918344192',
      language: 'German',
      length: 369,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@ernst_michalek',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/ernst_michalek" data-screen-name="ernst_michalek" target="_blank" rel="nofollow">@ernst_michalek</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/evazet" data-screen-name="evazet" target="_blank" rel="nofollow">@evazet</a> Tweetbot. Können Drittanbieter inzwischen Umfragen?',
      timeOfDay: '09:00',
      timestamp: '2019-08-12T07:32:16.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1160816251918344192',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-12',
      dayOfWeek: 1,
      id: '1160810860794789888',
      language: 'English',
      length: 289,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@kamal_hothi',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/kamal_hothi" data-screen-name="kamal_hothi" target="_blank" rel="nofollow">@kamal_hothi</a>: Yes, tech enables and spreads hate speech. \n\nIn this and many other cases, what’s at root is something more sinister.\n\nI k…',
      timeOfDay: '09:00',
      timestamp: '2019-08-12T07:10:50.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1160810860794789888',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-12',
      dayOfWeek: 1,
      id: '1160810428563369984',
      language: 'English',
      length: 226,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: '@zeynep',
      repliedUser: null,
      retweetedUser: null,
      text:
        'Author of „Twitter and Tear Gas: The Power and Fragility of Networked Protest“ in Hong Kong. <a href="https://t.co/332NkMwnTb" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/332NkMwnTb</a>',
      timeOfDay: '09:00',
      timestamp: '2019-08-12T07:09:07.000Z',
      type: 'Quote',
      url: 'https://twitter.com/luca/status/1160810428563369984',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-12',
      dayOfWeek: 1,
      id: '1160809772511350784',
      language: 'English',
      length: 297,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@samsonyuen852',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/SamsonYuen852" data-screen-name="SamsonYuen852" target="_blank" rel="nofollow">@SamsonYuen852</a>: For the past two months, a few scholars and I have been doing onsite survey on Hong Kong&#39;s anti-ELAB movement. Here is a…',
      timeOfDay: '09:00',
      timestamp: '2019-08-12T07:06:31.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1160809772511350784',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-12',
      dayOfWeek: 1,
      id: '1160808662849511424',
      language: 'English',
      length: 289,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@nathanlawkc',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/nathanlawkc" data-screen-name="nathanlawkc" target="_blank" rel="nofollow">@nathanlawkc</a>: 1. THREAD: Today, August 12, marks the single most violent day in more than two months of the Hong Kong protests as we see…',
      timeOfDay: '09:00',
      timestamp: '2019-08-12T07:02:06.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1160808662849511424',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-12',
      dayOfWeek: 1,
      id: '1160793358362664960',
      language: 'English',
      length: 295,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@nilchristopher',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/NilChristopher" data-screen-name="NilChristopher" target="_blank" rel="nofollow">@NilChristopher</a>: My first story for WIRED: I describe in detail the way casteist hate speech spreads on TikTok based on previously unsee…',
      timeOfDay: '08:00',
      timestamp: '2019-08-12T06:01:17.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1160793358362664960',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-12',
      dayOfWeek: 1,
      id: '1160792010367938560',
      language: 'German',
      length: 535,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@lenadoppel',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/lenadoppel" data-screen-name="lenadoppel" target="_blank" rel="nofollow">@lenadoppel</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/evazet" data-screen-name="evazet" target="_blank" rel="nofollow">@evazet</a> (Du spülst mir das Thema immer wieder rein, obwohl ich schon damit abgeschlossen habe.)\n\nNutze… <a href="https://t.co/goshOvgLEx" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/goshOvgLEx</a>',
      timeOfDay: '07:00',
      timestamp: '2019-08-12T05:55:56.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1160792010367938560',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-12',
      dayOfWeek: 1,
      id: '1160790179289997312',
      language: 'German',
      length: 331,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/evazet" data-screen-name="evazet" target="_blank" rel="nofollow">@evazet</a> Von Home auf Latest umschalten über die Sternchen. <a href="https://t.co/xmR6h7y2Vj" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/xmR6h7y2Vj</a>',
      timeOfDay: '07:00',
      timestamp: '2019-08-12T05:48:39.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1160790179289997312',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-12',
      dayOfWeek: 1,
      id: '1160787319844159488',
      language: 'English',
      length: 298,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/evazet" data-screen-name="evazet" target="_blank" rel="nofollow">@evazet</a> Spezialkeywords. \n<a href="https://t.co/wwBXXDBX2g" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/wwBXXDBX2g</a>',
      timeOfDay: '07:00',
      timestamp: '2019-08-12T05:37:18.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1160787319844159488',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-12',
      dayOfWeek: 1,
      id: '1160786838463930368',
      language: 'German',
      length: 328,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/evazet" data-screen-name="evazet" target="_blank" rel="nofollow">@evazet</a> Tool, um followings in eine Liste zu speichern. <a href="https://t.co/UefRjVByrP" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/UefRjVByrP</a>',
      timeOfDay: '07:00',
      timestamp: '2019-08-12T05:35:23.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1160786838463930368',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-12',
      dayOfWeek: 1,
      id: '1160786308400394240',
      language: 'German',
      length: 389,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@evazet',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/evazet" data-screen-name="evazet" target="_blank" rel="nofollow">@evazet</a> Drei Möglichkeiten.\n- Von Home auf Latest umstellen (Twitter ändert das selbständig immer wieder).\n- Follow… <a href="https://t.co/c3BlBIxaZf" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/c3BlBIxaZf</a>',
      timeOfDay: '07:00',
      timestamp: '2019-08-12T05:33:16.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1160786308400394240',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-12',
      dayOfWeek: 1,
      id: '1160778843533303808',
      language: 'English',
      length: 293,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@houssamelokda',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/HoussamElokda" data-screen-name="HoussamElokda" target="_blank" rel="nofollow">@HoussamElokda</a>: Is free transit a good thing? \n\nThis topic has been discussed on my twitter world quite a bit. \n\nI decided to do some di…',
      timeOfDay: '07:00',
      timestamp: '2019-08-12T05:03:37.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1160778843533303808',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-11',
      dayOfWeek: 0,
      id: '1160641337101881345',
      language: 'English',
      length: 250,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: '@alexandraerin',
      repliedUser: null,
      retweetedUser: null,
      text:
        'Great idea.\n\nBeing more explicit reduces misunderstandings and mental load for followers. Sometimes it means saying… <a href="https://t.co/gFqrGmdi21" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/gFqrGmdi21</a>',
      timeOfDay: '21:00',
      timestamp: '2019-08-11T19:57:13.000Z',
      type: 'Quote',
      url: 'https://twitter.com/luca/status/1160641337101881345',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-11',
      dayOfWeek: 0,
      id: '1160602485750476802',
      language: 'Unknown',
      length: 304,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@ana_nym',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/ana_nym" data-screen-name="ana_nym" target="_blank" rel="nofollow">@ana_nym</a> <a href="https://twitter.com/search?q=%23nofilter" title="#nofilter" class="text-brand hover:text-brand-dark" target="_blank" rel="nofollow">#nofilter</a>',
      timeOfDay: '19:00',
      timestamp: '2019-08-11T17:22:50.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1160602485750476802',
      usedApp: 'Twitter Web App',
      usedHashtags: ['#nofilter'],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-11',
      dayOfWeek: 0,
      id: '1160599537704214529',
      language: 'Unknown',
      length: 133,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text:
        '<a href="https://t.co/eY40uC0MaA" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/eY40uC0MaA</a>',
      timeOfDay: '19:00',
      timestamp: '2019-08-11T17:11:07.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1160599537704214529',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-11',
      dayOfWeek: 0,
      id: '1160555676877434880',
      language: 'English',
      length: 158,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@shl',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/shl" data-screen-name="shl" target="_blank" rel="nofollow">@shl</a> Like a public block?',
      timeOfDay: '16:00',
      timestamp: '2019-08-11T14:16:50.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1160555676877434880',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-11',
      dayOfWeek: 0,
      id: '1160503408245301249',
      language: 'Unknown',
      length: 133,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text:
        '<a href="https://t.co/o1bUzdrxc1" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/o1bUzdrxc1</a>',
      timeOfDay: '12:00',
      timestamp: '2019-08-11T10:49:08.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1160503408245301249',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-11',
      dayOfWeek: 0,
      id: '1160422131987046400',
      language: 'English',
      length: 737,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@clancynewyork',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/clancynewyork" data-screen-name="clancynewyork" target="_blank" rel="nofollow">@clancynewyork</a>: <a class="text-brand hover:text-brand-dark" href="https://twitter.com/AOBrien_news" data-screen-name="AOBrien_news" target="_blank" rel="nofollow">@AOBrien_news</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/limlouisa" data-screen-name="limlouisa" target="_blank" rel="nofollow">@limlouisa</a> .<a class="text-brand hover:text-brand-dark" href="https://twitter.com/limlouisa" data-screen-name="limlouisa" target="_blank" rel="nofollow">@limlouisa</a> is the Explainer for English-language readers about what&#39;s happening in Hong Kong.…',
      timeOfDay: '07:00',
      timestamp: '2019-08-11T05:26:10.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1160422131987046400',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-11',
      dayOfWeek: 0,
      id: '1160409211307773954',
      language: 'English',
      length: 267,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@alexstamos',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/alexstamos" data-screen-name="alexstamos" target="_blank" rel="nofollow">@alexstamos</a>: “But it’s newsworthy” is becoming the traditional media’s equivalent to “we’re just a neutral platform”.',
      timeOfDay: '06:00',
      timestamp: '2019-08-11T04:34:49.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1160409211307773954',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-10',
      dayOfWeek: 6,
      id: '1160193753858105344',
      language: 'English',
      length: 53,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text: 'The import/export of blocklists is still unavailable.',
      timeOfDay: '16:00',
      timestamp: '2019-08-10T14:18:40.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1160193753858105344',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-10',
      dayOfWeek: 6,
      id: '1160192586335883264',
      language: 'English',
      length: 216,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@unblock_list',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/unblock_list" data-screen-name="unblock_list" target="_blank" rel="nofollow">@unblock_list</a> Dear bot, what blocklists is my account on? Thanks.',
      timeOfDay: '16:00',
      timestamp: '2019-08-10T14:14:02.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1160192586335883264',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-09',
      dayOfWeek: 5,
      id: '1159904442608754688',
      language: 'German',
      length: 852,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@murmeltiers',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/MurmeltierS" data-screen-name="MurmeltierS" target="_blank" rel="nofollow">@MurmeltierS</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/mueller_andi" data-screen-name="mueller_andi" target="_blank" rel="nofollow">@mueller_andi</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/herrfrankmann" data-screen-name="herrfrankmann" target="_blank" rel="nofollow">@herrfrankmann</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/NilsLeopold" data-screen-name="NilsLeopold" target="_blank" rel="nofollow">@NilsLeopold</a> Habe das Paper nicht gelesen, aber es gibt mehre Möglichkeit… <a href="https://t.co/qeYUqSBCKm" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/qeYUqSBCKm</a>',
      timeOfDay: '21:00',
      timestamp: '2019-08-09T19:09:03.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1159904442608754688',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-09',
      dayOfWeek: 5,
      id: '1159875507355361280',
      language: 'English',
      length: 268,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/hanspagel" data-screen-name="hanspagel" target="_blank" rel="nofollow">@hanspagel</a> Yes, data in the free version are the most recent 600 Tweets. Paid accounts get 3200 Tweets (Twitter API limit).',
      timeOfDay: '19:00',
      timestamp: '2019-08-09T17:14:05.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1159875507355361280',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-09',
      dayOfWeek: 5,
      id: '1159875058690605056',
      language: 'English',
      length: 248,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/hanspagel" data-screen-name="hanspagel" target="_blank" rel="nofollow">@hanspagel</a> Ads and follow catcher will be gone in the next version. Instead there will be pro accounts.',
      timeOfDay: '19:00',
      timestamp: '2019-08-09T17:12:18.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1159875058690605056',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-09',
      dayOfWeek: 5,
      id: '1159874700325113856',
      language: 'English',
      length: 395,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@hanspagel',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/hanspagel" data-screen-name="hanspagel" target="_blank" rel="nofollow">@hanspagel</a> Thank you, that was super helpful.\n\nThree takeaways for me:\n- Make it obvious how to drill down into the… <a href="https://t.co/hu6Pch4oad" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/hu6Pch4oad</a>',
      timeOfDay: '19:00',
      timestamp: '2019-08-09T17:10:52.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1159874700325113856',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-09',
      dayOfWeek: 5,
      id: '1159866882352132096',
      language: 'English',
      length: 180,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@hanspagel',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/hanspagel" data-screen-name="hanspagel" target="_blank" rel="nofollow">@hanspagel</a> 70% luck, 30% addiction.',
      timeOfDay: '18:00',
      timestamp: '2019-08-09T16:39:48.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1159866882352132096',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-09',
      dayOfWeek: 5,
      id: '1159865808941703173',
      language: 'English',
      length: 289,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@hanspagel',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/hanspagel" data-screen-name="hanspagel" target="_blank" rel="nofollow">@hanspagel</a>: Are you a maker? Do you have a side project?\n\nReply with the URL, I&#39;ll test it next week and send you constructive feedback.…',
      timeOfDay: '18:00',
      timestamp: '2019-08-09T16:35:32.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1159865808941703173',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-09',
      dayOfWeek: 5,
      id: '1159865794014142464',
      language: 'Unknown',
      length: 289,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@hanspagel',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/hanspagel" data-screen-name="hanspagel" target="_blank" rel="nofollow">@hanspagel</a> <a href="https://t.co/GkTxZFCo0F" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/GkTxZFCo0F</a>',
      timeOfDay: '18:00',
      timestamp: '2019-08-09T16:35:29.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1159865794014142464',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['accountanalysis.lucahammer.com'],
      username: 'luca',
    },
    {
      date: '2019-08-09',
      dayOfWeek: 5,
      id: '1159860592078929920',
      language: 'English',
      length: 638,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@kpoulsen',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/kpoulsen" data-screen-name="kpoulsen" target="_blank" rel="nofollow">@kpoulsen</a>: I sat on the new ZeroNet version of  <a href="https://twitter.com/search?q=%238chan" title="#8chan" class="text-brand hover:text-brand-dark" target="_blank" rel="nofollow">#8chan</a> for a day and collected IP addresses. <a href="https://t.co/pNwcXdu021" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/pNwcXdu021</a> <a href="https://t.co/iW8X9f" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/iW8X9f</a>…',
      timeOfDay: '18:00',
      timestamp: '2019-08-09T16:14:48.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1159860592078929920',
      usedApp: 'Twitter for Android',
      usedHashtags: ['#8chan'],
      usedHostnames: ['www.thedailybeast.com'],
      username: 'luca',
    },
    {
      date: '2019-08-09',
      dayOfWeek: 5,
      id: '1159850362326716417',
      language: 'English',
      length: 846,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@igorbrigadir',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/IgorBrigadir" data-screen-name="IgorBrigadir" target="_blank" rel="nofollow">@IgorBrigadir</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/edsu" data-screen-name="edsu" target="_blank" rel="nofollow">@edsu</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/OpenHumansOrg" data-screen-name="OpenHumansOrg" target="_blank" rel="nofollow">@OpenHumansOrg</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/accountanalysis" data-screen-name="accountanalysis" target="_blank" rel="nofollow">@accountanalysis</a> I will have to test it again, but in the past it was possible t… <a href="https://t.co/GNR9lSniwY" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/GNR9lSniwY</a>',
      timeOfDay: '17:00',
      timestamp: '2019-08-09T15:34:10.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1159850362326716417',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-09',
      dayOfWeek: 5,
      id: '1159797903948488704',
      language: 'English',
      length: 289,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@rahmstorf',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/rahmstorf" data-screen-name="rahmstorf" target="_blank" rel="nofollow">@rahmstorf</a>: Climate change we&#39;re causing today does not stop in 2100. The human-caused CO2 increase will persist for tens of thousands o…',
      timeOfDay: '14:00',
      timestamp: '2019-08-09T12:05:42.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1159797903948488704',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-09',
      dayOfWeek: 5,
      id: '1159797468516769793',
      language: 'Unknown',
      length: 168,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@___dagger___',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/___Dagger___" data-screen-name="___Dagger___" target="_blank" rel="nofollow">@___Dagger___</a> 🏞️',
      timeOfDay: '14:00',
      timestamp: '2019-08-09T12:03:59.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1159797468516769793',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-09',
      dayOfWeek: 5,
      id: '1159785570144534528',
      language: 'Unknown',
      length: 133,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text:
        '<a href="https://t.co/s3kuxpSlXV" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/s3kuxpSlXV</a>',
      timeOfDay: '13:00',
      timestamp: '2019-08-09T11:16:42.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1159785570144534528',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-09',
      dayOfWeek: 5,
      id: '1159785287897231360',
      language: 'Unknown',
      length: 133,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text:
        '<a href="https://t.co/slcE7xY5FB" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/slcE7xY5FB</a>',
      timeOfDay: '13:00',
      timestamp: '2019-08-09T11:15:35.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1159785287897231360',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-09',
      dayOfWeek: 5,
      id: '1159785117260353536',
      language: 'English',
      length: 294,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@martinbangratz',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/MartinBangratz" data-screen-name="MartinBangratz" target="_blank" rel="nofollow">@MartinBangratz</a>: Is there a current scientific consensus on the influence of bots (or social media in general) on public opinion and/or…',
      timeOfDay: '13:00',
      timestamp: '2019-08-09T11:14:54.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1159785117260353536',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-09',
      dayOfWeek: 5,
      id: '1159785104639692800',
      language: 'English',
      length: 409,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@martinbangratz',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/MartinBangratz" data-screen-name="MartinBangratz" target="_blank" rel="nofollow">@MartinBangratz</a> There seems to be a consensus that Social Media influences elections and public opinion, but it&#39;s u… <a href="https://t.co/zOW7PT98d9" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/zOW7PT98d9</a>',
      timeOfDay: '13:00',
      timestamp: '2019-08-09T11:14:51.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1159785104639692800',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-09',
      dayOfWeek: 5,
      id: '1159716065213108224',
      language: 'English',
      length: 432,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@jajuenger',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/jajuenger" data-screen-name="jajuenger" target="_blank" rel="nofollow">@jajuenger</a>: <a href="https://twitter.com/search?q=%23Facepager" title="#Facepager" class="text-brand hover:text-brand-dark" target="_blank" rel="nofollow">#Facepager</a> probably will loose access to public Facebook pages on September 4 as well. Anyway, there are plenty of other API…',
      timeOfDay: '08:00',
      timestamp: '2019-08-09T06:40:31.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1159716065213108224',
      usedApp: 'Twitter for Android',
      usedHashtags: ['#Facepager'],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-09',
      dayOfWeek: 5,
      id: '1159711767293042689',
      language: 'English',
      length: 450,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@tristanhotham',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/TristanHotham" data-screen-name="TristanHotham" target="_blank" rel="nofollow">@TristanHotham</a>: Most researchers don&#39;t need <a class="text-brand hover:text-brand-dark" href="https://twitter.com/SocSciOne" data-screen-name="SocSciOne" target="_blank" rel="nofollow">@SocSciOne</a>&#39;s “privacy-preserving access to Facebook’s data through&quot;, we just want access to…',
      timeOfDay: '08:00',
      timestamp: '2019-08-09T06:23:26.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1159711767293042689',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-09',
      dayOfWeek: 5,
      id: '1159711651240796160',
      language: 'English',
      length: 833,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@tristanhotham',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/TristanHotham" data-screen-name="TristanHotham" target="_blank" rel="nofollow">@TristanHotham</a>: Thread on <a class="text-brand hover:text-brand-dark" href="https://twitter.com/facebook" data-screen-name="facebook" target="_blank" rel="nofollow">@facebook</a> shutting down <a class="text-brand hover:text-brand-dark" href="https://twitter.com/RiederB" data-screen-name="RiederB" target="_blank" rel="nofollow">@RiederB</a>&#39;s <a href="https://twitter.com/search?q=%23Netvizz" title="#Netvizz" class="text-brand hover:text-brand-dark" target="_blank" rel="nofollow">#Netvizz</a> and the hollowing out of our democracy. 1/ <a href="https://t.co/RQNq2jQqJY" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/RQNq2jQqJY</a>',
      timeOfDay: '08:00',
      timestamp: '2019-08-09T06:22:58.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1159711651240796160',
      usedApp: 'Twitter for Android',
      usedHashtags: ['#Netvizz'],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-08',
      dayOfWeek: 4,
      id: '1159556572974071808',
      language: 'English',
      length: 287,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@climateben',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/ClimateBen" data-screen-name="ClimateBen" target="_blank" rel="nofollow">@ClimateBen</a>: “The consequences of failing to keep the temperature below 1.5C will be to wilfully condemn hundreds of millions of the poo…',
      timeOfDay: '22:00',
      timestamp: '2019-08-08T20:06:45.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1159556572974071808',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-08',
      dayOfWeek: 4,
      id: '1159553121657376775',
      language: 'English',
      length: 357,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@gurmanbhatia',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/GurmanBhatia" data-screen-name="GurmanBhatia" target="_blank" rel="nofollow">@GurmanBhatia</a>: help me crowdsource some examples my data viz friends. <a href="https://t.co/gUImeSXxfV" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/gUImeSXxfV</a>',
      timeOfDay: '21:00',
      timestamp: '2019-08-08T19:53:02.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1159553121657376775',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-08',
      dayOfWeek: 4,
      id: '1159472215588528128',
      language: 'English',
      length: 650,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@jasonbaumgartne',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/jasonbaumgartne" data-screen-name="jasonbaumgartne" target="_blank" rel="nofollow">@jasonbaumgartne</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/tibor" data-screen-name="tibor" target="_blank" rel="nofollow">@tibor</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/IgorBrigadir" data-screen-name="IgorBrigadir" target="_blank" rel="nofollow">@IgorBrigadir</a> Not supported in Edge and Safari. <a href="https://t.co/KpiyGVSocv" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/KpiyGVSocv</a>',
      timeOfDay: '16:00',
      timestamp: '2019-08-08T14:31:32.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1159472215588528128',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['developer.mozilla.org'],
      username: 'luca',
    },
    {
      date: '2019-08-08',
      dayOfWeek: 4,
      id: '1159441997452718081',
      language: 'German',
      length: 285,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@fischblog',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/Fischblog" data-screen-name="Fischblog" target="_blank" rel="nofollow">@Fischblog</a>: Abholzung, Überdüngung, Erosion - und obendrauf noch der Klimawandel. Die Landnutzungsbilanz des IPCC bietet ein, äh, gemisc…',
      timeOfDay: '14:00',
      timestamp: '2019-08-08T12:31:28.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1159441997452718081',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-08',
      dayOfWeek: 4,
      id: '1159404582230999040',
      language: 'Unknown',
      length: 133,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text:
        '<a href="https://t.co/QGSDTOlpwp" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/QGSDTOlpwp</a>',
      timeOfDay: '12:00',
      timestamp: '2019-08-08T10:02:47.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1159404582230999040',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-08',
      dayOfWeek: 4,
      id: '1159377518929022977',
      language: 'German',
      length: 413,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@martinlindner',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/martinlindner" data-screen-name="martinlindner" target="_blank" rel="nofollow">@martinlindner</a> Alternativ kann man auch die Suche nutzen. (Über &quot;include:nativeretweets&quot; kann man dort Retweets zus… <a href="https://t.co/fEq1VbH52J" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/fEq1VbH52J</a>',
      timeOfDay: '10:00',
      timestamp: '2019-08-08T08:15:15.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1159377518929022977',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-08',
      dayOfWeek: 4,
      id: '1159359097097113600',
      language: 'German',
      length: 497,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@mikrotexte',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/Mikrotexte" data-screen-name="Mikrotexte" target="_blank" rel="nofollow">@Mikrotexte</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/martinlindner" data-screen-name="martinlindner" target="_blank" rel="nofollow">@martinlindner</a> Falls es Fragen gibt, gerne melden.\n\n<a href="https://t.co/UefRjVByrP" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/UefRjVByrP</a>',
      timeOfDay: '09:00',
      timestamp: '2019-08-08T07:02:03.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1159359097097113600',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-08',
      dayOfWeek: 4,
      id: '1159346034268659713',
      language: 'English',
      length: 436,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@hayward_katy',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/hayward_katy" data-screen-name="hayward_katy" target="_blank" rel="nofollow">@hayward_katy</a>: PM Johnson has made the removal of the <a href="https://twitter.com/search?q=%23backstop" title="#backstop" class="text-brand hover:text-brand-dark" target="_blank" rel="nofollow">#backstop</a> the make-or-break matter when it comes to an exit deal with the EU. \n\nSo…',
      timeOfDay: '08:00',
      timestamp: '2019-08-08T06:10:08.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1159346034268659713',
      usedApp: 'Twitter for Android',
      usedHashtags: ['#backstop'],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-08',
      dayOfWeek: 4,
      id: '1159341839809470464',
      language: 'English',
      length: 262,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@bakkooonn',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/BAKKOOONN" data-screen-name="BAKKOOONN" target="_blank" rel="nofollow">@BAKKOOONN</a>: &quot;the cowards ratio&quot; - no reply, low single digit &#39;retweet&#39;, several hundred favorite',
      timeOfDay: '07:00',
      timestamp: '2019-08-08T05:53:28.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1159341839809470464',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-07',
      dayOfWeek: 3,
      id: '1159211933037412352',
      language: 'English',
      length: 177,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: '@robaeprice',
      repliedUser: null,
      retweetedUser: null,
      text:
        'Why researchers can&#39;t have nice things. <a href="https://t.co/nc1Dlk7dhF" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/nc1Dlk7dhF</a>',
      timeOfDay: '23:00',
      timestamp: '2019-08-07T21:17:16.000Z',
      type: 'Quote',
      url: 'https://twitter.com/luca/status/1159211933037412352',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-07',
      dayOfWeek: 3,
      id: '1159209070806601728',
      language: 'English',
      length: 712,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@dfreelon',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/dfreelon" data-screen-name="dfreelon" target="_blank" rel="nofollow">@dfreelon</a>: The <a href="https://twitter.com/search?q=%23PostAPI" title="#PostAPI" class="text-brand hover:text-brand-dark" target="_blank" rel="nofollow">#PostAPI</a> age continues apace. This will likely also affect Facepager and other similar programs. Thx <a class="text-brand hover:text-brand-dark" href="https://twitter.com/riederb" data-screen-name="riederb" target="_blank" rel="nofollow">@riederb</a>, <a class="text-brand hover:text-brand-dark" href="https://twitter.com/jajuenger" data-screen-name="jajuenger" target="_blank" rel="nofollow">@jajuenger</a>…',
      timeOfDay: '23:00',
      timestamp: '2019-08-07T21:05:54.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1159209070806601728',
      usedApp: 'Twitter for Android',
      usedHashtags: ['#PostAPI'],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-07',
      dayOfWeek: 3,
      id: '1159188216743976962',
      language: 'English',
      length: 318,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: '@digiom',
      repliedUser: null,
      retweetedUser: null,
      text:
        'I enjoyed his <a href="https://twitter.com/search?q=%23MuMe" title="#MuMe" class="text-brand hover:text-brand-dark" target="_blank" rel="nofollow">#MuMe</a> lectures in Vienna in 2008. <a href="https://t.co/vRLEcnfX10" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/vRLEcnfX10</a>',
      timeOfDay: '21:00',
      timestamp: '2019-08-07T19:43:02.000Z',
      type: 'Quote',
      url: 'https://twitter.com/luca/status/1159188216743976962',
      usedApp: 'Twitter for Android',
      usedHashtags: ['#MuMe'],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-07',
      dayOfWeek: 3,
      id: '1159134441543016450',
      language: 'English',
      length: 492,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@luca',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/luca" data-screen-name="luca" target="_blank" rel="nofollow">@luca</a>: I am here for the stories of people who were pushed left by right wing content. <a href="https://t.co/fIJNELivWo" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/fIJNELivWo</a> <a href="https://t.co/lAV66ZVELd" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/lAV66ZVELd</a>',
      timeOfDay: '18:00',
      timestamp: '2019-08-07T16:09:21.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1159134441543016450',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['reddit.com'],
      username: 'luca',
    },
    {
      date: '2019-08-07',
      dayOfWeek: 3,
      id: '1159134393895727105',
      language: 'English',
      length: 321,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@luca',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/luca" data-screen-name="luca" target="_blank" rel="nofollow">@luca</a>: Radicalised and deradicalised via YouTube.\n<a href="https://t.co/oI0aJbizib" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/oI0aJbizib</a>',
      timeOfDay: '18:00',
      timestamp: '2019-08-07T16:09:09.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1159134393895727105',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-07',
      dayOfWeek: 3,
      id: '1159112514547978240',
      language: 'English',
      length: 387,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@helmi',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/helmi" data-screen-name="helmi" target="_blank" rel="nofollow">@helmi</a> Other countries where an emergency lane must be formed: Germany, Switzerland, Czech Republic, Hungary and Sl… <a href="https://t.co/ZrKHN8JPYd" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/ZrKHN8JPYd</a>',
      timeOfDay: '16:00',
      timestamp: '2019-08-07T14:42:13.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1159112514547978240',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-07',
      dayOfWeek: 3,
      id: '1159107097625337856',
      language: 'German',
      length: 275,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@n1si',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/N1si" data-screen-name="N1si" target="_blank" rel="nofollow">@N1si</a>: [Update] \nIm Mai zeigte ich einen Twitter-Account bei der Polizei an. \nEs handelte sich um eine Lisa G., welche angeblich wollte,…',
      timeOfDay: '16:00',
      timestamp: '2019-08-07T14:20:41.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1159107097625337856',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-07',
      dayOfWeek: 3,
      id: '1158989412132425728',
      language: 'German',
      length: 253,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@macsnider',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/macsnider" data-screen-name="macsnider" target="_blank" rel="nofollow">@macsnider</a> Inzwischen kann ich ihm auch etwas abgewinnen. Damals war es wichtig, um neue Accounts zu finden.',
      timeOfDay: '08:00',
      timestamp: '2019-08-07T06:33:03.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1158989412132425728',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-07',
      dayOfWeek: 3,
      id: '1158984507552407552',
      language: 'English',
      length: 407,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@jasonbaumgartne',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/jasonbaumgartne" data-screen-name="jasonbaumgartne" target="_blank" rel="nofollow">@jasonbaumgartne</a> The original account, who created the Tweet one replies to, is always at the beginning of the Twee… <a href="https://t.co/zp8sWwPkFL" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/zp8sWwPkFL</a>',
      timeOfDay: '08:00',
      timestamp: '2019-08-07T06:13:34.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1158984507552407552',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-07',
      dayOfWeek: 3,
      id: '1158983498499284993',
      language: 'English',
      length: 250,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text:
        'The dot-mention is the only thing that survived. \n\nBecause there was no difference between a reply and mention back… <a href="https://t.co/01h2muWV5h" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/01h2muWV5h</a>',
      timeOfDay: '08:00',
      timestamp: '2019-08-07T06:09:33.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1158983498499284993',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-07',
      dayOfWeek: 3,
      id: '1158982625626873858',
      language: 'English',
      length: 249,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text:
        'Today you can only remove additionally mentioned accounts. The original account will always be at the front of the… <a href="https://t.co/BwfxpMfDf9" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/BwfxpMfDf9</a>',
      timeOfDay: '08:00',
      timestamp: '2019-08-07T06:06:05.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1158982625626873858',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-07',
      dayOfWeek: 3,
      id: '1158982073136365569',
      language: 'English',
      length: 258,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text:
        'Back then there was no threading. You couldn&#39;t tap on a reply and see the whole conversation. Search didn&#39;t work we… <a href="https://t.co/yu38KdxTaQ" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/yu38KdxTaQ</a>',
      timeOfDay: '08:00',
      timestamp: '2019-08-07T06:03:53.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1158982073136365569',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-07',
      dayOfWeek: 3,
      id: '1158981137080958977',
      language: 'English',
      length: 250,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text:
        'About ten years ago Twitter changed from showing every reply from your followings to only replies to accounts you a… <a href="https://t.co/abWHQtFNaT" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/abWHQtFNaT</a>',
      timeOfDay: '08:00',
      timestamp: '2019-08-07T06:00:10.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1158981137080958977',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-07',
      dayOfWeek: 3,
      id: '1158973652244807682',
      language: 'English',
      length: 249,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: '@astrokatie',
      repliedUser: null,
      retweetedUser: null,
      text:
        'It&#39;s time to undust the reply accounts. From the time when Twitter showed every reply to all of your followers. <a href="https://t.co/heVRXT3O6L" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/heVRXT3O6L</a>',
      timeOfDay: '07:00',
      timestamp: '2019-08-07T05:30:26.000Z',
      type: 'Quote',
      url: 'https://twitter.com/luca/status/1158973652244807682',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-06',
      dayOfWeek: 2,
      id: '1158769806436446211',
      language: 'Norwegian',
      length: 146,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: null,
      text:
        'Hello Tyrol. <a href="https://t.co/uPIwxeGXyl" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/uPIwxeGXyl</a>',
      timeOfDay: '18:00',
      timestamp: '2019-08-06T16:00:25.000Z',
      type: 'Tweet',
      url: 'https://twitter.com/luca/status/1158769806436446211',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-06',
      dayOfWeek: 2,
      id: '1158750977714982915',
      language: 'English',
      length: 289,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@wellerstein',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/wellerstein" data-screen-name="wellerstein" target="_blank" rel="nofollow">@wellerstein</a>: 74 years ago today, the United States detonated an atomic bomb over the Japanese city of Hiroshima. There are some picture…',
      timeOfDay: '16:00',
      timestamp: '2019-08-06T14:45:36.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1158750977714982915',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-06',
      dayOfWeek: 2,
      id: '1158748390907416576',
      language: 'Unknown',
      length: 475,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@natsecmicah',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/NatSecMicah" data-screen-name="NatSecMicah" target="_blank" rel="nofollow">@NatSecMicah</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/sjacks26" data-screen-name="sjacks26" target="_blank" rel="nofollow">@sjacks26</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/swodinsky" data-screen-name="swodinsky" target="_blank" rel="nofollow">@swodinsky</a> Too.',
      timeOfDay: '16:00',
      timestamp: '2019-08-06T14:35:19.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1158748390907416576',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-06',
      dayOfWeek: 2,
      id: '1158746890495807488',
      language: 'English',
      length: 289,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@swodinsky',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/swodinsky" data-screen-name="swodinsky" target="_blank" rel="nofollow">@swodinsky</a>: lmao i literally wrote my grad school thesis (and there&#39;s been stellar reporting) on why these monitoring companies are pure…',
      timeOfDay: '16:00',
      timestamp: '2019-08-06T14:29:21.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1158746890495807488',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-06',
      dayOfWeek: 2,
      id: '1158746890126680064',
      language: 'English',
      length: 293,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@katrinanation',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/KatrinaNation" data-screen-name="KatrinaNation" target="_blank" rel="nofollow">@KatrinaNation</a>: ‘The Great Hack’ Is Just Silicon Snake Oil :Is Cambridge Analytica a magician of media manipulation—or the Theranos of p…',
      timeOfDay: '16:00',
      timestamp: '2019-08-06T14:29:21.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1158746890126680064',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-06',
      dayOfWeek: 2,
      id: '1158738962116009986',
      language: 'German',
      length: 677,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@stefan',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/stefan" data-screen-name="stefan" target="_blank" rel="nofollow">@stefan</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/i_am_fabs" data-screen-name="i_am_fabs" target="_blank" rel="nofollow">@i_am_fabs</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/faebiaen" data-screen-name="faebiaen" target="_blank" rel="nofollow">@faebiaen</a> Vermute, dass Taxis ähnlich unterwegs sind. Selbst wenn sie wo stehen statt leer hinfa… <a href="https://t.co/R3sMkZby3M" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/R3sMkZby3M</a>',
      timeOfDay: '15:00',
      timestamp: '2019-08-06T13:57:51.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1158738962116009986',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-06',
      dayOfWeek: 2,
      id: '1158718945932845057',
      language: 'German',
      length: 267,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@niels_bremen',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/Niels_Bremen" data-screen-name="Niels_Bremen" target="_blank" rel="nofollow">@Niels_Bremen</a> Dass das umbenennen nicht klappt, ist ein Bug. Werde ich mir anschauen. Falls ich es nicht einstampfe.',
      timeOfDay: '14:00',
      timestamp: '2019-08-06T12:38:19.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1158718945932845057',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-06',
      dayOfWeek: 2,
      id: '1158713595989057537',
      language: 'English',
      length: 278,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@luca',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/luca" data-screen-name="luca" target="_blank" rel="nofollow">@luca</a>: What&#39;s your opinion on this? If it violates the rule, how could it be improved to not violate them anymore?\n\nElse I will have to…',
      timeOfDay: '14:00',
      timestamp: '2019-08-06T12:17:03.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1158713595989057537',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-06',
      dayOfWeek: 2,
      id: '1158711779578585088',
      language: 'English',
      length: 565,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@c___cs',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/C___CS" data-screen-name="C___CS" target="_blank" rel="nofollow">@C___CS</a>: Some thoughts on the recent <a class="text-brand hover:text-brand-dark" href="https://twitter.com/Cloudflare" data-screen-name="Cloudflare" target="_blank" rel="nofollow">@Cloudflare</a> <a href="https://twitter.com/search?q=%238Chan" title="#8Chan" class="text-brand hover:text-brand-dark" target="_blank" rel="nofollow">#8Chan</a> decision, \nesp their recent blog outlining the decision to cut their services to…',
      timeOfDay: '14:00',
      timestamp: '2019-08-06T12:09:50.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1158711779578585088',
      usedApp: 'Twitter for Android',
      usedHashtags: ['#8Chan'],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-06',
      dayOfWeek: 2,
      id: '1158709983678255105',
      language: 'English',
      length: 209,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@rolfvanroot',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/rolfvanroot" data-screen-name="rolfvanroot" target="_blank" rel="nofollow">@rolfvanroot</a> Write everything in html. (Sorry, not helpful.)',
      timeOfDay: '14:00',
      timestamp: '2019-08-06T12:02:42.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1158709983678255105',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-06',
      dayOfWeek: 2,
      id: '1158700052875026432',
      language: 'German',
      length: 426,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@jsachse',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/jsachse" data-screen-name="jsachse" target="_blank" rel="nofollow">@jsachse</a>: Hat jemand über die Abzock-Methoden von <a href="https://twitter.com/search?q=%23Kollegah" title="#Kollegah" class="text-brand hover:text-brand-dark" target="_blank" rel="nofollow">#Kollegah</a> berichtet und bekam danach Post von Anwälten des Selbstdarstellers? Dann ber…',
      timeOfDay: '13:00',
      timestamp: '2019-08-06T11:23:14.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1158700052875026432',
      usedApp: 'Twitter for Android',
      usedHashtags: ['#Kollegah'],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-06',
      dayOfWeek: 2,
      id: '1158685783970275329',
      language: 'English',
      length: 250,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: '@easyjet',
      repliedUser: null,
      retweetedUser: null,
      text:
        '„We removed the back of the seat, now you remove the photo of it. Deal?“\n\nMaybe making flying more uncomfortable an… <a href="https://t.co/sXIVytdHaU" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/sXIVytdHaU</a>',
      timeOfDay: '12:00',
      timestamp: '2019-08-06T10:26:32.000Z',
      type: 'Quote',
      url: 'https://twitter.com/luca/status/1158685783970275329',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-06',
      dayOfWeek: 2,
      id: '1158677047063011334',
      language: 'English',
      length: 289,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@adamfleming',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/adamfleming" data-screen-name="adamfleming" target="_blank" rel="nofollow">@adamfleming</a>: An EU negotiator told EU27 diplomats this morning “With just weeks to go [until Brexit] we are back where we were three ye…',
      timeOfDay: '11:00',
      timestamp: '2019-08-06T09:51:49.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1158677047063011334',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-06',
      dayOfWeek: 2,
      id: '1158675395409039360',
      language: 'English',
      length: 397,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@achquatsch',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/AchQuatsch" data-screen-name="AchQuatsch" target="_blank" rel="nofollow">@AchQuatsch</a> Would that change something besides making it less efficient?\n\nI could have people press a button for e… <a href="https://t.co/i9Qo2pb0a2" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/i9Qo2pb0a2</a>',
      timeOfDay: '11:00',
      timestamp: '2019-08-06T09:45:16.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1158675395409039360',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-06',
      dayOfWeek: 2,
      id: '1158663852076277760',
      language: 'English',
      length: 254,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text:
        'What&#39;s your opinion on this? If it violates the rule, how could it be improved to not violate them anymore?\n\nElse I… <a href="https://t.co/1DKENZx0eA" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/1DKENZx0eA</a>',
      timeOfDay: '10:00',
      timestamp: '2019-08-06T08:59:23.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1158663852076277760',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-06',
      dayOfWeek: 2,
      id: '1158663850734104582',
      language: 'English',
      length: 258,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text:
        'I&#39;d argue that the tool does not add users in an indiscriminate manner, because it&#39;s a specific group of users (fol… <a href="https://t.co/ySxMTp3nsy" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/ySxMTp3nsy</a>',
      timeOfDay: '10:00',
      timestamp: '2019-08-06T08:59:23.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1158663850734104582',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-06',
      dayOfWeek: 2,
      id: '1158663846585876480',
      language: 'English',
      length: 360,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text:
        'I was informed (<a href="https://t.co/PefTM51vyJ" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/PefTM51vyJ</a>) that this may violate the Twitter automation rules.\n\n„You may not add Twit… <a href="https://t.co/kfRwjEsbNo" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/kfRwjEsbNo</a>',
      timeOfDay: '10:00',
      timestamp: '2019-08-06T08:59:22.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1158663846585876480',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['reddit.com', 'twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-06',
      dayOfWeek: 2,
      id: '1158613484621484032',
      language: 'English',
      length: 587,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@jasonbaumgartne',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/jasonbaumgartne" data-screen-name="jasonbaumgartne" target="_blank" rel="nofollow">@jasonbaumgartne</a>: How a <a href="https://twitter.com/search?q=%23meme" title="#meme" class="text-brand hover:text-brand-dark" target="_blank" rel="nofollow">#meme</a> gets born and raised on Twitter. Number of tweets mentioning &quot;feral hogs&quot; (five minute windows).\n\n<a href="https://twitter.com/search?q=%23feralho" title="#feralho" class="text-brand hover:text-brand-dark" target="_blank" rel="nofollow">#feralho</a>…',
      timeOfDay: '07:00',
      timestamp: '2019-08-06T05:39:15.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1158613484621484032',
      usedApp: 'Twitter for Android',
      usedHashtags: ['#meme'],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-06',
      dayOfWeek: 2,
      id: '1158609992448118785',
      language: 'English',
      length: 581,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@im__jane',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/im__jane" data-screen-name="im__jane" target="_blank" rel="nofollow">@im__jane</a>: We&#39;re researchers from <a class="text-brand hover:text-brand-dark" href="https://twitter.com/UMich" data-screen-name="UMich" target="_blank" rel="nofollow">@UMich</a> &amp;amp; <a class="text-brand hover:text-brand-dark" href="https://twitter.com/GeorgiaTech" data-screen-name="GeorgiaTech" target="_blank" rel="nofollow">@GeorgiaTech</a>, building a tool for improving social signals on Twitter that can help people wh…',
      timeOfDay: '07:00',
      timestamp: '2019-08-06T05:25:22.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1158609992448118785',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-06',
      dayOfWeek: 2,
      id: '1158594686971518976',
      language: 'English',
      length: 270,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@jessienyc',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/JessieNYC" data-screen-name="JessieNYC" target="_blank" rel="nofollow">@JessieNYC</a>: Here are a few highlights, in summation, about Things I Often Say, about the current White Supremacist Moment:',
      timeOfDay: '06:00',
      timestamp: '2019-08-06T04:24:33.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1158594686971518976',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-06',
      dayOfWeek: 2,
      id: '1158593681131220993',
      language: 'English',
      length: 293,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@pewinternet',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/pewinternet" data-screen-name="pewinternet" target="_blank" rel="nofollow">@pewinternet</a>: Share of teens who say social media makes them feel more…\n\n👍 Included 71%\n👎 Excluded 25% \n\n👍 Confident 69% \n👎 Insecure 26%…',
      timeOfDay: '06:00',
      timestamp: '2019-08-06T04:20:33.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1158593681131220993',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-05',
      dayOfWeek: 1,
      id: '1158453224367636480',
      language: 'English',
      length: 703,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@k_ramali',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/k_ramali" data-screen-name="k_ramali" target="_blank" rel="nofollow">@k_ramali</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/IgorBrigadir" data-screen-name="IgorBrigadir" target="_blank" rel="nofollow">@IgorBrigadir</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/parismartineau" data-screen-name="parismartineau" target="_blank" rel="nofollow">@parismartineau</a> According to the devs cited in the article it&#39;s not time based, but limited… <a href="https://t.co/4S5kQxJe8S" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/4S5kQxJe8S</a>',
      timeOfDay: '21:00',
      timestamp: '2019-08-05T19:02:26.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1158453224367636480',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-05',
      dayOfWeek: 1,
      id: '1158452344255864832',
      language: 'English',
      length: 699,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@igorbrigadir',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/IgorBrigadir" data-screen-name="IgorBrigadir" target="_blank" rel="nofollow">@IgorBrigadir</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/parismartineau" data-screen-name="parismartineau" target="_blank" rel="nofollow">@parismartineau</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/k_ramali" data-screen-name="k_ramali" target="_blank" rel="nofollow">@k_ramali</a> Just documenting phantom likes for future generations.\n\nThere was that acco… <a href="https://t.co/wtUex2YKTC" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/wtUex2YKTC</a>',
      timeOfDay: '20:00',
      timestamp: '2019-08-05T18:58:56.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1158452344255864832',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-05',
      dayOfWeek: 1,
      id: '1158450981811752962',
      language: 'English',
      length: 699,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@igorbrigadir',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/IgorBrigadir" data-screen-name="IgorBrigadir" target="_blank" rel="nofollow">@IgorBrigadir</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/parismartineau" data-screen-name="parismartineau" target="_blank" rel="nofollow">@parismartineau</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/k_ramali" data-screen-name="k_ramali" target="_blank" rel="nofollow">@k_ramali</a> You solved it. Instead of just removing the fav, they have to fav it first.… <a href="https://t.co/38jjIGyqCb" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/38jjIGyqCb</a>',
      timeOfDay: '20:00',
      timestamp: '2019-08-05T18:53:31.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1158450981811752962',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-05',
      dayOfWeek: 1,
      id: '1158441956990697472',
      language: 'English',
      length: 277,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@qch3n',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/qch3n" data-screen-name="qch3n" target="_blank" rel="nofollow">@qch3n</a>: 1) The core motivation for a right to privacy seems basically to come from people in a position of privilege wanting to remove t…',
      timeOfDay: '20:00',
      timestamp: '2019-08-05T18:17:40.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1158441956990697472',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-05',
      dayOfWeek: 1,
      id: '1158441695685595144',
      language: 'English',
      length: 434,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@qch3n',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/qch3n" data-screen-name="qch3n" target="_blank" rel="nofollow">@qch3n</a>: I&#39;ve been looking into the &quot;history&quot; of how we understand <a href="https://twitter.com/search?q=%23privacy" title="#privacy" class="text-brand hover:text-brand-dark" target="_blank" rel="nofollow">#privacy</a> for my thesis recently and the way the term is used over time…',
      timeOfDay: '20:00',
      timestamp: '2019-08-05T18:16:37.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1158441695685595144',
      usedApp: 'Twitter for Android',
      usedHashtags: ['#privacy'],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-05',
      dayOfWeek: 1,
      id: '1158433404762886145',
      language: 'English',
      length: 217,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text:
        'DDoS protection for hate content on leased infrastructure does not work that well.\n\n<a href="https://t.co/S2lZZ6dLvw" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/S2lZZ6dLvw</a>',
      timeOfDay: '19:00',
      timestamp: '2019-08-05T17:43:41.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1158433404762886145',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-05',
      dayOfWeek: 1,
      id: '1158403585136353282',
      language: 'English',
      length: 554,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/sterlingcrispin" data-screen-name="sterlingcrispin" target="_blank" rel="nofollow">@sterlingcrispin</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/glitch" data-screen-name="glitch" target="_blank" rel="nofollow">@glitch</a> Currently it&#39;s optimized in a way that it doesn&#39;t even know the names of the accounts, jus… <a href="https://t.co/JQIponklTb" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/JQIponklTb</a>',
      timeOfDay: '17:00',
      timestamp: '2019-08-05T15:45:11.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1158403585136353282',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-05',
      dayOfWeek: 1,
      id: '1158403229463535619',
      language: 'English',
      length: 545,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@sterlingcrispin',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/sterlingcrispin" data-screen-name="sterlingcrispin" target="_blank" rel="nofollow">@sterlingcrispin</a> Sounds interesting.\n\nWhen you click the button on the top right and then remix, <a class="text-brand hover:text-brand-dark" href="https://twitter.com/glitch" data-screen-name="glitch" target="_blank" rel="nofollow">@glitch</a> instantly… <a href="https://t.co/TS1LnK79H4" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/TS1LnK79H4</a>',
      timeOfDay: '17:00',
      timestamp: '2019-08-05T15:43:46.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1158403229463535619',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-05',
      dayOfWeek: 1,
      id: '1158402011999719431',
      language: 'English',
      length: 303,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: '@voxility',
      repliedUser: null,
      retweetedUser: null,
      text:
        'That was fast. <a href="https://twitter.com/search?q=%23hatechan" title="#hatechan" class="text-brand hover:text-brand-dark" target="_blank" rel="nofollow">#hatechan</a> <a href="https://t.co/9F4wTNYrCw" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/9F4wTNYrCw</a>',
      timeOfDay: '17:00',
      timestamp: '2019-08-05T15:38:56.000Z',
      type: 'Quote',
      url: 'https://twitter.com/luca/status/1158402011999719431',
      usedApp: 'Twitter for Android',
      usedHashtags: ['#hatechan'],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-05',
      dayOfWeek: 1,
      id: '1158401069526990848',
      language: 'English',
      length: 552,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@parismartineau',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/parismartineau" data-screen-name="parismartineau" target="_blank" rel="nofollow">@parismartineau</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/k_ramali" data-screen-name="k_ramali" target="_blank" rel="nofollow">@k_ramali</a> If nobody can remove them because nobody can access them, doesn&#39;t that mean that they are… <a href="https://t.co/WyCpBMgPpU" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/WyCpBMgPpU</a>',
      timeOfDay: '17:00',
      timestamp: '2019-08-05T15:35:11.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1158401069526990848',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-05',
      dayOfWeek: 1,
      id: '1158378682593009664',
      language: 'German',
      length: 552,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@alexkaterdemos',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/alexkaterdemos" data-screen-name="alexkaterdemos" target="_blank" rel="nofollow">@alexkaterdemos</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/SimonHurtz" data-screen-name="SimonHurtz" target="_blank" rel="nofollow">@SimonHurtz</a> Hier wird auf einen Fall verwiesen, wo ein Gericht einen Betreiber verpflichtet hat ein… <a href="https://t.co/5xTYxXFFn9" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/5xTYxXFFn9</a>',
      timeOfDay: '16:00',
      timestamp: '2019-08-05T14:06:14.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1158378682593009664',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-05',
      dayOfWeek: 1,
      id: '1158375759364448257',
      language: 'German',
      length: 423,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@simonhurtz',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/SimonHurtz" data-screen-name="SimonHurtz" target="_blank" rel="nofollow">@SimonHurtz</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/alexkaterdemos" data-screen-name="alexkaterdemos" target="_blank" rel="nofollow">@alexkaterdemos</a> Gab es dann nicht ein Urteil, dass Betreiber von Pages für Kommentare mitverantwortlich sind?',
      timeOfDay: '15:00',
      timestamp: '2019-08-05T13:54:37.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1158375759364448257',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-05',
      dayOfWeek: 1,
      id: '1158368116809904128',
      language: 'German',
      length: 554,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@thetruemilhouse',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/thetruemilhouse" data-screen-name="thetruemilhouse" target="_blank" rel="nofollow">@thetruemilhouse</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/JuergenKuehner" data-screen-name="JuergenKuehner" target="_blank" rel="nofollow">@JuergenKuehner</a> Twitter testet gerade, ob Listen stärker ob den Mittelpunkt rücken sollen.\n\n<a href="https://t.co/B35UeSYxxB" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/B35UeSYxxB</a>',
      timeOfDay: '15:00',
      timestamp: '2019-08-05T13:24:15.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1158368116809904128',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-05',
      dayOfWeek: 1,
      id: '1158338737790738433',
      language: 'English',
      length: 232,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@moeffju',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/moeffju" data-screen-name="moeffju" target="_blank" rel="nofollow">@moeffju</a> I didn&#39;t know about that project. From a first look, it does exactly the same.',
      timeOfDay: '13:00',
      timestamp: '2019-08-05T11:27:30.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1158338737790738433',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-05',
      dayOfWeek: 1,
      id: '1158302267562479617',
      language: 'English',
      length: 275,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@luca',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/luca" data-screen-name="luca" target="_blank" rel="nofollow">@luca</a>: How do you find threads of images without text on Twitter?\n\nCurrent approach:\nfilter:follows [only from people I follow]\nfilter:m…',
      timeOfDay: '11:00',
      timestamp: '2019-08-05T09:02:35.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1158302267562479617',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-05',
      dayOfWeek: 1,
      id: '1158291578395471872',
      language: 'English',
      length: 202,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text:
        'I added frequently given answers. Let me know if I missed something. <a href="https://t.co/dQ82HhvZYW" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/dQ82HhvZYW</a>',
      timeOfDay: '10:00',
      timestamp: '2019-08-05T08:20:06.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1158291578395471872',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-05',
      dayOfWeek: 1,
      id: '1158280206790139905',
      language: 'German',
      length: 530,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/mbukowski" data-screen-name="mbukowski" target="_blank" rel="nofollow">@mbukowski</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/writingwoman" data-screen-name="writingwoman" target="_blank" rel="nofollow">@writingwoman</a> Hat nur ein Jahr und vier Tage gedauert, jetzt fügt es alle Accounts hinzu. <a href="https://t.co/ffGAoydQo3" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/ffGAoydQo3</a>',
      timeOfDay: '09:00',
      timestamp: '2019-08-05T07:34:55.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1158280206790139905',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-05',
      dayOfWeek: 1,
      id: '1158279974870294528',
      language: 'German',
      length: 320,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@sigi_maurer',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/sigi_maurer" data-screen-name="sigi_maurer" target="_blank" rel="nofollow">@sigi_maurer</a> Funktioniert nun wieder. <a href="https://t.co/ffGAoydQo3" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/ffGAoydQo3</a>',
      timeOfDay: '09:00',
      timestamp: '2019-08-05T07:34:00.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1158279974870294528',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-05',
      dayOfWeek: 1,
      id: '1158249383135207424',
      language: 'English',
      length: 422,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@anildash',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/anildash" data-screen-name="anildash" target="_blank" rel="nofollow">@anildash</a>: Such a handy <a class="text-brand hover:text-brand-dark" href="https://twitter.com/Glitch" data-screen-name="Glitch" target="_blank" rel="nofollow">@Glitch</a> app! If you want to manage your follows better (including unfollowing a lot of folks), this will add all…',
      timeOfDay: '07:00',
      timestamp: '2019-08-05T05:32:26.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1158249383135207424',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-04',
      dayOfWeek: 0,
      id: '1158096009899253760',
      language: 'English',
      length: 253,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: null,
      text:
        'I made* a little tool to add everyone you follow to a list. \n\nUse cases:\n- Chronological timeline like it&#39;s 2009\n-… <a href="https://t.co/BE0mQVcZPR" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/BE0mQVcZPR</a>',
      timeOfDay: '21:00',
      timestamp: '2019-08-04T19:22:59.000Z',
      type: 'Tweet',
      url: 'https://twitter.com/luca/status/1158096009899253760',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-04',
      dayOfWeek: 0,
      id: '1158092128062492672',
      language: 'English',
      length: 262,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text:
        'It was my fault. I didn&#39;t consider that Javascript can&#39;t handle all Twitter IDs.\n\nProblem was solved by adding &#39;str… <a href="https://t.co/0Zi1Lpl6Lc" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/0Zi1Lpl6Lc</a>',
      timeOfDay: '21:00',
      timestamp: '2019-08-04T19:07:34.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1158092128062492672',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-04',
      dayOfWeek: 0,
      id: '1158074947127975936',
      language: 'English',
      length: 705,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@thenickmon',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/TheNickMon" data-screen-name="TheNickMon" target="_blank" rel="nofollow">@TheNickMon</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/jasonbaumgartne" data-screen-name="jasonbaumgartne" target="_blank" rel="nofollow">@jasonbaumgartne</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/IgorBrigadir" data-screen-name="IgorBrigadir" target="_blank" rel="nofollow">@IgorBrigadir</a> I tried it with 1800 accounts. The weird thing is that I started with /c… <a href="https://t.co/4N2PdP4yHd" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/4N2PdP4yHd</a>',
      timeOfDay: '19:00',
      timestamp: '2019-08-04T17:59:18.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1158074947127975936',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-04',
      dayOfWeek: 0,
      id: '1158066235608850433',
      language: 'English',
      length: 405,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@igorbrigadir',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/IgorBrigadir" data-screen-name="IgorBrigadir" target="_blank" rel="nofollow">@IgorBrigadir</a> The list is already private, because I don&#39;t want to annoy people with notifications.\n\nI ran it multi… <a href="https://t.co/6sDXVaRTrz" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/6sDXVaRTrz</a>',
      timeOfDay: '19:00',
      timestamp: '2019-08-04T17:24:41.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1158066235608850433',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-04',
      dayOfWeek: 0,
      id: '1158056627557359616',
      language: 'English',
      length: 270,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: null,
      text:
        'Has anyone experienced this Twitter API bug?\n\n&#39;lists/members/create&#39; works fine.\n&#39;lists/members/create_all&#39; doesn&#39;t… <a href="https://t.co/cJGUCYgTYN" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/cJGUCYgTYN</a>',
      timeOfDay: '18:00',
      timestamp: '2019-08-04T16:46:30.000Z',
      type: 'Tweet',
      url: 'https://twitter.com/luca/status/1158056627557359616',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-04',
      dayOfWeek: 0,
      id: '1158032205391769601',
      language: 'English',
      length: 291,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@limerick1914',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/Limerick1914" data-screen-name="Limerick1914" target="_blank" rel="nofollow">@Limerick1914</a>: This research on how neo-fascists were using the internet to spread propaganda and recruit new members was published *twe…',
      timeOfDay: '17:00',
      timestamp: '2019-08-04T15:09:27.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1158032205391769601',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-04',
      dayOfWeek: 0,
      id: '1158006479703687168',
      language: 'English',
      length: 301,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@iyad_elbaghdadi',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/iyad_elbaghdadi" data-screen-name="iyad_elbaghdadi" target="_blank" rel="nofollow">@iyad_elbaghdadi</a>: The grand enemy of liberalism isn&#39;t a certain ideology or culture or organization - the grand enemy is fear. When peop…',
      timeOfDay: '15:00',
      timestamp: '2019-08-04T13:27:14.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1158006479703687168',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-04',
      dayOfWeek: 0,
      id: '1157995960431198208',
      language: 'German',
      length: 515,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@lavievagabonde',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/LaVieVagabonde" data-screen-name="LaVieVagabonde" target="_blank" rel="nofollow">@LaVieVagabonde</a> Ich sollte endlich <a href="https://t.co/5upfjRrsBx" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/5upfjRrsBx</a> reparieren. Damit kann man alle Followings in eine Liste… <a href="https://t.co/F1G0ORZuxM" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/F1G0ORZuxM</a>',
      timeOfDay: '14:00',
      timestamp: '2019-08-04T12:45:26.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1157995960431198208',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['follow-management.glitch.me', 'twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-04',
      dayOfWeek: 0,
      id: '1157992580006719490',
      language: 'German',
      length: 704,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@contre__attaque',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/contre__attaque" data-screen-name="contre__attaque" target="_blank" rel="nofollow">@contre__attaque</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/comuna_ciurea" data-screen-name="comuna_ciurea" target="_blank" rel="nofollow">@comuna_ciurea</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/Adipoesie" data-screen-name="Adipoesie" target="_blank" rel="nofollow">@Adipoesie</a> Ich wurde gefragt, ob ich ein Interview zu Hass im Netz geben würde und… <a href="https://t.co/S1mXp2cg8P" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/S1mXp2cg8P</a>',
      timeOfDay: '14:00',
      timestamp: '2019-08-04T12:32:00.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1157992580006719490',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-04',
      dayOfWeek: 0,
      id: '1157953159299575809',
      language: 'English',
      length: 283,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@iwriteok',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/IwriteOK" data-screen-name="IwriteOK" target="_blank" rel="nofollow">@IwriteOK</a>: The El Paso shooting is the third 8chan shooting, and an example of the gamification of terrorism. My latest article: https:/…',
      timeOfDay: '11:00',
      timestamp: '2019-08-04T09:55:21.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1157953159299575809',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-04',
      dayOfWeek: 0,
      id: '1157947712987455488',
      language: 'English',
      length: 360,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text:
        'You can watch my talk about analyzing Twitter outrage with an English audio track here: <a href="https://t.co/NozwKvS2YX" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/NozwKvS2YX</a> (st… <a href="https://t.co/gx7ROP7eqY" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/gx7ROP7eqY</a>',
      timeOfDay: '11:00',
      timestamp: '2019-08-04T09:33:43.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1157947712987455488',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['www.youtube.com', 'twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-04',
      dayOfWeek: 0,
      id: '1157899084516274176',
      language: 'English',
      length: 289,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@m_nsiddique',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/m_nsiddique" data-screen-name="m_nsiddique" target="_blank" rel="nofollow">@m_nsiddique</a>: TweetedAt: Finding Tweet Timestamps for Pre- and Post-Snowflake Tweet IDs\n\nWhy use TweetedAt?\n*  find timestamp of any twe…',
      timeOfDay: '08:00',
      timestamp: '2019-08-04T06:20:29.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1157899084516274176',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-03',
      dayOfWeek: 6,
      id: '1157771695517835265',
      language: 'English',
      length: 287,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@supernodal',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/supernodal" data-screen-name="supernodal" target="_blank" rel="nofollow">@supernodal</a>: My view that all advertisement is misinformation maybe deserves debate, but twitter is literally profiting off of selling m…',
      timeOfDay: '23:00',
      timestamp: '2019-08-03T21:54:17.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1157771695517835265',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-03',
      dayOfWeek: 6,
      id: '1157762221046423556',
      language: 'English',
      length: 203,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@tinycarebot',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/tinycarebot" data-screen-name="tinycarebot" target="_blank" rel="nofollow">@tinycarebot</a>: 💗: send a message to a friend please',
      timeOfDay: '23:00',
      timestamp: '2019-08-03T21:16:38.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1157762221046423556',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-03',
      dayOfWeek: 6,
      id: '1157761454629670912',
      language: 'English',
      length: 254,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@kitstudying',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/kitstudying" data-screen-name="kitstudying" target="_blank" rel="nofollow">@kitstudying</a> Thankfully the next iteration won&#39;t have ads and therefore no need to follow me anymore.',
      timeOfDay: '23:00',
      timestamp: '2019-08-03T21:13:35.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1157761454629670912',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-03',
      dayOfWeek: 6,
      id: '1157757745493348353',
      language: 'English',
      length: 280,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@zeynep',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/zeynep" data-screen-name="zeynep" target="_blank" rel="nofollow">@zeynep</a>: Another mass shooter,  apparently another hateful manifesto. 😢Media: IT IS possible to cover his hateful ideology without using…',
      timeOfDay: '22:00',
      timestamp: '2019-08-03T20:58:51.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1157757745493348353',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-03',
      dayOfWeek: 6,
      id: '1157755352894644226',
      language: 'English',
      length: 250,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: '@wongmjane',
      repliedUser: null,
      retweetedUser: null,
      text:
        'I want all products to have a full chain of ownership on them.\n\nWhich company is responsible and which companies/pe… <a href="https://t.co/QUrqowTZqK" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/QUrqowTZqK</a>',
      timeOfDay: '22:00',
      timestamp: '2019-08-03T20:49:20.000Z',
      type: 'Quote',
      url: 'https://twitter.com/luca/status/1157755352894644226',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-03',
      dayOfWeek: 6,
      id: '1157753357110513664',
      language: 'English',
      length: 196,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text:
        'This is just a less extreme version of unfollowing everybody.\n\n<a href="https://t.co/WAMy0nWg0N" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/WAMy0nWg0N</a>',
      timeOfDay: '22:00',
      timestamp: '2019-08-03T20:41:25.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1157753357110513664',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['anildash.com'],
      username: 'luca',
    },
    {
      date: '2019-08-03',
      dayOfWeek: 6,
      id: '1157752877189914624',
      language: 'English',
      length: 192,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@soulcutter',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/soulcutter" data-screen-name="soulcutter" target="_blank" rel="nofollow">@soulcutter</a> You too. Thanks again for caring.',
      timeOfDay: '22:00',
      timestamp: '2019-08-03T20:39:30.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1157752877189914624',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-03',
      dayOfWeek: 6,
      id: '1157751868489158656',
      language: 'English',
      length: 401,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@soulcutter',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/soulcutter" data-screen-name="soulcutter" target="_blank" rel="nofollow">@soulcutter</a> I am sorry.\n\nThank you for asking. Yes, I am fine. I would say I&#39;m feeling better than I ever did. I am… <a href="https://t.co/PmJNSo5p3S" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/PmJNSo5p3S</a>',
      timeOfDay: '22:00',
      timestamp: '2019-08-03T20:35:30.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1157751868489158656',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-03',
      dayOfWeek: 6,
      id: '1157749388070047746',
      language: 'English',
      length: 245,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: '@swiftonsecurity',
      repliedUser: null,
      retweetedUser: null,
      text:
        'Your Tweets are a stage. By replying to troll comments, you put the spotlight on them.\n\nNot always a bad thing. <a href="https://t.co/yqWUF0AvUf" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/yqWUF0AvUf</a>',
      timeOfDay: '22:00',
      timestamp: '2019-08-03T20:25:38.000Z',
      type: 'Quote',
      url: 'https://twitter.com/luca/status/1157749388070047746',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-03',
      dayOfWeek: 6,
      id: '1157746787068928004',
      language: 'English',
      length: 136,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: null,
      text:
        'I&#39;m serious. Unfollow me. If you miss my Tweets one day, come back and follow me again. If not, enjoy your timeline with less noise.',
      timeOfDay: '22:00',
      timestamp: '2019-08-03T20:15:18.000Z',
      type: 'Tweet',
      url: 'https://twitter.com/luca/status/1157746787068928004',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-03',
      dayOfWeek: 6,
      id: '1157745293556899842',
      language: 'German',
      length: 540,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/NiklasCodes" data-screen-name="NiklasCodes" target="_blank" rel="nofollow">@NiklasCodes</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/Oldperl" data-screen-name="Oldperl" target="_blank" rel="nofollow">@Oldperl</a> (So viele Pläne, so wenig Zeit. Ich möchte es Leuten ermöglichen, ihre Cluster zu sehen, dami… <a href="https://t.co/f2fxntHmK5" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/f2fxntHmK5</a>',
      timeOfDay: '22:00',
      timestamp: '2019-08-03T20:09:22.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1157745293556899842',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-03',
      dayOfWeek: 6,
      id: '1157744305005572096',
      language: 'German',
      length: 540,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@niklascodes',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/NiklasCodes" data-screen-name="NiklasCodes" target="_blank" rel="nofollow">@NiklasCodes</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/Oldperl" data-screen-name="Oldperl" target="_blank" rel="nofollow">@Oldperl</a> Blasen sind eine schlechte Analogie. Es würde bedeuten, dass es eine Gruppe von Accounts gibt… <a href="https://t.co/z9664rwKyN" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/z9664rwKyN</a>',
      timeOfDay: '22:00',
      timestamp: '2019-08-03T20:05:26.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1157744305005572096',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-03',
      dayOfWeek: 6,
      id: '1157740701649637377',
      language: 'English',
      length: 224,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@luca',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/luca" data-screen-name="luca" target="_blank" rel="nofollow">@luca</a>: It&#39;s time to unfollow me. Please choose one of the people I follow instead.',
      timeOfDay: '21:00',
      timestamp: '2019-08-03T19:51:07.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1157740701649637377',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-03',
      dayOfWeek: 6,
      id: '1157739816735453184',
      language: 'English',
      length: 160,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@luca',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/luca" data-screen-name="luca" target="_blank" rel="nofollow">@luca</a>: . Unfollow me .',
      timeOfDay: '21:00',
      timestamp: '2019-08-03T19:47:36.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1157739816735453184',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-03',
      dayOfWeek: 6,
      id: '1157699752655560705',
      language: 'English',
      length: 73,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text: 'Correction: They all have two patties. Not sure why I believed otherwise.',
      timeOfDay: '19:00',
      timestamp: '2019-08-03T17:08:24.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1157699752655560705',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-03',
      dayOfWeek: 6,
      id: '1157699518625996807',
      language: 'English',
      length: 250,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text:
        '„Next Level Burger“ by Vefo. Available at Lidl for 3€ (2 bigger patties instead of four like the other ones). The p… <a href="https://t.co/3bKOWk6KhA" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/3bKOWk6KhA</a>',
      timeOfDay: '19:00',
      timestamp: '2019-08-03T17:07:28.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1157699518625996807',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-03',
      dayOfWeek: 6,
      id: '1157685830233837571',
      language: 'English',
      length: 318,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@flarup',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/flarup" data-screen-name="flarup" target="_blank" rel="nofollow">@flarup</a>: There. I fixed the Pizza Emoji 😛 <a href="https://t.co/4RXcWx3haO" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/4RXcWx3haO</a>',
      timeOfDay: '18:00',
      timestamp: '2019-08-03T16:13:05.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1157685830233837571',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-03',
      dayOfWeek: 6,
      id: '1157572609653059585',
      language: 'English',
      length: 539,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@lv__eu',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/LV__eu" data-screen-name="LV__eu" target="_blank" rel="nofollow">@LV__eu</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/DB_Bahn" data-screen-name="DB_Bahn" target="_blank" rel="nofollow">@DB_Bahn</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/Colibri_by_FZI" data-screen-name="Colibri_by_FZI" target="_blank" rel="nofollow">@Colibri_by_FZI</a> I&#39;d have hoped that&#39;s information they already have internally.',
      timeOfDay: '10:00',
      timestamp: '2019-08-03T08:43:11.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1157572609653059585',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-03',
      dayOfWeek: 6,
      id: '1157541945893359616',
      language: 'English',
      length: 349,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@lv__eu',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/LV__eu" data-screen-name="LV__eu" target="_blank" rel="nofollow">@LV__eu</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/DB_Bahn" data-screen-name="DB_Bahn" target="_blank" rel="nofollow">@DB_Bahn</a> Yes. I should have been more precise and less jokey.',
      timeOfDay: '08:00',
      timestamp: '2019-08-03T06:41:20.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1157541945893359616',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-03',
      dayOfWeek: 6,
      id: '1157538921728942080',
      language: 'English',
      length: 536,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@lv__eu',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/LVgermany" data-screen-name="LVgermany" target="_blank" rel="nofollow">@LVgermany</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/DB_Bahn" data-screen-name="DB_Bahn" target="_blank" rel="nofollow">@DB_Bahn</a> Helping passengers with deciding what to wear. Some ICEs have their AC on full blast, other tim… <a href="https://t.co/OD65M8xO1T" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/OD65M8xO1T</a>',
      timeOfDay: '08:00',
      timestamp: '2019-08-03T06:29:19.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1157538921728942080',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-03',
      dayOfWeek: 6,
      id: '1157535565832736768',
      language: 'English',
      length: 273,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@db_bahn',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/DB_Bahn" data-screen-name="DB_Bahn" target="_blank" rel="nofollow">@DB_Bahn</a> Can we get a weather forecast for trains in the app? Something like average temperature of last 3 hours and/or current one?',
      timeOfDay: '08:00',
      timestamp: '2019-08-03T06:15:59.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1157535565832736768',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-02',
      dayOfWeek: 5,
      id: '1157409473071341569',
      language: 'English',
      length: 451,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@driven_by_data',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/driven_by_data" data-screen-name="driven_by_data" target="_blank" rel="nofollow">@driven_by_data</a>: As a data journalist who is also a 👨‍🌾gardener and concerned about the <a href="https://twitter.com/search?q=%23climatecrisis" title="#climatecrisis" class="text-brand hover:text-brand-dark" target="_blank" rel="nofollow">#climatecrisis</a>, I started to look into droughts…',
      timeOfDay: '23:00',
      timestamp: '2019-08-02T21:54:56.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1157409473071341569',
      usedApp: 'Twitter for Android',
      usedHashtags: ['#climatecrisis'],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-02',
      dayOfWeek: 5,
      id: '1157405163826626560',
      language: 'German',
      length: 376,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@comuna_ciurea',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/comuna_ciurea" data-screen-name="comuna_ciurea" target="_blank" rel="nofollow">@comuna_ciurea</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/Adipoesie" data-screen-name="Adipoesie" target="_blank" rel="nofollow">@Adipoesie</a> Visualisierung mit Gephi. Daten von der Twitter API.',
      timeOfDay: '23:00',
      timestamp: '2019-08-02T21:37:49.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1157405163826626560',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-02',
      dayOfWeek: 5,
      id: '1157326898692808704',
      language: 'English',
      length: 228,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: null,
      text:
        'TIL: In Germany you can start drinking beer at 14, when accompanied by a legal guardian. Why?\n\n<a href="https://t.co/sa7qjFNd39" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/sa7qjFNd39</a>',
      timeOfDay: '18:00',
      timestamp: '2019-08-02T16:26:49.000Z',
      type: 'Tweet',
      url: 'https://twitter.com/luca/status/1157326898692808704',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['www.jugendschutz-aktiv.de'],
      username: 'luca',
    },
    {
      date: '2019-08-02',
      dayOfWeek: 5,
      id: '1157287188956143617',
      language: 'English',
      length: 407,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: '@twitterdev',
      repliedUser: null,
      retweetedUser: null,
      text:
        'Quote Tweet counts are now available in the Twitter Labs API.\n\nbrb updating* <a class="text-brand hover:text-brand-dark" href="https://twitter.com/accountanalysis" data-screen-name="accountanalysis" target="_blank" rel="nofollow">@accountanalysis</a>.\n\n*Rewriting from scr… <a href="https://t.co/e9BbywdHZ1" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/e9BbywdHZ1</a>',
      timeOfDay: '15:00',
      timestamp: '2019-08-02T13:49:01.000Z',
      type: 'Quote',
      url: 'https://twitter.com/luca/status/1157287188956143617',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-02',
      dayOfWeek: 5,
      id: '1157257767188647937',
      language: 'English',
      length: 198,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@jasonbaumgartne',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/jasonbaumgartne" data-screen-name="jasonbaumgartne" target="_blank" rel="nofollow">@jasonbaumgartne</a> Google Pixel 3. 240 FPS.',
      timeOfDay: '13:00',
      timestamp: '2019-08-02T11:52:07.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1157257767188647937',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-02',
      dayOfWeek: 5,
      id: '1157256843565117440',
      language: 'German',
      length: 283,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@alexsta_',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/AlexSta_" data-screen-name="AlexSta_" target="_blank" rel="nofollow">@AlexSta_</a>: Meine zweite Kolumne habe ich für jede einzelne Süßmaus geschrieben, die sich in den letzten Tagen solidarisch gezeigt hat. A…',
      timeOfDay: '13:00',
      timestamp: '2019-08-02T11:48:26.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1157256843565117440',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-02',
      dayOfWeek: 5,
      id: '1157256618880438272',
      language: 'German',
      length: 293,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@markusreuter_',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/markusreuter_" data-screen-name="markusreuter_" target="_blank" rel="nofollow">@markusreuter_</a>: Der Hass kommt vor allem von rechts: Zwischen 80 und 90 Prozent aller Straftaten im Bereich Hasskriminalität gehen auf d…',
      timeOfDay: '13:00',
      timestamp: '2019-08-02T11:47:33.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1157256618880438272',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-02',
      dayOfWeek: 5,
      id: '1157249840767782918',
      language: 'English',
      length: 159,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text:
        'Spiralling heavy liftoff. <a href="https://t.co/H78OwEVxoF" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/H78OwEVxoF</a>',
      timeOfDay: '13:00',
      timestamp: '2019-08-02T11:20:37.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1157249840767782918',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-02',
      dayOfWeek: 5,
      id: '1157246330940592130',
      language: 'English',
      length: 206,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text:
        'Second round. You are not a hummingbird. Nearly crashed into the camera. <a href="https://t.co/aMrLYE5Aic" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/aMrLYE5Aic</a>',
      timeOfDay: '13:00',
      timestamp: '2019-08-02T11:06:40.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1157246330940592130',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-02',
      dayOfWeek: 5,
      id: '1157243896423993344',
      language: 'English',
      length: 168,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: null,
      text:
        'Wasp getting some mashed potatoes. <a href="https://t.co/SFMtId5jCB" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/SFMtId5jCB</a>',
      timeOfDay: '12:00',
      timestamp: '2019-08-02T10:57:00.000Z',
      type: 'Tweet',
      url: 'https://twitter.com/luca/status/1157243896423993344',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-02',
      dayOfWeek: 5,
      id: '1157241153806376960',
      language: 'German',
      length: 395,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@hhumorlos',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/HHumorlos" data-screen-name="HHumorlos" target="_blank" rel="nofollow">@HHumorlos</a> Ich habe mich hingesetzt und aufgeschrieben, was mir wichtig ist. Das dient mir als Orientierung.\n\nAnson… <a href="https://t.co/7lgIyFmDi5" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/7lgIyFmDi5</a>',
      timeOfDay: '12:00',
      timestamp: '2019-08-02T10:46:06.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1157241153806376960',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-02',
      dayOfWeek: 5,
      id: '1157232375471988736',
      language: 'English',
      length: 228,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@mihkal',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/mihkal" data-screen-name="mihkal" target="_blank" rel="nofollow">@mihkal</a> The last time i tried, I got mixed results. Sometimes ä matched ae, sometime not.',
      timeOfDay: '12:00',
      timestamp: '2019-08-02T10:11:13.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1157232375471988736',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-02',
      dayOfWeek: 5,
      id: '1157229914917134339',
      language: 'English',
      length: 206,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: null,
      text:
        'When did Twitter stop combining umlauts? Or did they never for hashtags? <a href="https://t.co/NlRh3BNn7u" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/NlRh3BNn7u</a>',
      timeOfDay: '12:00',
      timestamp: '2019-08-02T10:01:26.000Z',
      type: 'Tweet',
      url: 'https://twitter.com/luca/status/1157229914917134339',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-01',
      dayOfWeek: 4,
      id: '1157038953469530112',
      language: 'English',
      length: 287,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@josephfcox',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/josephfcox" data-screen-name="josephfcox" target="_blank" rel="nofollow">@josephfcox</a>: New: I spoke to the ordinary Americans who are running phone farms of ~hundreds of phones to commit ad fraud, generate mone…',
      timeOfDay: '23:00',
      timestamp: '2019-08-01T21:22:37.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1157038953469530112',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-01',
      dayOfWeek: 4,
      id: '1157022888509227010',
      language: 'German',
      length: 393,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@mellcolm',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/Mellcolm" data-screen-name="Mellcolm" target="_blank" rel="nofollow">@Mellcolm</a> Ich habe es nie so richtig hinbekommen mehreren Accounts gerecht zu werden.\n\nDark Account würde ich trotz… <a href="https://t.co/3rzLytc2pz" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/3rzLytc2pz</a>',
      timeOfDay: '22:00',
      timestamp: '2019-08-01T20:18:47.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1157022888509227010',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-01',
      dayOfWeek: 4,
      id: '1157016145867739136',
      language: 'English',
      length: 193,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: null,
      text:
        'Muting &quot;card_name:animated_gif&quot; doesn&#39;t work. <a href="https://t.co/IF5HTMWGDo" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/IF5HTMWGDo</a>',
      timeOfDay: '21:00',
      timestamp: '2019-08-01T19:52:00.000Z',
      type: 'Tweet',
      url: 'https://twitter.com/luca/status/1157016145867739136',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-01',
      dayOfWeek: 4,
      id: '1157014324050878465',
      language: 'English',
      length: 723,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@igorbrigadir',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/IgorBrigadir" data-screen-name="IgorBrigadir" target="_blank" rel="nofollow">@IgorBrigadir</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/jasonbaumgartne" data-screen-name="jasonbaumgartne" target="_blank" rel="nofollow">@jasonbaumgartne</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/wongmjane" data-screen-name="wongmjane" target="_blank" rel="nofollow">@wongmjane</a> I want an &quot;include:hidden_ads&quot;. &quot;source:twitter_ads&quot; is already nice. (Wa… <a href="https://t.co/NI7uYGMa4K" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/NI7uYGMa4K</a>',
      timeOfDay: '21:00',
      timestamp: '2019-08-01T19:44:45.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1157014324050878465',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-01',
      dayOfWeek: 4,
      id: '1157008581885157377',
      language: 'English',
      length: 19,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text: 'Not Retweets. URLs.',
      timeOfDay: '21:00',
      timestamp: '2019-08-01T19:21:56.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1157008581885157377',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-01',
      dayOfWeek: 4,
      id: '1157008490092793857',
      language: 'English',
      length: 250,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: null,
      text:
        'Highly automated account with the primary goal of pushing another account through Retweets. \n\nFound it by looking f… <a href="https://t.co/iaaXnRqeAC" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/iaaXnRqeAC</a>',
      timeOfDay: '21:00',
      timestamp: '2019-08-01T19:21:34.000Z',
      type: 'Tweet',
      url: 'https://twitter.com/luca/status/1157008490092793857',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-01',
      dayOfWeek: 4,
      id: '1157005748418875393',
      language: 'English',
      length: 214,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: null,
      text:
        'I just entered a search query into the Tweet field. That&#39;s how excited I am. <a href="https://t.co/8QitItQxh2" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/8QitItQxh2</a>',
      timeOfDay: '21:00',
      timestamp: '2019-08-01T19:10:41.000Z',
      type: 'Tweet',
      url: 'https://twitter.com/luca/status/1157005748418875393',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-01',
      dayOfWeek: 4,
      id: '1157003915294138369',
      language: 'English',
      length: 1123,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/test5f1798" data-screen-name="test5f1798" target="_blank" rel="nofollow">@test5f1798</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/_523d" data-screen-name="_523d" target="_blank" rel="nofollow">@_523d</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/akiko_lawson" data-screen-name="akiko_lawson" target="_blank" rel="nofollow">@akiko_lawson</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/VENETHIS" data-screen-name="VENETHIS" target="_blank" rel="nofollow">@VENETHIS</a> Found two more: <a class="text-brand hover:text-brand-dark" href="https://twitter.com/milkshake_20163" data-screen-name="milkshake_20163" target="_blank" rel="nofollow">@milkshake_20163</a> and <a class="text-brand hover:text-brand-dark" href="https://twitter.com/milkshake_20164" data-screen-name="milkshake_20164" target="_blank" rel="nofollow">@milkshake_20164</a>. <a href="https://t.co/QqYqmQ9ns2" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/QqYqmQ9ns2</a>',
      timeOfDay: '21:00',
      timestamp: '2019-08-01T19:03:24.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1157003915294138369',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-01',
      dayOfWeek: 4,
      id: '1157002954416803840',
      language: 'English',
      length: 254,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text:
        'After 11 years on Twitter I learned that it&#39;s possible to search for Tweets that came from a specific client. I rem… <a href="https://t.co/oOpMRGDzPe" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/oOpMRGDzPe</a>',
      timeOfDay: '20:00',
      timestamp: '2019-08-01T18:59:35.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1157002954416803840',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-01',
      dayOfWeek: 4,
      id: '1157001939567165441',
      language: 'English',
      length: 713,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@igorbrigadir',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/IgorBrigadir" data-screen-name="IgorBrigadir" target="_blank" rel="nofollow">@IgorBrigadir</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/jasonbaumgartne" data-screen-name="jasonbaumgartne" target="_blank" rel="nofollow">@jasonbaumgartne</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/wongmjane" data-screen-name="wongmjane" target="_blank" rel="nofollow">@wongmjane</a> You forgot &quot;include:nativeretweets&quot;. I created a pull request with it and… <a href="https://t.co/BDTEKCQUbV" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/BDTEKCQUbV</a>',
      timeOfDay: '20:00',
      timestamp: '2019-08-01T18:55:33.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1157001939567165441',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-01',
      dayOfWeek: 4,
      id: '1156975755563343872',
      language: 'English',
      length: 278,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@alexcomninos',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/alexcomninos" data-screen-name="alexcomninos" target="_blank" rel="nofollow">@alexcomninos</a>: Facebook is announcing the final Charter of its Oversight Board. I will try live tweet any important details.',
      timeOfDay: '19:00',
      timestamp: '2019-08-01T17:11:30.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1156975755563343872',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-01',
      dayOfWeek: 4,
      id: '1156936403126820864',
      language: 'English',
      length: 455,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@visualisingdata',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/visualisingdata" data-screen-name="visualisingdata" target="_blank" rel="nofollow">@visualisingdata</a> Try this one:\n<a href="https://t.co/TwBun9ujnC" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/TwBun9ujnC</a> <a href="https://t.co/KX6E6zFAyV" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/KX6E6zFAyV</a>',
      timeOfDay: '16:00',
      timestamp: '2019-08-01T14:35:08.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1156936403126820864',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['www.facebook.com'],
      username: 'luca',
    },
    {
      date: '2019-08-01',
      dayOfWeek: 4,
      id: '1156935732856074241',
      language: 'English',
      length: 291,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@visualisingdata',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/visualisingdata" data-screen-name="visualisingdata" target="_blank" rel="nofollow">@visualisingdata</a> You want to remove them from that tab on the page? (I assumed you wanted to stop them show up in your notifications.)',
      timeOfDay: '16:00',
      timestamp: '2019-08-01T14:32:28.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1156935732856074241',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-01',
      dayOfWeek: 4,
      id: '1156934181655592961',
      language: 'English',
      length: 1557,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/k3vk4" data-screen-name="k3vk4" target="_blank" rel="nofollow">@k3vk4</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/NiklasCodes" data-screen-name="NiklasCodes" target="_blank" rel="nofollow">@NiklasCodes</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/test5f1798" data-screen-name="test5f1798" target="_blank" rel="nofollow">@test5f1798</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/_523d" data-screen-name="_523d" target="_blank" rel="nofollow">@_523d</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/akiko_lawson" data-screen-name="akiko_lawson" target="_blank" rel="nofollow">@akiko_lawson</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/VENETHIS" data-screen-name="VENETHIS" target="_blank" rel="nofollow">@VENETHIS</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/Twitter" data-screen-name="Twitter" target="_blank" rel="nofollow">@Twitter</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/TwitterAPI" data-screen-name="TwitterAPI" target="_blank" rel="nofollow">@TwitterAPI</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/TwitterSupport" data-screen-name="TwitterSupport" target="_blank" rel="nofollow">@TwitterSupport</a> It seems like t… <a href="https://t.co/Zu475aqXPX" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/Zu475aqXPX</a>',
      timeOfDay: '16:00',
      timestamp: '2019-08-01T14:26:18.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1156934181655592961',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-01',
      dayOfWeek: 4,
      id: '1156932264175034368',
      language: 'English',
      length: 285,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@visualisingdata',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/visualisingdata" data-screen-name="visualisingdata" target="_blank" rel="nofollow">@visualisingdata</a> Where did you get that notification? (In the screenshot above I turned off notifications from a page I manage.)',
      timeOfDay: '16:00',
      timestamp: '2019-08-01T14:18:41.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1156932264175034368',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-01',
      dayOfWeek: 4,
      id: '1156931420792733697',
      language: 'German',
      length: 403,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@claudiazettel',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/ClaudiaZettel" data-screen-name="ClaudiaZettel" target="_blank" rel="nofollow">@ClaudiaZettel</a> Spekulation: Nachdem er die Kreditkartenabrechnung gefunden hat, hat er bemerkt, dass durch die nega… <a href="https://t.co/SoobGlSvM3" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/SoobGlSvM3</a>',
      timeOfDay: '16:00',
      timestamp: '2019-08-01T14:15:20.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1156931420792733697',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-01',
      dayOfWeek: 4,
      id: '1156930304654938113',
      language: 'English',
      length: 1557,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@k3vk4',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/k3vk4" data-screen-name="k3vk4" target="_blank" rel="nofollow">@k3vk4</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/NiklasCodes" data-screen-name="NiklasCodes" target="_blank" rel="nofollow">@NiklasCodes</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/test5f1798" data-screen-name="test5f1798" target="_blank" rel="nofollow">@test5f1798</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/_523d" data-screen-name="_523d" target="_blank" rel="nofollow">@_523d</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/akiko_lawson" data-screen-name="akiko_lawson" target="_blank" rel="nofollow">@akiko_lawson</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/VENETHIS" data-screen-name="VENETHIS" target="_blank" rel="nofollow">@VENETHIS</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/Twitter" data-screen-name="Twitter" target="_blank" rel="nofollow">@Twitter</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/TwitterAPI" data-screen-name="TwitterAPI" target="_blank" rel="nofollow">@TwitterAPI</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/TwitterSupport" data-screen-name="TwitterSupport" target="_blank" rel="nofollow">@TwitterSupport</a> Are you sure? I… <a href="https://t.co/HzKO8KTwXK" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/HzKO8KTwXK</a>',
      timeOfDay: '16:00',
      timestamp: '2019-08-01T14:10:54.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1156930304654938113',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-01',
      dayOfWeek: 4,
      id: '1156929218934136832',
      language: 'English',
      length: 517,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@visualisingdata',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/visualisingdata" data-screen-name="visualisingdata" target="_blank" rel="nofollow">@visualisingdata</a> Only works on your personal notifications: <a href="https://t.co/mXGO1mHOb8" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/mXGO1mHOb8</a>\n\nNot sure if there is something… <a href="https://t.co/kL4tPbeYYA" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/kL4tPbeYYA</a>',
      timeOfDay: '16:00',
      timestamp: '2019-08-01T14:06:35.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1156929218934136832',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['www.facebook.com', 'twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-01',
      dayOfWeek: 4,
      id: '1156928915421782016',
      language: 'English',
      length: 1557,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@niklascodes',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/NiklasCodes" data-screen-name="NiklasCodes" target="_blank" rel="nofollow">@NiklasCodes</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/k3vk4" data-screen-name="k3vk4" target="_blank" rel="nofollow">@k3vk4</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/test5f1798" data-screen-name="test5f1798" target="_blank" rel="nofollow">@test5f1798</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/_523d" data-screen-name="_523d" target="_blank" rel="nofollow">@_523d</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/akiko_lawson" data-screen-name="akiko_lawson" target="_blank" rel="nofollow">@akiko_lawson</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/VENETHIS" data-screen-name="VENETHIS" target="_blank" rel="nofollow">@VENETHIS</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/Twitter" data-screen-name="Twitter" target="_blank" rel="nofollow">@Twitter</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/TwitterAPI" data-screen-name="TwitterAPI" target="_blank" rel="nofollow">@TwitterAPI</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/TwitterSupport" data-screen-name="TwitterSupport" target="_blank" rel="nofollow">@TwitterSupport</a> That could tric… <a href="https://t.co/UN0gsc0UML" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/UN0gsc0UML</a>',
      timeOfDay: '16:00',
      timestamp: '2019-08-01T14:05:22.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1156928915421782016',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-01',
      dayOfWeek: 4,
      id: '1156928046752051201',
      language: 'English',
      length: 1557,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@niklascodes',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/NiklasCodes" data-screen-name="NiklasCodes" target="_blank" rel="nofollow">@NiklasCodes</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/k3vk4" data-screen-name="k3vk4" target="_blank" rel="nofollow">@k3vk4</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/test5f1798" data-screen-name="test5f1798" target="_blank" rel="nofollow">@test5f1798</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/_523d" data-screen-name="_523d" target="_blank" rel="nofollow">@_523d</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/akiko_lawson" data-screen-name="akiko_lawson" target="_blank" rel="nofollow">@akiko_lawson</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/VENETHIS" data-screen-name="VENETHIS" target="_blank" rel="nofollow">@VENETHIS</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/Twitter" data-screen-name="Twitter" target="_blank" rel="nofollow">@Twitter</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/TwitterAPI" data-screen-name="TwitterAPI" target="_blank" rel="nofollow">@TwitterAPI</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/TwitterSupport" data-screen-name="TwitterSupport" target="_blank" rel="nofollow">@TwitterSupport</a> I am quite cert… <a href="https://t.co/EfjJmOT1Kk" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/EfjJmOT1Kk</a>',
      timeOfDay: '16:00',
      timestamp: '2019-08-01T14:01:55.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1156928046752051201',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-01',
      dayOfWeek: 4,
      id: '1156927536825360393',
      language: 'English',
      length: 402,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@visualisingdata',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/visualisingdata" data-screen-name="visualisingdata" target="_blank" rel="nofollow">@visualisingdata</a> When you hover over them, there should appear a menu on the right where you can turn them off. <a href="https://t.co/X6aKWZ4lKS" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/X6aKWZ4lKS</a>',
      timeOfDay: '15:00',
      timestamp: '2019-08-01T13:59:54.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1156927536825360393',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-01',
      dayOfWeek: 4,
      id: '1156926914784837632',
      language: 'German',
      length: 320,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@wurstzombie',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/wurstzombie" data-screen-name="wurstzombie" target="_blank" rel="nofollow">@wurstzombie</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/NikoAlm" data-screen-name="NikoAlm" target="_blank" rel="nofollow">@NikoAlm</a> Krapfen.',
      timeOfDay: '15:00',
      timestamp: '2019-08-01T13:57:25.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1156926914784837632',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-01',
      dayOfWeek: 4,
      id: '1156900391893766144',
      language: 'German',
      length: 518,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@florianklenk',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/florianklenk" data-screen-name="florianklenk" target="_blank" rel="nofollow">@florianklenk</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/misik" data-screen-name="misik" target="_blank" rel="nofollow">@misik</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/MelisaErkurt" data-screen-name="MelisaErkurt" target="_blank" rel="nofollow">@MelisaErkurt</a> TikTok. Es heißt TikTok, nicht Videotwitter.',
      timeOfDay: '14:00',
      timestamp: '2019-08-01T12:12:02.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1156900391893766144',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-01',
      dayOfWeek: 4,
      id: '1156880850740490240',
      language: 'English',
      length: 652,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@_523d',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/_523d" data-screen-name="_523d" target="_blank" rel="nofollow">@_523d</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/test5f1798" data-screen-name="test5f1798" target="_blank" rel="nofollow">@test5f1798</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/akiko_lawson" data-screen-name="akiko_lawson" target="_blank" rel="nofollow">@akiko_lawson</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/VENETHIS" data-screen-name="VENETHIS" target="_blank" rel="nofollow">@VENETHIS</a> Good luck with whatever you do.',
      timeOfDay: '12:00',
      timestamp: '2019-08-01T10:54:23.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1156880850740490240',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-01',
      dayOfWeek: 4,
      id: '1156878758063890433',
      language: 'English',
      length: 812,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/_523d" data-screen-name="_523d" target="_blank" rel="nofollow">@_523d</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/test5f1798" data-screen-name="test5f1798" target="_blank" rel="nofollow">@test5f1798</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/akiko_lawson" data-screen-name="akiko_lawson" target="_blank" rel="nofollow">@akiko_lawson</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/VENETHIS" data-screen-name="VENETHIS" target="_blank" rel="nofollow">@VENETHIS</a> Here are some suggestions. Not sure if they work for you. <a href="https://t.co/cKL7aWXFcM" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/cKL7aWXFcM</a>',
      timeOfDay: '12:00',
      timestamp: '2019-08-01T10:46:04.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1156878758063890433',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-01',
      dayOfWeek: 4,
      id: '1156877585906905089',
      language: 'English',
      length: 828,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@_523d',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/_523d" data-screen-name="_523d" target="_blank" rel="nofollow">@_523d</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/test5f1798" data-screen-name="test5f1798" target="_blank" rel="nofollow">@test5f1798</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/akiko_lawson" data-screen-name="akiko_lawson" target="_blank" rel="nofollow">@akiko_lawson</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/VENETHIS" data-screen-name="VENETHIS" target="_blank" rel="nofollow">@VENETHIS</a> Macrodroid is a nice start into programming, but I find the account quit… <a href="https://t.co/GX9gtnFry5" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/GX9gtnFry5</a>',
      timeOfDay: '12:00',
      timestamp: '2019-08-01T10:41:24.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1156877585906905089',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-01',
      dayOfWeek: 4,
      id: '1156875501199343616',
      language: 'English',
      length: 645,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@_523d',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/_523d" data-screen-name="_523d" target="_blank" rel="nofollow">@_523d</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/test5f1798" data-screen-name="test5f1798" target="_blank" rel="nofollow">@test5f1798</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/akiko_lawson" data-screen-name="akiko_lawson" target="_blank" rel="nofollow">@akiko_lawson</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/VENETHIS" data-screen-name="VENETHIS" target="_blank" rel="nofollow">@VENETHIS</a> The technical challenge?',
      timeOfDay: '12:00',
      timestamp: '2019-08-01T10:33:07.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1156875501199343616',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-01',
      dayOfWeek: 4,
      id: '1156874071004651520',
      language: 'English',
      length: 660,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@_523d',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/_523d" data-screen-name="_523d" target="_blank" rel="nofollow">@_523d</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/test5f1798" data-screen-name="test5f1798" target="_blank" rel="nofollow">@test5f1798</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/akiko_lawson" data-screen-name="akiko_lawson" target="_blank" rel="nofollow">@akiko_lawson</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/VENETHIS" data-screen-name="VENETHIS" target="_blank" rel="nofollow">@VENETHIS</a> Hi. What&#39;s the goal of the account?',
      timeOfDay: '12:00',
      timestamp: '2019-08-01T10:27:26.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1156874071004651520',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-01',
      dayOfWeek: 4,
      id: '1156856606044413952',
      language: 'English',
      length: 1411,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@k3vk4',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/k3vk4" data-screen-name="k3vk4" target="_blank" rel="nofollow">@k3vk4</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/test5f1798" data-screen-name="test5f1798" target="_blank" rel="nofollow">@test5f1798</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/_523d" data-screen-name="_523d" target="_blank" rel="nofollow">@_523d</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/akiko_lawson" data-screen-name="akiko_lawson" target="_blank" rel="nofollow">@akiko_lawson</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/VENETHIS" data-screen-name="VENETHIS" target="_blank" rel="nofollow">@VENETHIS</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/Twitter" data-screen-name="Twitter" target="_blank" rel="nofollow">@Twitter</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/TwitterAPI" data-screen-name="TwitterAPI" target="_blank" rel="nofollow">@TwitterAPI</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/TwitterSupport" data-screen-name="TwitterSupport" target="_blank" rel="nofollow">@TwitterSupport</a> It&#39;s 300 Tweets per 3 hours… <a href="https://t.co/IjszX87Mq5" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/IjszX87Mq5</a>',
      timeOfDay: '11:00',
      timestamp: '2019-08-01T09:18:02.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1156856606044413952',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-01',
      dayOfWeek: 4,
      id: '1156854516433788928',
      language: 'English',
      length: 941,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/test5f1798" data-screen-name="test5f1798" target="_blank" rel="nofollow">@test5f1798</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/_523d" data-screen-name="_523d" target="_blank" rel="nofollow">@_523d</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/akiko_lawson" data-screen-name="akiko_lawson" target="_blank" rel="nofollow">@akiko_lawson</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/VENETHIS" data-screen-name="VENETHIS" target="_blank" rel="nofollow">@VENETHIS</a> It writes replies, when mentioned. <a href="https://twitter.com/search?q=%23bottalk" title="#bottalk" class="text-brand hover:text-brand-dark" target="_blank" rel="nofollow">#bottalk</a> <a href="https://t.co/1xI1Xvtifv" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/1xI1Xvtifv</a>',
      timeOfDay: '11:00',
      timestamp: '2019-08-01T09:09:44.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1156854516433788928',
      usedApp: 'Twitter Web App',
      usedHashtags: ['#bottalk'],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-01',
      dayOfWeek: 4,
      id: '1156854014174212096',
      language: 'English',
      length: 181,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@_523d',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/_523d" data-screen-name="_523d" target="_blank" rel="nofollow">@_523d</a> Robert&#39;); DROP TABLE STUDENTS; --',
      timeOfDay: '11:00',
      timestamp: '2019-08-01T09:07:44.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1156854014174212096',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-01',
      dayOfWeek: 4,
      id: '1156853457858564102',
      language: 'English',
      length: 687,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/test5f1798" data-screen-name="test5f1798" target="_blank" rel="nofollow">@test5f1798</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/_523d" data-screen-name="_523d" target="_blank" rel="nofollow">@_523d</a> 23M Tweets is only the third most active account. <a class="text-brand hover:text-brand-dark" href="https://twitter.com/akiko_lawson" data-screen-name="akiko_lawson" target="_blank" rel="nofollow">@akiko_lawson</a> has 50M and <a class="text-brand hover:text-brand-dark" href="https://twitter.com/VENETHIS" data-screen-name="VENETHIS" target="_blank" rel="nofollow">@VENETHIS</a> 38M.',
      timeOfDay: '11:00',
      timestamp: '2019-08-01T09:05:32.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1156853457858564102',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-08-01',
      dayOfWeek: 4,
      id: '1156853230195879936',
      language: 'German',
      length: 533,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@pzumk',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/pzumk" data-screen-name="pzumk" target="_blank" rel="nofollow">@pzumk</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/test5f1798" data-screen-name="test5f1798" target="_blank" rel="nofollow">@test5f1798</a> Anscheinend nicht. Oder es ist einer von ihnen selbst. Aufgrund der neuen API Limits sollten so… <a href="https://t.co/LCSmrNFNnO" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/LCSmrNFNnO</a>',
      timeOfDay: '11:00',
      timestamp: '2019-08-01T09:04:38.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1156853230195879936',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-01',
      dayOfWeek: 4,
      id: '1156851856527777792',
      language: 'English',
      length: 534,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/test5f1798" data-screen-name="test5f1798" target="_blank" rel="nofollow">@test5f1798</a> Next one, which once used a similar query: <a class="text-brand hover:text-brand-dark" href="https://twitter.com/_523d" data-screen-name="_523d" target="_blank" rel="nofollow">@_523d</a>. 860k Tweets. Only 70 Tweets per hour. But it posts t… <a href="https://t.co/qZSsYENL6f" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/qZSsYENL6f</a>',
      timeOfDay: '10:00',
      timestamp: '2019-08-01T08:59:10.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1156851856527777792',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-01',
      dayOfWeek: 4,
      id: '1156848832187129856',
      language: 'English',
      length: 397,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: null,
      text:
        'Weird find of the day: An account that publishes up to 1000 Tweets per hour: <a class="text-brand hover:text-brand-dark" href="https://twitter.com/test5f1798" data-screen-name="test5f1798" target="_blank" rel="nofollow">@test5f1798</a>. Each Tweet contains a UUI… <a href="https://t.co/SUqK9S4iPH" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/SUqK9S4iPH</a>',
      timeOfDay: '10:00',
      timestamp: '2019-08-01T08:47:09.000Z',
      type: 'Tweet',
      url: 'https://twitter.com/luca/status/1156848832187129856',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-08-01',
      dayOfWeek: 4,
      id: '1156846581204869121',
      language: 'German',
      length: 863,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@buechsenoeffner',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/Buechsenoeffner" data-screen-name="Buechsenoeffner" target="_blank" rel="nofollow">@Buechsenoeffner</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/schwarissa" data-screen-name="schwarissa" target="_blank" rel="nofollow">@schwarissa</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/herrpandabaer" data-screen-name="herrpandabaer" target="_blank" rel="nofollow">@herrpandabaer</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/Fraeulein_Krise" data-screen-name="Fraeulein_Krise" target="_blank" rel="nofollow">@Fraeulein_Krise</a> Abgesehen von den ganzen allgemeingültigen(?) Sachen,… <a href="https://t.co/io6F9y9VCr" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/io6F9y9VCr</a>',
      timeOfDay: '10:00',
      timestamp: '2019-08-01T08:38:12.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1156846581204869121',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-31',
      dayOfWeek: 3,
      id: '1156657692947210243',
      language: 'Unknown',
      length: 652,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@manandmachiner1',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/ManAndMachineR1" data-screen-name="ManAndMachineR1" target="_blank" rel="nofollow">@ManAndMachineR1</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/drawandstrike" data-screen-name="drawandstrike" target="_blank" rel="nofollow">@drawandstrike</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/realDonaldTrump" data-screen-name="realDonaldTrump" target="_blank" rel="nofollow">@realDonaldTrump</a> Or <a href="https://t.co/ZYTeLPDdFo" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/ZYTeLPDdFo</a>',
      timeOfDay: '22:00',
      timestamp: '2019-07-31T20:07:38.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1156657692947210243',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['www.trumptwitterarchive.com'],
      username: 'luca',
    },
    {
      date: '2019-07-31',
      dayOfWeek: 3,
      id: '1156656542458007553',
      language: 'English',
      length: 239,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: '@wongmjane',
      repliedUser: null,
      retweetedUser: null,
      text:
        'I like how fast Twitter is with improving the new web app. The real reason why the old client had to die. <a href="https://t.co/ahOf931YJV" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/ahOf931YJV</a>',
      timeOfDay: '22:00',
      timestamp: '2019-07-31T20:03:04.000Z',
      type: 'Quote',
      url: 'https://twitter.com/luca/status/1156656542458007553',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-31',
      dayOfWeek: 3,
      id: '1156635802752995335',
      language: 'English',
      length: 233,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: '@twittersupport',
      repliedUser: null,
      retweetedUser: null,
      text:
        'Expanding is the first step to collapsing, right?\n\n(Announcement is about the prototype app twttr.) <a href="https://t.co/6tqN8X9I8b" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/6tqN8X9I8b</a>',
      timeOfDay: '20:00',
      timestamp: '2019-07-31T18:40:39.000Z',
      type: 'Quote',
      url: 'https://twitter.com/luca/status/1156635802752995335',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-31',
      dayOfWeek: 3,
      id: '1156634499733708802',
      language: 'English',
      length: 269,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@secou',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/secou" data-screen-name="secou" target="_blank" rel="nofollow">@secou</a> That&#39;s a new one for me. Thanks.\n\nI always used the domain as normal term, which would include Tweets with multiple URLs.',
      timeOfDay: '20:00',
      timestamp: '2019-07-31T18:35:28.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1156634499733708802',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-07-31',
      dayOfWeek: 3,
      id: '1156628680560525312',
      language: 'English',
      length: 544,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@secou',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/secou" data-screen-name="secou" target="_blank" rel="nofollow">@secou</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/digintelosint" data-screen-name="digintelosint" target="_blank" rel="nofollow">@digintelosint</a> Thanks. Fixed the typo.\n\nfilter:media is there, but the order doesn&#39;t make sense. Maybe I wil… <a href="https://t.co/nWMCM9zFZi" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/nWMCM9zFZi</a>',
      timeOfDay: '20:00',
      timestamp: '2019-07-31T18:12:21.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1156628680560525312',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-31',
      dayOfWeek: 3,
      id: '1156602636524818433',
      language: 'German',
      length: 350,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@lino',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/lino" data-screen-name="lino" target="_blank" rel="nofollow">@lino</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/vodafone" data-screen-name="vodafone" target="_blank" rel="nofollow">@vodafone</a> Total überraschend findet das jährliche Stadtfest statt.',
      timeOfDay: '18:00',
      timestamp: '2019-07-31T16:28:51.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1156602636524818433',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-07-31',
      dayOfWeek: 3,
      id: '1156602199818080256',
      language: 'Unknown',
      length: 445,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@saufgelage',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/saufgelage" data-screen-name="saufgelage" target="_blank" rel="nofollow">@saufgelage</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/vodafone" data-screen-name="vodafone" target="_blank" rel="nofollow">@vodafone</a> <a href="https://t.co/2QaP67URNT" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/2QaP67URNT</a>',
      timeOfDay: '18:00',
      timestamp: '2019-07-31T16:27:07.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1156602199818080256',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-07-31',
      dayOfWeek: 3,
      id: '1156599578126442496',
      language: 'English',
      length: 207,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: null,
      text:
        'Whoops.\n\n„No mobile data service\nTemporarily turned off by your operator“ <a href="https://t.co/U7zA25fSsh" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/U7zA25fSsh</a>',
      timeOfDay: '18:00',
      timestamp: '2019-07-31T16:16:42.000Z',
      type: 'Tweet',
      url: 'https://twitter.com/luca/status/1156599578126442496',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-07-31',
      dayOfWeek: 3,
      id: '1156514653683494912',
      language: 'English',
      length: 565,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@jasonbaumgartne',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/jasonbaumgartne" data-screen-name="jasonbaumgartne" target="_blank" rel="nofollow">@jasonbaumgartne</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/IgorBrigadir" data-screen-name="IgorBrigadir" target="_blank" rel="nofollow">@IgorBrigadir</a> I didn&#39;t know that max_id works with regular search. That&#39;s awesome.\n\nMakes me want… <a href="https://t.co/0TKYx0OXMX" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/0TKYx0OXMX</a>',
      timeOfDay: '12:00',
      timestamp: '2019-07-31T10:39:15.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1156514653683494912',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-31',
      dayOfWeek: 3,
      id: '1156503535640596480',
      language: 'English',
      length: 403,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@jasonbaumgartne',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/jasonbaumgartne" data-screen-name="jasonbaumgartne" target="_blank" rel="nofollow">@jasonbaumgartne</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/IgorBrigadir" data-screen-name="IgorBrigadir" target="_blank" rel="nofollow">@IgorBrigadir</a> I prefer -min_faves:X. Tweets that were faved less than X times.',
      timeOfDay: '11:00',
      timestamp: '2019-07-31T09:55:04.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1156503535640596480',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-07-31',
      dayOfWeek: 3,
      id: '1156495589305139200',
      language: 'English',
      length: 171,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text:
        'It&#39;s around since at least 2015. \n<a href="https://t.co/Mla2j9tHXX" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/Mla2j9tHXX</a>',
      timeOfDay: '11:00',
      timestamp: '2019-07-31T09:23:29.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1156495589305139200',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-31',
      dayOfWeek: 3,
      id: '1156494289481936897',
      language: 'English',
      length: 265,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text:
        'By combining it with &quot;filter:retweets&quot;, you can search only through Retweets. \n\nExample: &quot;filter:retweets include:n… <a href="https://t.co/lUkyDi1DmC" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/lUkyDi1DmC</a>',
      timeOfDay: '11:00',
      timestamp: '2019-07-31T09:18:19.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1156494289481936897',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-31',
      dayOfWeek: 3,
      id: '1156493370493214720',
      language: 'English',
      length: 327,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text:
        'I added it to my Advanced Guide How to Use Twitter Search. <a href="https://t.co/WGUXnkxw4q" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/WGUXnkxw4q</a>. <a href="https://t.co/3dOycO5aDZ" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/3dOycO5aDZ</a>',
      timeOfDay: '11:00',
      timestamp: '2019-07-31T09:14:40.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1156493370493214720',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['freshvanroot.com'],
      username: 'luca',
    },
    {
      date: '2019-07-31',
      dayOfWeek: 3,
      id: '1156493358249984000',
      language: 'English',
      length: 411,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: null,
      text:
        'I learned about a new search operator: &quot;include:nativeretweets&quot;. Thanks <a class="text-brand hover:text-brand-dark" href="https://twitter.com/IgorBrigadir" data-screen-name="IgorBrigadir" target="_blank" rel="nofollow">@IgorBrigadir</a>.\n\nNative Retweets are exclude… <a href="https://t.co/GnkGMk2Cyn" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/GnkGMk2Cyn</a>',
      timeOfDay: '11:00',
      timestamp: '2019-07-31T09:14:37.000Z',
      type: 'Tweet',
      url: 'https://twitter.com/luca/status/1156493358249984000',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-31',
      dayOfWeek: 3,
      id: '1156488162602078208',
      language: 'English',
      length: 473,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@tjukanov',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/tjukanov" data-screen-name="tjukanov" target="_blank" rel="nofollow">@tjukanov</a> Twitter is helping out as well. <a href="https://twitter.com/search?q=%23megaface" title="#megaface" class="text-brand hover:text-brand-dark" target="_blank" rel="nofollow">#megaface</a> <a href="https://t.co/DV6Bqly74G" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/DV6Bqly74G</a>',
      timeOfDay: '10:00',
      timestamp: '2019-07-31T08:53:59.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1156488162602078208',
      usedApp: 'Twitter Web App',
      usedHashtags: ['#megaface'],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-07-31',
      dayOfWeek: 3,
      id: '1156470371056541696',
      language: 'German',
      length: 311,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@stefan',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/stefan" data-screen-name="stefan" target="_blank" rel="nofollow">@stefan</a> lol, jedes Handy is schneller. <a href="https://t.co/26PlRrnbxC" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/26PlRrnbxC</a>',
      timeOfDay: '09:00',
      timestamp: '2019-07-31T07:43:17.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1156470371056541696',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-30',
      dayOfWeek: 2,
      id: '1156299823374635008',
      language: 'English',
      length: 281,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@caissie',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/Caissie" data-screen-name="Caissie" target="_blank" rel="nofollow">@Caissie</a>: My Facebook timeline is literally just people asking for advice on what they should consume. Podcasts. Sock brands. Juice. The…',
      timeOfDay: '22:00',
      timestamp: '2019-07-30T20:25:35.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1156299823374635008',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-07-30',
      dayOfWeek: 2,
      id: '1156298408057016323',
      language: 'English',
      length: 250,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text:
        '⚡ Allow me collapse reply-threads.\n\nPopular Tweets with hundreds of replies have complicated sub-threads in the rep… <a href="https://t.co/WgAUfrQujI" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/WgAUfrQujI</a>',
      timeOfDay: '22:00',
      timestamp: '2019-07-30T20:19:58.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1156298408057016323',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-30',
      dayOfWeek: 2,
      id: '1156297042110689280',
      language: 'English',
      length: 433,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@tsrandall',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/tsrandall" data-screen-name="tsrandall" target="_blank" rel="nofollow">@tsrandall</a>: This <a class="text-brand hover:text-brand-dark" href="https://twitter.com/exxonmobile" data-screen-name="exxonmobile" target="_blank" rel="nofollow">@exxonmobile</a> chart from 1982 predicted that in 2019 our atmospheric CO2 level would reach about 415 parts per million,…',
      timeOfDay: '22:00',
      timestamp: '2019-07-30T20:14:32.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1156297042110689280',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-07-30',
      dayOfWeek: 2,
      id: '1156293824429416448',
      language: 'English',
      length: 342,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@ubiquity75',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/ubiquity75" data-screen-name="ubiquity75" target="_blank" rel="nofollow">@ubiquity75</a> I need to look for a app to annotate screenshots. <a href="https://t.co/8auKHjJBiS" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/8auKHjJBiS</a>',
      timeOfDay: '22:00',
      timestamp: '2019-07-30T20:01:45.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1156293824429416448',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-07-30',
      dayOfWeek: 2,
      id: '1156292634006171648',
      language: 'English',
      length: 211,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@ubiquity75',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/ubiquity75" data-screen-name="ubiquity75" target="_blank" rel="nofollow">@ubiquity75</a> That you follow the person. If I remember correctly.',
      timeOfDay: '21:00',
      timestamp: '2019-07-30T19:57:01.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1156292634006171648',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-07-30',
      dayOfWeek: 2,
      id: '1156284152972550144',
      language: 'German',
      length: 233,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@stormgrass',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/stormgrass" data-screen-name="stormgrass" target="_blank" rel="nofollow">@stormgrass</a> Solche Reply-Threads habe ich auch einige. Gefühlt aber zu anderen Themen.',
      timeOfDay: '21:00',
      timestamp: '2019-07-30T19:23:19.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1156284152972550144',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-07-30',
      dayOfWeek: 2,
      id: '1156242186771546116',
      language: 'English',
      length: 281,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@jmysegades',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/JMysegades" data-screen-name="JMysegades" target="_blank" rel="nofollow">@JMysegades</a> No. The modularity algorithm puts each node into a class and I interpret those clusters myself by looking at the accounts.',
      timeOfDay: '18:00',
      timestamp: '2019-07-30T16:36:33.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1156242186771546116',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-07-30',
      dayOfWeek: 2,
      id: '1156241911725903873',
      language: 'English',
      length: 149,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@edsu',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/edsu" data-screen-name="edsu" target="_blank" rel="nofollow">@edsu</a> Correct.',
      timeOfDay: '18:00',
      timestamp: '2019-07-30T16:35:28.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1156241911725903873',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-07-30',
      dayOfWeek: 2,
      id: '1156241807983951874',
      language: 'German',
      length: 391,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@kofi2go',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/kofi2go" data-screen-name="kofi2go" target="_blank" rel="nofollow">@kofi2go</a> Mit 87 eingehenden Verbindungen bist du leider unter dem 100er-Grenzwert. Aber ich habe dich kurz rausgesu… <a href="https://t.co/88TorpScdX" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/88TorpScdX</a>',
      timeOfDay: '18:00',
      timestamp: '2019-07-30T16:35:03.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1156241807983951874',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-30',
      dayOfWeek: 2,
      id: '1156235220712067072',
      language: 'English',
      length: 521,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@focus_frameless',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/focus_frameless" data-screen-name="focus_frameless" target="_blank" rel="nofollow">@focus_frameless</a> According to <a href="https://t.co/khFDRL3X6Q" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/khFDRL3X6Q</a> and old data (haven&#39;t updated my lists yet).\n\nGermany 50% wom… <a href="https://t.co/qAJl7spWx7" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/qAJl7spWx7</a>',
      timeOfDay: '18:00',
      timestamp: '2019-07-30T16:08:53.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1156235220712067072',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['www.proporti.onl', 'twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-30',
      dayOfWeek: 2,
      id: '1156233020699029505',
      language: 'English',
      length: 247,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@edsu',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/edsu" data-screen-name="edsu" target="_blank" rel="nofollow">@edsu</a> Thanks and don&#39;t worry. Self-replies always* take precedent over replies by others.\n\n*as far as I know',
      timeOfDay: '18:00',
      timestamp: '2019-07-30T16:00:08.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1156233020699029505',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-07-30',
      dayOfWeek: 2,
      id: '1156228308104876032',
      language: 'English',
      length: 250,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text:
        'Everyone I follow as of 2019-07-30.\n\nGermany (29%, green)\nTwitter/Startups/Tech (23%, violet)\nAustria (22%, red)\nSc… <a href="https://t.co/HmUGJw4SG6" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/HmUGJw4SG6</a>',
      timeOfDay: '17:00',
      timestamp: '2019-07-30T15:41:24.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1156228308104876032',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-30',
      dayOfWeek: 2,
      id: '1156214556580032513',
      language: 'English',
      length: 256,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: '@willoremus',
      repliedUser: null,
      retweetedUser: null,
      text:
        'Twitter $32B\n\nBerkshire Hathaway $508\nJPMorgan Chase $368B\nWalmart $320B\nJohnson &amp;amp; Johnson $320B\nExxon Mobil $318B <a href="https://t.co/KLLHfBNybu" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/KLLHfBNybu</a>',
      timeOfDay: '16:00',
      timestamp: '2019-07-30T14:46:46.000Z',
      type: 'Quote',
      url: 'https://twitter.com/luca/status/1156214556580032513',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-30',
      dayOfWeek: 2,
      id: '1156210598469951494',
      language: 'English',
      length: 421,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@ingridlunden',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/ingridlunden" data-screen-name="ingridlunden" target="_blank" rel="nofollow">@ingridlunden</a> Option 1: Every time you open Twitter, check the top right for &quot;Latest Tweets&quot; instead of &quot;Home&quot;.\nOpt… <a href="https://t.co/vUZ0IY7mqD" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/vUZ0IY7mqD</a>',
      timeOfDay: '16:00',
      timestamp: '2019-07-30T14:31:02.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1156210598469951494',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-30',
      dayOfWeek: 2,
      id: '1156209517719433225',
      language: 'English',
      length: 166,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: null,
      text:
        'Fact check your parents, please. <a href="https://t.co/AXQWhb6ECD" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/AXQWhb6ECD</a>',
      timeOfDay: '16:00',
      timestamp: '2019-07-30T14:26:45.000Z',
      type: 'Quote',
      url: 'https://twitter.com/luca/status/1156209517719433225',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-30',
      dayOfWeek: 2,
      id: '1156199414450544640',
      language: 'English',
      length: 305,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@irinimalliaraki',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/irinimalliaraki" data-screen-name="irinimalliaraki" target="_blank" rel="nofollow">@irinimalliaraki</a>: I made a graph of the people I follow on twitter and the ones that they follow(3,6k nodes &amp;amp; 290k edges). Some communit…',
      timeOfDay: '15:00',
      timestamp: '2019-07-30T13:46:36.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1156199414450544640',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-07-30',
      dayOfWeek: 2,
      id: '1156199323530645504',
      language: 'English',
      length: 375,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@irinimalliaraki',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/irinimalliaraki" data-screen-name="irinimalliaraki" target="_blank" rel="nofollow">@irinimalliaraki</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/JonathonKitson" data-screen-name="JonathonKitson" target="_blank" rel="nofollow">@JonathonKitson</a> I should update that tutorial.',
      timeOfDay: '15:00',
      timestamp: '2019-07-30T13:46:14.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1156199323530645504',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-07-30',
      dayOfWeek: 2,
      id: '1156199042302566406',
      language: 'English',
      length: 389,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@irinimalliaraki',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/irinimalliaraki" data-screen-name="irinimalliaraki" target="_blank" rel="nofollow">@irinimalliaraki</a> Thanks for reminding me that it is time to do my yearly following visualization.\n\n<a href="https://t.co/DjvVMm6xMP" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/DjvVMm6xMP</a>',
      timeOfDay: '15:00',
      timestamp: '2019-07-30T13:45:07.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1156199042302566406',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-30',
      dayOfWeek: 2,
      id: '1156156098275086336',
      language: 'English',
      length: 398,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/baltpeter" data-screen-name="baltpeter" target="_blank" rel="nofollow">@baltpeter</a> IFPI is sure that they have no records on me.\n\nTheir responses are worded in a way that it&#39;s unclear if… <a href="https://t.co/FUvr6oIUPX" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/FUvr6oIUPX</a>',
      timeOfDay: '12:00',
      timestamp: '2019-07-30T10:54:28.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1156156098275086336',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-29',
      dayOfWeek: 1,
      id: '1155944215672569859',
      language: 'English',
      length: 391,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@oihamza',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/oihamza" data-screen-name="oihamza" target="_blank" rel="nofollow">@oihamza</a> I saw people using a pinned thread for that. \n\nHere are my best Tweets.\n|\nQuote-Tweet\n|\nQuote-Tweet\n|\nQuot… <a href="https://t.co/fw6S0v8olB" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/fw6S0v8olB</a>',
      timeOfDay: '22:00',
      timestamp: '2019-07-29T20:52:32.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1155944215672569859',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-29',
      dayOfWeek: 1,
      id: '1155906004078485505',
      language: 'English',
      length: 198,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@budacub',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/budacub" data-screen-name="budacub" target="_blank" rel="nofollow">@budacub</a>: Damn ye twitter bugs! Ye shall burn in hell!',
      timeOfDay: '20:00',
      timestamp: '2019-07-29T18:20:41.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1155906004078485505',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-07-29',
      dayOfWeek: 1,
      id: '1155855782950395904',
      language: 'English',
      length: 985,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@adfichter',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/adfichter" data-screen-name="adfichter" target="_blank" rel="nofollow">@adfichter</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/grssnbchr" data-screen-name="grssnbchr" target="_blank" rel="nofollow">@grssnbchr</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/RepublikMagazin" data-screen-name="RepublikMagazin" target="_blank" rel="nofollow">@RepublikMagazin</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/facebook" data-screen-name="facebook" target="_blank" rel="nofollow">@facebook</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/nytimes" data-screen-name="nytimes" target="_blank" rel="nofollow">@nytimes</a> Even if they don&#39;t apply, the Ad Library makes all ads pu… <a href="https://t.co/cFxbMKDsoj" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/cFxbMKDsoj</a>',
      timeOfDay: '17:00',
      timestamp: '2019-07-29T15:01:08.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1155855782950395904',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-29',
      dayOfWeek: 1,
      id: '1155850277813215232',
      language: 'English',
      length: 258,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text:
        'In the USA the percentage of people is up to five times higher than in Germany. Still you shouldn&#39;t believe it&#39;s re… <a href="https://t.co/FZhWWf4s5K" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/FZhWWf4s5K</a>',
      timeOfDay: '16:00',
      timestamp: '2019-07-29T14:39:15.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1155850277813215232',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-29',
      dayOfWeek: 1,
      id: '1155843479861379073',
      language: 'German',
      length: 390,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@uedio',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/uedio" data-screen-name="uedio" target="_blank" rel="nofollow">@uedio</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/nicolediekmann" data-screen-name="nicolediekmann" target="_blank" rel="nofollow">@nicolediekmann</a> Man muss zwischen Staats-Antifa, Antifa GmbH und Antifa e.V. unterscheiden.',
      timeOfDay: '16:00',
      timestamp: '2019-07-29T14:12:14.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1155843479861379073',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-07-29',
      dayOfWeek: 1,
      id: '1155820735228723200',
      language: 'English',
      length: 389,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@edsu',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/edsu" data-screen-name="edsu" target="_blank" rel="nofollow">@edsu</a> Could also be a bias because I see them as unavailable here and then switch to another account. Haven&#39;t stumb… <a href="https://t.co/4RyLq1PCnS" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/4RyLq1PCnS</a>',
      timeOfDay: '14:00',
      timestamp: '2019-07-29T12:41:52.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1155820735228723200',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-29',
      dayOfWeek: 1,
      id: '1155817991076028416',
      language: 'English',
      length: 385,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@edsu',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/edsu" data-screen-name="edsu" target="_blank" rel="nofollow">@edsu</a> There are the legitimately unavailable Tweets as well. Replies from private accounts for example. Or Tweets t… <a href="https://t.co/bUBYbuN29l" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/bUBYbuN29l</a>',
      timeOfDay: '14:00',
      timestamp: '2019-07-29T12:30:57.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1155817991076028416',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-29',
      dayOfWeek: 1,
      id: '1155815258960871430',
      language: 'English',
      length: 408,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@frederikriedel',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/frederikRiedel" data-screen-name="frederikRiedel" target="_blank" rel="nofollow">@frederikRiedel</a> Yes, it&#39;s account and situation specific. I see the Tweet when I use search as well as directly on… <a href="https://t.co/XTcDaXuna3" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/XTcDaXuna3</a>',
      timeOfDay: '14:00',
      timestamp: '2019-07-29T12:20:06.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1155815258960871430',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-29',
      dayOfWeek: 1,
      id: '1155806490831347712',
      language: 'English',
      length: 352,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text:
        'Dear Twitter, why do you do this to me? <a href="https://twitter.com/search?q=%23TweetUnavailable" title="#TweetUnavailable" class="text-brand hover:text-brand-dark" target="_blank" rel="nofollow">#TweetUnavailable</a> <a href="https://t.co/a1JRvDGTuv" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/a1JRvDGTuv</a>',
      timeOfDay: '13:00',
      timestamp: '2019-07-29T11:45:15.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1155806490831347712',
      usedApp: 'Twitter for Android',
      usedHashtags: ['#TweetUnavailable'],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-07-29',
      dayOfWeek: 1,
      id: '1155800538300211200',
      language: 'German',
      length: 389,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@digiom',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/digiom" data-screen-name="digiom" target="_blank" rel="nofollow">@digiom</a> Ich nutze Forest dazu. Ist aber immer nur bis zu 2 Stunden. Also anders ausgerichtet.\n\nAuch nur lokal, aber… <a href="https://t.co/ums709QcEY" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/ums709QcEY</a>',
      timeOfDay: '13:00',
      timestamp: '2019-07-29T11:21:36.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1155800538300211200',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-29',
      dayOfWeek: 1,
      id: '1155776403691974657',
      language: 'English',
      length: 683,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@elena_gil_liza',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/Elena_Gil_Liza" data-screen-name="Elena_Gil_Liza" target="_blank" rel="nofollow">@Elena_Gil_Liza</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/Antonio_Pita" data-screen-name="Antonio_Pita" target="_blank" rel="nofollow">@Antonio_Pita</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/TE_GranEmpresa" data-screen-name="TE_GranEmpresa" target="_blank" rel="nofollow">@TE_GranEmpresa</a> It&#39;s <a class="text-brand hover:text-brand-dark" href="https://twitter.com/LUCA_D3" data-screen-name="LUCA_D3" target="_blank" rel="nofollow">@LUCA_D3</a>. Stop tagging me.',
      timeOfDay: '11:00',
      timestamp: '2019-07-29T09:45:42.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1155776403691974657',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-07-29',
      dayOfWeek: 1,
      id: '1155758450774876160',
      language: 'German',
      length: 533,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@pascoda',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/pascoda" data-screen-name="pascoda" target="_blank" rel="nofollow">@pascoda</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/A1Telekom" data-screen-name="A1Telekom" target="_blank" rel="nofollow">@A1Telekom</a> Können schon, aber ob sie es per default machen?\n\nWeiß nicht, ob und wie man das testen kann. <a href="https://t.co/eaioF7mTEz" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/eaioF7mTEz</a>',
      timeOfDay: '10:00',
      timestamp: '2019-07-29T08:34:22.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1155758450774876160',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['whatismyipaddress.com'],
      username: 'luca',
    },
    {
      date: '2019-07-29',
      dayOfWeek: 1,
      id: '1155757919960535041',
      language: 'German',
      length: 436,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@fiete_stegers',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/fiete_stegers" data-screen-name="fiete_stegers" target="_blank" rel="nofollow">@fiete_stegers</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/LarsWienand" data-screen-name="LarsWienand" target="_blank" rel="nofollow">@LarsWienand</a> Ginge es nur um Follows, wäre das eine Möglichkeit, aber es sind ja Likes. Halte ich für unwahrscheinlich.',
      timeOfDay: '10:00',
      timestamp: '2019-07-29T08:32:15.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1155757919960535041',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-07-29',
      dayOfWeek: 1,
      id: '1155757581048123394',
      language: 'German',
      length: 331,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@pascoda',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/pascoda" data-screen-name="pascoda" target="_blank" rel="nofollow">@pascoda</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/A1Telekom" data-screen-name="A1Telekom" target="_blank" rel="nofollow">@A1Telekom</a> IPv4 weg, IPv6 geht aber?',
      timeOfDay: '10:00',
      timestamp: '2019-07-29T08:30:54.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1155757581048123394',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-07-29',
      dayOfWeek: 1,
      id: '1155755703900024833',
      language: 'English',
      length: 556,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@yestiseye',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/yestiseye" data-screen-name="yestiseye" target="_blank" rel="nofollow">@yestiseye</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/TheRealPBarry" data-screen-name="TheRealPBarry" target="_blank" rel="nofollow">@TheRealPBarry</a> Let&#39;s read that initial Tweet again. If the study is correct, it&#39;s not cheap, but the cos… <a href="https://t.co/3f4yQq98uq" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/3f4yQq98uq</a>',
      timeOfDay: '10:00',
      timestamp: '2019-07-29T08:23:27.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1155755703900024833',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-29',
      dayOfWeek: 1,
      id: '1155752688937820160',
      language: 'English',
      length: 548,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@yestiseye',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/yestiseye" data-screen-name="yestiseye" target="_blank" rel="nofollow">@yestiseye</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/TheRealPBarry" data-screen-name="TheRealPBarry" target="_blank" rel="nofollow">@TheRealPBarry</a> - A big part of the costs come from building the nuclear plants. Depending on the mainten… <a href="https://t.co/oaOhvLQrtZ" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/oaOhvLQrtZ</a>',
      timeOfDay: '10:00',
      timestamp: '2019-07-29T08:11:28.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1155752688937820160',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-29',
      dayOfWeek: 1,
      id: '1155750660001820672',
      language: 'English',
      length: 292,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@therealpbarry',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/TheRealPBarry" data-screen-name="TheRealPBarry" target="_blank" rel="nofollow">@TheRealPBarry</a>: Wow! German Inst for Econ Research claims average 1000MW nuclear power station built since 1951 has resulted in average…',
      timeOfDay: '10:00',
      timestamp: '2019-07-29T08:03:24.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1155750660001820672',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-07-29',
      dayOfWeek: 1,
      id: '1155721096991313920',
      language: 'English',
      length: 295,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@seamusblackley',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/SeamusBlackley" data-screen-name="SeamusBlackley" target="_blank" rel="nofollow">@SeamusBlackley</a>: ...an actual loaf of Old Kingdom bread. It’s from the Giza plateau, and it’s in an awesome triangular shape. You can se…',
      timeOfDay: '08:00',
      timestamp: '2019-07-29T06:05:56.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1155721096991313920',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-07-29',
      dayOfWeek: 1,
      id: '1155719771045937152',
      language: 'English',
      length: 518,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text:
        'Dear <a class="text-brand hover:text-brand-dark" href="https://twitter.com/TwitterSupport" data-screen-name="TwitterSupport" target="_blank" rel="nofollow">@TwitterSupport</a>, I need my ancient bread fix. <a href="https://twitter.com/search?q=%23TweetUnavailable" title="#TweetUnavailable" class="text-brand hover:text-brand-dark" target="_blank" rel="nofollow">#TweetUnavailable</a> <a href="https://t.co/b5lWfHXGy7" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/b5lWfHXGy7</a>',
      timeOfDay: '08:00',
      timestamp: '2019-07-29T06:00:40.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1155719771045937152',
      usedApp: 'Twitter for Android',
      usedHashtags: ['#TweetUnavailable'],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-07-29',
      dayOfWeek: 1,
      id: '1155718590211022849',
      language: 'English',
      length: 577,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@seamusblackley',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/SeamusBlackley" data-screen-name="SeamusBlackley" target="_blank" rel="nofollow">@SeamusBlackley</a>: Just now, the dormant yeast I collected this week from Ancient Egyptian artifacts (with help from <a class="text-brand hover:text-brand-dark" href="https://twitter.com/drserenalove" data-screen-name="drserenalove" target="_blank" rel="nofollow">@drserenalove</a> and <a class="text-brand hover:text-brand-dark" href="https://twitter.com/rb" data-screen-name="rb" target="_blank" rel="nofollow">@rb</a>…',
      timeOfDay: '07:00',
      timestamp: '2019-07-29T05:55:58.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1155718590211022849',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-07-29',
      dayOfWeek: 1,
      id: '1155715687182209024',
      language: 'English',
      length: 607,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@camillefrancois',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/camillefrancois" data-screen-name="camillefrancois" target="_blank" rel="nofollow">@camillefrancois</a>: <a class="text-brand hover:text-brand-dark" href="https://twitter.com/washingtonpost" data-screen-name="washingtonpost" target="_blank" rel="nofollow">@washingtonpost</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/ShibaniMahtani" data-screen-name="ShibaniMahtani" target="_blank" rel="nofollow">@ShibaniMahtani</a> It’s not all bad news though. The piece suggests that mitigations and platform takedow…',
      timeOfDay: '07:00',
      timestamp: '2019-07-29T05:44:26.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1155715687182209024',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-07-29',
      dayOfWeek: 1,
      id: '1155715574485438464',
      language: 'English',
      length: 607,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@camillefrancois',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/camillefrancois" data-screen-name="camillefrancois" target="_blank" rel="nofollow">@camillefrancois</a>: <a class="text-brand hover:text-brand-dark" href="https://twitter.com/washingtonpost" data-screen-name="washingtonpost" target="_blank" rel="nofollow">@washingtonpost</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/ShibaniMahtani" data-screen-name="ShibaniMahtani" target="_blank" rel="nofollow">@ShibaniMahtani</a> The piece deserves an in-depth read for the few fascinating and worrisome trends it hi…',
      timeOfDay: '07:00',
      timestamp: '2019-07-29T05:43:59.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1155715574485438464',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-07-28',
      dayOfWeek: 0,
      id: '1155577765179138049',
      language: 'Unknown',
      length: 476,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@edgeofeurope',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/EdgeOfEurope" data-screen-name="EdgeOfEurope" target="_blank" rel="nofollow">@EdgeOfEurope</a>: <a class="text-brand hover:text-brand-dark" href="https://twitter.com/MarietjeSchaake" data-screen-name="MarietjeSchaake" target="_blank" rel="nofollow">@MarietjeSchaake</a> <a href="https://t.co/ARvPPsc2KG" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/ARvPPsc2KG</a>',
      timeOfDay: '22:00',
      timestamp: '2019-07-28T20:36:23.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1155577765179138049',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-28',
      dayOfWeek: 0,
      id: '1155577738910216195',
      language: 'English',
      length: 454,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@marietjeschaake',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/MarietjeSchaake" data-screen-name="MarietjeSchaake" target="_blank" rel="nofollow">@MarietjeSchaake</a>: Let’s hope this is not another poisoning &amp;gt;&amp;gt; ‘Russian opposition leader <a href="https://twitter.com/search?q=%23Navalny" title="#Navalny" class="text-brand hover:text-brand-dark" target="_blank" rel="nofollow">#Navalny</a> hospitalized for allergic reaction’  h…',
      timeOfDay: '22:00',
      timestamp: '2019-07-28T20:36:17.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1155577738910216195',
      usedApp: 'Twitter for Android',
      usedHashtags: ['#Navalny'],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-07-28',
      dayOfWeek: 0,
      id: '1155560518574325760',
      language: 'English',
      length: 281,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@balajis',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/balajis" data-screen-name="balajis" target="_blank" rel="nofollow">@balajis</a>: It lets them triple dip.\n\n1) Gain money from a startup\n2) Gain status by denouncing the source of the money\n3) As a side effec…',
      timeOfDay: '21:00',
      timestamp: '2019-07-28T19:27:51.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1155560518574325760',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-07-28',
      dayOfWeek: 0,
      id: '1155536381931921417',
      language: 'German',
      length: 549,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@rolandgiersig',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/RolandGiersig" data-screen-name="RolandGiersig" target="_blank" rel="nofollow">@RolandGiersig</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/buerstmayr" data-screen-name="buerstmayr" target="_blank" rel="nofollow">@buerstmayr</a> Da ich das schon öfters über unterschiedliche Wege probiert habe, vermute ich, dass das… <a href="https://t.co/BiUXWnCKpC" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/BiUXWnCKpC</a>',
      timeOfDay: '19:00',
      timestamp: '2019-07-28T17:51:56.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1155536381931921417',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-28',
      dayOfWeek: 0,
      id: '1155456533649641474',
      language: 'English',
      length: 388,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@k_ramali',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/k_ramali" data-screen-name="k_ramali" target="_blank" rel="nofollow">@k_ramali</a>: They’ve started informing people of WiFi connection data collection. I like the opt out option 🤣 <a href="https://t.co/kpSaPOLQKT" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/kpSaPOLQKT</a>',
      timeOfDay: '14:00',
      timestamp: '2019-07-28T12:34:39.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1155456533649641474',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-07-28',
      dayOfWeek: 0,
      id: '1155443714673860608',
      language: 'German',
      length: 357,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@racingpit',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/RacingPit" data-screen-name="RacingPit" target="_blank" rel="nofollow">@RacingPit</a>: Wegen aktuellem Antifagebashe:\n<a href="https://twitter.com/search?q=%23IchBinAntifa" title="#IchBinAntifa" class="text-brand hover:text-brand-dark" target="_blank" rel="nofollow">#IchBinAntifa</a>',
      timeOfDay: '13:00',
      timestamp: '2019-07-28T11:43:43.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1155443714673860608',
      usedApp: 'Twitter Web App',
      usedHashtags: ['#IchBinAntifa'],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-07-28',
      dayOfWeek: 0,
      id: '1155439404695085056',
      language: 'English',
      length: 819,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@sillium',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/Sillium" data-screen-name="Sillium" target="_blank" rel="nofollow">@Sillium</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/HilliKnixibix" data-screen-name="HilliKnixibix" target="_blank" rel="nofollow">@HilliKnixibix</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/Hyundai" data-screen-name="Hyundai" target="_blank" rel="nofollow">@Hyundai</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/Hyundai_DE" data-screen-name="Hyundai_DE" target="_blank" rel="nofollow">@Hyundai_DE</a> Seems like they have no channels for such reports at all.… <a href="https://t.co/FzpwTwVkQj" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/FzpwTwVkQj</a>',
      timeOfDay: '13:00',
      timestamp: '2019-07-28T11:26:35.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1155439404695085056',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-28',
      dayOfWeek: 0,
      id: '1155429322016141312',
      language: 'English',
      length: 401,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@igorbrigadir',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/IgorBrigadir" data-screen-name="IgorBrigadir" target="_blank" rel="nofollow">@IgorBrigadir</a> Yes. Has to be some weird bug. Only happens on my verified account.\n\nThis Tweet got fixed. But others… <a href="https://t.co/IUUEGQRENy" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/IUUEGQRENy</a>',
      timeOfDay: '12:00',
      timestamp: '2019-07-28T10:46:31.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1155429322016141312',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-28',
      dayOfWeek: 0,
      id: '1155411088726548481',
      language: 'English',
      length: 214,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text:
        '“Creating and distributing inspirational video content for the millennial woman” <a href="https://t.co/ap70ZNkw92" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/ap70ZNkw92</a>',
      timeOfDay: '11:00',
      timestamp: '2019-07-28T09:34:04.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1155411088726548481',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-07-28',
      dayOfWeek: 0,
      id: '1155409337294217216',
      language: 'English',
      length: 253,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: '@botanygeek',
      repliedUser: null,
      retweetedUser: null,
      text:
        'Life pro tip fake videos because they get even more clicks than polarising political disinformation.\n\nIt&#39;s not the… <a href="https://t.co/wcyZYVnCDL" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/wcyZYVnCDL</a>',
      timeOfDay: '11:00',
      timestamp: '2019-07-28T09:27:07.000Z',
      type: 'Quote',
      url: 'https://twitter.com/luca/status/1155409337294217216',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-28',
      dayOfWeek: 0,
      id: '1155380919613775872',
      language: 'German',
      length: 486,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@peter_jelinek',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/Peter_Jelinek" data-screen-name="Peter_Jelinek" target="_blank" rel="nofollow">@Peter_Jelinek</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/bobschi" data-screen-name="bobschi" target="_blank" rel="nofollow">@bobschi</a> City Maut ist nicht radikal genug. <a href="https://t.co/JTT0WiwVLr" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/JTT0WiwVLr</a>',
      timeOfDay: '09:00',
      timestamp: '2019-07-28T07:34:11.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1155380919613775872',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-28',
      dayOfWeek: 0,
      id: '1155356685134966784',
      language: 'English',
      length: 286,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@metaviv',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/metaviv" data-screen-name="metaviv" target="_blank" rel="nofollow">@metaviv</a>: So, about 6 months ago, I started giving a talk called: &quot;Towards Ensuring that ML Research Accounts for Realistic Threats to D…',
      timeOfDay: '07:00',
      timestamp: '2019-07-28T05:57:53.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1155356685134966784',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-07-28',
      dayOfWeek: 0,
      id: '1155354800210481152',
      language: 'English',
      length: 198,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@igorbrigadir',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/IgorBrigadir" data-screen-name="IgorBrigadir" target="_blank" rel="nofollow">@IgorBrigadir</a> Yes. Tweet and image are visible.',
      timeOfDay: '07:00',
      timestamp: '2019-07-28T05:50:24.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1155354800210481152',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-07-27',
      dayOfWeek: 6,
      id: '1155229333415432192',
      language: 'English',
      length: 244,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text:
        'It happens multiple times per day and it&#39;s annoying.\n\nThere is no reason for this Tweet to be unavailable. <a href="https://t.co/PIStsKPzWO" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/PIStsKPzWO</a>',
      timeOfDay: '23:00',
      timestamp: '2019-07-27T21:31:50.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1155229333415432192',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-07-27',
      dayOfWeek: 6,
      id: '1155228439839944706',
      language: 'English',
      length: 319,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@5thcircappeals',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/5thCircAppeals" data-screen-name="5thCircAppeals" target="_blank" rel="nofollow">@5thCircAppeals</a>: 2. You&#39;re never &quot;just joking.&quot; Nobody is ever &quot;just joking.&quot; Humor is a social act that performs a social function (alw…',
      timeOfDay: '23:00',
      timestamp: '2019-07-27T21:28:17.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1155228439839944706',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-07-27',
      dayOfWeek: 6,
      id: '1155226931622797313',
      language: 'English',
      length: 290,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@megankonar',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/MeganKonar" data-screen-name="MeganKonar" target="_blank" rel="nofollow">@MeganKonar</a>: We&#39;ve modeled food flows between all counties in the United States! Our estimates are provided in the supporting material.…',
      timeOfDay: '23:00',
      timestamp: '2019-07-27T21:22:18.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1155226931622797313',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-07-27',
      dayOfWeek: 6,
      id: '1155226764026810368',
      language: 'English',
      length: 533,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@lexialex',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/Lexialex" data-screen-name="Lexialex" target="_blank" rel="nofollow">@Lexialex</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/Die_Liser" data-screen-name="Die_Liser" target="_blank" rel="nofollow">@Die_Liser</a> Twitter suggested such usernames in the past, but stopped last year as far as I remember.\n\n<a href="https://t.co/Ux0rUtR3b4" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/Ux0rUtR3b4</a>',
      timeOfDay: '23:00',
      timestamp: '2019-07-27T21:21:38.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1155226764026810368',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-27',
      dayOfWeek: 6,
      id: '1155216216744693760',
      language: 'English',
      length: 262,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text:
        'I reported &#39;suicide&#39; as a harmful trend to Twitter and am mad that it&#39;s not on a forbidden list already. Or at leas… <a href="https://t.co/7dlJXdMibL" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/7dlJXdMibL</a>',
      timeOfDay: '22:00',
      timestamp: '2019-07-27T20:39:43.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1155216216744693760',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-27',
      dayOfWeek: 6,
      id: '1155212548880457728',
      language: 'English',
      length: 253,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: '@tsbordeme',
      repliedUser: null,
      retweetedUser: null,
      text:
        'TIL: Sending unwanted dickpicks in Germany can cost one month&#39;s wage.\n\nI received some I the past, now I know what… <a href="https://t.co/GUYM023MIW" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/GUYM023MIW</a>',
      timeOfDay: '22:00',
      timestamp: '2019-07-27T20:25:09.000Z',
      type: 'Quote',
      url: 'https://twitter.com/luca/status/1155212548880457728',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-27',
      dayOfWeek: 6,
      id: '1155208314721951744',
      language: 'English',
      length: 395,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/mattblaze" data-screen-name="mattblaze" target="_blank" rel="nofollow">@mattblaze</a> There have been some changes to the  search results as well.\n\nNo tested, but from my experience there ar… <a href="https://t.co/0S48RUKjoo" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/0S48RUKjoo</a>',
      timeOfDay: '22:00',
      timestamp: '2019-07-27T20:08:19.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1155208314721951744',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-27',
      dayOfWeek: 6,
      id: '1155207561307656193',
      language: 'English',
      length: 384,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@mattblaze',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/mattblaze" data-screen-name="mattblaze" target="_blank" rel="nofollow">@mattblaze</a> I love to search for old Tweets (pre-2010) and retweet them instead of writing my own. Sorry.\n\n<a href="https://t.co/SEWyPv5APc" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/SEWyPv5APc</a>',
      timeOfDay: '22:00',
      timestamp: '2019-07-27T20:05:19.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1155207561307656193',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-27',
      dayOfWeek: 6,
      id: '1155205851826860032',
      language: 'English',
      length: 388,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@klonick',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/Klonick" data-screen-name="Klonick" target="_blank" rel="nofollow">@Klonick</a>: I think the second level joke here is the Berlin Wall was built to keep people in, not keep people out... <a href="https://t.co/qNh869f" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/qNh869f</a>…',
      timeOfDay: '21:00',
      timestamp: '2019-07-27T19:58:32.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1155205851826860032',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-07-27',
      dayOfWeek: 6,
      id: '1155152696577576960',
      language: 'English',
      length: 394,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@jesslynnrose',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/jesslynnrose" data-screen-name="jesslynnrose" target="_blank" rel="nofollow">@jesslynnrose</a> From what I see in my timeline, substack is the current favourite. I still like tinyletter and <a href="https://t.co/llvkontupp" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/llvkontupp</a>.',
      timeOfDay: '18:00',
      timestamp: '2019-07-27T16:27:19.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1155152696577576960',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['buttondown.email'],
      username: 'luca',
    },
    {
      date: '2019-07-27',
      dayOfWeek: 6,
      id: '1155140949783322624',
      language: 'Unknown',
      length: 451,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@inkorrupt',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/inkorrupt" data-screen-name="inkorrupt" target="_blank" rel="nofollow">@inkorrupt</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/hambinition" data-screen-name="hambinition" target="_blank" rel="nofollow">@hambinition</a> <a href="https://t.co/wDRCHWnDDN" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/wDRCHWnDDN</a>',
      timeOfDay: '17:00',
      timestamp: '2019-07-27T15:40:38.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1155140949783322624',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-27',
      dayOfWeek: 6,
      id: '1155117240230039553',
      language: 'English',
      length: 295,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@georgsauerwein',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/GeorgSauerwein" data-screen-name="GeorgSauerwein" target="_blank" rel="nofollow">@GeorgSauerwein</a>: Because of our coal, oil and gas dependence, our governments too often shut up about human rights abuses or even work t…',
      timeOfDay: '16:00',
      timestamp: '2019-07-27T14:06:25.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1155117240230039553',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-07-27',
      dayOfWeek: 6,
      id: '1155097186394357766',
      language: 'English',
      length: 278,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@teirdes',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/teirdes" data-screen-name="teirdes" target="_blank" rel="nofollow">@teirdes</a> Couldn&#39;t that be easily stolen together with the phone? (I am ignoring that face recognition is hackable with most devices.)',
      timeOfDay: '14:00',
      timestamp: '2019-07-27T12:46:44.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1155097186394357766',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-07-27',
      dayOfWeek: 6,
      id: '1155065646541156352',
      language: 'German',
      length: 552,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@hellessachsen',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/HellesSachsen" data-screen-name="HellesSachsen" target="_blank" rel="nofollow">@HellesSachsen</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/Schmidtlepp" data-screen-name="Schmidtlepp" target="_blank" rel="nofollow">@Schmidtlepp</a> Er meint es so, aber er handelt nicht so. Das weiß man, wenn man ihm halbwegs aufmerksa… <a href="https://t.co/Pq03qGIwoH" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/Pq03qGIwoH</a>',
      timeOfDay: '12:00',
      timestamp: '2019-07-27T10:41:24.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1155065646541156352',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-27',
      dayOfWeek: 6,
      id: '1155063822207651842',
      language: 'German',
      length: 193,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@schmidtlepp',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/Schmidtlepp" data-screen-name="Schmidtlepp" target="_blank" rel="nofollow">@Schmidtlepp</a> Trolling wie es im Buche steht.',
      timeOfDay: '12:00',
      timestamp: '2019-07-27T10:34:09.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1155063822207651842',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-07-27',
      dayOfWeek: 6,
      id: '1155038353995239425',
      language: 'English',
      length: 301,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@iyad_elbaghdadi',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/iyad_elbaghdadi" data-screen-name="iyad_elbaghdadi" target="_blank" rel="nofollow">@iyad_elbaghdadi</a>: Bear witness. Bahrain&#39;s DC embassy statement on the killings literally cites how capital punishment is legal in the Un…',
      timeOfDay: '10:00',
      timestamp: '2019-07-27T08:52:57.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1155038353995239425',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-07-27',
      dayOfWeek: 6,
      id: '1155037932627120129',
      language: 'English',
      length: 285,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@salwadaei',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/SAlwadaei" data-screen-name="SAlwadaei" target="_blank" rel="nofollow">@SAlwadaei</a>: These executions mark one of Bahrain’s darkest days. Ali and Ahmed were brutally tortured and forced to sign false confessio…',
      timeOfDay: '10:00',
      timestamp: '2019-07-27T08:51:17.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1155037932627120129',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-07-27',
      dayOfWeek: 6,
      id: '1155036090094555136',
      language: 'English',
      length: 470,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: null,
      text:
        'Recommendations for Reporting on Suicide:\n<a href="https://t.co/Tqj1xlpCQI" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/Tqj1xlpCQI</a>\n\nGerman helpline: <a href="https://t.co/TM2rVKpAud" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/TM2rVKpAud</a> (phone,… <a href="https://t.co/YsLZRWiC8w" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/YsLZRWiC8w</a>',
      timeOfDay: '10:00',
      timestamp: '2019-07-27T08:43:58.000Z',
      type: 'Tweet',
      url: 'https://twitter.com/luca/status/1155036090094555136',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['reportingonsuicide.org', 'www.telefonseelsorge.de', 'twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-26',
      dayOfWeek: 5,
      id: '1154828176574562305',
      language: 'English',
      length: 289,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@pomeranian99',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/pomeranian99" data-screen-name="pomeranian99" target="_blank" rel="nofollow">@pomeranian99</a> I like the context it gives when looking at threads. Would prefer it not to be fixed on the feed, but don&#39;t really mind.',
      timeOfDay: '20:00',
      timestamp: '2019-07-26T18:57:47.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1154828176574562305',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-07-26',
      dayOfWeek: 5,
      id: '1154811754616168448',
      language: 'English',
      length: 401,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@jonas1015119',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/jonas1015119" data-screen-name="jonas1015119" target="_blank" rel="nofollow">@jonas1015119</a> If Twitter decides that the account is malicious, they could ban all accounts that used the same numb… <a href="https://t.co/dOrxn91MtL" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/dOrxn91MtL</a>',
      timeOfDay: '19:00',
      timestamp: '2019-07-26T17:52:32.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1154811754616168448',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-26',
      dayOfWeek: 5,
      id: '1154807498723012609',
      language: 'English',
      length: 262,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@zachcottam',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/ZachCottam" data-screen-name="ZachCottam" target="_blank" rel="nofollow">@ZachCottam</a> I don&#39;t argue that they should ban him, but that he shouldn&#39;t get recommended to all new users.',
      timeOfDay: '19:00',
      timestamp: '2019-07-26T17:35:37.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1154807498723012609',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-07-26',
      dayOfWeek: 5,
      id: '1154803083865722881',
      language: 'English',
      length: 373,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@hocinecherifi',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/HocineCherifi" data-screen-name="HocineCherifi" target="_blank" rel="nofollow">@HocineCherifi</a>: Our new paper on centrality in overlapping modular networks is out.\n<a href="https://t.co/k2BFdGbHoc" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/k2BFdGbHoc</a>',
      timeOfDay: '19:00',
      timestamp: '2019-07-26T17:18:05.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1154803083865722881',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['www.nature.com'],
      username: 'luca',
    },
    {
      date: '2019-07-26',
      dayOfWeek: 5,
      id: '1154798128551333889',
      language: 'English',
      length: 502,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/anildash" data-screen-name="anildash" target="_blank" rel="nofollow">@anildash</a> In October 2016 Trump was not on the suggested list in India. \n\nScreenshots from <a href="https://t.co/Jy2yxLbxJ9" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/Jy2yxLbxJ9</a>… <a href="https://t.co/UVBn89IXcQ" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/UVBn89IXcQ</a>',
      timeOfDay: '18:00',
      timestamp: '2019-07-26T16:58:23.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1154798128551333889',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['www.youtube.com', 'twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-26',
      dayOfWeek: 5,
      id: '1154796353060515842',
      language: 'English',
      length: 401,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/anildash" data-screen-name="anildash" target="_blank" rel="nofollow">@anildash</a> 2017 before the corners got round. Sadly they don&#39;t scroll down and we don&#39;t know if Trump was already on… <a href="https://t.co/IZdltAt27U" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/IZdltAt27U</a>',
      timeOfDay: '18:00',
      timestamp: '2019-07-26T16:51:20.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1154796353060515842',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-26',
      dayOfWeek: 5,
      id: '1154794324929994753',
      language: 'English',
      length: 385,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/anildash" data-screen-name="anildash" target="_blank" rel="nofollow">@anildash</a> 2015. You have to manually unselect each suggested account to not follow them. \n\nScreenshots from… <a href="https://t.co/SCfCcaTIEP" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/SCfCcaTIEP</a>',
      timeOfDay: '18:00',
      timestamp: '2019-07-26T16:43:16.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1154794324929994753',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-26',
      dayOfWeek: 5,
      id: '1154793403579797505',
      language: 'English',
      length: 466,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/anildash" data-screen-name="anildash" target="_blank" rel="nofollow">@anildash</a> Suggested users in 2014.\n\nScreenshot from <a href="https://t.co/3Yd1wEBQo5" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/3Yd1wEBQo5</a> /20 <a href="https://t.co/RlCuiUfwuY" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/RlCuiUfwuY</a>',
      timeOfDay: '18:00',
      timestamp: '2019-07-26T16:39:37.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1154793403579797505',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['socialnewsdaily.com'],
      username: 'luca',
    },
    {
      date: '2019-07-26',
      dayOfWeek: 5,
      id: '1154792111188959232',
      language: 'German',
      length: 407,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@gerlindeinaktiv',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/GerlindeInaktiv" data-screen-name="GerlindeInaktiv" target="_blank" rel="nofollow">@GerlindeInaktiv</a> Falls sie ein bestimmtes Follow-Muster haben, also immer den gleichen Accounts folgen könnte man e… <a href="https://t.co/m1dn0MrNrz" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/m1dn0MrNrz</a>',
      timeOfDay: '18:00',
      timestamp: '2019-07-26T16:34:28.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1154792111188959232',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-26',
      dayOfWeek: 5,
      id: '1154791215734427653',
      language: 'English',
      length: 393,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/anildash" data-screen-name="anildash" target="_blank" rel="nofollow">@anildash</a> This is what the suggested list looked like in March 2012 (before it was replaced).\n\nScreenshot form this… <a href="https://t.co/OXVotHod33" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/OXVotHod33</a>',
      timeOfDay: '18:00',
      timestamp: '2019-07-26T16:30:55.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1154791215734427653',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-26',
      dayOfWeek: 5,
      id: '1154789571630161920',
      language: 'English',
      length: 403,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@laughingsmurf',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/LaughingSmurf" data-screen-name="LaughingSmurf" target="_blank" rel="nofollow">@LaughingSmurf</a> You are right. Facebook has two explicit options. Deletion has a waiting period of 30 days and it ta… <a href="https://t.co/O3DVwwBYag" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/O3DVwwBYag</a>',
      timeOfDay: '18:00',
      timestamp: '2019-07-26T16:24:23.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1154789571630161920',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-26',
      dayOfWeek: 5,
      id: '1154789142330531840',
      language: 'English',
      length: 503,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/anildash" data-screen-name="anildash" target="_blank" rel="nofollow">@anildash</a> In 2012 they switched from the manual list to tailored suggestions. <a href="https://t.co/hRAEEqxufE" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/hRAEEqxufE</a>\n\nI am missing… <a href="https://t.co/IKo3buyU9G" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/IKo3buyU9G</a>',
      timeOfDay: '18:00',
      timestamp: '2019-07-26T16:22:41.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1154789142330531840',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['blog.twitter.com', 'twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-26',
      dayOfWeek: 5,
      id: '1154788145650704384',
      language: 'English',
      length: 250,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text:
        'Back to the initial thought: Why does Twitter recommend Trump to new users?\n\nIn 2009 Twitter started to suggest acc… <a href="https://t.co/8VSJkYPCve" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/8VSJkYPCve</a>',
      timeOfDay: '18:00',
      timestamp: '2019-07-26T16:18:43.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1154788145650704384',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-26',
      dayOfWeek: 5,
      id: '1154786335938555904',
      language: 'English',
      length: 407,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@laughingsmurf',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/LaughingSmurf" data-screen-name="LaughingSmurf" target="_blank" rel="nofollow">@LaughingSmurf</a> I used their support form and requested them to delete the account.\n\nDoesn&#39;t Facebook have a similar… <a href="https://t.co/zHBjukz77d" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/zHBjukz77d</a>',
      timeOfDay: '18:00',
      timestamp: '2019-07-26T16:11:32.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1154786335938555904',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-26',
      dayOfWeek: 5,
      id: '1154785721418506240',
      language: 'English',
      length: 252,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text:
        'I don&#39;t want to burn one of my phone numbers, so I will try to get the support team to delete my test account. /16 <a href="https://t.co/yOxg7Ls7e0" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/yOxg7Ls7e0</a>',
      timeOfDay: '18:00',
      timestamp: '2019-07-26T16:09:05.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1154785721418506240',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-07-26',
      dayOfWeek: 5,
      id: '1154784619948118016',
      language: 'English',
      length: 270,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@laughingsmurf',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/LaughingSmurf" data-screen-name="LaughingSmurf" target="_blank" rel="nofollow">@LaughingSmurf</a> Looks like it, yes. The account is now locked, so I can&#39;t even use the old interface to delete it.',
      timeOfDay: '18:00',
      timestamp: '2019-07-26T16:04:42.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1154784619948118016',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-07-26',
      dayOfWeek: 5,
      id: '1154784451190239232',
      language: 'English',
      length: 249,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text:
        'Maybe the new interface is a problem, so I tried to login to the old interface (Opera User Agent String). Well, my… <a href="https://t.co/kSx9SwTBMP" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/kSx9SwTBMP</a>',
      timeOfDay: '18:00',
      timestamp: '2019-07-26T16:04:02.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1154784451190239232',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-26',
      dayOfWeek: 5,
      id: '1154781668311543808',
      language: 'English',
      length: 250,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text:
        'And I am in. No explanation how everything works this time. \n\nAt no point I was able to choose username, Twitter au… <a href="https://t.co/HO8foxWOYC" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/HO8foxWOYC</a>',
      timeOfDay: '17:00',
      timestamp: '2019-07-26T15:52:59.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1154781668311543808',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-26',
      dayOfWeek: 5,
      id: '1154780577654083584',
      language: 'English',
      length: 250,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text:
        'I choose Environment and Society as my interests. On position 15 Trump is recommended to me. At least no follow all… <a href="https://t.co/f3BHqjpZms" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/f3BHqjpZms</a>',
      timeOfDay: '17:00',
      timestamp: '2019-07-26T15:48:39.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1154780577654083584',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-26',
      dayOfWeek: 5,
      id: '1154780567042502656',
      language: 'English',
      length: 254,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text:
        'Request for a description was moved as well. I think that&#39;s great because it makes it easier to differentiate betwe… <a href="https://t.co/rhxELfNRkm" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/rhxELfNRkm</a>',
      timeOfDay: '17:00',
      timestamp: '2019-07-26T15:48:36.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1154780567042502656',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-26',
      dayOfWeek: 5,
      id: '1154778139962351618',
      language: 'English',
      length: 250,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text:
        'New anti spam measure: You have to enter the mail verification code before you can use the account. In the past you… <a href="https://t.co/C0XerA09bj" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/C0XerA09bj</a>',
      timeOfDay: '17:00',
      timestamp: '2019-07-26T15:38:57.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1154778139962351618',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-26',
      dayOfWeek: 5,
      id: '1154777331426320384',
      language: 'English',
      length: 258,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text:
        'It&#39;s July 2019. Let&#39;s take a quick look how onboarding works at the moment.\n\nInitial steps look similar to the last… <a href="https://t.co/PjietWLb6s" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/PjietWLb6s</a>',
      timeOfDay: '17:00',
      timestamp: '2019-07-26T15:35:45.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1154777331426320384',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-26',
      dayOfWeek: 5,
      id: '1154776537557798913',
      language: 'English',
      length: 250,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text:
        'In April 2019 I tested the onboarding in the Android app. Guess what, Trump was back on the recommended accounts li… <a href="https://t.co/adN4Bqw5FC" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/adN4Bqw5FC</a>',
      timeOfDay: '17:00',
      timestamp: '2019-07-26T15:32:35.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1154776537557798913',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-26',
      dayOfWeek: 5,
      id: '1154776488035663873',
      language: 'English',
      length: 254,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text:
        'In August 2018 the recommendations changed. Trump wasn&#39;t recommended to me any more. There were some weird and many… <a href="https://t.co/YiVgRUwGuK" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/YiVgRUwGuK</a>',
      timeOfDay: '17:00',
      timestamp: '2019-07-26T15:32:24.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1154776488035663873',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-26',
      dayOfWeek: 5,
      id: '1154776292782497792',
      language: 'English',
      length: 254,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text:
        'In May 2018 they changed the onboarding process. Trump was still recommended, but they didn&#39;t tell me why. At least… <a href="https://t.co/MQu7NBp20x" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/MQu7NBp20x</a>',
      timeOfDay: '17:00',
      timestamp: '2019-07-26T15:31:37.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1154776292782497792',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-26',
      dayOfWeek: 5,
      id: '1154776189376053249',
      language: 'English',
      length: 254,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text:
        'In January 2018 Trump was recommended to new users. Still the same process. If you didn&#39;t actively unselect him and… <a href="https://t.co/uAwaUdAfjP" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/uAwaUdAfjP</a>',
      timeOfDay: '17:00',
      timestamp: '2019-07-26T15:31:12.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1154776189376053249',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-26',
      dayOfWeek: 5,
      id: '1154776142697680897',
      language: 'English',
      length: 250,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text:
        'I started to document Twitter onboarding three years ago. Maybe someone has screenshots which accounts were recomme… <a href="https://t.co/lyBsQeRl0H" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/lyBsQeRl0H</a>',
      timeOfDay: '17:00',
      timestamp: '2019-07-26T15:31:01.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1154776142697680897',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-26',
      dayOfWeek: 5,
      id: '1154775924228919296',
      language: 'English',
      length: 254,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text:
        'Some of these new users abandon Twitter on the same day. That&#39;s normal and happens on other services as well. Often… <a href="https://t.co/daNZcHJCsH" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/daNZcHJCsH</a>',
      timeOfDay: '17:00',
      timestamp: '2019-07-26T15:30:09.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1154775924228919296',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-26',
      dayOfWeek: 5,
      id: '1154775922509303809',
      language: 'English',
      length: 253,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text:
        'I often get asked if Trump buys followers because most of them look like fake accounts. I don&#39;t know. But there is… <a href="https://t.co/IHW1AgdJ4k" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/IHW1AgdJ4k</a>',
      timeOfDay: '17:00',
      timestamp: '2019-07-26T15:30:09.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1154775922509303809',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-26',
      dayOfWeek: 5,
      id: '1154775919745294336',
      language: 'English',
      length: 254,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: '@jilliancyork',
      repliedUser: null,
      retweetedUser: null,
      text:
        'Why does Twitter suggest Trump to every new user?\n\nTwitter made it clear that they won&#39;t ban Trump or remove his Tw… <a href="https://t.co/dxJEj76Sf6" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/dxJEj76Sf6</a>',
      timeOfDay: '17:00',
      timestamp: '2019-07-26T15:30:08.000Z',
      type: 'Quote',
      url: 'https://twitter.com/luca/status/1154775919745294336',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-26',
      dayOfWeek: 5,
      id: '1154762830446518272',
      language: 'English',
      length: 391,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@drbrake',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/drbrake" data-screen-name="drbrake" target="_blank" rel="nofollow">@drbrake</a>: A fascinating illustration of a problem outlined in my book <a href="https://t.co/21SmMZ3OwZ" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/21SmMZ3OwZ</a> Public postings that seemed (and were!) in…',
      timeOfDay: '16:00',
      timestamp: '2019-07-26T14:38:07.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1154762830446518272',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['www.facebook.com'],
      username: 'luca',
    },
    {
      date: '2019-07-26',
      dayOfWeek: 5,
      id: '1154760481552392193',
      language: 'German',
      length: 236,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@clemensprill',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/ClemensPrill" data-screen-name="ClemensPrill" target="_blank" rel="nofollow">@ClemensPrill</a> Vielleicht Richtung Recht auf Vergessenwerden und Urteile gegen Google?',
      timeOfDay: '16:00',
      timestamp: '2019-07-26T14:28:47.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1154760481552392193',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-07-26',
      dayOfWeek: 5,
      id: '1154747526400086018',
      language: 'English',
      length: 250,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: '@twittersupport',
      repliedUser: null,
      retweetedUser: null,
      text:
        'Will the filter be removed or only the setting?\n\n(The filter was there in the old interface as well, but nobody car… <a href="https://t.co/mLp8GNUp7P" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/mLp8GNUp7P</a>',
      timeOfDay: '15:00',
      timestamp: '2019-07-26T13:37:19.000Z',
      type: 'Quote',
      url: 'https://twitter.com/luca/status/1154747526400086018',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-26',
      dayOfWeek: 5,
      id: '1154735205963485186',
      language: 'English',
      length: 173,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text:
        'I&#39;m not good with cashtags. It should be <a href="https://twitter.com/search?q=%24twtr" title="$twtr" class="tweet-url cashtag" target="_blank" rel="nofollow">$twtr</a>.',
      timeOfDay: '14:00',
      timestamp: '2019-07-26T12:48:21.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1154735205963485186',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-07-26',
      dayOfWeek: 5,
      id: '1154733790155157505',
      language: 'German',
      length: 189,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@yungchristus',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/YungChristus" data-screen-name="YungChristus" target="_blank" rel="nofollow">@YungChristus</a> Nie wieder Overlayhölle!',
      timeOfDay: '14:00',
      timestamp: '2019-07-26T12:42:44.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1154733790155157505',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-07-26',
      dayOfWeek: 5,
      id: '1154731848922214401',
      language: 'English',
      length: 385,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/Jack" data-screen-name="Jack" target="_blank" rel="nofollow">@Jack</a> talks about how well received the comeback of the reverse chronological timeline was. Not mentioning how they… <a href="https://t.co/PqPEs8Q2gE" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/PqPEs8Q2gE</a>',
      timeOfDay: '14:00',
      timestamp: '2019-07-26T12:35:01.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1154731848922214401',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-26',
      dayOfWeek: 5,
      id: '1154731158695043072',
      language: 'German',
      length: 401,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@yungchristus',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/YungChristus" data-screen-name="YungChristus" target="_blank" rel="nofollow">@YungChristus</a> Actually…\n\nNeuer VP Design wurde kürzlich eingestellt und soll laut Jack im Call in dem Bereich viele… <a href="https://t.co/1aTTqHc4Qp" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/1aTTqHc4Qp</a>',
      timeOfDay: '14:00',
      timestamp: '2019-07-26T12:32:16.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1154731158695043072',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-26',
      dayOfWeek: 5,
      id: '1154725477275308032',
      language: 'English',
      length: 99,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text: 'Jack said in the call they plan to add conversation labels from the twttr app to the main one soon.',
      timeOfDay: '14:00',
      timestamp: '2019-07-26T12:09:42.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1154725477275308032',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-07-26',
      dayOfWeek: 5,
      id: '1154724349930291201',
      language: 'English',
      length: 70,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text: 'Correction: I missed the last part. How do I become a public official?',
      timeOfDay: '14:00',
      timestamp: '2019-07-26T12:05:13.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1154724349930291201',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-07-26',
      dayOfWeek: 5,
      id: '1154722861522784258',
      language: 'English',
      length: 337,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@awguo',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/awguo" data-screen-name="awguo" target="_blank" rel="nofollow">@awguo</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/TwitterIR" data-screen-name="TwitterIR" target="_blank" rel="nofollow">@TwitterIR</a> Yes, use the buttons below the Tweet.',
      timeOfDay: '13:00',
      timestamp: '2019-07-26T11:59:18.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1154722861522784258',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-07-26',
      dayOfWeek: 5,
      id: '1154721785126035456',
      language: 'English',
      length: 681,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@twitterir',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/TwitterIR" data-screen-name="TwitterIR" target="_blank" rel="nofollow">@TwitterIR</a>: Still with us? Let’s do this! Twitter Q2’19 earnings Q&amp;amp;A with <a class="text-brand hover:text-brand-dark" href="https://twitter.com/jack" data-screen-name="jack" target="_blank" rel="nofollow">@jack</a> &amp;amp; <a class="text-brand hover:text-brand-dark" href="https://twitter.com/nedsegal" data-screen-name="nedsegal" target="_blank" rel="nofollow">@nedsegal</a> is starting soon! Listen via <a href="https://t.co/yt" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/yt</a>…',
      timeOfDay: '13:00',
      timestamp: '2019-07-26T11:55:01.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1154721785126035456',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-07-26',
      dayOfWeek: 5,
      id: '1154717803133448195',
      language: 'English',
      length: 574,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@davethackeray',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/DaveThackeray" data-screen-name="DaveThackeray" target="_blank" rel="nofollow">@DaveThackeray</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/MikeAtherton" data-screen-name="MikeAtherton" target="_blank" rel="nofollow">@MikeAtherton</a> Yes, you have. &quot;Twitter Web Client&quot; was the old interface, &quot;Twitter Web App&quot; is the ne… <a href="https://t.co/2wQcjxG16H" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/2wQcjxG16H</a>',
      timeOfDay: '13:00',
      timestamp: '2019-07-26T11:39:12.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1154717803133448195',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-26',
      dayOfWeek: 5,
      id: '1154716004234256384',
      language: 'English',
      length: 250,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text:
        'I need another 90k followers to become immune against ToS violations. /s\n\nHas anyone seen such a notice yet? Please… <a href="https://t.co/LgPluUHbkX" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/LgPluUHbkX</a>',
      timeOfDay: '13:00',
      timestamp: '2019-07-26T11:32:03.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1154716004234256384',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-26',
      dayOfWeek: 5,
      id: '1154715288149135361',
      language: 'English',
      length: 233,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text:
        'Number of spam reports keep going down which shows that the measures Twitter takes have an effect.\n\n<a href="https://t.co/uhLFkXV63V" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/uhLFkXV63V</a>',
      timeOfDay: '13:00',
      timestamp: '2019-07-26T11:29:12.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1154715288149135361',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-26',
      dayOfWeek: 5,
      id: '1154714656637947905',
      language: 'English',
      length: 232,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text:
        'Stronger growth internationally than in the USA. Fits their claim to enable global conversations.\n\n<a href="https://t.co/ry4sxxEn56" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/ry4sxxEn56</a>',
      timeOfDay: '13:00',
      timestamp: '2019-07-26T11:26:42.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1154714656637947905',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-26',
      dayOfWeek: 5,
      id: '1154714149022294016',
      language: 'English',
      length: 199,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text:
        'They hired many new people. Is there an overview in which areas?\n\n<a href="https://t.co/9pb7udK44V" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/9pb7udK44V</a>',
      timeOfDay: '13:00',
      timestamp: '2019-07-26T11:24:41.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1154714149022294016',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-26',
      dayOfWeek: 5,
      id: '1154713146503942145',
      language: 'English',
      length: 189,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: '@twitterir',
      repliedUser: null,
      retweetedUser: null,
      text:
        'Seems like the financial situation of Twitter improves. <a href="https://t.co/0lyXvjE56n" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/0lyXvjE56n</a>',
      timeOfDay: '13:00',
      timestamp: '2019-07-26T11:20:42.000Z',
      type: 'Quote',
      url: 'https://twitter.com/luca/status/1154713146503942145',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-26',
      dayOfWeek: 5,
      id: '1154691794749313025',
      language: 'German',
      length: 333,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@strippel',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/strippel" data-screen-name="strippel" target="_blank" rel="nofollow">@strippel</a> Fliegen und (über-) kompensieren. Zum Beispiel <a href="https://t.co/Od5aXWWXOl" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/Od5aXWWXOl</a>',
      timeOfDay: '11:00',
      timestamp: '2019-07-26T09:55:51.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1154691794749313025',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['www.atmosfair.de'],
      username: 'luca',
    },
    {
      date: '2019-07-26',
      dayOfWeek: 5,
      id: '1154691229638123520',
      language: 'English',
      length: 297,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@adrienjoly',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/adrienjoly" data-screen-name="adrienjoly" target="_blank" rel="nofollow">@adrienjoly</a>: Every engineer of my team wrote and presented a &quot;Personal README&quot;.\n\nIt uncovered drivers, personality traits and expectatio…',
      timeOfDay: '11:00',
      timestamp: '2019-07-26T09:53:36.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1154691229638123520',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-07-26',
      dayOfWeek: 5,
      id: '1154688472839536641',
      language: 'English',
      length: 395,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/baltpeter" data-screen-name="baltpeter" target="_blank" rel="nofollow">@baltpeter</a> Send press and influencers promo videos to share with their audience and hit them with a DMCA takedown a… <a href="https://t.co/rx3reqVc29" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/rx3reqVc29</a>',
      timeOfDay: '11:00',
      timestamp: '2019-07-26T09:42:39.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1154688472839536641',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-26',
      dayOfWeek: 5,
      id: '1154687888984072193',
      language: 'English',
      length: 368,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/baltpeter" data-screen-name="baltpeter" target="_blank" rel="nofollow">@baltpeter</a> How do you identify music in a wedding video that was published two years ago?\n<a href="https://t.co/wucRudH7fP" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/wucRudH7fP</a>',
      timeOfDay: '11:00',
      timestamp: '2019-07-26T09:40:20.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1154687888984072193',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-26',
      dayOfWeek: 5,
      id: '1154687636122013702',
      language: 'English',
      length: 399,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@contiki',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/contiki" data-screen-name="contiki" target="_blank" rel="nofollow">@contiki</a> That&#39;s not a photo, but a screenshot. I don&#39;t own the rights to the stuff in the screenshot. I was able to… <a href="https://t.co/sQDE2hCg7Y" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/sQDE2hCg7Y</a>',
      timeOfDay: '11:00',
      timestamp: '2019-07-26T09:39:20.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1154687636122013702',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-26',
      dayOfWeek: 5,
      id: '1154684301704945664',
      language: 'German',
      length: 366,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@nicole_schoen',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/nicole_schoen" data-screen-name="nicole_schoen" target="_blank" rel="nofollow">@nicole_schoen</a> Falls bei einer Lesung gut ankommt, was auf Twitter gut ankommt: <a href="https://t.co/OrRF4ENjTq" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/OrRF4ENjTq</a>',
      timeOfDay: '11:00',
      timestamp: '2019-07-26T09:26:05.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1154684301704945664',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-26',
      dayOfWeek: 5,
      id: '1154682566416523264',
      language: 'English',
      length: 395,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/baltpeter" data-screen-name="baltpeter" target="_blank" rel="nofollow">@baltpeter</a> IFPI believes that they did not process any of my data. I explained the reason why I think that they did… <a href="https://t.co/vF7GFPGTla" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/vF7GFPGTla</a>',
      timeOfDay: '11:00',
      timestamp: '2019-07-26T09:19:11.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1154682566416523264',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-26',
      dayOfWeek: 5,
      id: '1154649198559674368',
      language: 'English',
      length: 229,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@lacourseparker',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/LaCourseParker" data-screen-name="LaCourseParker" target="_blank" rel="nofollow">@LaCourseParker</a>: what if we removed men from political photos: a thread',
      timeOfDay: '09:00',
      timestamp: '2019-07-26T07:06:35.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1154649198559674368',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-07-25',
      dayOfWeek: 4,
      id: '1154510000418623489',
      language: 'German',
      length: 697,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@mathiasertl',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/mathiasertl" data-screen-name="mathiasertl" target="_blank" rel="nofollow">@mathiasertl</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/ClaudiaZettel" data-screen-name="ClaudiaZettel" target="_blank" rel="nofollow">@ClaudiaZettel</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/GDornauer" data-screen-name="GDornauer" target="_blank" rel="nofollow">@GDornauer</a> Verbreiten war so oder so nicht klug. Selbst wenn das Begleitschreiben tatsä… <a href="https://t.co/KexeEWSSzE" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/KexeEWSSzE</a>',
      timeOfDay: '23:00',
      timestamp: '2019-07-25T21:53:28.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1154510000418623489',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-25',
      dayOfWeek: 4,
      id: '1154507625956073472',
      language: 'German',
      length: 697,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@mathiasertl',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/mathiasertl" data-screen-name="mathiasertl" target="_blank" rel="nofollow">@mathiasertl</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/ClaudiaZettel" data-screen-name="ClaudiaZettel" target="_blank" rel="nofollow">@ClaudiaZettel</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/GDornauer" data-screen-name="GDornauer" target="_blank" rel="nofollow">@GDornauer</a> Angeblich hat er es per Post bekommen. Gibt aber sicher eine Beraterfirma, d… <a href="https://t.co/UgEtOZiRIC" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/UgEtOZiRIC</a>',
      timeOfDay: '23:00',
      timestamp: '2019-07-25T21:44:02.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1154507625956073472',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-25',
      dayOfWeek: 4,
      id: '1154481773973164032',
      language: 'German',
      length: 368,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@jollife',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/jollife" data-screen-name="jollife" target="_blank" rel="nofollow">@jollife</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/i_am_fabs" data-screen-name="i_am_fabs" target="_blank" rel="nofollow">@i_am_fabs</a> (Der Dank muss an Fabian gehen, ich habe nur rein-gegrätscht.)',
      timeOfDay: '22:00',
      timestamp: '2019-07-25T20:01:18.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1154481773973164032',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-07-25',
      dayOfWeek: 4,
      id: '1154470793465729024',
      language: 'English',
      length: 672,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@_len_a',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/_len_A" data-screen-name="_len_A" target="_blank" rel="nofollow">@_len_A</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/LarsWienand" data-screen-name="LarsWienand" target="_blank" rel="nofollow">@LarsWienand</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/mbrennanchina" data-screen-name="mbrennanchina" target="_blank" rel="nofollow">@mbrennanchina</a> Their testing facilities look much cleaner. Like labs should. <a href="https://t.co/HFCITvcGLx" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/HFCITvcGLx</a>',
      timeOfDay: '21:00',
      timestamp: '2019-07-25T19:17:40.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1154470793465729024',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-07-25',
      dayOfWeek: 4,
      id: '1154446330728574976',
      language: 'English',
      length: 238,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@beeblebrix',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/beeblebrix" data-screen-name="beeblebrix" target="_blank" rel="nofollow">@beeblebrix</a> That sucks. There are many situations where a car is the better or only option.',
      timeOfDay: '19:00',
      timestamp: '2019-07-25T17:40:28.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1154446330728574976',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-07-25',
      dayOfWeek: 4,
      id: '1154437157676883970',
      language: 'English',
      length: 507,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@beeblebrix',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/beeblebrix" data-screen-name="beeblebrix" target="_blank" rel="nofollow">@beeblebrix</a> It depends. For trains as well as electric cars. <a href="https://t.co/73KPN6rNsT" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/73KPN6rNsT</a>\n\nCould obviously be improved a… <a href="https://t.co/yqHa7vku1U" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/yqHa7vku1U</a>',
      timeOfDay: '19:00',
      timestamp: '2019-07-25T17:04:01.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1154437157676883970',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['www.adac.de', 'twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-25',
      dayOfWeek: 4,
      id: '1154436380950482945',
      language: 'English',
      length: 685,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@lindsaypb',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/LindsayPB" data-screen-name="LindsayPB" target="_blank" rel="nofollow">@LindsayPB</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/taylowrk" data-screen-name="taylowrk" target="_blank" rel="nofollow">@taylowrk</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/Hbomberguy" data-screen-name="Hbomberguy" target="_blank" rel="nofollow">@Hbomberguy</a> Thank you.\n\nThree years ago it was initially developed as Twitter Lite. With a foc… <a href="https://t.co/UFbByzrIwA" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/UFbByzrIwA</a>',
      timeOfDay: '19:00',
      timestamp: '2019-07-25T17:00:56.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1154436380950482945',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-25',
      dayOfWeek: 4,
      id: '1154394293580247041',
      language: 'English',
      length: 388,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@aallan',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/aallan" data-screen-name="aallan" target="_blank" rel="nofollow">@aallan</a> About the new interface: I like it and it became a bit of an obsession trying to understand why it gets so… <a href="https://t.co/iIFvm7igpS" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/iIFvm7igpS</a>',
      timeOfDay: '16:00',
      timestamp: '2019-07-25T14:13:41.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1154394293580247041',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-25',
      dayOfWeek: 4,
      id: '1154393551255539712',
      language: 'English',
      length: 199,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/aallan" data-screen-name="aallan" target="_blank" rel="nofollow">@aallan</a> It&#39;s still a lot of work for an unclear benefit.',
      timeOfDay: '16:00',
      timestamp: '2019-07-25T14:10:44.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1154393551255539712',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-07-25',
      dayOfWeek: 4,
      id: '1154393449883406336',
      language: 'English',
      length: 389,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@aallan',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/aallan" data-screen-name="aallan" target="_blank" rel="nofollow">@aallan</a> I read various ideas how to implement it. For example similar to threads. When there is a reply to the orig… <a href="https://t.co/poD82p2gMF" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/poD82p2gMF</a>',
      timeOfDay: '16:00',
      timestamp: '2019-07-25T14:10:20.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1154393449883406336',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-25',
      dayOfWeek: 4,
      id: '1154389807440781313',
      language: 'English',
      length: 695,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@_len_a',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/_len_A" data-screen-name="_len_A" target="_blank" rel="nofollow">@_len_A</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/LarsWienand" data-screen-name="LarsWienand" target="_blank" rel="nofollow">@LarsWienand</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/mbrennanchina" data-screen-name="mbrennanchina" target="_blank" rel="nofollow">@mbrennanchina</a> OnePlus does manual testing, but they don&#39;t use such tables. (Source: Sponsored… <a href="https://t.co/cKUTYiXBDZ" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/cKUTYiXBDZ</a>',
      timeOfDay: '15:00',
      timestamp: '2019-07-25T13:55:52.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1154389807440781313',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-25',
      dayOfWeek: 4,
      id: '1154381750681968646',
      language: 'English',
      length: 684,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/LindsayPB" data-screen-name="LindsayPB" target="_blank" rel="nofollow">@LindsayPB</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/taylowrk" data-screen-name="taylowrk" target="_blank" rel="nofollow">@taylowrk</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/Hbomberguy" data-screen-name="Hbomberguy" target="_blank" rel="nofollow">@Hbomberguy</a> things. The new layout always has the menu on the left, then comes the content in… <a href="https://t.co/v1TxtXct5c" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/v1TxtXct5c</a>',
      timeOfDay: '15:00',
      timestamp: '2019-07-25T13:23:51.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1154381750681968646',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-25',
      dayOfWeek: 4,
      id: '1154381731493031936',
      language: 'English',
      length: 685,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@lindsaypb',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/LindsayPB" data-screen-name="LindsayPB" target="_blank" rel="nofollow">@LindsayPB</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/taylowrk" data-screen-name="taylowrk" target="_blank" rel="nofollow">@taylowrk</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/Hbomberguy" data-screen-name="Hbomberguy" target="_blank" rel="nofollow">@Hbomberguy</a> - Yes, algorithmic timeline is bad.\n- Can you be more specific what is awkward? Co… <a href="https://t.co/8BXXUJ8yuv" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/8BXXUJ8yuv</a>',
      timeOfDay: '15:00',
      timestamp: '2019-07-25T13:23:46.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1154381731493031936',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-25',
      dayOfWeek: 4,
      id: '1154362432804007937',
      language: 'English',
      length: 286,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@policy',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/Policy" data-screen-name="Policy" target="_blank" rel="nofollow">@Policy</a>: GIFCT is committed to disrupting terrorists’ ability to promote terrorism, disseminate violent extremist propaganda, &amp;amp; exploit…',
      timeOfDay: '14:00',
      timestamp: '2019-07-25T12:07:05.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1154362432804007937',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-07-25',
      dayOfWeek: 4,
      id: '1154358864810270720',
      language: 'English',
      length: 176,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@binderstefan',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/BinDerStefan" data-screen-name="BinDerStefan" target="_blank" rel="nofollow">@BinDerStefan</a> Poor Boris.',
      timeOfDay: '13:00',
      timestamp: '2019-07-25T11:52:54.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1154358864810270720',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-07-25',
      dayOfWeek: 4,
      id: '1154353752662532096',
      language: 'German',
      length: 222,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@binderstefan',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/BinDerStefan" data-screen-name="BinDerStefan" target="_blank" rel="nofollow">@BinDerStefan</a> Musste das sein? Ich finde es ist heute schon heiß genug.',
      timeOfDay: '13:00',
      timestamp: '2019-07-25T11:32:36.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1154353752662532096',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-07-25',
      dayOfWeek: 4,
      id: '1154349104459386881',
      language: 'English',
      length: 544,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@horseboytfe',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/HorseboyTFE" data-screen-name="HorseboyTFE" target="_blank" rel="nofollow">@HorseboyTFE</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/wongmjane" data-screen-name="wongmjane" target="_blank" rel="nofollow">@wongmjane</a> Do you have any screenshots or can you change your user agent to Opera and make one? Becaus… <a href="https://t.co/8uGPF3ENLx" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/8uGPF3ENLx</a>',
      timeOfDay: '13:00',
      timestamp: '2019-07-25T11:14:07.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1154349104459386881',
      usedApp: 'Twitter Web Client',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-25',
      dayOfWeek: 4,
      id: '1154347717499465728',
      language: 'English',
      length: 546,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@lindsaypb',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/LindsayPB" data-screen-name="LindsayPB" target="_blank" rel="nofollow">@LindsayPB</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/Hbomberguy" data-screen-name="Hbomberguy" target="_blank" rel="nofollow">@Hbomberguy</a> Sorry for the gloating.\n\nI honestly don&#39;t understand what people hate about the new interfac… <a href="https://t.co/Ve1YG7mVzQ" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/Ve1YG7mVzQ</a>',
      timeOfDay: '13:00',
      timestamp: '2019-07-25T11:08:37.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1154347717499465728',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-25',
      dayOfWeek: 4,
      id: '1154346447023816704',
      language: 'English',
      length: 544,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@horseboytfe',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/HorseboyTFE" data-screen-name="HorseboyTFE" target="_blank" rel="nofollow">@HorseboyTFE</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/wongmjane" data-screen-name="wongmjane" target="_blank" rel="nofollow">@wongmjane</a> Ar you sure? Because I am using the old interface to reply to your Tweet and it looks exact… <a href="https://t.co/7hqSatJhqS" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/7hqSatJhqS</a>',
      timeOfDay: '13:00',
      timestamp: '2019-07-25T11:03:34.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1154346447023816704',
      usedApp: 'Twitter Web Client',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-25',
      dayOfWeek: 4,
      id: '1154329855208710146',
      language: 'Unknown',
      length: 448,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@lindsaypb',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/LindsayPB" data-screen-name="LindsayPB" target="_blank" rel="nofollow">@LindsayPB</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/Hbomberguy" data-screen-name="Hbomberguy" target="_blank" rel="nofollow">@Hbomberguy</a> <a href="https://t.co/x0VRepJV0f" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/x0VRepJV0f</a>',
      timeOfDay: '11:00',
      timestamp: '2019-07-25T09:57:38.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1154329855208710146',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-25',
      dayOfWeek: 4,
      id: '1154329684718555136',
      language: 'English',
      length: 395,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/wongmjane" data-screen-name="wongmjane" target="_blank" rel="nofollow">@wongmjane</a> I am looking forward to Twitter turning off the old Twitter interface in some weeks. When the inevitable… <a href="https://t.co/WzU3HGr9xV" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/WzU3HGr9xV</a>',
      timeOfDay: '11:00',
      timestamp: '2019-07-25T09:56:57.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1154329684718555136',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-25',
      dayOfWeek: 4,
      id: '1154327466321821698',
      language: 'English',
      length: 597,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@wikiresearch',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/WikiResearch" data-screen-name="WikiResearch" target="_blank" rel="nofollow">@WikiResearch</a>: &quot;Mapping the backbone of the humanities through the eyes of <a href="https://twitter.com/search?q=%23Wikipedia" title="#Wikipedia" class="text-brand hover:text-brand-dark" target="_blank" rel="nofollow">#Wikipedia</a>&quot; - A reproducible methodology to map <a href="https://twitter.com/search?q=%23Humanities" title="#Humanities" class="text-brand hover:text-brand-dark" target="_blank" rel="nofollow">#Humanities</a> k…',
      timeOfDay: '11:00',
      timestamp: '2019-07-25T09:48:08.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1154327466321821698',
      usedApp: 'Twitter Web App',
      usedHashtags: ['#Wikipedia', '#Humanities'],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-07-25',
      dayOfWeek: 4,
      id: '1154325175292350464',
      language: 'English',
      length: 399,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@pkreissel',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/pkreissel" data-screen-name="pkreissel" target="_blank" rel="nofollow">@pkreissel</a> I don&#39;t think they use the IP.\n- Violates user privacy. Same as GPS data of mobile devices.\n- Works real… <a href="https://t.co/genzxibZpe" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/genzxibZpe</a>',
      timeOfDay: '11:00',
      timestamp: '2019-07-25T09:39:02.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1154325175292350464',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-25',
      dayOfWeek: 4,
      id: '1154318952258031617',
      language: 'English',
      length: 262,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text:
        'I don&#39;t think that it&#39;s wrong if it&#39;s true, but would prefer them to be more transparent about it.\n\nEg.: Differenti… <a href="https://t.co/Gky0j06T36" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/Gky0j06T36</a>',
      timeOfDay: '11:00',
      timestamp: '2019-07-25T09:14:19.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1154318952258031617',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-25',
      dayOfWeek: 4,
      id: '1154318950769053697',
      language: 'English',
      length: 87,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text: 'Be aware that this is just an assumption at the moment. I will ask Twitter for comment.',
      timeOfDay: '11:00',
      timestamp: '2019-07-25T09:14:18.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1154318950769053697',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-07-25',
      dayOfWeek: 4,
      id: '1154317806499979264',
      language: 'English',
      length: 391,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@shobz',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/shobz" data-screen-name="shobz" target="_blank" rel="nofollow">@shobz</a> I don&#39;t know, but assume that it uses the user input. Else it could unintentionally violate the privacy of u… <a href="https://t.co/lDYQ8zU7w9" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/lDYQ8zU7w9</a>',
      timeOfDay: '11:00',
      timestamp: '2019-07-25T09:09:45.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1154317806499979264',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-25',
      dayOfWeek: 4,
      id: '1154316673303887872',
      language: 'English',
      length: 387,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/shobz" data-screen-name="shobz" target="_blank" rel="nofollow">@shobz</a> The country with the most speakers of that language get the other half, but because of their size the impact… <a href="https://t.co/VDKfM3OYGl" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/VDKfM3OYGl</a>',
      timeOfDay: '11:00',
      timestamp: '2019-07-25T09:05:15.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1154316673303887872',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-25',
      dayOfWeek: 4,
      id: '1154316363214807040',
      language: 'English',
      length: 394,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@shobz',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/shobz" data-screen-name="shobz" target="_blank" rel="nofollow">@shobz</a> I wouldn&#39;t say, it ruins it. When I look at trends of smaller countries that don&#39;t have their own language,… <a href="https://t.co/KvCLa3KeG9" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/KvCLa3KeG9</a>',
      timeOfDay: '11:00',
      timestamp: '2019-07-25T09:04:01.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1154316363214807040',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-25',
      dayOfWeek: 4,
      id: '1154314147053654016',
      language: 'English',
      length: 250,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text:
        'You find many Tweets that Twitter wrongly identified as German. If they are counted as published in Germany, it exp… <a href="https://t.co/OKYZj5KSpB" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/OKYZj5KSpB</a>',
      timeOfDay: '10:00',
      timestamp: '2019-07-25T08:55:13.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1154314147053654016',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-25',
      dayOfWeek: 4,
      id: '1154314015901962240',
      language: 'English',
      length: 356,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text:
        'Example: <a href="https://twitter.com/search?q=%23SeoulTownRoad" title="#SeoulTownRoad" class="text-brand hover:text-brand-dark" target="_blank" rel="nofollow">#SeoulTownRoad</a> is trending in Germany, but not in Austria. <a href="https://t.co/a3VjCeGI9r" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/a3VjCeGI9r</a>',
      timeOfDay: '10:00',
      timestamp: '2019-07-25T08:54:42.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1154314015901962240',
      usedApp: 'Twitter Web App',
      usedHashtags: ['#SeoulTownRoad'],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-07-25',
      dayOfWeek: 4,
      id: '1154314006917763072',
      language: 'English',
      length: 360,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: '@luca',
      repliedUser: null,
      retweetedUser: null,
      text:
        'Remember when I uncovered that Talkwalker uses Tweet language to attribute locations? <a href="https://t.co/36wPYbKBOh" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/36wPYbKBOh</a>\n\nI ha… <a href="https://t.co/CMnhT3JAB0" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/CMnhT3JAB0</a>',
      timeOfDay: '10:00',
      timestamp: '2019-07-25T08:54:40.000Z',
      type: 'Quote',
      url: 'https://twitter.com/luca/status/1154314006917763072',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['twitter.com', 'twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-24',
      dayOfWeek: 3,
      id: '1154118900457594881',
      language: 'English',
      length: 241,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/sriramk" data-screen-name="sriramk" target="_blank" rel="nofollow">@sriramk</a> Just went through their process and was disappointed that they don&#39;t offer Ryzen 9 yet.',
      timeOfDay: '21:00',
      timestamp: '2019-07-24T19:59:23.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1154118900457594881',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-07-24',
      dayOfWeek: 3,
      id: '1154114880917725185',
      language: 'English',
      length: 395,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@sriramk',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/sriramk" data-screen-name="sriramk" target="_blank" rel="nofollow">@sriramk</a> I would recommend my local computer shop, but I don&#39;t think that would help.\n\nI read good things about NZX… <a href="https://t.co/ZrpqMhaTy5" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/ZrpqMhaTy5</a>',
      timeOfDay: '21:00',
      timestamp: '2019-07-24T19:43:24.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1154114880917725185',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-24',
      dayOfWeek: 3,
      id: '1154113951426338816',
      language: 'English',
      length: 385,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@hatr',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/hatr" data-screen-name="hatr" target="_blank" rel="nofollow">@hatr</a>: Re: our joint investigation on Winnti (<a href="https://t.co/hBYKCbIIHa" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/hBYKCbIIHa</a>) here are some technical details in a short thread. We looked at m…',
      timeOfDay: '21:00',
      timestamp: '2019-07-24T19:39:43.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1154113951426338816',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['br24.de'],
      username: 'luca',
    },
    {
      date: '2019-07-24',
      dayOfWeek: 3,
      id: '1154112805248937987',
      language: 'German',
      length: 221,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@buechsenoeffner',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/Buechsenoeffner" data-screen-name="Buechsenoeffner" target="_blank" rel="nofollow">@Buechsenoeffner</a> Und: Ihr? Mit wem wirfst du mich in einen Topf?',
      timeOfDay: '21:00',
      timestamp: '2019-07-24T19:35:09.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1154112805248937987',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-07-24',
      dayOfWeek: 3,
      id: '1154112129731112961',
      language: 'Spanish',
      length: 183,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@buechsenoeffner',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/Buechsenoeffner" data-screen-name="Buechsenoeffner" target="_blank" rel="nofollow">@Buechsenoeffner</a> Mastodon?',
      timeOfDay: '21:00',
      timestamp: '2019-07-24T19:32:28.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1154112129731112961',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-07-24',
      dayOfWeek: 3,
      id: '1154085549097082881',
      language: 'German',
      length: 564,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@buechsenoeffner',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/Buechsenoeffner" data-screen-name="Buechsenoeffner" target="_blank" rel="nofollow">@Buechsenoeffner</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/kriegundfreitag" data-screen-name="kriegundfreitag" target="_blank" rel="nofollow">@kriegundfreitag</a> Gibt zu viele Faktoren als das man das sinnvoll testen könnte. Auch weil die Mens… <a href="https://t.co/yJt2lKgy3t" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/yJt2lKgy3t</a>',
      timeOfDay: '19:00',
      timestamp: '2019-07-24T17:46:51.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1154085549097082881',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-24',
      dayOfWeek: 3,
      id: '1154062090027622404',
      language: 'English',
      length: 283,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@went1955',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/went1955" data-screen-name="went1955" target="_blank" rel="nofollow">@went1955</a>: I’m a data scientist who is skeptical about data. Data is not a perfect representation of reality: It’s a fundamentally human…',
      timeOfDay: '18:00',
      timestamp: '2019-07-24T16:13:38.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1154062090027622404',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-07-24',
      dayOfWeek: 3,
      id: '1154033815364333568',
      language: 'English',
      length: 395,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/baltpeter" data-screen-name="baltpeter" target="_blank" rel="nofollow">@baltpeter</a> Two weeks later. IFPI asked me for proof of my identity. I complied and am awaiting access to the inform… <a href="https://t.co/WYebAlhmfk" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/WYebAlhmfk</a>',
      timeOfDay: '16:00',
      timestamp: '2019-07-24T14:21:17.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1154033815364333568',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-24',
      dayOfWeek: 3,
      id: '1153984460796375040',
      language: 'English',
      length: 289,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@alucaslopez',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/aLucasLopez" data-screen-name="aLucasLopez" target="_blank" rel="nofollow">@aLucasLopez</a>: What does 50 years of human migration look like? The percentage of migrants worldwide has actually held steady at 3% in th…',
      timeOfDay: '13:00',
      timestamp: '2019-07-24T11:05:10.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1153984460796375040',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-07-24',
      dayOfWeek: 3,
      id: '1153983312832225280',
      language: 'German',
      length: 286,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@stormgrass',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/stormgrass" data-screen-name="stormgrass" target="_blank" rel="nofollow">@stormgrass</a>: Diese Woche: Jubiläum! In der 200. Folge Zeitsprung beantworten wir viele, viele Fragen, beinahe 3h lang. Es hat sehr viel…',
      timeOfDay: '13:00',
      timestamp: '2019-07-24T11:00:36.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1153983312832225280',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-07-24',
      dayOfWeek: 3,
      id: '1153960602257252352',
      language: 'English',
      length: 697,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@matthiascremer',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/MatthiasCremer" data-screen-name="MatthiasCremer" target="_blank" rel="nofollow">@MatthiasCremer</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/digiom" data-screen-name="digiom" target="_blank" rel="nofollow">@digiom</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/Technikfolgen" data-screen-name="Technikfolgen" target="_blank" rel="nofollow">@Technikfolgen</a> I remember carefully replacing words with ‚…‘ or just deleting the last word… <a href="https://t.co/ODKSNPG6VU" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/ODKSNPG6VU</a>',
      timeOfDay: '11:00',
      timestamp: '2019-07-24T09:30:21.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1153960602257252352',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-24',
      dayOfWeek: 3,
      id: '1153944234023669760',
      language: 'English',
      length: 488,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@rolfvanroot',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/rolfvanroot" data-screen-name="rolfvanroot" target="_blank" rel="nofollow">@rolfvanroot</a>: How To Use Twitter Search – Advanced Guide by <a class="text-brand hover:text-brand-dark" href="https://twitter.com/luca" data-screen-name="luca" target="_blank" rel="nofollow">@luca</a> \n\n<a href="https://t.co/WRJzFlWLps" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/WRJzFlWLps</a>',
      timeOfDay: '10:00',
      timestamp: '2019-07-24T08:25:19.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1153944234023669760',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['freshvanroot.com'],
      username: 'luca',
    },
    {
      date: '2019-07-24',
      dayOfWeek: 3,
      id: '1153931110734336000',
      language: 'English',
      length: 388,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/digiom" data-screen-name="digiom" target="_blank" rel="nofollow">@digiom</a> My mind tells me I read several similar stories in the past. „I built a monster and this is how I will fix… <a href="https://t.co/XG3hNhQBJy" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/XG3hNhQBJy</a>',
      timeOfDay: '09:00',
      timestamp: '2019-07-24T07:33:10.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1153931110734336000',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-24',
      dayOfWeek: 3,
      id: '1153923465239506944',
      language: 'Danish',
      length: 186,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@markusreuter_',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/markusreuter_" data-screen-name="markusreuter_" target="_blank" rel="nofollow">@markusreuter_</a> Messenger Gruppen?',
      timeOfDay: '09:00',
      timestamp: '2019-07-24T07:02:47.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1153923465239506944',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-07-24',
      dayOfWeek: 3,
      id: '1153922018489880576',
      language: 'English',
      length: 389,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/digiom" data-screen-name="digiom" target="_blank" rel="nofollow">@digiom</a> I am just waiting for a follow up press release: This is his new startup that will fix the internet.\n\nThe o… <a href="https://t.co/iiKq1v6yOV" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/iiKq1v6yOV</a>',
      timeOfDay: '08:00',
      timestamp: '2019-07-24T06:57:02.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1153922018489880576',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-24',
      dayOfWeek: 3,
      id: '1153920524373909504',
      language: 'English',
      length: 389,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@digiom',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/digiom" data-screen-name="digiom" target="_blank" rel="nofollow">@digiom</a> The developer is aware that he neither invented not build it, but that he was part of the team that is resp… <a href="https://t.co/9ZiBrG6JIn" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/9ZiBrG6JIn</a>',
      timeOfDay: '08:00',
      timestamp: '2019-07-24T06:51:06.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1153920524373909504',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-24',
      dayOfWeek: 3,
      id: '1153917460803928064',
      language: 'English',
      length: 289,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@digiom',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/digiom" data-screen-name="digiom" target="_blank" rel="nofollow">@digiom</a>: As everyone using Twitter before 2009 knows: There is no man who &quot;built&quot; or invented the retweet. Users did, by manually adding…',
      timeOfDay: '08:00',
      timestamp: '2019-07-24T06:38:56.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1153917460803928064',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-07-23',
      dayOfWeek: 2,
      id: '1153778449351348226',
      language: 'English',
      length: 399,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@martinsfp',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/MartinSFP" data-screen-name="MartinSFP" target="_blank" rel="nofollow">@MartinSFP</a> Makes it sound, like it&#39;s a technical problem, that can be solved with more tech.\n\nOnly 4% of Germans us… <a href="https://t.co/6BaLZ7IsFE" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/6BaLZ7IsFE</a>',
      timeOfDay: '23:00',
      timestamp: '2019-07-23T21:26:33.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1153778449351348226',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-23',
      dayOfWeek: 2,
      id: '1153774246923055105',
      language: 'German',
      length: 224,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@binderstefan',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/BinDerStefan" data-screen-name="BinDerStefan" target="_blank" rel="nofollow">@BinDerStefan</a> Ne, aber ihr bekommt extra Soße auf euer Schweineschnitzel.',
      timeOfDay: '23:00',
      timestamp: '2019-07-23T21:09:51.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1153774246923055105',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-07-23',
      dayOfWeek: 2,
      id: '1153772462821302272',
      language: 'English',
      length: 542,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@muelld',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/muelld" data-screen-name="muelld" target="_blank" rel="nofollow">@muelld</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/ResearcherApp" data-screen-name="ResearcherApp" target="_blank" rel="nofollow">@ResearcherApp</a> I found journals too noisy and switched to consuming feeds based on keywords. I should go th… <a href="https://t.co/9CiV1WSfNQ" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/9CiV1WSfNQ</a>',
      timeOfDay: '23:00',
      timestamp: '2019-07-23T21:02:45.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1153772462821302272',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-23',
      dayOfWeek: 2,
      id: '1153771957755752449',
      language: 'English',
      length: 554,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@kevslashnull',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/KevSlashNull" data-screen-name="KevSlashNull" target="_blank" rel="nofollow">@KevSlashNull</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/ResearcherApp" data-screen-name="ResearcherApp" target="_blank" rel="nofollow">@ResearcherApp</a> At first I went through all journals to pick interesting ones, but they have all so mu… <a href="https://t.co/fJZWhgOxDy" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/fJZWhgOxDy</a>',
      timeOfDay: '23:00',
      timestamp: '2019-07-23T21:00:45.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1153771957755752449',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-23',
      dayOfWeek: 2,
      id: '1153767922734706688',
      language: 'German',
      length: 393,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@rosinnjo',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/Rosinnjo" data-screen-name="Rosinnjo" target="_blank" rel="nofollow">@Rosinnjo</a> Nein. \n\n(Störe mich daran, dass der Tweet den Eindruck erweckt hier wäre nicht echtes Leben und/oder Mens… <a href="https://t.co/KNhTuE20WU" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/KNhTuE20WU</a>',
      timeOfDay: '22:00',
      timestamp: '2019-07-23T20:44:43.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1153767922734706688',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-23',
      dayOfWeek: 2,
      id: '1153765191903055873',
      language: 'English',
      length: 589,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@nirebu',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/nirebu" data-screen-name="nirebu" target="_blank" rel="nofollow">@nirebu</a>: How I discovered this so late? I think <a href="https://twitter.com/search?q=%23CommandLinePod" title="#CommandLinePod" class="text-brand hover:text-brand-dark" target="_blank" rel="nofollow">#CommandLinePod</a> hosted by <a class="text-brand hover:text-brand-dark" href="https://twitter.com/saronyitbarek" data-screen-name="saronyitbarek" target="_blank" rel="nofollow">@saronyitbarek</a> is one of the highest quality podcasts out ther…',
      timeOfDay: '22:00',
      timestamp: '2019-07-23T20:33:52.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1153765191903055873',
      usedApp: 'Twitter for Android',
      usedHashtags: ['#CommandLinePod'],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-07-23',
      dayOfWeek: 2,
      id: '1153764321815683073',
      language: 'English',
      length: 418,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@shl',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/shl" data-screen-name="shl" target="_blank" rel="nofollow">@shl</a>: We&#39;re looking for a part-time communications person to join us <a class="text-brand hover:text-brand-dark" href="https://twitter.com/gumroad" data-screen-name="gumroad" target="_blank" rel="nofollow">@gumroad</a>! You will be...\n\n- Crafting our public messaging\n- Buildin…',
      timeOfDay: '22:00',
      timestamp: '2019-07-23T20:30:24.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1153764321815683073',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-07-23',
      dayOfWeek: 2,
      id: '1153763869271252994',
      language: 'German',
      length: 196,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@larswienand',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/LarsWienand" data-screen-name="LarsWienand" target="_blank" rel="nofollow">@LarsWienand</a> Ich dachte, sie outen sich selbst.',
      timeOfDay: '22:00',
      timestamp: '2019-07-23T20:28:36.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1153763869271252994',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-07-23',
      dayOfWeek: 2,
      id: '1153763339811659776',
      language: 'English',
      length: 402,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: null,
      text:
        'I replaced my evening scroll through Reddit with a swipe session in <a class="text-brand hover:text-brand-dark" href="https://twitter.com/ResearcherApp" data-screen-name="ResearcherApp" target="_blank" rel="nofollow">@ResearcherApp</a>. Already bookmarked 131 papers.… <a href="https://t.co/9uSLsREezS" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/9uSLsREezS</a>',
      timeOfDay: '22:00',
      timestamp: '2019-07-23T20:26:30.000Z',
      type: 'Tweet',
      url: 'https://twitter.com/luca/status/1153763339811659776',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-23',
      dayOfWeek: 2,
      id: '1153760601518354433',
      language: 'English',
      length: 557,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@noniq',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/noniq" data-screen-name="noniq" target="_blank" rel="nofollow">@noniq</a>: Smarte LED-Panels (Nanoleafs mit Custom Effect) + Apple Home + Siri + Apple Watch. <a href="https://twitter.com/search?q=%23KnightRiderReenactment" title="#KnightRiderReenactment" class="text-brand hover:text-brand-dark" target="_blank" rel="nofollow">#KnightRiderReenactment</a> 🤓😎 <a href="https://t.co/ncROZ" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/ncROZ</a>…',
      timeOfDay: '22:00',
      timestamp: '2019-07-23T20:15:37.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1153760601518354433',
      usedApp: 'Twitter for Android',
      usedHashtags: ['#KnightRiderReenactment'],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-07-23',
      dayOfWeek: 2,
      id: '1153759588870762498',
      language: 'English',
      length: 232,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: null,
      text:
        'On average trending topics stay in the Top 10 for one and a half hour. (arXiv:1907.09007v1)\n[PDF]… <a href="https://t.co/DqvUYpv4vf" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/DqvUYpv4vf</a>',
      timeOfDay: '22:00',
      timestamp: '2019-07-23T20:11:36.000Z',
      type: 'Tweet',
      url: 'https://twitter.com/luca/status/1153759588870762498',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-23',
      dayOfWeek: 2,
      id: '1153633049436971009',
      language: 'German',
      length: 654,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@novemberregen',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/novemberregen" data-screen-name="novemberregen" target="_blank" rel="nofollow">@novemberregen</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/umienne" data-screen-name="umienne" target="_blank" rel="nofollow">@umienne</a> Ich würde über die Follow-Liste gehen. Also <a href="https://t.co/uebJSL8lWP" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/uebJSL8lWP</a> und dann die Accounts a… <a href="https://t.co/AdqgBTDXRx" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/AdqgBTDXRx</a>',
      timeOfDay: '13:00',
      timestamp: '2019-07-23T11:48:47.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1153633049436971009',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['twitter.com', 'twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-23',
      dayOfWeek: 2,
      id: '1153565155629903872',
      language: 'English',
      length: 250,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: null,
      text:
        'Trains are like shared high capacity self driving electric scooters with integrated continuous charging that get yo… <a href="https://t.co/w7dTkUmjEt" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/w7dTkUmjEt</a>',
      timeOfDay: '09:00',
      timestamp: '2019-07-23T07:18:59.000Z',
      type: 'Tweet',
      url: 'https://twitter.com/luca/status/1153565155629903872',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-23',
      dayOfWeek: 2,
      id: '1153543105800474624',
      language: 'English',
      length: 390,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@oppsyirwin',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/oppsyirwin" data-screen-name="oppsyirwin" target="_blank" rel="nofollow">@oppsyirwin</a>: A thread for my non Spanish speaking followers explaining what’s going on in Puerto Rico 🇵🇷 <a href="https://t.co/2K5Qr3GOSu" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/2K5Qr3GOSu</a>',
      timeOfDay: '07:00',
      timestamp: '2019-07-23T05:51:22.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1153543105800474624',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-07-22',
      dayOfWeek: 1,
      id: '1153418294642257921',
      language: 'English',
      length: 295,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@emilygorcenski',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/EmilyGorcenski" data-screen-name="EmilyGorcenski" target="_blank" rel="nofollow">@EmilyGorcenski</a>: ICYMI: Yesterday I discovered that in court documents that Charlottesville attacker James Fields was radicalized online…',
      timeOfDay: '23:00',
      timestamp: '2019-07-22T21:35:25.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1153418294642257921',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-07-22',
      dayOfWeek: 1,
      id: '1153403595301249024',
      language: 'German',
      length: 273,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@techwalt_de',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/techwalt_de" data-screen-name="techwalt_de" target="_blank" rel="nofollow">@techwalt_de</a> Hat Microsoft nicht kürzlich die Azure-Server in Deutschland abgedreht? Oder habe ich das falsch in Erinnerung.',
      timeOfDay: '22:00',
      timestamp: '2019-07-22T20:37:00.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1153403595301249024',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-07-22',
      dayOfWeek: 1,
      id: '1153403108929744898',
      language: 'English',
      length: 675,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@zeynep',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/zeynep" data-screen-name="zeynep" target="_blank" rel="nofollow">@zeynep</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/tdburrell" data-screen-name="tdburrell" target="_blank" rel="nofollow">@tdburrell</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/Twitter" data-screen-name="Twitter" target="_blank" rel="nofollow">@Twitter</a> Adding it as a Quote Tweet works.\n\nSearching for it without removing the client code do… <a href="https://t.co/MADSGr6XcS" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/MADSGr6XcS</a>',
      timeOfDay: '22:00',
      timestamp: '2019-07-22T20:35:05.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1153403108929744898',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-22',
      dayOfWeek: 1,
      id: '1153401708413935616',
      language: 'English',
      length: 512,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@tdburrell',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/tdburrell" data-screen-name="tdburrell" target="_blank" rel="nofollow">@tdburrell</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/zeynep" data-screen-name="zeynep" target="_blank" rel="nofollow">@zeynep</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/Twitter" data-screen-name="Twitter" target="_blank" rel="nofollow">@Twitter</a> The copy URL option is in the share button below the Tweet.',
      timeOfDay: '22:00',
      timestamp: '2019-07-22T20:29:31.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1153401708413935616',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-07-22',
      dayOfWeek: 1,
      id: '1153400486009167873',
      language: 'English',
      length: 335,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@sternenrot',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/Sternenrot" data-screen-name="Sternenrot" target="_blank" rel="nofollow">@Sternenrot</a> Toms Instagame besser als Justins MySpace. <a href="https://t.co/piRrZcJ03J" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/piRrZcJ03J</a>',
      timeOfDay: '22:00',
      timestamp: '2019-07-22T20:24:39.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1153400486009167873',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['www.instagram.com'],
      username: 'luca',
    },
    {
      date: '2019-07-22',
      dayOfWeek: 1,
      id: '1153397495071956995',
      language: 'English',
      length: 275,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@zeynep',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/zeynep" data-screen-name="zeynep" target="_blank" rel="nofollow">@zeynep</a> I&#39;m using the new UI since half a year now and am quite happy with it. It&#39;s faster and more consistent than the old one.',
      timeOfDay: '22:00',
      timestamp: '2019-07-22T20:12:46.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1153397495071956995',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-07-22',
      dayOfWeek: 1,
      id: '1153387205462765570',
      language: 'English',
      length: 281,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@ekp',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/ekp" data-screen-name="ekp" target="_blank" rel="nofollow">@ekp</a>: Just because someone was nice to you or did something good once (or many times) or you didn&#39;t mind his behavior doesn&#39;t mean he di…',
      timeOfDay: '21:00',
      timestamp: '2019-07-22T19:31:53.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1153387205462765570',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-07-22',
      dayOfWeek: 1,
      id: '1153372057201184769',
      language: 'English',
      length: 209,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@rolfvanroot',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/rolfvanroot" data-screen-name="rolfvanroot" target="_blank" rel="nofollow">@rolfvanroot</a> Not enough experience in B2B PR. How about you?',
      timeOfDay: '20:00',
      timestamp: '2019-07-22T18:31:41.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1153372057201184769',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-07-22',
      dayOfWeek: 1,
      id: '1153370275075055616',
      language: 'English',
      length: 193,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: '@kersting',
      repliedUser: null,
      retweetedUser: null,
      text:
        'Twitter is looking for a Head of Communications in Germany. <a href="https://t.co/DL9Xtvbi3R" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/DL9Xtvbi3R</a>',
      timeOfDay: '20:00',
      timestamp: '2019-07-22T18:24:36.000Z',
      type: 'Quote',
      url: 'https://twitter.com/luca/status/1153370275075055616',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-22',
      dayOfWeek: 1,
      id: '1153362674572550146',
      language: 'English',
      length: 279,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@nivi',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/nivi" data-screen-name="nivi" target="_blank" rel="nofollow">@nivi</a>: &quot;Confirmation bias: If I am selective about which arguments I inspect for errors, or even how hard I inspect for errors, then...…',
      timeOfDay: '19:00',
      timestamp: '2019-07-22T17:54:24.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1153362674572550146',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-07-22',
      dayOfWeek: 1,
      id: '1153353571947225088',
      language: 'German',
      length: 297,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@natascha_strobl',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/Natascha_Strobl" data-screen-name="Natascha_Strobl" target="_blank" rel="nofollow">@Natascha_Strobl</a>: Alles was ich hier schreibe hat Konsequenzen. Ich werde darauf angesprochen. Nicht nur bei politischen Terminen, sonde…',
      timeOfDay: '19:00',
      timestamp: '2019-07-22T17:18:14.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1153353571947225088',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-07-22',
      dayOfWeek: 1,
      id: '1153352783380324352',
      language: 'English',
      length: 299,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@jesslynnrose',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/jesslynnrose" data-screen-name="jesslynnrose" target="_blank" rel="nofollow">@jesslynnrose</a>: Looking for work in tech but can&#39;t be open about it? DM me a Tweet-length summary (desired title/skills/location) and I&#39;l…',
      timeOfDay: '19:00',
      timestamp: '2019-07-22T17:15:06.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1153352783380324352',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-07-22',
      dayOfWeek: 1,
      id: '1153336615101784064',
      language: 'English',
      length: 58,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: null,
      text: 'How do I block all videos with a specific sound on TikTok?',
      timeOfDay: '18:00',
      timestamp: '2019-07-22T16:10:51.000Z',
      type: 'Tweet',
      url: 'https://twitter.com/luca/status/1153336615101784064',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-07-22',
      dayOfWeek: 1,
      id: '1153283939747979269',
      language: 'German',
      length: 274,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/AndrevonZDDK" data-screen-name="AndrevonZDDK" target="_blank" rel="nofollow">@AndrevonZDDK</a> &quot;ATA Secure Erase&quot; ist dein Keyword. Allerdings haben es wohl nicht alle SSDs sauber implementiert.',
      timeOfDay: '14:00',
      timestamp: '2019-07-22T12:41:32.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1153283939747979269',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-07-22',
      dayOfWeek: 1,
      id: '1153283091991007234',
      language: 'German',
      length: 401,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@andrevonzddk',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/AndrevonZDDK" data-screen-name="AndrevonZDDK" target="_blank" rel="nofollow">@AndrevonZDDK</a> Wenn die Daten noch nicht drauf sind: Full Disk Encryption verhindert, dass sie wiederhergestellt wer… <a href="https://t.co/YHQrbIGLKE" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/YHQrbIGLKE</a>',
      timeOfDay: '14:00',
      timestamp: '2019-07-22T12:38:10.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1153283091991007234',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-22',
      dayOfWeek: 1,
      id: '1153194319744053249',
      language: 'German',
      length: 844,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@jhaslauer',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/jhaslauer" data-screen-name="jhaslauer" target="_blank" rel="nofollow">@jhaslauer</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/GerlindeInaktiv" data-screen-name="GerlindeInaktiv" target="_blank" rel="nofollow">@GerlindeInaktiv</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/derKirchner" data-screen-name="derKirchner" target="_blank" rel="nofollow">@derKirchner</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/squadrat" data-screen-name="squadrat" target="_blank" rel="nofollow">@squadrat</a> Und nach DSGVO dürfen sie die Daten sowieso nicht anders verwert… <a href="https://t.co/m9eQ1SDVXN" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/m9eQ1SDVXN</a>',
      timeOfDay: '08:00',
      timestamp: '2019-07-22T06:45:25.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1153194319744053249',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-21',
      dayOfWeek: 0,
      id: '1153036334619123713',
      language: 'German',
      length: 554,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@vondentillewech',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/vondentillewech" data-screen-name="vondentillewech" target="_blank" rel="nofollow">@vondentillewech</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/Sternenrot" data-screen-name="Sternenrot" target="_blank" rel="nofollow">@Sternenrot</a> Achso, um die 15W. Also etwa ein Viertel. Schon ziemlich ein Unterschied.\n\nEin Compute… <a href="https://t.co/ybRCzbsxg8" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/ybRCzbsxg8</a>',
      timeOfDay: '22:00',
      timestamp: '2019-07-21T20:17:39.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1153036334619123713',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-21',
      dayOfWeek: 0,
      id: '1153032977527529472',
      language: 'German',
      length: 418,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@vondentillewech',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/vondentillewech" data-screen-name="vondentillewech" target="_blank" rel="nofollow">@vondentillewech</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/Sternenrot" data-screen-name="Sternenrot" target="_blank" rel="nofollow">@Sternenrot</a> Noch nie einen Fön als Lampe verwendet. Wie viel Watt braucht eine vergleichbare LED?',
      timeOfDay: '22:00',
      timestamp: '2019-07-21T20:04:18.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1153032977527529472',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-07-21',
      dayOfWeek: 0,
      id: '1153005150275084288',
      language: 'German',
      length: 542,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/ose_rouge" data-screen-name="ose_rouge" target="_blank" rel="nofollow">@ose_rouge</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/Sternenrot" data-screen-name="Sternenrot" target="_blank" rel="nofollow">@Sternenrot</a> Ein populäres System ist Philips Hue und wird auch von Drittherstellern unterstützt. Braucht… <a href="https://t.co/gx5o4dC0AP" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/gx5o4dC0AP</a>',
      timeOfDay: '20:00',
      timestamp: '2019-07-21T18:13:44.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1153005150275084288',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-21',
      dayOfWeek: 0,
      id: '1153005101059051520',
      language: 'German',
      length: 542,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@ose_rouge',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/ose_rouge" data-screen-name="ose_rouge" target="_blank" rel="nofollow">@ose_rouge</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/Sternenrot" data-screen-name="Sternenrot" target="_blank" rel="nofollow">@Sternenrot</a> Smart nachrüsten ist meist einfach. Großteil der Lampen sind glorifizierte Verlängerungskabe… <a href="https://t.co/YYVoS0CTbC" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/YYVoS0CTbC</a>',
      timeOfDay: '20:00',
      timestamp: '2019-07-21T18:13:32.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1153005101059051520',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-21',
      dayOfWeek: 0,
      id: '1152992546425098240',
      language: 'German',
      length: 397,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@sternenrot',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/Sternenrot" data-screen-name="Sternenrot" target="_blank" rel="nofollow">@Sternenrot</a> Achte darauf, dass die Leuchtmittel nicht fest verbaut sind und keine komische Fassung haben. E14, E27,… <a href="https://t.co/fGusocqKpe" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/fGusocqKpe</a>',
      timeOfDay: '19:00',
      timestamp: '2019-07-21T17:23:39.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1152992546425098240',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-21',
      dayOfWeek: 0,
      id: '1152969041675456512',
      language: 'English',
      length: 369,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@literalplus',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/literalplus" data-screen-name="literalplus" target="_blank" rel="nofollow">@literalplus</a> &quot;ratio&#39;d&quot; may be the better term, when referring to Tweets. <a href="https://t.co/4IzvolICBD" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/4IzvolICBD</a>',
      timeOfDay: '17:00',
      timestamp: '2019-07-21T15:50:15.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1152969041675456512',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-21',
      dayOfWeek: 0,
      id: '1152968624052813825',
      language: 'English',
      length: 213,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@christophjanke',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/ChristophJanke" data-screen-name="ChristophJanke" target="_blank" rel="nofollow">@ChristophJanke</a> I am slightly confused. What are comments?',
      timeOfDay: '17:00',
      timestamp: '2019-07-21T15:48:35.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1152968624052813825',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-07-21',
      dayOfWeek: 0,
      id: '1152965977065361408',
      language: 'English',
      length: 44,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: null,
      text: 'When you talk about ratio, what do you mean?',
      timeOfDay: '17:00',
      timestamp: '2019-07-21T15:38:04.000Z',
      type: 'Tweet',
      url: 'https://twitter.com/luca/status/1152965977065361408',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-07-21',
      dayOfWeek: 0,
      id: '1152959389747306496',
      language: 'English',
      length: 303,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@jschwabish',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/jschwabish" data-screen-name="jschwabish" target="_blank" rel="nofollow">@jschwabish</a> Room Bold? <a href="https://t.co/9QsRkHHwOI" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/9QsRkHHwOI</a>',
      timeOfDay: '17:00',
      timestamp: '2019-07-21T15:11:54.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1152959389747306496',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['www.myfonts.com'],
      username: 'luca',
    },
    {
      date: '2019-07-21',
      dayOfWeek: 0,
      id: '1152958291997351936',
      language: 'English',
      length: 184,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text:
        'Encoding it works, but where is the fun in that. <a href="https://t.co/UiB160ahGw" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/UiB160ahGw</a> .',
      timeOfDay: '17:00',
      timestamp: '2019-07-21T15:07:32.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1152958291997351936',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['zws.im'],
      username: 'luca',
    },
    {
      date: '2019-07-21',
      dayOfWeek: 0,
      id: '1152957596015484929',
      language: 'English',
      length: 280,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@kevslashnull',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/KevSlashNull" data-screen-name="KevSlashNull" target="_blank" rel="nofollow">@KevSlashNull</a> Same. Whitespace means end of URL to Twitter. No matter if it&#39;s zero width or not. Would say, that&#39;s right.',
      timeOfDay: '17:00',
      timestamp: '2019-07-21T15:04:46.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1152957596015484929',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-07-21',
      dayOfWeek: 0,
      id: '1152956973513629697',
      language: 'English',
      length: 81,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text: 'That didn&#39;t work. Twitter sees the whitespace and thinks the URL is finished.',
      timeOfDay: '17:00',
      timestamp: '2019-07-21T15:02:18.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1152956973513629697',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-07-21',
      dayOfWeek: 0,
      id: '1152956203976273920',
      language: 'English',
      length: 250,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: '@rrhoover',
      repliedUser: null,
      retweetedUser: null,
      text:
        'At first I thought about all the bad things one could do with such a shortener, but then I reminded myself, that ev… <a href="https://t.co/XeBmCy0YHi" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/XeBmCy0YHi</a>',
      timeOfDay: '16:00',
      timestamp: '2019-07-21T14:59:14.000Z',
      type: 'Quote',
      url: 'https://twitter.com/luca/status/1152956203976273920',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-21',
      dayOfWeek: 0,
      id: '1152942665337585664',
      language: 'German',
      length: 485,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@i_am_fabs',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/i_am_fabs" data-screen-name="i_am_fabs" target="_blank" rel="nofollow">@i_am_fabs</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/jollife" data-screen-name="jollife" target="_blank" rel="nofollow">@jollife</a> Amazon hat es. Bewertungen sind durchwachsen. <a href="https://t.co/B5NeUNSJAo" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/B5NeUNSJAo</a>',
      timeOfDay: '16:00',
      timestamp: '2019-07-21T14:05:26.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1152942665337585664',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['www.amazon.de'],
      username: 'luca',
    },
    {
      date: '2019-07-21',
      dayOfWeek: 0,
      id: '1152941910694223872',
      language: 'German',
      length: 536,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@jollife',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/jollife" data-screen-name="jollife" target="_blank" rel="nofollow">@jollife</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/i_am_fabs" data-screen-name="i_am_fabs" target="_blank" rel="nofollow">@i_am_fabs</a> Kurz gegooglet, weil es mich interessiert hat. Mighty um 90€ ist ein Spotify offline Player zum… <a href="https://t.co/1H88R43ROn" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/1H88R43ROn</a>',
      timeOfDay: '16:00',
      timestamp: '2019-07-21T14:02:26.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1152941910694223872',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-21',
      dayOfWeek: 0,
      id: '1152850845928759296',
      language: 'English',
      length: 161,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@lstrojny',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/lstrojny" data-screen-name="lstrojny" target="_blank" rel="nofollow">@lstrojny</a>: Nein',
      timeOfDay: '10:00',
      timestamp: '2019-07-21T08:00:35.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1152850845928759296',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-07-21',
      dayOfWeek: 0,
      id: '1152822925399068673',
      language: 'English',
      length: 297,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@swiftonsecurity',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/SwiftOnSecurity" data-screen-name="SwiftOnSecurity" target="_blank" rel="nofollow">@SwiftOnSecurity</a>: I use this and recommend it. Get the Titan key bundle and another one or two U2F keys to keep in safe places. Enroll y…',
      timeOfDay: '08:00',
      timestamp: '2019-07-21T06:09:38.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1152822925399068673',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-07-21',
      dayOfWeek: 0,
      id: '1152820631400005633',
      language: 'English',
      length: 610,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/kayvz" data-screen-name="kayvz" target="_blank" rel="nofollow">@kayvz</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/TwitterSafety" data-screen-name="TwitterSafety" target="_blank" rel="nofollow">@TwitterSafety</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/Twitter" data-screen-name="Twitter" target="_blank" rel="nofollow">@Twitter</a> Older example: <a href="https://t.co/2UyFRsxjAx" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/2UyFRsxjAx</a>',
      timeOfDay: '08:00',
      timestamp: '2019-07-21T06:00:31.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1152820631400005633',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-21',
      dayOfWeek: 0,
      id: '1152820410003726337',
      language: 'English',
      length: 681,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@kayvz',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/kayvz" data-screen-name="kayvz" target="_blank" rel="nofollow">@kayvz</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/TwitterSafety" data-screen-name="TwitterSafety" target="_blank" rel="nofollow">@TwitterSafety</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/Twitter" data-screen-name="Twitter" target="_blank" rel="nofollow">@Twitter</a> Another example of a Tweet that is unavailable for no reason.\n\nThe one after this on… <a href="https://t.co/prOqY0jJEm" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/prOqY0jJEm</a>',
      timeOfDay: '07:00',
      timestamp: '2019-07-21T05:59:38.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1152820410003726337',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-21',
      dayOfWeek: 0,
      id: '1152817900979441664',
      language: 'English',
      length: 416,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@andreasklinger',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/andreasklinger" data-screen-name="andreasklinger" target="_blank" rel="nofollow">@andreasklinger</a>: &quot;What about Farting in space? - It does not propel you. Astronauts have tried.&quot;\n\nWhat a thread 🙀 <a href="https://t.co/sDRk4TIHhv" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/sDRk4TIHhv</a>',
      timeOfDay: '07:00',
      timestamp: '2019-07-21T05:49:40.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1152817900979441664',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-21',
      dayOfWeek: 0,
      id: '1152805653393620992',
      language: 'English',
      length: 281,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@icijorg',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/ICIJorg" data-screen-name="ICIJorg" target="_blank" rel="nofollow">@ICIJorg</a>: Here’s one story behind the difficult, and often dangerous, journey of investigating the dark money middle-men managing the fi…',
      timeOfDay: '07:00',
      timestamp: '2019-07-21T05:01:00.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1152805653393620992',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-07-21',
      dayOfWeek: 0,
      id: '1152801967556759552',
      language: 'English',
      length: 438,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@ekvraga',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/ekvraga" data-screen-name="ekvraga" target="_blank" rel="nofollow">@ekvraga</a>: Hot off the press! <a class="text-brand hover:text-brand-dark" href="https://twitter.com/tullyme" data-screen-name="tullyme" target="_blank" rel="nofollow">@tullyme</a> &amp;amp; I examine the relationship between news literacy (NL), seeing &amp;amp; sharing political information on…',
      timeOfDay: '06:00',
      timestamp: '2019-07-21T04:46:21.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1152801967556759552',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-07-20',
      dayOfWeek: 6,
      id: '1152677352096313346',
      language: 'English',
      length: 202,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@drx',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/drx" data-screen-name="drx" target="_blank" rel="nofollow">@drx</a>: downward spiral:  getting sad about feeling sorry for myself',
      timeOfDay: '22:00',
      timestamp: '2019-07-20T20:31:11.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1152677352096313346',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-07-20',
      dayOfWeek: 6,
      id: '1152615977865109510',
      language: 'English',
      length: 250,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text:
        'I am able to quote tweet the account, because I manually added a user string.\n\nTweet-IDs are unique. Twitter does n… <a href="https://t.co/UnSWWTdbkm" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/UnSWWTdbkm</a>',
      timeOfDay: '18:00',
      timestamp: '2019-07-20T16:27:18.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1152615977865109510',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-20',
      dayOfWeek: 6,
      id: '1152600432583282695',
      language: 'English',
      length: 138,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: '@',
      repliedUser: null,
      retweetedUser: null,
      text:
        'Test <a href="https://t.co/A47eow45Nd" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/A47eow45Nd</a>',
      timeOfDay: '17:00',
      timestamp: '2019-07-20T15:25:32.000Z',
      type: 'Quote',
      url: 'https://twitter.com/luca/status/1152600432583282695',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-20',
      dayOfWeek: 6,
      id: '1152582004917100544',
      language: 'English',
      length: 402,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@washingtonpost',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/washingtonpost" data-screen-name="washingtonpost" target="_blank" rel="nofollow">@washingtonpost</a>: Analysis: Terrorism does increase with immigration — but only homegrown, right-wing terrorism <a href="https://t.co/atp7wS7T1U" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/atp7wS7T1U</a>',
      timeOfDay: '16:00',
      timestamp: '2019-07-20T14:12:18.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1152582004917100544',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['wapo.st'],
      username: 'luca',
    },
    {
      date: '2019-07-20',
      dayOfWeek: 6,
      id: '1152575252330930177',
      language: 'English',
      length: 254,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: null,
      text:
        'Inline Tweet box is back. I didn&#39;t miss it. I always thought it was a bit confusing that there are two different op… <a href="https://t.co/g3ZboZuRzS" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/g3ZboZuRzS</a>',
      timeOfDay: '15:00',
      timestamp: '2019-07-20T13:45:28.000Z',
      type: 'Tweet',
      url: 'https://twitter.com/luca/status/1152575252330930177',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-20',
      dayOfWeek: 6,
      id: '1152541820385144832',
      language: 'German',
      length: 221,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@sibylleberg',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/SibylleBerg" data-screen-name="SibylleBerg" target="_blank" rel="nofollow">@SibylleBerg</a> Geht nur mit Custom CSS. Browsererweiterung oder ähnliches.',
      timeOfDay: '13:00',
      timestamp: '2019-07-20T11:32:37.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1152541820385144832',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-07-19',
      dayOfWeek: 5,
      id: '1152309195036925952',
      language: 'English',
      length: 231,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@swiftonsecurity',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/SwiftOnSecurity" data-screen-name="SwiftOnSecurity" target="_blank" rel="nofollow">@SwiftOnSecurity</a>: Stop dunking on idiots who’ve already been dunked on.',
      timeOfDay: '22:00',
      timestamp: '2019-07-19T20:08:15.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1152309195036925952',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-07-19',
      dayOfWeek: 5,
      id: '1152296035290628097',
      language: 'English',
      length: 350,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@fredguesner',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/fredguesner" data-screen-name="fredguesner" target="_blank" rel="nofollow">@fredguesner</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/Twitter" data-screen-name="Twitter" target="_blank" rel="nofollow">@Twitter</a> The old interface won&#39;t come back.',
      timeOfDay: '21:00',
      timestamp: '2019-07-19T19:15:58.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1152296035290628097',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-07-19',
      dayOfWeek: 5,
      id: '1152276225693573121',
      language: 'English',
      length: 303,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: null,
      retweetedUser: '@icelandicecon',
      text:
        'RT <a class="text-brand hover:text-brand-dark" href="https://twitter.com/IcelandicEcon" data-screen-name="IcelandicEcon" target="_blank" rel="nofollow">@IcelandicEcon</a>: The glacier &quot;Ok&quot; used to be a glacier but lost its status as such in 2014 when it had shrunk too much. This is a brand n…',
      timeOfDay: '19:00',
      timestamp: '2019-07-19T17:57:15.000Z',
      type: 'Retweet',
      url: 'https://twitter.com/luca/status/1152276225693573121',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-07-19',
      dayOfWeek: 5,
      id: '1152256327755980801',
      language: 'English',
      length: 210,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@matt_mollinedo',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/matt_mollinedo" data-screen-name="matt_mollinedo" target="_blank" rel="nofollow">@matt_mollinedo</a> Can you give me an example for clutter?',
      timeOfDay: '18:00',
      timestamp: '2019-07-19T16:38:11.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1152256327755980801',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-07-19',
      dayOfWeek: 5,
      id: '1152250093086674945',
      language: 'English',
      length: 466,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@odannyboy',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/odannyboy" data-screen-name="odannyboy" target="_blank" rel="nofollow">@odannyboy</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/hondanhon" data-screen-name="hondanhon" target="_blank" rel="nofollow">@hondanhon</a> Probably the source: <a href="https://t.co/NpN7ZV5Ic0" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/NpN7ZV5Ic0</a>',
      timeOfDay: '18:00',
      timestamp: '2019-07-19T16:13:24.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1152250093086674945',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['www.reddit.com'],
      username: 'luca',
    },
    {
      date: '2019-07-19',
      dayOfWeek: 5,
      id: '1152248647679139840',
      language: 'English',
      length: 231,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@avi_bueno',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/Avi_Bueno" data-screen-name="Avi_Bueno" target="_blank" rel="nofollow">@Avi_Bueno</a> It&#39;s not clutter. Those are the most used links.\n\nSubmenus hide things.',
      timeOfDay: '18:00',
      timestamp: '2019-07-19T16:07:40.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1152248647679139840',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-07-19',
      dayOfWeek: 5,
      id: '1152248170639953920',
      language: 'English',
      length: 554,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@avi_bueno',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/Avi_Bueno" data-screen-name="Avi_Bueno" target="_blank" rel="nofollow">@Avi_Bueno</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/RVAwonk" data-screen-name="RVAwonk" target="_blank" rel="nofollow">@RVAwonk</a> Because they went for a left to right flow. \n\nMain menu &amp;gt; secondary menu &amp;gt; content\n\nIt&#39;s the sa… <a href="https://t.co/Bn1CBiOoBn" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/Bn1CBiOoBn</a>',
      timeOfDay: '18:00',
      timestamp: '2019-07-19T16:05:46.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1152248170639953920',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-19',
      dayOfWeek: 5,
      id: '1152246774343589888',
      language: 'English',
      length: 366,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@avi_bueno',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/Avi_Bueno" data-screen-name="Avi_Bueno" target="_blank" rel="nofollow">@Avi_Bueno</a> Not for people who use multiple windows side by side. Or on smaller screens. <a href="https://t.co/b5H4nfBhac" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/b5H4nfBhac</a>',
      timeOfDay: '18:00',
      timestamp: '2019-07-19T16:00:13.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1152246774343589888',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-07-19',
      dayOfWeek: 5,
      id: '1152246484882087936',
      language: 'English',
      length: 536,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@avi_bueno',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/Avi_Bueno" data-screen-name="Avi_Bueno" target="_blank" rel="nofollow">@Avi_Bueno</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/RVAwonk" data-screen-name="RVAwonk" target="_blank" rel="nofollow">@RVAwonk</a> The old DM inbox was so bad. Especially when you had multiple conversations at the same time. I… <a href="https://t.co/GFSvauHjwN" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/GFSvauHjwN</a>',
      timeOfDay: '17:00',
      timestamp: '2019-07-19T15:59:04.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1152246484882087936',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-19',
      dayOfWeek: 5,
      id: '1152245037423611907',
      language: 'English',
      length: 399,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@avi_bueno',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/Avi_Bueno" data-screen-name="Avi_Bueno" target="_blank" rel="nofollow">@Avi_Bueno</a> The new menu has 9 instead of 6 options and it&#39;s easier to add more in the future. Cramming everything i… <a href="https://t.co/wKRY0v8rOi" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/wKRY0v8rOi</a>',
      timeOfDay: '17:00',
      timestamp: '2019-07-19T15:53:19.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1152245037423611907',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-19',
      dayOfWeek: 5,
      id: '1152244198705418240',
      language: 'English',
      length: 540,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@avi_bueno',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/Avi_Bueno" data-screen-name="Avi_Bueno" target="_blank" rel="nofollow">@Avi_Bueno</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/RVAwonk" data-screen-name="RVAwonk" target="_blank" rel="nofollow">@RVAwonk</a> You couldn&#39;t leave while typing. Only exception being when using the inline field on top of the… <a href="https://t.co/MfpI5H8Qhs" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/MfpI5H8Qhs</a>',
      timeOfDay: '17:00',
      timestamp: '2019-07-19T15:49:59.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1152244198705418240',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-19',
      dayOfWeek: 5,
      id: '1152243045154328576',
      language: 'English',
      length: 395,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@avi_bueno',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/Avi_Bueno" data-screen-name="Avi_Bueno" target="_blank" rel="nofollow">@Avi_Bueno</a> Probable reason for this is the complete rewrite in the background. They made everything into components… <a href="https://t.co/sisQL6z6h9" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/sisQL6z6h9</a>',
      timeOfDay: '17:00',
      timestamp: '2019-07-19T15:45:24.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1152243045154328576',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-19',
      dayOfWeek: 5,
      id: '1152242120574603264',
      language: 'English',
      length: 258,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@rvawonk',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/RVAwonk" data-screen-name="RVAwonk" target="_blank" rel="nofollow">@RVAwonk</a> How long will it take until someone quotes this out of context? &quot;RVAwonk doesn&#39;t like change.&quot;',
      timeOfDay: '17:00',
      timestamp: '2019-07-19T15:41:43.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1152242120574603264',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-07-19',
      dayOfWeek: 5,
      id: '1152240921423032321',
      language: 'English',
      length: 399,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@luca',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/RVAwonk" data-screen-name="RVAwonk" target="_blank" rel="nofollow">@RVAwonk</a> Advanced search doesn&#39;t look good, but this will probably be fixed in the future. I didn&#39;t really notice i… <a href="https://t.co/aJOVSLzGpI" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/aJOVSLzGpI</a>',
      timeOfDay: '17:00',
      timestamp: '2019-07-19T15:36:57.000Z',
      type: 'Self-Reply',
      url: 'https://twitter.com/luca/status/1152240921423032321',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-19',
      dayOfWeek: 5,
      id: '1152240894927618048',
      language: 'English',
      length: 389,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@rvawonk',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/RVAwonk" data-screen-name="RVAwonk" target="_blank" rel="nofollow">@RVAwonk</a> The old one had many boxes as well. But we were so used to them, that we didn&#39;t notice them any more. <a href="https://t.co/x2Cut44DRO" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/x2Cut44DRO</a>',
      timeOfDay: '17:00',
      timestamp: '2019-07-19T15:36:51.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1152240894927618048',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-19',
      dayOfWeek: 5,
      id: '1152238808768888833',
      language: 'English',
      length: 220,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@rvawonk',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/RVAwonk" data-screen-name="RVAwonk" target="_blank" rel="nofollow">@RVAwonk</a> It&#39;s faster, cleaner and more consistent.\n\nWhy do you not like it?',
      timeOfDay: '17:00',
      timestamp: '2019-07-19T15:28:34.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1152238808768888833',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-07-19',
      dayOfWeek: 5,
      id: '1152238569827815424',
      language: 'German',
      length: 509,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@sigi_maurer',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/sigi_maurer" data-screen-name="sigi_maurer" target="_blank" rel="nofollow">@sigi_maurer</a> Irgendwann baue ich <a href="https://t.co/5upfjRrsBx" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/5upfjRrsBx</a> zuende, damit man sich die Follows in eine Liste synchroni… <a href="https://t.co/ygSuMMdPGP" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/ygSuMMdPGP</a>',
      timeOfDay: '17:00',
      timestamp: '2019-07-19T15:27:37.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1152238569827815424',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['follow-management.glitch.me', 'twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-19',
      dayOfWeek: 5,
      id: '1152216879521513477',
      language: 'in',
      length: 290,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@riptari',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/riptari" data-screen-name="riptari" target="_blank" rel="nofollow">@riptari</a> Kinda. <a href="https://t.co/cxp9WKP54K" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/cxp9WKP54K</a>',
      timeOfDay: '16:00',
      timestamp: '2019-07-19T14:01:25.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1152216879521513477',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-07-19',
      dayOfWeek: 5,
      id: '1152172866248630274',
      language: 'English',
      length: 192,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@martinsfp',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/MartinSFP" data-screen-name="MartinSFP" target="_blank" rel="nofollow">@MartinSFP</a> Or a little checkmark: Not a person.',
      timeOfDay: '13:00',
      timestamp: '2019-07-19T11:06:32.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1152172866248630274',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
    {
      date: '2019-07-19',
      dayOfWeek: 5,
      id: '1152171433763778560',
      language: 'German',
      length: 552,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@polyvagaltheori',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/polyvagaltheori" data-screen-name="polyvagaltheori" target="_blank" rel="nofollow">@polyvagaltheori</a> <a class="text-brand hover:text-brand-dark" href="https://twitter.com/Schmidtlepp" data-screen-name="Schmidtlepp" target="_blank" rel="nofollow">@Schmidtlepp</a> Jede CO2 Emissionen muss kompensiert werden. Etwa durch das Pflanzen von Bäumen. \n\n<a href="https://t.co/iQTvnG1HNf" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/iQTvnG1HNf</a>',
      timeOfDay: '13:00',
      timestamp: '2019-07-19T11:00:50.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1152171433763778560',
      usedApp: 'Twitter for Android',
      usedHashtags: [],
      usedHostnames: ['de.m.wikipedia.org'],
      username: 'luca',
    },
    {
      date: '2019-07-19',
      dayOfWeek: 5,
      id: '1152157906130939904',
      language: 'German',
      length: 509,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@schmidtlepp',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/Schmidtlepp" data-screen-name="Schmidtlepp" target="_blank" rel="nofollow">@Schmidtlepp</a> Kopenhagen möchte bis 2025 CO2-neutral werden. Und sie haben einen Plan. <a href="https://t.co/CHEAf74e8s" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/CHEAf74e8s</a>\n\n(Hab… <a href="https://t.co/0cZNWDasY9" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/0cZNWDasY9</a>',
      timeOfDay: '12:00',
      timestamp: '2019-07-19T10:07:05.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1152157906130939904',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['stateofgreen.com', 'twitter.com'],
      username: 'luca',
    },
    {
      date: '2019-07-19',
      dayOfWeek: 5,
      id: '1152156502834200577',
      language: 'Unknown',
      length: 286,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@blubball',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/blubball" data-screen-name="blubball" target="_blank" rel="nofollow">@blubball</a> <a href="https://t.co/me0bT3S0df" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/me0bT3S0df</a>',
      timeOfDay: '12:00',
      timestamp: '2019-07-19T10:01:30.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1152156502834200577',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: ['gist.github.com'],
      username: 'luca',
    },
    {
      date: '2019-07-19',
      dayOfWeek: 5,
      id: '1152156057810198529',
      language: 'German',
      length: 222,
      name: 'Luca Hammer',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
      quotedUser: null,
      repliedUser: '@bloodylauch',
      retweetedUser: null,
      text:
        '<a class="text-brand hover:text-brand-dark" href="https://twitter.com/bloodyLauch" data-screen-name="bloodyLauch" target="_blank" rel="nofollow">@bloodyLauch</a> Wahrscheinlich: Autor_in &amp;gt; Mentioned &amp;gt; Follow.',
      timeOfDay: '11:00',
      timestamp: '2019-07-19T09:59:44.000Z',
      type: 'Reply',
      url: 'https://twitter.com/luca/status/1152156057810198529',
      usedApp: 'Twitter Web App',
      usedHashtags: [],
      usedHostnames: [],
      username: 'luca',
    },
  ],
  tweetsError: false,
  tweetsFetches: 4,
  tweetsLoading: false,
  tweetsLoadingMore: false,
  user: {
    bioUrl: 'https://lucahammer.com/',
    createdAt: 'Tue Jan 08 14:18:35 +0000 2008',
    description:
      'Data analyst. Working on <a class="text-brand hover:text-brand-dark" href="https://twitter.com/accountanalysis" data-screen-name="accountanalysis" target="_blank" rel="nofollow">@accountanalysis</a>. A tool to evaluate Twitter accounts.\n\n📜 Code of Conduct: <a href="https://t.co/LrodE5b3wK" target="_blank" class="text-brand hover:text-brand-dark" rel="nofollow">https://t.co/LrodE5b3wK</a>…',
    followersCount: 13146,
    followingCount: 1815,
    hasDefaultProfile: false,
    hasDefaultProfileImage: false,
    id: '11985982',
    isProtected: false,
    isVerified: true,
    likedCount: 21798,
    listedCount: 882,
    location: 'Altenbeken, Germany',
    name: 'Luca Hammer',
    profileImageUrl: 'https://pbs.twimg.com/profile_images/1055807149786439680/sQiHu-95_normal.jpg',
    tweetsCount: 59556,
    username: 'luca',
  },
  userError: false,
  userLoading: false,
  userNotFound: false,
  username: 'luca',
};

export default storeSnapshot;
