import React from 'react';

import ContentPageHeader from '../ContentPageItems/ContentPageHeader';
import ContentPageLayout from '../ContentPageItems/ContentPageLayout';
import ContentPageSection from '../ContentPageItems/ContentPageSection';
import ContentPageParagraph from '../ContentPageItems/ContentPageParagraph';
import ContentPageSectionHeader from '../ContentPageItems/ContentPageSectionHeader';
import ContentPageParagraphLink from '../ContentPageItems/ContentPageParagraphLink';
import envConfig from '../../config/env';

type Props = {};

const About = (_props: Props) => {
  return (
    <ContentPageLayout>
      <ContentPageHeader>About</ContentPageHeader>

      <ContentPageSection>
        <ContentPageParagraph>
          {envConfig.appName} is a tool to evaluate social media accounts. Currently, it allows users to analyze Twitter profiles. It is
          used by journalists, academics, marketers and other individuals to quickly get an overview how a social media account is used and
          to drill deep into the data through cross-filtering. Users can inspect various aspects of the account. For example, when and what
          type of content they publish, what accounts they interact with, and what websites they share. It works without any machine
          learning or AI magic. Instead, it presents the data in a way that enables people to interpret it. As a result, everything is
          understandable and reproducible.
        </ContentPageParagraph>
      </ContentPageSection>

      <ContentPageSection>
        <ContentPageParagraph>
          <ContentPageSectionHeader>Team</ContentPageSectionHeader>
          {envConfig.appName} was created by <ContentPageParagraphLink url="https://twitter.com/Luca">Luca Hammer</ContentPageParagraphLink>{' '}
          and <ContentPageParagraphLink url="https://twitter.com/mmkaradeniz">Max Karadeniz</ContentPageParagraphLink>.
        </ContentPageParagraph>
      </ContentPageSection>

      <ContentPageSection>
        <ContentPageSectionHeader>Some history</ContentPageSectionHeader>
        <ContentPageParagraph>
          {envConfig.appName} started as a weekend project by Luca Hammer in{' '}
          <ContentPageParagraphLink url="https://lucahammer.com/2017/09/28/i-built-a-tool-to-analyze-twitter-accounts/">
            2017
          </ContentPageParagraphLink>
          . In his work as a Social Media Analyst he evaluates many Twitter accounts every day. For example how automated they are (if they
          could be described as bots). Doing that manually takes a lot of time and is imprecise. Other tools to identify bots exist. But
          they are based on machine learning and produce too many false positives and false negatives. To make his work easier, Luca created
          the first version of {envConfig.appName}. It had many rough corners but worked good enough. To help others with similar tasks,
          Luca made the tool available online. 30.000 users and numerous requests for additional features later. Together with Max Karadeniz he rebuilt the service from scratch with modern web technologies to make it usable
          for even more people.
        </ContentPageParagraph>
      </ContentPageSection>
    </ContentPageLayout>
  );
};

export default About;
