import React from 'react';

import AboutContainer from '../../containers/About';
import AppContainer from '../../containers/App';

type Props = {};

const AboutPage = (_props: Props) => {
  return (
    <AppContainer>
      <AboutContainer />
    </AppContainer>
  );
};

export default AboutPage;
