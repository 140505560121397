import RethrownError from '../../../../utils/misc/RethrownError';
import axios from '../../../../utils/network/axios';
import logger from '../../../../utils/misc/logger';
import routesApi from '../../../../config/routing/routesApi';

// Types
import { TwitterNormalizedUser } from '../../../../types/twitter';

const fetchUser = async (username: string): Promise<TwitterNormalizedUser | null> => {
  try {
    const response = await axios.get<TwitterNormalizedUser>(routesApi.twitter.fetchUser, { params: { username } });

    if (!response.data) {
      return null;
    }

    return response.data;
  } catch (err) {
    if (err.response.status === 404) {
      return null;
    }

    logger.error(err);

    throw new RethrownError(err, 'fetchUser.ts');
  }
};

export default fetchUser;
