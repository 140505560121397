import React from 'react';

import AppContainer from '../../containers/App';
import HomeContainer from '../../containers/Home';

type Props = {};

const HomePage = (_props: Props) => {
  return (
    <AppContainer>
      <HomeContainer />
    </AppContainer>
  );
};

export default HomePage;
