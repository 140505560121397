/* eslint-disable react/button-has-type */
import React from 'react';

import cx from '../../utils/styling/cx';

// Types
import { ButtonHTMLAttributes } from 'react';

type Props = {
  isDisabled?: boolean;
  onClick: () => void;
  size?: 'sm' | 'md';
} & ButtonHTMLAttributes<HTMLButtonElement>;

const Button = ({ children, className, isDisabled, onClick, size = 'md', type = 'button', ...rest }: Props) => {
  const isMedium = size === 'md';
  const isSmall = size === 'sm';

  return (
    <button
      className={cx(
        {
          'cursor-not-allowed': isDisabled,
          'hover:bg-brand-dark': !isDisabled,
          'opacity-50': isDisabled,
          'px-2': isSmall,
          'px-4': isMedium,
          'py-1': isSmall,
          'py-2': isMedium,
          'text-sm': isSmall,
        },
        'bg-brand text-gray-100 rounded',
        className,
      )}
      onClick={!isDisabled ? onClick : undefined}
      type={type}
      {...rest}
    >
      {children}
    </button>
  );
};

export default Button;
