import React from 'react';

import Link from '../../Link';
import cx from '../../../utils/styling/cx';

// Types
import { HTMLAttributes, ReactNode } from 'react';

type Props = {
  children: ReactNode;
  params?: { [key: string]: string | number };
  target?: string;
  url?: string | null;
} & HTMLAttributes<HTMLAnchorElement>;

const ContentPageParagraphLink = ({ children, className, ...rest }: Props) => {
  const classes = cx('text-brand hover:text-brand-dark', className);

  return (
    <Link className={classes} {...rest}>
      {children}
    </Link>
  );
};

export default ContentPageParagraphLink;
