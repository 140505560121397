import RethrownError from '../../../../utils/misc/RethrownError';
import axios from '../../../../utils/network/axios';
import logger from '../../../../utils/misc/logger';
import routesApi from '../../../../config/routing/routesApi';
import normalizeTweet from './normalizeTweet';

// Types
import { TwitterMinimizedTweet } from '../../../../types/twitter';
import { TwitterNormalizedTweet } from '../../../../types/twitter';

type FetchTweetsInput = {
  maxId: string | null;
  username: string;
};

const notNullOrUndefined = <T>(input: T | null | undefined): input is T => {
  // eslint-disable-next-line no-eq-null
  return input != null && input !== null && input !== undefined;
};

const fetchTweets = async ({ maxId, username }: FetchTweetsInput): Promise<TwitterNormalizedTweet[]> => {
  try {
    const response = await axios.get<TwitterMinimizedTweet[]>(routesApi.twitter.fetchTweets, { params: { maxId, username } });
    const normalizedTweets = response.data.map(normalizeTweet).filter(notNullOrUndefined);

    return normalizedTweets;
  } catch (err) {
    logger.error(err);

    throw new RethrownError(err, 'fetchTweets.ts');
  }
};

export default fetchTweets;
