import React from 'react';
import { Helmet } from 'react-helmet';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import DailyRhythmChart from '../Charts/DailyRhythmChart';
import DataCountContainer from '../Charts/DataCount/DataCountContainer';
import DayOfWeekChart from '../Charts/DayOfWeekChart';
import LastTweetsGridContainer from '../Charts/LastTweetsGrid';
import QuotedUserChart from '../Charts/QuotedUserChart';
import RepliedUserChart from '../Charts/RepliedUserChart';
import RetweetedUserChart from '../Charts/RetweetedUserChart';
import TweetTypeChart from '../Charts/TweetTypeChart';
import TwitterAccount from '../../components/TwitterAccount';
import TwitterAccountHeaderContainer from './TwitterAccountHeader';
import TwitterUserCardContainer from '../TwitterUserCard';
import UsedAppChart from '../Charts/UsedAppChart';
import UsedHashtagsChart from '../Charts/UsedHashtagsChart';
import UsedHostnamesChart from '../Charts/UsedHostnamesChart';
import UsedLanguageChart from '../Charts/UsedLanguageChart';
import VolumeByDateChart from '../Charts/VolumeByDateChart';
import envConfig from '../../config/env';
import useLocalStorage from '../../utils/hooks/useLocalStorage';
import { useTwitterCrossfilterContext } from '../../utils/context/TwitterCrossfilterContext';
import { useTwitterStore } from '../../data/models/twitter/TwitterStore';

// Types
import { RouteComponentProps } from 'react-router-dom';

type Props = {
  username: string;
} & RouteComponentProps;

const TwitterAccountContainer = ({ username }: Props) => {
  const twitterStore = useTwitterStore();
  const { crossfilterInstance, fetchInitialTweets, removeAllTweets } = useTwitterCrossfilterContext();

  const [isHelpVisible, setIsHelpVisible] = useLocalStorage(envConfig.localStorageKeyHelpMode, false);

  const areAxisVisible = twitterStore.userTweetsCount > 0;
  const isLastTweetsGridVisible = twitterStore.tweets.length > 0;

  const handleToggleIsHelpVisible = () => {
    setIsHelpVisible(prevState => !prevState);
  };

  React.useEffect(() => {
    twitterStore.setUsername(username);

    fetchInitialTweets(crossfilterInstance);

    twitterStore.fetchUser();

    return () => {
      removeAllTweets(crossfilterInstance);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [username]);

  return (
    <>
      <Helmet>
        <title>{`Analysis of @${username}`}</title>
      </Helmet>

      <TwitterAccountHeaderContainer />

      <TwitterAccount
        areAxisVisible={areAxisVisible}
        dailyRhythmChart={<DailyRhythmChart crossfilterInstance={crossfilterInstance} twitterStore={twitterStore} />}
        dataCount={
          <DataCountContainer
            crossfilterInstance={crossfilterInstance}
            isHelpVisible={isHelpVisible}
            onToggleHelp={handleToggleIsHelpVisible}
          />
        }
        dayOfWeekChart={<DayOfWeekChart crossfilterInstance={crossfilterInstance} twitterStore={twitterStore} />}
        isHelpVisible={isHelpVisible}
        isUserNotFound={twitterStore.userNotFound}
        lastTweetsGrid={
          isLastTweetsGridVisible && <LastTweetsGridContainer crossfilterInstance={crossfilterInstance} twitterStore={twitterStore} />
        }
        quotedUserChart={<QuotedUserChart crossfilterInstance={crossfilterInstance} twitterStore={twitterStore} />}
        repliedUserChart={<RepliedUserChart crossfilterInstance={crossfilterInstance} twitterStore={twitterStore} />}
        retweetedUserChart={<RetweetedUserChart crossfilterInstance={crossfilterInstance} twitterStore={twitterStore} />}
        title={`Analysis of @${username}`}
        tweetTypeChart={<TweetTypeChart crossfilterInstance={crossfilterInstance} twitterStore={twitterStore} />}
        twitterUserCard={<TwitterUserCardContainer twitterStore={twitterStore} />}
        usedAppChart={<UsedAppChart crossfilterInstance={crossfilterInstance} twitterStore={twitterStore} />}
        usedHashtagsChart={<UsedHashtagsChart crossfilterInstance={crossfilterInstance} twitterStore={twitterStore} />}
        usedHostnamesChart={<UsedHostnamesChart crossfilterInstance={crossfilterInstance} twitterStore={twitterStore} />}
        usedLanguageChart={<UsedLanguageChart crossfilterInstance={crossfilterInstance} twitterStore={twitterStore} />}
        volumeByDateChart={<VolumeByDateChart crossfilterInstance={crossfilterInstance} twitterStore={twitterStore} />}
      />
    </>
  );
};

export default withRouter(observer(TwitterAccountContainer));
