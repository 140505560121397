import React from 'react';
import Helmet from 'react-helmet';

import Plans from '../../components/Plans';
import SubscribeButtonContainer from './SubscribeButton';

type Props = {};

const PlansContainer = (_props: Props) => {
  const subscribeButton = <SubscribeButtonContainer planId="pro-v1" price="15€/month" />;

  return (
    <>
      <Helmet>
        <title>Plans</title>
      </Helmet>

      <Plans subscribeButton={subscribeButton} />
    </>
  );
};

export default PlansContainer;
