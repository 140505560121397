import React from 'react';
import Portal from '@reach/portal';
import { useTooltip, TooltipPopup } from '@reach/tooltip';

import { centered } from './utils';

// Types
import { ReactNode } from 'react';

type Props = {
  ariaLabel?: string;
  children: ReactNode;
  isDisabled?: boolean;
  label: string;
};

// Source: https://ui.reach.tech/tooltip/
const Tooltip = ({ ariaLabel, children, isDisabled = false, label }: Props) => {
  const [trigger, tooltip] = useTooltip();

  const { isVisible, triggerRect } = tooltip;

  if (!React.isValidElement(children)) {
    return null;
  }

  return (
    <>
      {React.cloneElement(children, trigger)}

      {!isDisabled && isVisible && (
        <Portal>
          <div
            className="absolute h-0 w-0 z-50 border-b-8 border-gray-800"
            style={{
              borderLeft: '10px solid transparent',
              borderRight: '10px solid transparent',
              left: triggerRect && triggerRect.left - 10 + triggerRect.width / 2,
              top: triggerRect && triggerRect.bottom + window.scrollY,
            }}
          />
        </Portal>
      )}

      {!isDisabled && <TooltipPopup {...tooltip} ariaLabel={ariaLabel} label={label} position={centered as any} />}
    </>
  );
};

export default Tooltip;
