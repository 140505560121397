import React from 'react';
import cx from 'classnames';

import Section from '../../Heading/Section';

// Types
import { HTMLAttributes, ReactNode } from 'react';

type Props = {
  children: ReactNode;
} & HTMLAttributes<HTMLDivElement>;

const ContentPageSection = ({ children, className, ...rest }: Props) => {
  const classes = cx('mb-4', className);

  return (
    <Section className={classes} {...rest}>
      {children}
    </Section>
  );
};

export default ContentPageSection;
