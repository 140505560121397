import React from 'react';
import Helmet from 'react-helmet';

import TermsOfService from '../../components/TermsOfService';

type Props = {};

const TermsOfServiceContainer = (_props: Props) => {
  return (
    <>
      <Helmet>
        <title>Terms of Service</title>
      </Helmet>

      <TermsOfService />
    </>
  );
};

export default TermsOfServiceContainer;
