// For more information see: https://tailwindcss.com/docs/configuration

const config = {
  plugins: [],
  theme: {
    extend: {
      colors: {
        brand: {
          default: 'hsl(205, 89%, 54%)',
          dark: 'hsl(205, 89%, 29%)',
          light: 'hsl(205, 89%, 74%)',
          'light-extra': 'hsl(205, 89%, 94%)',
        },
        twitter: 'hsl(203, 89%, 53%)',
      },
      inset: {
        '1/2': '50%',
        '45': '45%',
      },
      maxWidth: {
        '2xs': '15rem',
        '3xs': '10rem',
      },
      minWidth: {
        '3xs': '10rem',
        '2xs': '15rem',
      },
    },
    screens: {
      sm: '640px',
      md: '768px',
      lg: '1024px',
      xl: '1380px',
    },
  },
  variants: {
    textColor: ['focus-within', 'group-hover', 'hover'],
  },
};

module.exports = config;
