import React from 'react';

import LastTweetsGridTweet from '../../../../components/LastTweetsGrid/LastTweetsGridTweet';

// Types
import { TwitterNormalizedTweet } from '../../../../types/twitter';

type Props = {
  tweet: TwitterNormalizedTweet;
};

const LastTweetsGridTweetContainer = ({ tweet }: Props) => {
  const [isRenderingOriginalTweet, setIsRenderingOriginalTweet] = React.useState<boolean>(false);

  const handleRenderOriginalTweet = () => {
    setIsRenderingOriginalTweet(true);
  };

  return (
    <LastTweetsGridTweet
      id={tweet.id}
      isRenderingOriginalTweet={isRenderingOriginalTweet}
      key={tweet.id}
      name={tweet.name}
      onRenderOriginalTweet={handleRenderOriginalTweet}
      text={tweet.text}
      timestamp={tweet.timestamp}
      type={tweet.type}
      username={tweet.username}
    />
  );
};

export default LastTweetsGridTweetContainer;
