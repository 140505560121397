import React from 'react';
import ContentLoader from 'react-content-loader';

import envConfig from '../../../config/env';
import tailwind from '../../../config/tailwind';

const RowChartTypeBContentLoader = () => (
  <ContentLoader
    ariaLabel="Loading Chart"
    height={425}
    primaryColor={tailwind.theme.colors.brand.light}
    secondaryColor={tailwind.theme.colors.brand['light-extra']}
    speed={envConfig.twitterChartsContentLoaderSpeed}
    width={650}
  >
    <rect x="20" y="2" rx="2" ry="2" width="349" height="30" />
    <rect x="20" y="33" rx="2" ry="2" width="175" height="30" />
    <rect x="20" y="64" rx="2" ry="2" width="175" height="30" />
    <rect x="20" y="95" rx="2" ry="2" width="88" height="30" />
    <rect x="20" y="126" rx="2" ry="2" width="88" height="30" />
    <rect x="20" y="157" rx="2" ry="2" width="88" height="30" />
    <rect x="20" y="188" rx="2" ry="2" width="88" height="30" />
    <rect x="20" y="219" rx="2" ry="2" width="88" height="30" />
    <rect x="20" y="250" rx="2" ry="2" width="88" height="30" />
    <rect x="20" y="281" rx="2" ry="2" width="88" height="30" />
    <rect x="20" y="312" rx="2" ry="2" width="610" height="30" />
  </ContentLoader>
);

export default RowChartTypeBContentLoader;
