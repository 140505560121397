import React from 'react';
import ContentLoader from 'react-content-loader';

import envConfig from '../../../config/env';
import tailwind from '../../../config/tailwind';

const DailyRhythmChartContentLoader = () => (
  <ContentLoader
    ariaLabel="Loading Chart"
    height={266}
    primaryColor={tailwind.theme.colors.brand.light}
    secondaryColor={tailwind.theme.colors.brand['light-extra']}
    speed={envConfig.twitterChartsContentLoaderSpeed}
    width={866}
  >
    <rect x="47" y="31" rx="0" ry="0" width="32" height="29" />
    <rect x="47" y="89" rx="0" ry="0" width="32" height="29" />
    <rect x="47" y="118" rx="0" ry="0" width="32" height="29" />
    <rect x="47" y="147" rx="0" ry="0" width="32" height="29" />

    <rect x="79" y="2" rx="0" ry="0" width="32" height="29" />
    <rect x="79" y="31" rx="0" ry="0" width="32" height="29" />

    <rect x="79" y="176" rx="0" ry="0" width="32" height="29" />

    <rect x="271" y="147" rx="0" ry="0" width="32" height="29" />

    <rect x="303" y="89" rx="0" ry="0" width="32" height="29" />
    <rect x="303" y="118" rx="0" ry="0" width="32" height="29" />
    <rect x="303" y="147" rx="0" ry="0" width="32" height="29" />

    <rect x="335" y="60" rx="0" ry="0" width="32" height="29" />
    <rect x="335" y="89" rx="0" ry="0" width="32" height="29" />
    <rect x="335" y="118" rx="0" ry="0" width="32" height="29" />
    <rect x="335" y="147" rx="0" ry="0" width="32" height="29" />
    <rect x="335" y="176" rx="0" ry="0" width="32" height="29" />

    <rect x="367" y="2" rx="0" ry="0" width="32" height="29" />
    <rect x="367" y="89" rx="0" ry="0" width="32" height="29" />
    <rect x="367" y="118" rx="0" ry="0" width="32" height="29" />
    <rect x="367" y="147" rx="0" ry="0" width="32" height="29" />

    <rect x="399" y="2" rx="0" ry="0" width="32" height="29" />
    <rect x="399" y="31" rx="0" ry="0" width="32" height="29" />
    <rect x="399" y="118" rx="0" ry="0" width="32" height="29" />
    <rect x="399" y="147" rx="0" ry="0" width="32" height="29" />

    <rect x="431" y="2" rx="0" ry="0" width="32" height="29" />
    <rect x="431" y="31" rx="0" ry="0" width="32" height="29" />
    <rect x="431" y="60" rx="0" ry="0" width="32" height="29" />
    <rect x="431" y="89" rx="0" ry="0" width="32" height="29" />
    <rect x="431" y="147" rx="0" ry="0" width="32" height="29" />

    <rect x="463" y="2" rx="0" ry="0" width="32" height="29" />
    <rect x="463" y="31" rx="0" ry="0" width="32" height="29" />
    <rect x="463" y="89" rx="0" ry="0" width="32" height="29" />
    <rect x="463" y="147" rx="0" ry="0" width="32" height="29" />

    <rect x="495" y="2" rx="0" ry="0" width="32" height="29" />
    <rect x="495" y="60" rx="0" ry="0" width="32" height="29" />
    <rect x="495" y="118" rx="0" ry="0" width="32" height="29" />
    <rect x="495" y="147" rx="0" ry="0" width="32" height="29" />
    <rect x="495" y="176" rx="0" ry="0" width="32" height="29" />

    <rect x="527" y="2" rx="0" ry="0" width="32" height="29" />
    <rect x="527" y="31" rx="0" ry="0" width="32" height="29" />
    <rect x="527" y="60" rx="0" ry="0" width="32" height="29" />
    <rect x="527" y="118" rx="0" ry="0" width="32" height="29" />
    <rect x="527" y="147" rx="0" ry="0" width="32" height="29" />
    <rect x="527" y="176" rx="0" ry="0" width="32" height="29" />

    <rect x="559" y="2" rx="0" ry="0" width="32" height="29" />
    <rect x="559" y="60" rx="0" ry="0" width="32" height="29" />
    <rect x="559" y="89" rx="0" ry="0" width="32" height="29" />

    <rect x="591" y="2" rx="0" ry="0" width="32" height="29" />
    <rect x="591" y="31" rx="0" ry="0" width="32" height="29" />
    <rect x="591" y="60" rx="0" ry="0" width="32" height="29" />
    <rect x="591" y="118" rx="0" ry="0" width="32" height="29" />
    <rect x="591" y="176" rx="0" ry="0" width="32" height="29" />

    <rect x="623" y="2" rx="0" ry="0" width="32" height="29" />
    <rect x="623" y="60" rx="0" ry="0" width="32" height="29" />
    <rect x="623" y="89" rx="0" ry="0" width="32" height="29" />
    <rect x="623" y="176" rx="0" ry="0" width="32" height="29" />

    <rect x="655" y="31" rx="0" ry="0" width="32" height="29" />
    <rect x="655" y="60" rx="0" ry="0" width="32" height="29" />
    <rect x="655" y="89" rx="0" ry="0" width="32" height="29" />
    <rect x="655" y="147" rx="0" ry="0" width="32" height="29" />

    <rect x="687" y="2" rx="0" ry="0" width="32" height="29" />
    <rect x="687" y="147" rx="0" ry="0" width="32" height="29" />

    <rect x="719" y="2" rx="0" ry="0" width="32" height="29" />
    <rect x="719" y="147" rx="0" ry="0" width="32" height="29" />

    <rect x="751" y="89" rx="0" ry="0" width="32" height="29" />
    <rect x="751" y="118" rx="0" ry="0" width="32" height="29" />
    <rect x="751" y="147" rx="0" ry="0" width="32" height="29" />
    <rect x="751" y="176" rx="0" ry="0" width="32" height="29" />

    <rect x="783" y="60" rx="0" ry="0" width="32" height="29" />
    <rect x="783" y="89" rx="0" ry="0" width="32" height="29" />
    <rect x="783" y="118" rx="0" ry="0" width="32" height="29" />
    <rect x="783" y="147" rx="0" ry="0" width="32" height="29" />
    <rect x="783" y="176" rx="0" ry="0" width="32" height="29" />
  </ContentLoader>
);

export default DailyRhythmChartContentLoader;
