import React from 'react';

type Props = {
  description: string | null;
};

const TwitterUserCardDescription = ({ description }: Props) => {
  if (!description) {
    return null;
  }

  return (
    <div>
      <p className="break-words leading-tight" dangerouslySetInnerHTML={{ __html: description }} />
    </div>
  );
};

export default TwitterUserCardDescription;
