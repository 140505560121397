import React from 'react';
import { withRouter } from 'react-router-dom';

import AppContainer from '../../containers/App';
import TwitterAccountContainer from '../../containers/TwitterAccount';

// Types
import { RouteComponentProps } from 'react-router-dom';

type Params = {
  username: string;
};

type Props = {} & RouteComponentProps<Params>;

const TwitterAccountPage = ({ match }: Props) => {
  const username = match.params.username;

  return (
    <AppContainer isNotRenderingHeader={true}>
      <TwitterAccountContainer key={username} username={username} />
    </AppContainer>
  );
};

export default withRouter(TwitterAccountPage);
