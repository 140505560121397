import React from 'react';

import HeadingContext from '../../utils/context/HeadingContext';

// Types
import { CSSProperties, HTMLAttributes } from 'react';

type Props = {
  isVisuallyHidden?: boolean;
} & HTMLAttributes<HTMLHeadingElement>;

const Heading = ({ isVisuallyHidden = false, style = {}, ...rest }: Props) => {
  const visuallyHiddenStyle: CSSProperties = { ...style, left: -9999, position: 'absolute', top: -9999 };

  return (
    <HeadingContext.Consumer>
      {level => {
        const HeadingTag = `h${Math.min(level, 6)}` as 'h1';

        return <HeadingTag style={!isVisuallyHidden ? style : visuallyHiddenStyle} {...rest} />;
      }}
    </HeadingContext.Consumer>
  );
};

export default Heading;
