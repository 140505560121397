enum LogLevel {
  DEBUG = 'debug',
  ERROR = 'error',
  INFO = 'info',
  WARN = 'warn',
}

const logFunction = (level: LogLevel, ...args: any[]): void => {
  // eslint-disable-next-line no-console
  return console[level](...args);
};

const logObject = {
  debug: (...args: any[]) => logFunction(LogLevel.DEBUG, ...args),
  info: (...args: any[]) => logFunction(LogLevel.INFO, ...args),
  warn: (...args: any[]) => logFunction(LogLevel.WARN, ...args),
  error: (...args: any[]) => logFunction(LogLevel.ERROR, ...args),
};

const logger = Object.assign(logFunction, logObject);

export default logger;
