import React from 'react';
import crossfilter from 'crossfilter2';
import dc from 'dc';

import storeSnapshot from '../../data/storeSnapshot';
import { useTwitterStreamStore } from '../../data/models/twitter/TwitterStreamStore';

// Types
import { Crossfilter } from 'crossfilter2';
import { ReactNode } from 'react';
import { TwitterNormalizedTweet } from '../../types/twitter';

const initialValue = {
  crossfilterInstance: crossfilter<TwitterNormalizedTweet>([]),
  crossfilterMockInstance: crossfilter<TwitterNormalizedTweet>([]),
};

type TwitterCrossFilterContextValue = {
  crossfilterInstance: Crossfilter<TwitterNormalizedTweet>;
  crossfilterMockInstance: Crossfilter<TwitterNormalizedTweet>;
};

const TwitterStreamingCrossfilterContext = React.createContext<TwitterCrossFilterContextValue>(initialValue);

type Props = {
  children: ReactNode;
  data: TwitterNormalizedTweet[];
};

export const TwitterStreamingCrossfilterContextProvider = ({ children, data }: Props) => {
  const crossfilterInstance = crossfilter(data);
  const crossfilterMockInstance = crossfilter(storeSnapshot.tweets as TwitterNormalizedTweet[]);

  const value = { crossfilterInstance, crossfilterMockInstance };

  return <TwitterStreamingCrossfilterContext.Provider value={value}>{children}</TwitterStreamingCrossfilterContext.Provider>;
};

export const useTwitterStreamingCrossfilterContext = () => {
  const { crossfilterInstance, crossfilterMockInstance } = React.useContext(TwitterStreamingCrossfilterContext);

  const twitterStore = useTwitterStreamStore();

  const removeAllTweets = () => {
    crossfilterInstance.remove(() => true);
    twitterStore.resetStore();

    dc.redrawAll();
  };

  const resetAllFilters = () => {
    dc.filterAll();

    dc.redrawAll();
  };

  return { crossfilterInstance, crossfilterMockInstance, removeAllTweets, resetAllFilters };
};

export default TwitterStreamingCrossfilterContext;
