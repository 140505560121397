import React from 'react';

import ContentPageHeader from '../ContentPageItems/ContentPageHeader';
import ContentPageLayout from '../ContentPageItems/ContentPageLayout';
import ContentPageSection from '../ContentPageItems/ContentPageSection';
import PlansCardFree from './PlansCardFree';
import PlansCardProV1 from './PlansCardProV1';

// Types
import { ReactNode } from 'react';

type Props = {
  subscribeButton: ReactNode;
};

const Plans = ({ subscribeButton }: Props) => {
  return (
    <ContentPageLayout>
      <ContentPageHeader>Plans</ContentPageHeader>

      <ContentPageSection>
        <div className="my-8 flex flex-wrap md:items-center w-full">
          <PlansCardFree className="w-full lg:w-1/3" />

          <PlansCardProV1 className="w-full lg:w-1/3" subscribeButton={subscribeButton} />
        </div>
      </ContentPageSection>
    </ContentPageLayout>
  );
};

export default Plans;
