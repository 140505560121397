import React from 'react';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import Icon from '../../Icon';
import Infobox from '../../Infobox';
import Link from '../../Link';
import cx from '../../../utils/styling/cx';

// Types
import { HTMLAttributes } from 'react';

type Props = {
  level?: 'error' | 'info' | 'warning';
  onClose: () => void;
} & HTMLAttributes<HTMLDivElement>;

const GlobalMessageError = ({ className, onClose, ...rest }: Props) => {
  return (
    <Infobox
      className={cx('flex-col absolute inset-x-0 mx-auto z-50 max-w-xl relative', className)}
      level="error"
      style={{ top: 80 }}
      {...rest}
    >
      <button onClick={onClose} type="button">
        <Icon className="absolute right-0 mr-2 top-0 mt-2 fill-current hover:text-gray-800 cursor-pointer" icon={faTimes} />
      </button>

      <p className="text-3xl font-bold leading-none">Something went terribly wrong.</p>

      <p className="mt-2">
        This sucks. An error report has been saved. If this keeps happening or you want to make sure I get the message, drop an email to{' '}
        <Link className="hover:text-red-600" url="mailto:accountanalysis@lucahammer.com">
          accountanalysis@lucahammer.com
        </Link>
        .
      </p>
    </Infobox>
  );
};

export default GlobalMessageError;
