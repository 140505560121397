import React from 'react';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import Header from '../../components/Header';
import isRoute from '../../utils/routing/isRoute';
import routes from '../../config/routing/routes';
import { useAuthStore } from '../../data/models/auth/AuthStore';
import { useTwitterStore } from '../../data/models/twitter/TwitterStore';

// Types
import { RouteComponentProps } from 'react-router-dom';
import { SyntheticEvent } from 'react';

type Props = {} & RouteComponentProps;

const HeaderContainer = ({ history, match }: Props) => {
  const { isSignedIn, isSubscribed, userFetched } = useAuthStore();
  const { username } = useTwitterStore();

  const [nextUsername, setNextUsername] = React.useState<string>('');

  const isAccountRoute = isRoute(match, routes.account);
  const isTwitterRoute = isRoute(match, routes.withParams.twitterAccount);

  React.useEffect(() => {
    setNextUsername(username || '');
  }, [username]);

  const handleAnalyzeClick = (event: SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();

    history.push(`${routes.twitterAccount}/${nextUsername}`);
  };

  const handleUsernameChange = (event: SyntheticEvent<HTMLInputElement>) => {
    setNextUsername(event.currentTarget.value);
  };

  return (
    <Header
      handleAnalyzeClick={handleAnalyzeClick}
      handleUsernameChange={handleUsernameChange}
      isAccountRoute={isAccountRoute}
      isLoadingUser={!userFetched}
      isSignedIn={isSignedIn}
      isSubscribed={isSubscribed}
      isTwitterRoute={isTwitterRoute}
      username={nextUsername}
    />
  );
};

export default withRouter(observer(HeaderContainer));
