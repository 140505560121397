import React from 'react';

import cx from '../../utils/styling/cx';

import Arrow1 from './Arrow1';
import Arrow2 from './Arrow2';
import Arrow3 from './Arrow3';

// Types
import { HTMLAttributes } from 'react';
import { getStyle } from './utils';

type Props = {
  direction: 'down' | 'up';
  number: 1 | 2 | 3;
  side: 'left' | 'right';
  size: 'mobile' | 'desktop';
} & HTMLAttributes<SVGElement>;

const Arrow = ({ direction, number, side, size, ...rest }: Props) => {
  const className = cx('fill-current w-16', {
    'ml-16': side === 'left' && size === 'desktop',
    'ml-4 sm:ml-8 md:ml-16': side === 'left' && size === 'mobile',
    'mr-16': side === 'right' && size === 'desktop',
    'mr-4 sm:mr-8 md:mr-16': side === 'right' && size === 'mobile',
  });

  const style = getStyle({ direction, side });

  switch (number) {
    case 1:
      return <Arrow1 {...rest} className={className} style={style} />;

    case 2:
      return <Arrow2 {...rest} className={className} style={style} />;

    case 3:
      return <Arrow3 {...rest} className={className} style={style} />;
  }
};

export default Arrow;
